export const SHOW_NUMPAD = "SHOW_NUMPAD";
export const SAVE_VALUE = "SAVE_VALUE";
export const HIDE_MODAL = "HIDE_MODAL";
export const EDIT_VALUE = 'EDIT_VALUE';
export const REMOVE_VALUE = 'REMOVE_VALUE';
export const TOGGLE_INPUT_SELECTED = "TOGGLE_INPUT_SELECTED";
export const TOGGLE_OPTION_SELECTED = "TOGGLE_OPTION_SELECTED";
export const RESET_OPTIONS_SELECTED = 'RESET_OPTIONS_SELECTED';

export const UPDATE_OTHER_OPTION_METRIC = "UPDATE_OTHER_OPTION_METRIC";

export const UPDATE_FIELD = 'UPDATE_FIELD';
export const SET_STATE = 'SET_STATE';
export const ADD_METRIC = 'ADD_METRIC';
export const UPDATE_METRICS = "UPDATE_METRICS";

export const RESET_ALL_METRICS = "RESET_ALL_METRICS";


export const SET_CURRENT_LEAD_DATA = "SET_CURRENT_LEAD_DATA";