import React, { useState } from "react";
import { Form } from "react-bootstrap";
import validator from "validator";

export default function QTYInput({
  disabled = false,
  style,
  onChange,
  hasChanged = false,
  value,
  ...rest
}) {
  const [err, setErr] = useState(false);
  const onBlur = () => {
    if (value) {
      return validator.isNumeric(value.toString())
        ? setErr(false)
        : setErr(true);
    }
  };
  return (
    <Form.Group>
      <Form.Control
        disabled={disabled}
        type="number"
        value={value}
        onChange={onChange}
        style={
          style
            ? hasChanged
              ? { textAlign: "center", ...style, backgroundColor: "#f6d46f" }
              : { textAlign: "center", ...style }
            : hasChanged
            ? { backgroundColor: "#f6d46f", textAlign: "center" }
            : { textAlign: "center" }
        }
        {...rest}
        onBlur={onBlur}
        isInvalid={err}
        // style={
        //   styless
        //     ? {
        //         backgroundColor: "#f6d46f",
        //       }
        //     : {}
        // }
      />
    </Form.Group>
  );
}
