import { axiosInstance } from "services";
import { repairsActionTypes } from "./repairs.types";
import { getData, saveData } from "services/storageManager";
import { ApiEndpoints } from "lib/config/baseSettings";


export const fetchTask = () => async (dispatch) => {
  dispatch({ type: repairsActionTypes.TASK_START });

  try {
    const response = await axiosInstance.get(ApiEndpoints.TASK.FETCH_TASK);
    const data = response.data;

    if (data.success) {
      dispatch({
        type: repairsActionTypes.TASK_SUCCESS,
        payload: data,
      });
    } else {
      dispatch({
        type: repairsActionTypes.TASK_FAILURE,
        payload: data.error,
      });
    }
  } catch (error) {
    dispatch({
      type: repairsActionTypes.TASK_FAILURE,
      payload: error.message,
    });
  }
};


export const addTask = (taskData) => async (dispatch) => {
  dispatch({ type: repairsActionTypes.ADD_TASK_START });

  try {
    const response = await axiosInstance.post(ApiEndpoints.TASK.ADD_TASK, taskData);
    const data = response.data;

    if (data.success) {
      return dispatch({
        type: repairsActionTypes.ADD_TASK_SUCCESS,
        payload: { _id: data.result._id, ...taskData },
        message: "Successfully added a task.",
      });
    } else {
      return dispatch({
        type: repairsActionTypes.ADD_TASK_FAILURE,
        payload: data.error,
      });
    }
  } catch (errors) {
    return dispatch({
      type: repairsActionTypes.ADD_TASK_FAILURE,
      payload: errors.message,
    });
  }
};


export const updateTask = (data, dataId) => async (dispatch) => {
  dispatch({ type: repairsActionTypes.EDIT_TASK_START });

  try {
    const response = await axiosInstance.post(ApiEndpoints.TASK.EDIT_TASK, { ...data });
    const resData = response.data;

    if (resData.success) {
      dispatch(fetchTask());
      dispatch({
        type: repairsActionTypes.EDIT_TASK_SUCCESS,
        payload: data,
        message: resData.result.message,
        dataId,
      });
    } else {
      dispatch({
        type: repairsActionTypes.EDIT_TASK_FAILURE,
        payload: resData.error,
      });
    }
  } catch (error) {
    dispatch({
      type: repairsActionTypes.EDIT_TASK_FAILURE,
      payload: error.message,
    });
  }
};

export const getRepairRequest = (data) => async (dispatch) => {
  dispatch({ type: repairsActionTypes.GET_REPAIR_REQUEST_START });

  if (!navigator.onLine) {
    try {
      let stored_data = getData(`repairMetrics${data.requestId}`);

      const changesMetricsString = getData(`changesRepair${data.requestId}`);
      if (changesMetricsString !== null) {
        stored_data.data = changesMetricsString;
      }

      dispatch({
        type: repairsActionTypes.GET_REPAIR_REQUEST_SUCCESS,
        payload: { success: true, result: stored_data.data },
      });
    } catch (error) {
      console.log("storage error", error);
    }
  } else {
    try {
      const response = await axiosInstance.post(ApiEndpoints.REPAIR_REQUEST.GET_REPAIR_REQUEST, { ...data });
      const resData = response.data;

      if (resData.success) {
        dispatch({
          type: repairsActionTypes.GET_REPAIR_REQUEST_SUCCESS,
          payload: resData,
        });
      } else {
        dispatch({
          type: repairsActionTypes.GET_REPAIR_REQUEST_FAILURE,
          payload: resData.error,
        });
      }
    } catch (error) {
      dispatch({
        type: repairsActionTypes.GET_REPAIR_REQUEST_FAILURE,
        payload: error.message,
      });
    }
  }
};

export const updateRepairRequest = (data) => async (dispatch) => {
  dispatch({ type: repairsActionTypes.UPDATE_REPAIR_REQUEST_START });

  if (!navigator.onLine) {
    try {
      const metricData = { ...data };
      saveData(`changesRepair${data.requestId}`, metricData);

      const successPayload = {
        success: true,
        result: { message: "Service request is updated successfully." },
      };

      return new Promise((resolve) => {
        dispatch({
          type: repairsActionTypes.UPDATE_REPAIR_REQUEST_SUCCESS,
          payload: successPayload,
        });
        resolve({ payload: successPayload });
      });
    } catch (error) {
      console.error(error);
    }
  } else {
    try {
      const response = await axiosInstance.post(ApiEndpoints.REPAIR_REQUEST.UPDATE_REPAIR_REQUEST, { ...data });
      const resData = response.data;

      if (resData.success) {
        dispatch({
          type: repairsActionTypes.UPDATE_REPAIR_REQUEST_SUCCESS,
          payload: resData,
        });
      } else {
        dispatch({
          type: repairsActionTypes.UPDATE_REPAIR_REQUEST_FAILURE,
          payload: resData.error,
        });
      }
    } catch (error) {
      dispatch({
        type: repairsActionTypes.UPDATE_REPAIR_REQUEST_FAILURE,
        payload: error.message,
      });
    }
  }
};

export const toggleEditMode = (id) => {
  return {
    type: repairsActionTypes.TOGGLE_EDIT_MODE,
    payload: id,
  };
};
