import { useSelector } from "react-redux";

import styles from "lib/config/baseStyles";
import { Col, Form, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { PriceInput } from "components";
import PercentageModal from "screens/QuotesProposals/ProposalTab/PercentageModal";
import { useDispatch } from "react-redux";
import {
  getQuoteMarkup,
  updateProposalInfo,
} from "screens/QuotesProposals/redux/proposals/proposals.actions";

const TotalQuote = ({
  totalWithoutMarkUp,
  markUp,
  markUpAmt,
  totalWithMarkUp,
  isLockQuoteData,
  maxMarkUp,
  minMarkUp,
}) => {
  const [per, setPer] = useState(markUp);
  const [percent, setPercent] = useState(per);
  const [showMarkUpModal, setShowMarkUpModal] = useState(false);
  const proposalData = useSelector((state) => state.proposal);
  const { selectedProposal } = proposalData;
  const changePer = (value) => setPer(value);
  const dispatch = useDispatch();

  const { isLoadingQuoteMarkup, quoteMarkupError, quoteMarkupResponse } =
    useSelector((state) => state.proposal);
  console.log({
    quoteMarkupResponse,
  });

  const selectedMarkup = selectedProposal?.quotes?.markupPercentage;
  const [markupPercentage, seteMarkupPercentage] = useState(0);

  // Fetch quote markup
  useEffect(() => {
    if (!quoteMarkupResponse) {
      dispatch(getQuoteMarkup());
    }
  }, [quoteMarkupResponse]);

  useEffect(() => {
    if (selectedMarkup) {
      seteMarkupPercentage(Number(selectedMarkup));
    }
  }, [selectedMarkup]);

  const onChangeMarkup = (increase = true) => {
    console.log({ increase });

    if (increase) {
      seteMarkupPercentage((prevValue) => prevValue + 1);
    } else {
      seteMarkupPercentage((prevValue) => prevValue - 1);
    }
  };

  const onMarkupModalShow = () => {
    setShowMarkUpModal(true);
  };

  const hideMarkupModal = () => {
    setShowMarkUpModal(false);
  };
  const saveMarkup = () => {
    console.log({
      markupPercentage,
    });

    const data = {
      requestId: selectedProposal?.requestId,
      // proposalId,
      proposalId: selectedProposal._id,
      materialQuoteId: selectedProposal?.quotes?._id,

      markupPercentage: markupPercentage,
      // materialQuoteId
    };
    dispatch(updateProposalInfo(data));
    hideMarkupModal();
  };

  const cancelChanges = () => {
    setPercent(per);
    hideMarkupModal();
  };

  return (
    <div style={{ padding: "1em" }}>
      <section>
        <Row>
          {true && (
            <p style={{ textAlign: "right" }}>
              Please choose tax rate for material to enable total price
              calculations
            </p>
          )}
          <Col style={styles.reduceWidthCol} lg="6" />
          <Col style={styles.reduceWidthCol} lg="6">
            <Row
              style={{
                justifyContent: "flex-end",
              }}
            >
              <Col
                style={{
                  paddingTop: "1.5em",
                  ...styles.reduceWidthCol,
                }}
                lg="9"
              />
            </Row>
            <Row style={{ marginBottom: "1%" }}>
              <Col
                style={{ ...styles.reduceWidthCol, textAlign: "right" }}
                lg="9"
              >
                <Form.Label className="mt-1">
                  Total Material & Labor:
                </Form.Label>
              </Col>
              <Col style={styles.reduceWidthCol} lg="3">
                <PriceInput
                  input="Total"
                  price={selectedProposal?.total || 0}
                  disabled
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: "1%" }}>
              <Col
                style={{ ...styles.reduceWidthCol, textAlign: "right" }}
                lg="6"
              >
                <Form.Label className="mt-1">Mark-Up:</Form.Label>
              </Col>
              <Col style={styles.reduceWidthCol} lg="3">
                <button
                  onClick={onMarkupModalShow}
                  style={{
                    textAlign: "center",
                    background: "yellow",
                    color: "#111",
                  }}
                  className="form-control"
                  disabled={isLockQuoteData?.isLocked}
                >
                  {markupPercentage}%
                </button>
                <PercentageModal
                  onHide={hideMarkupModal}
                  show={showMarkUpModal}
                  percent={markupPercentage}
                  onCancel={cancelChanges}
                  onSubmit={saveMarkup}
                  changePerData={onChangeMarkup}
                  submitText="Apply"
                  heading="Mark-Up Percentage"
                  maxMarkUp={quoteMarkupResponse?.max}
                  minMarkUp={quoteMarkupResponse?.min}
                />
              </Col>
              <Col style={styles.reduceWidthCol} lg="3">
                <PriceInput
                  input="Total"
                  // price={badStateTax ? 0 : markUpAmt}
                  price={(selectedProposal?.total / 100) * markupPercentage}
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col
                style={{ ...styles.reduceWidthCol, textAlign: "right" }}
                lg="9"
              >
                <Form.Label className="mt-1">Total Sale Price:</Form.Label>
              </Col>
              <Col style={styles.reduceWidthCol} lg="3">
                <PriceInput
                  input="Total"
                  // price={badStateTax ? 0 : totalWithMarkUp}
                  price={selectedProposal?.total || 0}
                  style={{
                    background: "#28a745",
                    color: "#fff",
                  }}
                  disabled
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </section>
    </div>
  );
};

export default TotalQuote;
