export const SHOW_NUMPAD_GUTTER = "SHOW_NUMPAD_GUTTER";
export const SAVE_VALUE_GUTTER = "SAVE_VALUE_GUTTER";
export const HIDE_MODAL_GUTTER = "HIDE_MODAL_GUTTER";
export const EDIT_VALUE_GUTTER = 'EDIT_VALUE_GUTTER';
export const REMOVE_VALUE_GUTTER = 'REMOVE_VALUE_GUTTER';
export const TOGGLE_INPUT_SELECTED_GUTTER = "TOGGLE_INPUT_SELECTED_GUTTER";
export const TOGGLE_OPTION_SELECTED_GUTTER = "TOGGLE_OPTION_SELECTED_GUTTER";
export const RESET_OPTIONS_SELECTED_GUTTER = 'RESET_OPTIONS_SELECTED_GUTTER';

export const UPDATE_OTHER_OPTION_METRIC_GUTTER = "UPDATE_OTHER_OPTION_METRIC_GUTTER";

export const UPDATE_FIELD_GUTTER = 'UPDATE_FIELD_GUTTER';
export const SET_STATE_GUTTER = 'SET_STATE_GUTTER';
export const ADD_METRIC_GUTTER = 'ADD_METRIC_GUTTER';
export const UPDATE_METRICS_GUTTER = "UPDATE_METRICS_GUTTER";

export const RESET_ALL_METRICS_GUTTER = "RESET_ALL_METRICS_GUTTER";

export const SET_CURRENT_LEAD_DATA_GUTTER = "SET_CURRENT_LEAD_DATA_GUTTER";
