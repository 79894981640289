import { envTypes } from "lib/constants/const";

const localServer = "http://localhost:4002/";
const prodServer = "https://uat-api.organizedroofer.net/";
const isUnderDevelopment = process.env.NODE_ENV === envTypes.development;

export default {
  appName: "Organised Roofers",
  baseUrl: isUnderDevelopment ? localServer : prodServer,
  dbName: "userDB",
  dbColName: "user",
  axiosTimeout: 300000,
  dbUserId: "OR:USER",
};

export const ApiEndpoints = {
  AUTOTEXT: {
    AUTO_TEXT: "/admin/autoText/", //get, patch
    STANDARD_STATEMENT: "/admin/standardStatements/",
  },
  LEAD: {
    EDIT: "lead/edit",
  },
  CALENDAR: {
    FETCH_CALENDAR: "/calendar/",
    GET_AVAILABLE_PERSONS: "/calendar/getAvailablePersons/",
    SCHEDULE_EVENT: "/calendar/schedule/",
    FETCH_EVENTS: "/calendar/getEvents/",
    UNSCHEDULE_EVENT: "/calendar/unschedule/",
  },
  DROP_DOWN: {
    GET_DROPDOWN: "/admin/ddmenu/",
    ADMIN: "/admin/", //CRUD
    QUOTE_MARKUP: "/admin/quoteMarkup",
  },
  METRICS: {
    ADD_METRIC: "/metrics",
    UPDATE_BUILDING: "/building/",
    GET_ROOF_DATA: "/metrics/roof/",
    GET_GUTTERS_DATA: "/metrics/gutter/",
    EDIT_TASK: "/task/edit",
    COMPLETE_TASK: "/task/complete",
    GET_REPAIR_REQUEST: "/activity/getRepairRequest",
    UPDATE_REPAIR_REQUEST: "/activity/updateRepairRequest",
    UPLOAD_DATA: "/files",
    ADD_METRIC_V2: "/lead_v2/metrics",
    GET_METRIC_BY_REQUEST_ID: "/lead_v2/metrics/request",
    UPDATE_METRIC_V2: "/lead_v2/metrics",
    DELETE_METRIC_V2: "/lead_v2/metrics",

    ADD_GUTTER_METRIC: "/lead_v2/gutter/metrics",
    GET_GUTTER_METRIC_BY_REQUEST_ID: "/lead_v2/gutter/metrics/request",
    UPDATE_GUTTER_METRIC_V2: "/lead_v2/gutter/metrics",
    DELETE_GUTTER_METRIC_V2: "/lead_v2/gutter/metrics",
  },
  PROPOSALS: {
    GENERATE: "/proposal_v2/generateQuote",
    REGENERATE: "/proposal_v2/re-generateQuote",
    GET_PROPOSAL: "/proposal_v2/getProposals",
    GET_ACTIVE_MATERIAL_SUPPLIERS: "/resources/material-active-suppliers",
    GET_ACTIVE_LABOUR_SUPPLIERS: "/resources/labours-active-suppliers",
    GET_ACTIVE_LABOUR_GUTTER_SUPPLIERS:
      "/resources/labours-active-suppliers-gutter",
    GET_ACTIVE_CARTING_SUPPLIERS: "/resources/dumpster-active-suppliers",
    GET_CARTING_TYPES: "/resources/carting-types",

    ADD_MISC_MATERIAL: "/proposal_v2/material-misc",
    DELETE_MISC_MATERIAL_QUOTE: "/proposal_v2/material-misc-quote",

    ADD_MISC_LABOUR: "/proposal_v2/labour-misc",
    UPDATE_LABOUR_QUOTE: "/proposal_v2/update-labour",
    DELETE_MISC_LABOUR_QUOTE: "/proposal_v2/labour-misc-quote",

    ADD_MISC_GUTTER: "/proposal_v2/gutter-misc",
    UPDATE_GUTTER_QUOTE: "/proposal_v2/update-gutter",
    DELETE_MISC_GUTTER_QUOTE: "/proposal_v2/gutter-misc-quote",

    CHANGE_MATERIAL_SUPPLIER_PROPOSAL: "/proposal_v2/change-material-supplier",
    CHANGE_LABOUR_SUPPLIER_PROPOSAL: "/proposal_v2/change-labour-supplier",
    CHANGE_GUTTER_SUPPLIER_PROPOSAL: "/proposal_v2/change-gutter-supplier",

    UPDATE_PROPOSAL_INFO: "/proposal_v2/update-info",
    GET_SERIES: "/proposal_v2/series-by-category",

    UPDATE_SUPPLIER_CARTING: "/proposal_v2/change-supplier-dumpster",
    DELETE_SUPPLIER_CARTING: "/proposal_v2/dumpster-quote",
    ADD_SUPPLIER_CARTING: "/proposal_v2/dumpster-quote",
    UPDATE_CARTING_QUOTE: "/proposal_v2/update-dumpster",

    STATE_TAXES: "/proposal_v2/state-taxes",

    QUOTE_MARKUP: "/resources/quote-markup",
  },
  PERMISSION: {
    GET_ALL_PERMISSIONS: "admin/permission/getAllPermission",
  },
  PRICING: {
    FETCH_MATERIAL_PRICING: "/pricing/quote/material/", // POST
    FETCH_LABOR_PRICING: "/pricing/quote/labor/", // POST
  },
  SUPPLIERS: {
    FETCH_MATERIAL_SUPPLIERS: "/quote/materialSuppliers", // GET
    FETCH_SUBS_CREW_SUPPLIERS: "/quote/subsCrewSuppliers", // GET
    FETCH_CARTING_SUPPLIERS: "/quote/cartingSuppliers", // GET
  },
  QUOTE: {
    MATERIAL_SECTION_DATA: "/quote/material", // GET, PUT
    LABOR_SECTION_DATA: "/quote/labor", // PUT
    DEBRIS_SECTION_DATA: "/quote/debris", // PUT
    GUTTERS_SECTION_DATA: "/quote/gutters", // PUT
    QUOTE_PROPOSAL_DATA: "/quote/proposal", // PUT
    CHECK_QUOTE_PROPOSAL_DATA: "/quote/checkproposal", // GET
    DELETE_PROPOSAL_DATA: "/quote/deleteproposal", // DELETE
    CREATE_PROPOSAL: "/quote/createProposal", // POST
    LOCK_QUOTE_DATA: "/quote/material/isLocked", // PATCH
    //hook useQuote
    cancellationReason: "/quote/cancellationReason",
    changeStage: "/activity/changeStage",
    getLetterHead: "/quote/getLetterHead",
  },
  TASK: {
    FETCH_TASK: "/task", // GET
    ADD_TASK: "/task/add", // POST
    EDIT_TASK: "/task/edit", // POST
    COMPLETE_TASK: "/task/complete", // POST
  },
  REPAIR_REQUEST: {
    GET_REPAIR_REQUEST: "/activity/getRepairRequest", // POST
    UPDATE_REPAIR_REQUEST: "/activity/updateRepairRequest", // POST
  },
  STAGE: {
    GET_STAGE: "/activity_v2/stage", // POST
    GET_STAGE_COUNT: "/activity_v2/stage/count", // POST
    NOT_ACTED_OPEN: "/activity_v2/stage/not-acted",
    UPDATE_STATUS: "/activity_v2/update-status", // POST
    UPDATE_FOLLOW_UP_STATUS: "/activity_v2/updateFollowUp",

    REPAIR_STAGE_COUNT: "/activity_v2/stage/repair/count",
    GET_REPAIR_LEADS: "/activity_v2/stage/repair",
    REPAIR_NOT_ACTED_OPEN: "/activity_v2/stage/repair/not-acted",
    REPAIR_UPDATE_STATUS: "/activity_v2/update-status", // POST

    REPAIR_LEAD_UPDATE: "/activity_v2/stage/repair/update", // PUT
  },
  FOLLOW_UP: {
    UPDATE_FOLLOW_UP: "/activity/updateFollowUp", // POST
    UPDATE_WON_OR_FUTURE_FOLLOW_UP: "/activity/updateWonOrFutureFollowUp", // POST
  },
  STATIC_ALL: {
    STATIC_ALL: "/static/all",
  },
  MATERIAL_SUPPLIERS: {
    M_SUPPLIERS: "/suppliers/material", // GET
    PRICING_MATERIAL: "/pricing/material", // POST
    RESET_PRICING_MATERIAL: "/pricing/material/reset", // GET
    ADD_PRICING_CUSTOM: "/pricing/material/custom", // POST
  },
  SUBCONTRACTOR_SUPPLIERS: {
    SUBCONTRACTOR_SUPPLIERS: "/suppliers/subcontractor", // CRUD
  },
  CREW_SUPPLIERS: {
    CREW_SUPPLIERS: "/suppliers/crew", // CRUD
    PRICING_CREW: "/pricing/crew", // POST

    ADD_CREW_PRICING_CUSTOM: "/pricing/crew/custom", // POST
  },
  CARTING_SUPPLIERS: {
    CARTING_SUPPLIERS: "/suppliers/carting", // GET POST PATCH DELETE
    PRICING_CARTING: "/pricing/carting", // POST PATCH
  },
  TOGGLE_PREFERRED_SUPPLIER: {
    TOGGLE_IS_PREFERRED: "/suppliers/preferred", // PATCH
  },
  LABOR_PRICING: {
    PRICING_LABOR: "/pricing/labor", // POST
    RESET_PRICING_LABOR: "/pricing/labor/reset", // GET
    ADD_L_PRICING_CUSTOM: "/pricing/labor/custom", // POST
  },

  USER: {
    registerUser: "/signup",
    get_stripe_test_public_key: "/admin/payment/config",
    get_stripe_test_secret_key: "/admin/payment/createPaymentIntent",
    update_payment_method: (companyId) =>
      `company/updatePaymentMethod?companyId=${companyId}`,
    get_payment_method: (pId) => `/admin/payment/getPaymentMethod?pId=${pId}`,
    verify_otp: (email, otp, type = "verify") =>
      `verify/${email}/${otp}/${type}`,
    get_company_by_companyId: "/getCompanyById",
    update_paid_in_user: "/updateIsPaid",
    create_payment_details_for_company_in_database:
      "/admin/payment/postSubscriberDetailsAndHistory",
    userLogin: "/login",
    checkUserData: "/me",
    users: "/user",
    userWithId: (id) => `/user/${id}`,
    toggleLock: (uid) => `/user/${uid}/toggleLock`,
    forgetPassword: "/forgotpassword",
    resendVerifiation: "/resendVerification",
    getUserRoles: "/admin/permission/role",
    getBasePlan: "/admin/baseplanuser/user",
    getBasePlanSignup: "/admin/baseplanuser",
    getAllNotification: "/admin/notifications/userNotifications",
    updateNotification: (id) => `/admin/notifications/check/${id}`,
  },
  SCHEDULE_MODAL: {
    GET_SCEDULING_TIME: "admin/getSchedulingTime",
    GET_SCEDULING_TIME_BY_ID: "admin/getSchedulingTime/",

    UPDATE_SSCHEDULING_MODAL_SALES: "admin/updateSchedulingModalSaleTime/",
    UPDATE_SSCHEDULING_MODAL_REPAIR: "admin/updateSchedulingModalRepairTime/",
    UPDATE_SSCHEDULING_MODAL_PROJECT: "admin/updateSchedulingModalPMT/",
    UPDATE_SSCHEDULING_MODAL_GAPTIME_REPAIR:
      "admin/updateSchedulingModalRepairGapTime/",
    UPDATE_SSCHEDULING_MODAL_GAPTIME_SALES:
      "admin/updateSchedulingModalSalesGapTime/",
    GET_ALLVIDEO: "admin/videos/firstTimeSetup",
    GET_ALL_HELP_VIDEO: "admin/videos/getScreensIds",
    GET_ALL_COUNT: "admin/notifications/countAndCheck",

    GET_STAGE_BEFORE_DATA: "activity/getDataOfTodayAndBefore",
    GET_ALL_UPDATEON: "activity/updateWonOrFutureFollowUp",
  },
  CONTACT: {
    getContactList: "/contact-management",
    getContactDetails: "/contact-management/:leadAddressId",
    contactManagementSchedule: "/contactManagement/create",
    addContactManagementProperty: "/contact-management/property",
    editContactManagementProperty:
      "/contact-management/property/:propertyAddressId",
    addNewSvcRequestToProperty:
      "/contact-management/property/:propertyId/service-request",
    editContact: "/contact-management/:leadAddressId",
    getCompanyDetails: "/admin/payment/getCompanyData",
  },
  EMAIL_CONTRACT: {
    FirstTime: "/quote/proposal/firstTime",
    SendEmailContract: "/makeContract/addLeadRequestId",
    MakeContract: "/makeContract/addLeadRequestId",
    AddDateToContract: "/makeContract/addDate",
    GetContractById: "/makeContract/getContract",
    updatedContract: "/makeContract/updateProposalState",
    createContractDetails: "/makeContract/getContractState",
    updatedContractDetails: "/makeContract/createContractPage",
    edit_quote: "/quote/proposal",
    update_signed_contract: "/makeContract/uploadSignedContract",
  },
  PERSONEL_DATA: {
    MaterialPersonnel: "/supplier/materialPersonel",
    SuplierPersonnel: "/supplier/subcrewsPersonel",
    CartingPersonnel: "/supplier/cartingPersonel",
  },
  PRODUCTION_DASH: {
    getEventsForProjectSchedule: "/projectSchedule/getEventsForProjectSchedule",
    getWeather: "/projectSchedule/getWeather",
  },
  PROPOSAL_TAB: {
    AddSignature: "makeContract/addSignature",
  },
  STATE_COUNTY_TAX: "/admin/stateAndCountyTax/getactivestate",
  getCountyByStateAndZip: (stateId, zipCode) =>
    `/admin/stateAndCountyTax/county?stateId=${stateId}&zipCode=${zipCode}`,
  SUBSCRIPTION: {
    particularCompanyDataForAdminDromCompanyId:
      "/admin/payment/particularCompanyDataForAdminDromCompanyId",
    getUpcomingInvoiceForParticularCompany:
      "/admin/payment/getUpcomingInvoiceForParticularCompany",
    particularCompanyDataForAdminFromCompanyId:
      "/admin/payment/particularCompanyDataForAdminFromCompanyId",
    createPaymentIntent: "/admin/payment/createPaymentIntent",
    createSubscription: "/admin/payment/createSubscription",
    changeCardData: "/admin/payment/changeCardData",
    GET_BASE_PRICING: "/getBasePricing",
    GET_ALL_SUBSCRIPTION_INFO: "/admin/getAllUserData",
    EDIT_SUBSCRIPTION_INFO: "/admin/updateBillingReport",
  },
  WEATHER: {
    GET: "/admin/getWeatherZipCode",
    UPDATE: "/admin/updateWeatherZipCode",
  },
};
