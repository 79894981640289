import React, { useState, useEffect } from "react";
import "./QuotesProposals.scss";
import { Row, Col, Tab, Tabs } from "react-bootstrap";

import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { QuoteProposalLayout } from "./QuoteProposalLayout/QuoteProposalLayout";
import Estimator from "./Estimator";
import ProposalTab from "./ProposalTab/ProposalTab/ProposalTab";
import { JobNotes } from "./JobNotes/JobNotes";
import userDetails from "hooks/user/userDetails";
import { getProposal } from "./redux/proposals/proposals.actions";
import { useSelector } from "react-redux";
import { LoadingLoader } from "components";

export default function QuotesProposals(props) {
  const proposalData = useSelector((state) => state.proposal);

  const selectedProposal = proposalData?.selectedProposal;

  const MAIN_TABS = [
    {
      name: "Estimator",
      id: "001",
    },
    {
      name: `${props.type ? `Proposal/Contract` : `Proposal`}`,
      id: "002",
    },
    {
      name: "Job Notes",
      id: "003",
    },
  ];

  const [key, setKey] = useState(MAIN_TABS[0].id);
  const { type } = props;
  const metricId = props.match?.params?.id || props.leadReqId;

  const dispatch = useDispatch();
  const { companyId } = userDetails();
  const proposal = useSelector((state) => state.proposal);

  useEffect(() => {
    const data = {
      requestId: metricId,
      companyId: companyId,
    };
    console.log({
      data2222: data,
    });

    dispatch(getProposal(data));
  }, []);

  const switchMainTabs = () => {
    switch (key) {
      case MAIN_TABS[0].id:
        return <Estimator key={key} setKey={setKey} requestId={metricId} />;

      case MAIN_TABS[1].id:
        return (
          <div className="_cont_tab __cont_quote">
            <div className="_quote_cont">
              <ProposalTab
                //modalShow={modalShow}
                //modalShowv2={modalShowv2}
                //show_modal={show_modal}
                //show_modal_v2={show_modal_v2}
                type={type}
                tabKey={key}
              />
            </div>
          </div>
        );

      case MAIN_TABS[2].id:
        return <JobNotes proposalId={selectedProposal?._id} />;
      default:
        return null;
    }
  };

  if (proposal.isLoading) {
    return <LoadingLoader />;
  }
  return (
    <div
      className="wrapper"
      // style={qProposalPointerEvent ? {} : { pointerEvents: "none" }}
    >
      {/* header */}
      <Row
        style={{ margin: "0 .2em", alignItems: "center", marginBottom: "15px" }}
      >
        {selectedProposal?.otherInfo?.address && (
          <h4>
            <span style={{ fontWeight: "bold" }}>
              <Link
                to={{
                  pathname: "/dashboard/help",
                  search: "Quotes & Proposals",
                }}
                target="_blank"
                rel="noreferrer"
                style={{ cursor: "pointer" }}
              >
                <b style={{ color: "red", marginRight: "17px" }}>?</b>
              </Link>
              Quotes & Proposals &nbsp; &nbsp; &nbsp;
              <span style={{ fontWeight: "bold", marginLeft: "-2rem" }}>
                {" - "} {selectedProposal?.otherInfo?.address}
              </span>
            </span>
          </h4>
        )}
      </Row>
      <QuoteProposalLayout
        // updateAutoTextData={updateAutoTextData}
        // changeProposalId={changeProposalId}
        proposalsData={[]}
      >
        <div style={{ flex: 1 }}>
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
          >
            {MAIN_TABS.map((tab) => (
              <Tab
                key={tab.id}
                eventKey={tab.id}
                title={tab.name}
                // disabled={
                //   tab.id === MAIN_TABS[1].id
                //     ? !quoteProposalData[proposalIdx]?.isProposalCreated
                //     : false
                // }
              >
                {tab.id === key ? switchMainTabs() : null}
              </Tab>
            ))}
          </Tabs>
        </div>
      </QuoteProposalLayout>
    </div>
  );
}
