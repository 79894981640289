export const salesActionTypes = {
  STAGE_START: "STAGE_START",
  STAGE_SUCCESS: "STAGE_SUCCESS",
  STAGE_FAILURE: "STAGE_FAILURE",
  CHANGE_STAGE_START: "CHANGE_STAGE_START",
  CHANGE_STAGE_SUCCESS: "CHANGE_STAGE_SUCCESS",
  CHANGE_STAGE_FAILURE: "CHANGE_STAGE_FAILURE",
  CHANGE_STAGE_RESET: "CHANGE_STAGE_RESET",
  UPDATE_FOLLOWUP_START: "UPDATE_FOLLOWUP_START",
  UPDATE_FOLLOWUP_SUCCESS: "UPDATE_FOLLOWUP_SUCCESS",
  UPDATE_FOLLOWUP_FAILURE: "UPDATE_FOLLOWUP_FAILURE",
  UPDATE_FOLLOWUP_DATA_START: "UPDATE_FOLLOWUP_DATA_START",
  UPDATE_FOLLOWUP_DATA_SUCCESS: "UPDATE_FOLLOWUP_DATA_SUCCESS",
  UPDATE_FOLLOWUP_DATA_FAILURE: "UPDATE_FOLLOWUP_DATA_FAILURE",
  STAGE_SCHEDULED_SUCCESS: "STAGE_SCHEDULED_SUCCESS",
  STAGE_PROPOSALS_DUE_SUCCESS: "STAGE_PROPOSALS_DUE_SUCCESS",
  STAGE_SENT_FOLLOW_UP_SUCCESS: "STAGE_SENT_FOLLOW_UP_SUCCESS",
  STAGE_WON_SUCCESS: "STAGE_WON_SUCCESS",
  STAGE_COUNT_SUCCESS: "STAGE_COUNT_SUCCESS",
  STAGE_FUTURE_FOLLOW_UP_SUCCESS: "STAGE_FUTURE_FOLLOW_UP_SUCCESS",
  STAGE_LOST_SUCCESS: "STAGE_LOST_SUCCESS",
  STAGE_CANCELED_SUCCESS: "STAGE_CANCELED_SUCCESS",
  STAGE_DEFAULT: "STAGE_DEFAULT",
  STAGE_SEARCH_SUCCESS: "STAGE_SEARCH_SUCCESS",
  STAGE_SELECT_SALE_PERSON_SUCCESS: "STAGE_SELECT_SALE_PERSON_SUCCESS",
  STAGE_NOT_ACTED_UPON_SUCCESS: "STAGE_NOT_ACTED_UPON_SUCCESS",
  STAGE_REPAIR_COUNT_SUCCESS: "STAGE_REPAIR_COUNT_SUCCESS",
  STAGE_REPAIR_SCHEDULED_SUCCESS: "STAGE_REPAIR_SCHEDULED_SUCCESS",
  STAGE_REPAIR_SENT_FOLLOW_UP_SUCCESS: "STAGE_REPAIR_SENT_FOLLOW_UP_SUCCESS",
  STAGE_REPAIR_COMPLETE_UNPAID_SUCCESS: "STAGE_REPAIR_COMPLETE_UNPAID_SUCCESS",
  STAGE_REPAIR_CANCELED_SUCCESS: "STAGE_REPAIR_CANCELED_SUCCESS",
  STAGE_REPAIR_PAID_CLOSED_SUCCESS: "STAGE_REPAIR_PAID_CLOSED_SUCCESS",
  STAGE_REPAIR_DEFAULT_SUCCESS: "STAGE_REPAIR_DEFAULT_SUCCESS",
  STAGE_REPAIR_NOT_ACTED_UPON_SUCCESS: "STAGE_REPAIR_NOT_ACTED_UPON_SUCCESS",
};
