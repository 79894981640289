import { SuppliersText } from "../const";

export function hasNestedFieldChanged(material, field, subField) {
  return (
    material.originalData &&
    material.originalData[field] &&
    material.originalData[field][subField] !== material[field][subField] // Ensure comparison between original and new value
  );
}

export function hasFieldChanged(material, field) {
  return material?.originalData?.[field] !== undefined
    ? material.originalData[field] !== material[field]
    : false;
}

export function hasSupplierFieldChanged(
  originalQuoteSupplier,
  field,
  supplierId
) {
  console.log({
    originalQuoteSupplier,
    field,
    supplierId,
  });

  return (
    originalQuoteSupplier && originalQuoteSupplier[field] !== supplierId // Ensure comparison between original and new value
  );
}

export const supplierEmptyText = (ListLength) =>
  ListLength > 0
    ? SuppliersText.selectSuppliers
    : SuppliersText.noDataAvailable;
