import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import PopupModal from "components/popup-modal/popup-modal";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  SaveValueGutter,
  toggleOptionSelectedGutter,
  updateMetricsGutter,
} from "screens/metric/redux/gutter-metric-collection/gutter-metric.action";
import Numpad from "../Numpad";
import {
  findItemByKeyAndSubTab,
  getTotalValue,
  gutterCategoryMappings,
} from "utils/utils";
import {
  addGutterMetric,
  editGutterSectionMetric,
} from "screens/metric/redux/gutter-metrics-v2/metrics.actions";
import userDetails from "hooks/user/userDetails";
import { editGutters } from "services/offlineComputing/routes/generateQuote";
const { uuid } = require("uuidv4");

// Define all styles at the top
const styles = {
  block: {
    paddingTop: "0.2em",
    fontSize: "2.1em",
    marginRight: "5px",
  },
  flexCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  inputContainer: {
    display: "flex",
    flexDirection: "row",
  },
  labelText: {
    width: "380px",
    paddingTop: "0.2em",
    fontSize: "2.1em",
  },
  slideRight: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  rowMargin: {
    margin: "1em auto",
  },
  carsl: {
    border: "none",
  },
  blockText: {
    fontSize: "1rem",
  },
};

const GuttersOptionsTabModal = ({
  showTabModal,
  section,
  selectedData,
  onHide,
  handleSelectedData,
  modalInfo,
  requestId,
}) => {
  const [viewTallyMode, setViewTallyMode] = useState(false);
  const [showNextContent, setShowNextContent] = useState(false);
  const guttersData = useSelector((state) => state.gutterMetric);
  const dispatch = useDispatch();
  const [selectedDimension, setSelectedDimension] = useState({});
  const { companyId } = userDetails();

  const gutterData = useSelector((state) => state.gutterMetricV2Data);
  const gutterMetrics = useSelector((state) => state.gutterMetric?.metrics);
  const gutterMetricOptions = useSelector(
    (state) => state.gutterMetric?.metricOptions
  );

  // Select the current values of the opened tab
  const [currentValue, setCurrentValue] = useState({});

  console.log({
    gutterMetrics,
  });
  useEffect(() => {
    const mappedCategory =
      gutterCategoryMappings[modalInfo?.data?.categoryName];
    const value = gutterMetrics?.[mappedCategory]?.[modalInfo?.data?.key];
    setCurrentValue(value);
    console.log({ value });
  }, [gutterMetrics, modalInfo]);

  const HideModal = () => {
    onHide();
    setShowNextContent(false);

    setSelectedDimension({}); // Set all dimensions to an empty state
  };

  const findCurrentTab = (metricOptions, categoryName, subTabName) => {
    const category = metricOptions.find((mt) => mt.name === categoryName);
    if (!category) return null;

    const subTab = category.subTabs.find((st) => st.name === subTabName);
    return subTab || null;
  };

  console.log({ guttersData });

  const toggleInputSelection = (input) => {
    console.log({ input });
    // const toggleData = {
    //   categoryName: modalInfo?.data?.categoryName,
    //   subTabName: modalInfo?.data?.name,
    //   inputKey: input?.key
    // }
    // console.log({ toggleData });
    // dispatch(toggleInputSelected(toggleData))
  };

  const findTabCurrentsubTab = () => {
    const categoryName = modalInfo?.data?.categoryName;
    const id = uuid();

    const mappedKey = gutterCategoryMappings[categoryName] || categoryName;

    const result = findItemByKeyAndSubTab(
      gutterMetricOptions,
      mappedKey,
      modalInfo?.data?.key
    );
    console.log({ result });
    return result;
  };

  const saveValue = () => {
    const categoryName = modalInfo?.data?.categoryName;
    const id = uuid();

    const mappedKey = gutterCategoryMappings[categoryName] || categoryName;

    const currentTab = findTabCurrentsubTab();

    const options = getSelectedOptionsss(currentTab?.modalOptions);

    console.log({
      modalInfo,
      options,
    });

    // Create a new section
    if (!gutterMetrics?.sectionId) {
      const elevation = getElevation(gutterMetrics?.elevationDetails);
      console.log({
        elevation,
        mappedKey,
        gutterMetrics,
      });

      // return;
      const apiData = {
        requestId: requestId,
        companyId: companyId,
        sections: [
          {
            level: gutterMetrics?.level,
            elevationDetails: elevation,
            elevation: gutterMetrics?.elevation,
            ...(gutterMetrics?.notes && { notes: gutterMetrics?.notes }), // Conditionally include notes
            [mappedKey]: {
              [modalInfo?.data?.key]: {
                values: [
                  {
                    unitKey: modalInfo?.data?.unitKey,
                    unit: modalInfo?.data?.unit,
                    id: id,
                    ...selectedDimension,
                    value: selectedDimension[modalInfo?.data?.unitKey],
                  },
                ],
                options: options,
              },
            },
          },
        ],
      };

      dispatch(addGutterMetric(apiData));
    } else {
      console.log({
        options,
        mappedKey,
        kk: modalInfo?.data?.key,
        ss: gutterMetrics?.[mappedKey],
      });

      // return;

      const updateApiData = {
        ...gutterMetrics,
        [mappedKey]: {
          ...(gutterMetrics?.[mappedKey] || {}), // Ensure it's an object
          [modalInfo?.data?.key]: {
            values: [
              ...(gutterMetrics?.[mappedKey]?.[modalInfo?.data?.key]?.values ||
                []), // Ensure it's an array
              {
                unitKey: modalInfo?.data?.unitKey,
                unit: modalInfo?.data?.unit,
                id: id,
                ...selectedDimension,
                value: selectedDimension[modalInfo?.data?.unitKey],
              },
            ],
            key: modalInfo?.data?.key,
            options: options,
          },
        },
        requestId: requestId,
        sectionId: gutterMetrics?.sectionId,
      };

      delete updateApiData?.floor;
      delete updateApiData?.pitch;
      delete updateApiData?.section;
      delete updateApiData?.FasciaAndCapping;
      delete updateApiData?.GuttersAndLeaders;
      delete updateApiData?._id;

      dispatch(editGutterSectionMetric(updateApiData));
    }
  };

  /**
   *
   * @returns
   * @description This will render in the top like list of sections, unit and number of units
   */
  const topView = () => {
    // Check if modalInfo and necessary keys are present
    if (
      !modalInfo ||
      !modalInfo.data ||
      !modalInfo.data.unit ||
      !modalInfo.data.categoryName ||
      !modalInfo.data.key
    ) {
      return (
        <div>
          {/* Render a message or placeholder when necessary keys are not available */}
          <p>Missing necessary data in modalInfo</p>
        </div>
      );
    }

    const mappedCategory =
      gutterCategoryMappings[modalInfo?.data?.categoryName];

    let totalLabel = modalInfo.data.unit || "Linear Feet";
    const currentCategoryValue = gutterMetrics
      ? gutterMetrics[mappedCategory][modalInfo.data.key]
      : [];
    console.log({ currentCategoryValue });
    const total = currentCategoryValue?.values?.length
      ? getTotalValue(currentCategoryValue?.values)
      : 0;

    return (
      <Row>
        <Col>
          <div className="_lbl">Section</div>
          <p className="_txt">{section}</p>
        </Col>
        <Col>
          <div className="_lbl">Total {totalLabel}</div>
          <p className="_txt">{total}</p>
        </Col>
        <Col>
          <div className="_lbl">Entries</div>
          <p className="_txt">
            {currentCategoryValue && currentCategoryValue?.values.length
              ? currentCategoryValue?.values?.length
              : 0}
          </p>
        </Col>
      </Row>
    );
  };
  console.log("modalInfo ", { modalInfo });

  /**
   *
   * @returns
   * @description This is to render the value of inputs in the top of Numpads
   */
  const renderInputs = (inputs, selectedDimension) => {
    return inputs.map((input, index) => (
      <div
        key={index}
        style={styles.block}
        onClick={() => toggleInputSelection(input)}
        className={`_block ${input.selected ? "selected" : "unselected"}`}
      >
        <p style={styles.blockText} className="_block-txt">
          {input?.name}
        </p>
        <p style={styles.blockText} className="_block-txt">
          {selectedDimension[input?.key] || ""}
        </p>
      </div>
    ));
  };

  const toggleKeypad = () => {
    setViewTallyMode((prevState) => !prevState);
  };

  console.log({
    selectedDimension,
  });

  const renderNextContent = () => {
    const currentTab = findCurrentTab(
      guttersData?.metricOptions,
      modalInfo?.data?.categoryName,
      modalInfo?.data?.name
    );

    const inputs = currentTab?.inputs;
    const activeInput = inputs?.find((ct) => ct.selected);

    const isDisabled =
      !activeInput?.key ||
      !(selectedDimension && selectedDimension[activeInput.key]);

    return (
      <>
        <div style={styles.flexCenter}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            {inputs && inputs.length
              ? renderInputs(inputs, selectedDimension)
              : null}
          </div>
          <Numpad
            addIntoDimen={(data, num) => {
              console.log({
                data,
                num,
              });

              setSelectedDimension((prevState) => {
                if (activeInput?.key) {
                  return {
                    ...prevState,
                    [activeInput.key]: num
                      ? (prevState[activeInput.key] || "") + num.toString()
                      : "",
                  };
                }
                return prevState;
              });
            }}
            selectDimen={"Starter"}
            handleAdd={() => saveValue({ fromOptions: false })}
            editDimen={""}
            disableAddOrSave={isDisabled}
            saveText={"Save"}
          />
        </div>
        <Modal.Footer style={{ border: "none" }}>
          <Button className="modified_btn" variant="danger" onClick={HideModal}>
            Close
          </Button>
          <Button className="modified_btn" onClick={toggleKeypad}>
            View Tally
          </Button>
        </Modal.Footer>
      </>
    );
  };

  const checkIfSelected = (value) => {
    return value.selected;
  };

  // const checkIfAnyIsSelected = (modalOption) => {
  //   const currentTab = findCurrentTab(guttersData?.metricOptions, modalInfo?.data?.categoryName, modalInfo?.data?.name);
  //   currentTab?.modalOptions?.find()
  // }

  const setSelectedOption = (btn, optionKey, configKey) => {
    const data = {
      categoryName: modalInfo?.data?.categoryName,
      subTabName: modalInfo?.data?.name,
      optionKey: optionKey?.key,
      optionValueName: btn?.name,
      configKey: configKey,
    };

    console.log({ data, btn, optionKey });
    dispatch(toggleOptionSelectedGutter(data));
  };

  const getSelectedOptionsss = (modalOptions) => {
    console.log({
      modalOptions,
    });
    // Initialize the result object
    const result = {};

    // Iterate through modalOptions
    modalOptions?.forEach((modalOption) => {
      console.log({
        modalOption,
      });
      modalOption?.options?.forEach((optionGroup) => {
        console.log({
          optionGroup,
        });
        if (optionGroup.keyOptions) {
          // Extract selected options
          const selectedOptions = optionGroup.keyOptions.reduce(
            (acc, optionItem) => {
              if (optionItem.selected) {
                console.log({
                  optionItem,
                });
                // Prepare the 'others' field if present
                const selectedOthers = optionItem.others || {};

                // Build the option object
                acc[optionGroup.key] = {
                  name: optionItem.name,
                  value: optionItem.name,
                  // others: {
                  //   ...selectedOthers,
                  //   inputs: selectedOtherInputs
                  // }
                };
              }
              return acc;
            },
            {}
          );

          // Add to result if options are found
          if (Object.keys(selectedOptions).length > 0) {
            result[optionGroup.key] = selectedOptions[optionGroup.key];
          }
        }
      });
    });

    return result;
  };

  const getElevation = (elevationDetails) => {
    console.log({
      elevationDetails,
      dd: elevationDetails?.more?.lists?.length,
      other: elevationDetails?.more?.other,
      aaa: modalInfo?.data?.key,
    });

    if (elevationDetails?.more?.lists?.length) {
      return {
        type: elevationDetails?.type,
        more: {
          lists: elevationDetails?.more?.lists,
          ...(elevationDetails?.more?.other && {
            other: elevationDetails?.more?.other,
          }), // Include 'other' only if it's not empty
        },
      };
    } else {
      return { type: elevationDetails?.type };
    }
  };

  const getSelectedOptions = (modalOptions) => {
    const selectedOptions = {};
    modalOptions?.forEach((mOption) => {
      mOption.options.forEach((option) => {
        option.keyOptions.forEach((innerOption) => {
          if (innerOption.selected) {
            selectedOptions[option.key] = innerOption.name;
          }
        });
      });
    });
    return selectedOptions;
  };

  const isOptionDisabled = (option, selectedOptions) => {
    // Disable the option if its corresponding name key is not in selectedOptions
    return option?.config?.disabledOptions?.some((disabled) => {
      return !(disabled.name in selectedOptions);
    });
  };

  const isOptionHidden = (option, selectedOptions) => {
    return option?.config?.hideOptions?.some((hidden) => {
      // Check if the hide condition is met based on the name and value
      const selectedValue = selectedOptions[hidden.name];
      if (!selectedValue) return true;
      console.log({
        selectedValue,
        option,
        selectedOptions,
      });

      return selectedValue === hidden.value;
    });
  };

  const editCurrentValue = (value) => {};

  const deleteCurrentValue = (value) => {};

  console.log({
    viewTallyMode,
    showNextContent,
  });

  const renderTally = () => {
    const currentTab = findCurrentTab(
      guttersData?.metricOptions,
      modalInfo?.data?.categoryName,
      modalInfo?.data?.name
    );

    // Check if currentTab is defined and has inputs and options
    if (currentTab && currentTab.inputs) {
      // Check if inputs is an array
      const inputsArray = Array.isArray(currentTab.inputs)
        ? currentTab.inputs
        : [];
      // Check if options.values is an object
      const optionsValues =
        typeof currentTab?.options?.values === "object"
          ? currentTab?.options?.values
          : {};

      console.log({
        currentTab,
        inputsArray,
        optionsValues,
        currentValue,
      });

      if (inputsArray.length && currentValue?.values?.length) {
        const mappedCategory =
          gutterCategoryMappings[modalInfo?.data?.categoryName];
        const optionValues =
          gutterMetrics?.[mappedCategory]?.[modalInfo?.data?.key];

        console.log({
          optionValues,
        });

        return (
          <div className="slide-right">
            <>
              <table className="dimen_table">
                <thead>
                  <tr>
                    <th></th>
                    {/* Render headers for options */}
                    {optionValues?.options &&
                      Object.keys(optionValues?.options)?.map((thead) => (
                        <th key={thead}>
                          {optionValues?.options?.[thead]?.name}
                        </th>
                      ))}
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {/* Render rows for each value in currentCategoryValue */}

                  {optionValues?.values &&
                  Array.isArray(optionValues.values) ? (
                    optionValues.values.map((unitValue, index) => (
                      <tr>
                        <td>
                          <Button onClick={() => editCurrentValue(value)}>
                            Edit
                          </Button>
                        </td>

                        <td key={unitValue.id || index}>
                          {unitValue?.value || "Default Value"}
                        </td>

                        <td>
                          <Button variant="danger" onClick={deleteCurrentValue}>
                            Delete
                          </Button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <td>No values available</td>
                  )}

                  {/* </tr> */}
                  {/* <td>
                        <Button onClick={() => editCurrentValue(value)}>Edit</Button>
                      </td>


                      <td>
                        <Button variant="danger" onClick={() => deleteCurrentValue(value)}>
                          Delete
                        </Button>
                      </td> */}
                </tbody>
              </table>
            </>
            {/*   
              <Modal.Footer style={{ justifyContent: "space-between", border: "none" }}>
                <div>
                  <Button className="modified_btn" style={{ float: "left" }} onClick={toggleKeypad}>
                    Keypad
                  </Button>
                </div>
                {showPagination()}
                <div>
                  <Button className="modified_btn" variant="danger" onClick={() => {
                    HideModal();
                  }}>
                    Close
                  </Button>
                </div>
              </Modal.Footer> */}
          </div>
        );
      }
    }
    return null;
  };

  const handleButtonPress = (type) => {
    if (type === "Next") {
      setShowNextContent(true);
      setViewTallyMode(false);
    } else {
      setShowNextContent(false);
      setViewTallyMode(true);
    }
  };

  const renderOptions = () => {
    const currentTab = findCurrentTab(
      guttersData?.metricOptions,
      modalInfo?.data?.categoryName,
      modalInfo?.data?.name
    );
    if (!currentTab) return null;

    const selectedOptions = getSelectedOptions(currentTab.modalOptions);
    return (
      <>
        {currentTab.modalOptions.map((mOption, modalIndex) => {
          // Determine if the label should be hidden based on some condition
          const isLabelHidden = isOptionHidden(mOption, selectedOptions);
          console.log({
            isLabelHidden,
          });
          return (
            <div key={modalIndex}>
              <div
                style={{
                  display: "flex",
                  marginBottom: "1.5rem",
                  justifyContent: "space-around",
                  marginTop: ".2rem",
                }}
              >
                {mOption.options.map((value, index) => {
                  const isDisabled = isOptionDisabled(value, selectedOptions);
                  const isHidden = isOptionHidden(value, selectedOptions);
                  // {!isHidden && (

                  // )}

                  return (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {!isHidden && (
                        <label
                          className="btnheading"
                          style={{
                            textAlign: "center",
                            width: "100%",
                            // ...(value?.config?.displayHeader && !mOption?.options?.length > 1 ? {} : { position: 'relative', left: '45%' })
                          }}
                        >
                          {value?.config?.displayHeader
                            ? mOption.config.headerName
                            : " "}
                        </label>
                      )}

                      <Row key={index}>
                        {value.keyOptions.map((innerOption, innerIndex) => {
                          return (
                            !isHidden && (
                              <Col>
                                <button
                                  key={innerIndex}
                                  className={`ClickableBtn ${
                                    innerOption.selected ? "selected" : ""
                                  }`}
                                  style={{ width: "28%" }}
                                  onClick={() =>
                                    setSelectedOption(
                                      innerOption,
                                      value,
                                      mOption?.config?.key
                                    )
                                  }
                                  disabled={isDisabled}
                                >
                                  {innerOption.name}
                                </button>
                              </Col>
                            )
                          );
                        })}
                      </Row>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}

        <Modal.Footer style={{ border: "none" }}>
          <Button className="modified_btn" variant="danger" onClick={HideModal}>
            Close
          </Button>
          <Button
            onClick={() => setShowNextContent(true)}
            className="modified_btn"
          >
            Next
          </Button>
        </Modal.Footer>
      </>
    );
  };

  return (
    <PopupModal
      show={showTabModal}
      heading={modalInfo?.data?.additionalLabel}
      onHide={() => {
        HideModal();
        if (viewTallyMode) setViewTallyMode(false);
      }}
      bold
      styles={styles.carsl}
      scrollable={false}
    >
      <div className="_carsl">
        <Modal.Body>
          <div className="slide-left view">
            {showNextContent && viewTallyMode ? topView() : null}
            {/* {modalInfo?.data?.modalOptions?.length ? renderOptions() : null} */}
            {/* {!viewTallyMode ? showNextContent ? renderOptions() : renderOptions() : renderTally()} */}
            {/* {!showNextContent ? renderOptions() : renderNextContent()} */}
            {!viewTallyMode
              ? showNextContent
                ? renderNextContent()
                : renderOptions()
              : renderTally()}
          </div>
        </Modal.Body>
      </div>
    </PopupModal>
  );
};

GuttersOptionsTabModal.propTypes = {
  label: PropTypes.string,
  showTabModal: PropTypes.bool,
  selectDimension: PropTypes.any,
  editDimension: PropTypes.bool,
  section: PropTypes.number,
  selectedData: PropTypes.any,
  onHide: PropTypes.func,
  totalLabel: PropTypes.string,
  modalInfo: PropTypes.any,
};

export default GuttersOptionsTabModal;
