import userPermission from "hooks/usePermission";
import React from "react";
import { useSelector } from "react-redux";
import { Link, Route, Switch } from "react-router-dom";
import ContactManagement from "screens/contact-management/contact-management";
import NewLead from "screens/new-lead/new-lead";
import "./dashboard.scss";
import ProductionSchedule from "screens/ProductionSchedule/ProductionSchedule";

const links = ["production", "", "contactmanagement"];

const Dashboard = (props) => {
  const { gettingPermission, permission, permissionErr } = useSelector(
    (state) => state.allPermissions
  );

  const {
    location: { pathname },
    match: { path },
  } = props;
  const activePath = pathname.split("/")[2];

  const { hasAccessForDashboard } = userPermission(permission);

  return (
    <>
      <div className="wrapper">
        <div className="_tab">
          {["Production Schedule", "New Lead", "Contact Management"].map(
            (linkName, i) =>
              hasAccessForDashboard ? (
                <Link
                  key={i}
                  id={links[i]}
                  to={`${path}/${links[i]}`}
                  className={`_tabItem ${
                    activePath === links[i] ? "active" : ""
                  }`}
                  style={{ fontSize: "1.4rem" }}
                >
                  {linkName}
                </Link>
              ) : null
          )}
        </div>
        <Switch>
          <Route exact path="/dashboard" component={NewLead} />
          <Route
            path="/dashboard/contactmanagement"
            component={ContactManagement}
          ></Route>
          <Route path="/dashboard/production" component={ProductionSchedule} />
        </Switch>
      </div>
    </>
  );
};

export default Dashboard;
