export const metricsActionTypes = {
  METRIC_ROOFING_GET_START: "METRIC_ROOFING_GET_START",
  METRIC_ROOFING_GET_SUCCESS: "METRIC_ROOFING_GET_SUCCESS",
  METRIC_ROOFING_GET_FAILURE: "METRIC_ROOFING_GET_FAILURE",

  METRIC_ADD_ROOFING_START: "METRIC_ADD_ROOFING_START",
  METRIC_ADD_ROOFING_SUCCESS: "METRIC_ADD_ROOFING_SUCCESS",
  METRIC_ADD_ROOFING_FAILURE: "METRIC_ADD_ROOFING_FAILURE",

  SECTION_UPDATE_START: "SECTION_UPDATE_START",
  SECTION_UPDATE__SUCCESS: "SECTION_UPDATE__SUCCESS",
  SECTION_UPDATE__FAILURE: "SECTION_UPDATE__FAILURE",

  SECTION_DELETE_START: "SECTION_DELETE_START",
  SECTION_DELETE__SUCCESS: "SECTION_DELETE__SUCCESS",
  SECTION_DELETE__FAILURE: "SECTION_DELETE__FAILURE",
};
