import React, { useEffect, useState, useMemo } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
  CardElement,
} from "@stripe/react-stripe-js";
import { GetCompanySuccess, update_paid_in_user } from "lib/redux/user/user.actions";
import { toast } from "react-toastify";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import useSubscription from "hooks/useSubscription";
import "./CardElementStyles.css";
import useResponsiveFontSize from "./useResponsiveFontSize";
import { Add, MoneySend } from "iconsax-react";
import { Alert } from "react-bootstrap";
import { appURL } from "api/api";
import { useCompany } from "hooks/useCompany";
import { useDispatch } from "react-redux";
import { hideCheckoutModal } from "lib/redux/checkout/checkout.action";

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    [fontSize]
  );

  return options;
};

const useStyles = makeStyles({
  root: {
    maxWidth: 800,
    margin: "1rem",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignContent: "flex-start",
  },
  div: {
    display: "flex",
    flexDirection: "row",
    alignContent: "flex-start",
    justifyContent: "space-between",
  },
  button: {
    margin: "2em auto 1em",
  },
});

export default function CheckoutForm(props) {
  const [message, setMessage] = useState(null);
  const { create_subscription, change_card_data, loading } = useSubscription();
  const classes = useStyles();
  const {
    company_id,
    stripeClientId,
    onHide,
    from,
    additionalUser,
    clientSecret,
  } = props;
  // State
  const [email, setEmail] = useState("");
  const [cardHolderName, setCardHolderName] = useState("");
  const [errMsg, setErrorMsg] = useState("");
  const [errType, setErrorType] = useState("warning");
  const [stateClientSecret, setStateClientSecret] = useState();
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const { updatePaymentMethod, isLoading, error, updateResponse } = useCompany();
  const dispatch = useDispatch();
  
  useEffect(() => {
    setStateClientSecret(clientSecret);
  }, [clientSecret]);

  useEffect(() => {
    setEmail(props.email);
  }, [props.email]);


  useEffect(() => {
    if (updateResponse) {
      toast.success("Successfully Added the card.");
      dispatch(hideCheckoutModal());
      dispatch(GetCompanySuccess(updateResponse));
    }
    if (error) {
      toast.error("Oops! Something went wrong while adding card.");
    }
  }, [isLoading])

  const handle_pay = async (event) => {
    event.preventDefault();
    if (!cardHolderName && cardHolderName.length < 1) {
      setErrorMsg("Card holder name is required!")
      return;
    }
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      console.log("Card information is missing or invalid.");
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: cardHolderName,
      },
    });

    console.log({ paymentMethod, error });
    if (!error) {
      const body = {
        paymentMethodId: paymentMethod.id
      }

      updatePaymentMethod(company_id, body);

    } else {
      setErrorMsg(error.message);
      // const res = await create_subscription({
      //   payment_method: paymentMethod.id,
      //   email: email,
      //   companyId: company_id,
      //   additionalUser: additionalUser,
      // });

      // if (!res || !res.data || !res.data.result) {
      //   toast.error("Something Went Wrong");
      //   return;
      // }
      // toast.success("Your Subscription Was Successful");
      // localStorage.setItem("payment", "success");

      // update_paid_in_user({ companyId: company_id }).then((res) => {
      //   if (res.data.success) {
      //     toast.success("Payment Updated");
      //     toast.success("One Time Payment Was Successful");
      //     window.location.replace(`${appURL}/completion`);
      //   }
      // });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (!stripe || !elements) {
        return;
      }
      var paymentElement = elements.getElement("payment");
      stripe
        .createToken(paymentElement)
        .then((res) => { })
        .catch((err) => {
          console.log({ err });
        });
    } catch (error) {
      console.log({ error321: error });
    }
  };

  const sendTokenToBackend = async (token) => {
    try {
      change_card_data({ token: token.id, customerId: stripeClientId })
        .then((res) => {
          toast.success("Your card has been updated");
          onHide();
        })
        .catch((err) => {
          toast.error("Something Went Wrong");
          onHide();
        });
    } catch (error) {
      console.error("Error updating card information:", error.message);
      toast.error("Something Went Wrong");
      onHide();
    }
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return (
    <>
      {loading ? (
        <Alert variant="primary">Please Wait</Alert>
      ) : (
        <Card className={classes.root}>
          <CardContent className={classes.content}>
            {errMsg && <Alert variant={errType}>{errMsg}</Alert>}
            <div className="card-form">
              <div className="row">
                {!from ? (
                  <div className="col">
                    <div className="form-group">
                      <label className="custom-label" for="cardHolderNameInput">
                        Email
                      </label>
                      <input
                        value={props.email}
                        disabled={true}
                        type="email"
                        className="form-control"
                        id="cardHolderNameInput"
                        aria-describedby="nameHelp"
                      />
                      <small id="emailHelp" className="form-text text-muted">
                        Email you'll receive updates and receipts on.
                      </small>
                    </div>
                  </div>
                ) : null}
                <div className="col">
                  <div className="form-group">
                    <label className="custom-label" for="cardHolderNameInput">
                      Card Holder Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => setCardHolderName(e.target.value)}
                      id="cardHolderNameInput"
                      aria-describedby="nameHelp"
                    />
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <CardElement
                  id="card-element"
                  options={options}
                  className="card-element"
                />
              </div>

              <div className={classes.div}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  disabled={isLoading}
                  onClick={(event) => {
                    if (from && from === "client_admin") {
                      handleSubmit(event);
                    } else {
                      handle_pay(event);
                    }
                  }}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "10rem",
                  }}
                >
                  <Add size="32" color="#d9e3f0" />
                  {from ? "Edit Card Information" : "Add card"}
                </Button>
              </div>
            </div>
          </CardContent>
        </Card>
      )}
    </>
  );
}
