export const repairsActionTypes = {
    TASK_START: 'TASK_START',
    TASK_SUCCESS: 'TASK_SUCCESS',
    TASK_FAILURE: 'TASK_FAILURE',
    ADD_TASK_START: 'ADD_TASK_START',
    ADD_TASK_SUCCESS: 'ADD_TASK_SUCCESS',
    ADD_TASK_FAILURE: 'ADD_TASK_FAILURE',
    EDIT_TASK_START: 'EDIT_TASK_START',
    EDIT_TASK_SUCCESS: 'EDIT_TASK_SUCCESS',
    EDIT_TASK_FAILURE: 'EDIT_TASK_FAILURE',
    DELETE_TASK_START: 'DELETE_TASK_START',
    DELETE_TASK_SUCCESS: 'DELETE_TASK_SUCCESS',
    DELETE_TASK_FAILURE: 'DELETE_TASK_FAILURE',
    GET_REPAIR_REQUEST_START: 'GET_REPAIR_REQUEST_START',
    GET_REPAIR_REQUEST_SUCCESS: 'GET_REPAIR_REQUEST_SUCCESS',   
    GET_REPAIR_REQUEST_FAILURE: 'GET_REPAIR_REQUEST_FAILURE',   
    UPDATE_REPAIR_REQUEST_START: 'UPDATE_REPAIR_REQUEST_START',   
    UPDATE_REPAIR_REQUEST_SUCCESS: 'UPDATE_REPAIR_REQUEST_SUCCESS',   
    UPDATE_REPAIR_REQUEST_FAILURE: 'UPDATE_REPAIR_REQUEST_FAILURE',   
    TOGGLE_EDIT_MODE: "TOGGLE_EDIT_MODE",
}