const { Row, Col, Form } = require("react-bootstrap");
import DollarInputWrapper from 'components/inputs/DollarInputWrapper';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { calculateTax } from 'utils/utils';


const styles = {
    formInput: {
        marginBottom: "15px",
    },
};

export const RepairInfo = ({ selectedRequest, updateRepairData, setTotalPrice, totalPrice }) => {

    console.log({
        selectedRequest
    });

    const [requestFor, setRequestFor] = useState([]);
    const taxRates = useSelector(state => state.tasksRate);

    const [repairTypes, setRepairTypes] = useState([
        {
            repairTypeId: "repair01",
            repairTypeName: "Roof",
            other: false,
            selected: false
        },
        {
            repairTypeId: "repair02",
            repairTypeName: "Gutters",
            other: false,
            selected: false
        },
        {
            repairTypeId: "repair03",
            repairTypeName: "Siding",
            other: false,
            selected: false
        },
        {
            repairTypeId: "repair04",
            repairTypeName: "Windows",
            other: false,
            selected: false
        },
        {
            repairTypeId: "repair05",
            repairTypeName: "Doors",
            other: false,
            selected: false
        },
        {
            repairTypeId: "repair06",
            repairTypeName: "Other",
            other: true,
            selected: false
        },
    ])

    const selectedrepairTypes = (selectedRepair) => {

        const newRepairData = repairTypes?.map((repair) => {
            if (selectedRepair === repair.repairTypeId) {
                return {
                    ...repair,
                    selected: !repair.selected
                }
            }

            return repair;

        })

        setRepairTypes(newRepairData)

        const newRequestFor = newRepairData.filter(repair => repair.selected)?.map(repair => repair.repairTypeId) || [];

        updateValue("requestFor", newRequestFor);

    };


    console.log({
        repairTypes
    });


    useEffect(() => {
        if (selectedRequest?.requestFor?.length) {
            const newRepairData = repairTypes?.map((repair) => {
                return {
                    ...repair,
                    selected: selectedRequest?.requestFor?.includes(repair.repairTypeId)
                }
            })

            setRepairTypes(newRepairData)
        }
    }, [selectedRequest?.requestFor]);

    const calculateTotal = (servicePrice, taxRate) => {
        console.log({
            servicePrice, taxRate
        });

        const tax = calculateTax(Number(servicePrice), Number(taxRate)).totalAmount || 0;

        console.log({
            tax
        });

        setTotalPrice(tax)
    }

    const updateValue = (key, value) => {

        console.log({
            value,
            key
        });

        if (key === "taxRate") {
            calculateTotal(selectedRequest?.servicePrice, value)
        }

        if (key === "servicePrice") {
            calculateTotal(value, selectedRequest?.taxRate)
        }
        updateRepairData(key, value);
    }

    const displayRepairType = () => {
        return (
            <Row className='d-flex flex-column justify-center'>
                <b>Repair is for:</b>
                <div>
                    {repairTypes.map((request) => (
                        <button
                            key={request}
                            className={`serviceRequestBtn ${request?.selected && "selected"}`}
                            onClick={() => selectedrepairTypes(request.repairTypeId)}
                        >
                            {request?.repairTypeName}
                        </button>
                    ))}
                </div>
            </Row>
        )
    };

    return (
        <Row className='w-full p-2'>
            {displayRepairType()}

            <Row>
                <Col md="12" sm="12">
                    {requestFor.includes("repair06") && (
                        <Row>
                            <Col md="6" sm="6">
                                <Form.Group style={styles.formInput}>
                                    <Form.Control
                                        type="text"
                                        name="repair06Name"
                                        placeholder="Enter Other"
                                        value={repair06Name}
                                        onChange={(e) => setRepair06Name(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    )}
                    <Row>
                        <Col sm="8" md="6">
                            <Form.Group style={styles.formInput}>
                                <Form.Label>Did we perform original work?</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="originalWorkPerformed"
                                    value={selectedRequest?.originalWorkDone ? "true" : "false"}
                                    onChange={(event) => updateValue("originalWorkDone", event.target.value === "true")}
                                >
                                    <option value="true">YES</option>
                                    <option value="false">NO</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>

                        {selectedRequest?.originalWorkDone && (
                            <>
                                <Col sm="3" md="3">
                                    <Form.Group style={styles.formInput}>
                                        <Form.Label>Year</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="WorkPerformedYear"
                                            value={selectedRequest?.originalWorkYear}
                                            onChange={(event) => updateValue("originalWorkYear", event.target.value)}
                                            maxLength={4}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col sm="6" md="3">
                                    <Form.Group style={styles.formInput}>
                                        <Form.Label>Is it under Warranty?</Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="isUnderWarranty"
                                            value={selectedRequest?.underWarrenty ? "true" : "false"}
                                            onChange={(event) => updateValue("underWarrenty", event.target.value === "true")}
                                        >
                                            <option value="true">YES</option>
                                            <option value="false">NO</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>

                            </>
                        )}
                    </Row>

                    {!selectedRequest?.underWarrenty && (
                        <Row>
                            <Col sm="6" md="6">
                                <Form.Group style={styles.formInput}>
                                    <Form.Label>Repair/Service Price</Form.Label>
                                    <DollarInputWrapper>
                                        <Form.Control
                                            type="number"
                                            name="repairServicePrice"
                                            placeholder="Pre Tax"
                                            value={selectedRequest?.servicePrice}
                                            // value={totalPrice ? Number(totalPrice).toFixed(2) : 0}

                                            onChange={(event) => updateValue("servicePrice", event.target.value)}
                                        />
                                    </DollarInputWrapper>

                                </Form.Group>
                            </Col>
                            <Col sm="6" md="3">
                                <Form.Group style={styles.formInput}>
                                    <Form.Label>Tax Rate</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="taxRate"
                                        value={selectedRequest?.taxRate}
                                        onChange={(event) => updateValue("taxRate", event.target.value)}
                                    >
                                        <option value="">Choose...</option>
                                        {taxRates?.taskratedata?.length > 0 &&
                                            taxRates?.taskratedata?.map((item) => (
                                                <option key={item.taxId} value={item.taxPercentage}>
                                                    {item.taxDescription}
                                                </option>
                                            ))
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col sm="6" md="3">
                                <Form.Group style={styles.formInput}>
                                    <Form.Label>Total</Form.Label>
                                    <DollarInputWrapper>
                                        <Form.Control
                                            type="number"
                                            // value={totalPrice ? Number(totalPrice?.toFixed(2)) || 0}
                                            value={totalPrice ? Number(totalPrice).toFixed(2) : 0}
                                            disabled={true}
                                            style={{
                                                paddingLeft: '25px'  // Adjust padding to make room for the dollar sign
                                            }}
                                        />
                                    </DollarInputWrapper>
                                </Form.Group>
                            </Col>

                        </Row>
                    )}

                    <Row>
                        <Col>
                            <Form.Group style={styles.formInput}>
                                <Form.Label>Description of Repair</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows="2"
                                    name="repairDescription"
                                    value={selectedRequest?.repairDescreption}
                                    onChange={(event) => updateValue("repairDescreption", event.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Row>
    );
};