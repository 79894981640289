import InitialLoader from 'components/InitilalLoader';
import { ApiEndpoints } from 'lib/config/baseSettings';
import { addTask, fetchTask, toggleEditMode, updateTask } from 'lib/redux/repairs/repairs.actions';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { axiosInstance } from 'services';
import { TaskCard } from './TaskCard';
import { useSelector } from 'react-redux';
import { Alert, Button, Col, Row } from 'react-bootstrap';

import { useLocation } from 'react-router-dom';


const styles = {
    mainRow: {

    }
}
export const RepairTasks = () => {

    const [isLoadingLeads, setIsLoadingLeads] = useState(false);
    const [taskName, setTaskName] = useState("");

    const dispatch = useDispatch();
    // const [allTasks, setAllTasks] = useState([]);

    const [msgType, setMsgType] = useState("");
    const [msgFor, setMsgFor] = useState("!modal");

    const [msg, setMsg] = useState("");

    const { isLoading: isLoadingTasks, data: allTasks, errors, ...rest } = useSelector(state => state.tasks);

    console.log({
        task12345: errors,
        rest,
        location
    });

    const [allTaskLists, setAllTaskLists] = useState([]);


    console.log({ allTaskLists });


    useEffect(() => {
        if (!isLoadingTasks && allTasks?.length) {
            setAllTaskLists(allTasks)
        }
    }, [isLoadingTasks, allTasks]);

    useEffect(() => {
        if (errors) {
            setMsgType("error");
            setMsg(errors || "Something went wrong!")
        } else {
            setMsg("");
            setMsgType("")
        }

    }, [errors]);


    const renderEmptyTab = () => (
        <div className="empty-_tab">
            <p>No new tasks are added.</p>
        </div>
    );


    function displayTabItem(data) {
        const cardData = {
            taskName: data?.details,
            dateChoosen: data?.dueDate,
            handleDateChangesave: handleDateChangesave
        }

        return (
            <TaskCard {...cardData}
                cancelEdit={cancelEdit}
                task={data}
                editTask={editTask}
                completeTask={completeTask}
                deleteTask={deleteTask}
                setTaskName={setTaskName}
                updateValue={updateValue}
                // taskName={taskName}
                handleEdit={handleEdit}
                handleDateChangesave={handleDateChangesave}
            />
        )
    }

    function addTabItem() {

    }

    function handleDateChangesave(data) {
        console.log({
            data
        });

    }

    const editTask = (id) => {
        console.log({
            id
        });

        dispatch(toggleEditMode(id))

    }

    const cancelEdit = (id) => {
        console.log({
            id
        });

        dispatch(toggleEditMode(id))

    }


    const completeTask = (id) => {
        console.log({
            id
        });
        const body = {
            isComplete: true,
            _id: id,
        }
        dispatch(updateTask(body, id))

    }


    const deleteTask = (id) => {
        console.log({
            id
        });
        const body = {
            isDeleted: true,
            _id: id,
        }
        dispatch(updateTask(body, id))

    }

    const addNewTask = () => {
        //    const newAllTaskLists =  allTaskLists.unshift({
        //         dueDate: new Date(),
        //         createdAt: new Date(),
        //         details: ''
        //     });

        setAllTaskLists(prevLists => ([{
            dueDate: new Date(),
            createdAt: new Date(),
            details: '',
            edit: true
        }, ...(prevLists?.length ? prevLists : [])]))
    }


    const updateValue = (id, key, value) => {
        const newTasks = allTaskLists.map(taskObject => {
            let task = taskObject;
            if (taskObject?._id === id) {
                task = { ...task, [key]: value }
            }

            return task;
        })

        setAllTaskLists(newTasks)
    }


    const handleEdit = (id) => {
        console.log({
            id
        });

        if (!id) {

            // return;
            const currentTask = allTaskLists?.find(task => !task?._id);
            console.log({
                currentTask
            });

            const body = {
                details: currentTask?.details,
                dueDate: currentTask?.dueDate
            }

            dispatch(addTask(body))
            return;
        }

        const currentTask = allTaskLists?.find(task => task?._id === id);

        const body = {
            _id: id,
            details: currentTask?.details,
            dueDate: currentTask?.currentTask
        }
        dispatch(updateTask(body, id))

    }
    return (
        <Row className='my-3 mx-1 relative mb-5'>
            <Row>
                {msg && (
                    <Alert variant={msgType}>{msg}</Alert>
                )}
            </Row>

            <Row className='d-flex flex-row mb-3 justify-content-end'>
                <Button style={{
                    width: '8rem'
                }}
                    onClick={() => addNewTask()}
                >
                    New Task
                </Button>
            </Row>
            {/* 
            <Row>
               {  addTabItem()}
            </Row> */}

            <Row>
                {!isLoadingTasks ? (
                    <>
                        {
                            allTaskLists?.length ? (
                                allTaskLists?.map((data) => (
                                    displayTabItem(data)
                                ))
                            ) : (
                                renderEmptyTab()
                            )
                        }


                    </>
                ) : (
                    // <InitialLoader onReload={getAllLeads} />
                    <InitialLoader />
                )}
            </Row>
        </Row>
    )
}