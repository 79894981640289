import InitialLoader from "components/InitilalLoader";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { DragDropContainer } from "react-drag-drop-container";
import { FilterCircleOutline, FilterCircle } from "react-ionicons";
import { PiHandGrabbing, PiHand } from "react-icons/pi";

export const CalendarEventsList = ({
  loading,
  error,
  handleDrop,
  onDragStart,
  requests,
  searchResult,
  searchTerm,
  onReload,
}) => {
  console.log({
    requests,
  });

  let hasTouchScreen = false;

  if ("maxTouchPoints" in navigator) {
    hasTouchScreen = navigator.maxTouchPoints > 0;
  }

  const [isDragging, setIsDragging] = useState(false);

  if (loading || error) {
    return <InitialLoader error={error} onReload={onReload} />;
  }
  const listData = searchTerm.trim() ? searchResult : requests;
  if (!listData || listData.length === 0) {
    return (
      <div className="empty-message">
        <div>No request found.</div>
      </div>
    );
  }

  return (
    listData &&
    listData.length > 0 &&
    listData.map((request, i) => {
      let reqs = "";
      request.requestFor.map((req) =>
        reqs ? (reqs = `${reqs} & ${req}`) : (reqs = `${req}`)
      );
      return (
        <div key={i}>
          <DragDropContainer
            noDragging={!isDragging && hasTouchScreen}
            targetKey="calendar"
            key={request.requestId}
            onDragStart={(e) => {
              onDragStart(e, request);
            }}
            onDrop={(e) => {
              handleDrop(e, request);
              setIsDragging(false);
            }}
          >
            <li draggable={isDragging || !hasTouchScreen}>
              <Row>
                <Col sm="10" md="10">
                  {request.companyNameEntered.length > 0 && (
                    <h5>{request.companyNameEntered}</h5>
                  )}
                  {request.companyNameEntered.length > 0 ? (
                    <h6 className="title">{request.leadName}</h6>
                  ) : (
                    <h5>{request.leadName}</h5>
                  )}
                  <a
                    href={`https://www.google.com/maps/place?q=${request.address}`}
                    className="address"
                    style={{
                      textDecoration: "underline",
                    }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {request.propertyName + " " + request.address}
                  </a>
                  <p className="type">
                    {request.propertyType} - {reqs}
                  </p>
                  <p className="date">
                    {new Date(request.createdOn).toDateString()}
                  </p>
                </Col>
                {hasTouchScreen && (
                  <Col
                    sm="2"
                    md="2"
                    style={{
                      paddingRight: "5px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "right",
                    }}
                  >
                    {isDragging ? (
                      <PiHandGrabbing
                        onClick={() => {
                          setIsDragging(false);
                        }}
                        color={"#00000"}
                        size={30}
                        fill="#fff"
                        className="p-1 bg-black border border-black"
                        style={{
                          borderRadius: "9999px",
                        }}
                      />
                    ) : (
                      <PiHand
                        onClick={() => {
                          setIsDragging(true);
                        }}
                        color={"#00000"}
                        size={30}
                        className="p-1 border border-black"
                        style={{
                          borderRadius: "9999px",
                        }}
                      />
                    )}
                  </Col>
                )}
                {/* <Col sm="2" md="2">
                <div className="arrow">
                  <img src={Arrow} height={20} width={20} alt="arrow" />
                </div>
              </Col> */}
              </Row>
            </li>
          </DragDropContainer>
        </div>
      );
    })
  );
};
