import { axiosInstance as axios } from "services";
import { getEndPoints } from "api/api";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ApiEndpoints } from "lib/config/baseSettings";

const url = getEndPoints(process.env.REACT_APP_MODE);

const useContactData = () => {
  const { success } = useSelector((state) => state.saveLead);
  const { successEdit } = useSelector((state) => state.editLead);

  const [selectedContact, setSelectedContact] = useState();
  const [addContactmanagement, setAddContactmanagement] = useState(false);
  const [addNewSvc, setAddNewSvc] = useState(false);
  const [editContactManagement, setEditContactManagement] = useState("");
  const [contactManagement, setContactManagement] = useState([]);
  const [contactManagementSchedule, setContactManagementSchedule] =
    useState(false);
  const [sendEmail, setSendEmail] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isDetailLoading, setIsDetailLoading] = useState(false);
  const [error, setError] = useState();
  const [contactManagementData, setContactManagementData] = useState([]);
  const [selectedLeadId, setSelectedLeadId] = useState("");
  const [requestLoading, setRequestLoading] = useState(false);
  const [companyData, setCompanyData] = useState();

  useEffect(() => {
    getContactList();
  }, []);

  useEffect(() => {
    if (selectedLeadId && selectedLeadId !== "") {
      getContactDetails({ leadId: selectedLeadId });
    }
  }, [
    selectedLeadId,
    contactManagement,
    editContactManagement,
    addNewSvc,
    success,
    successEdit,
    addContactmanagement,
  ]);

  const getContactList = () => {
    setIsLoading(true);
    return axios
      .get(ApiEndpoints.CONTACT.getContactList)
      .then((response) => {
        if (response.data.success) {
          setContactManagement(response.data.result);
          setContactManagementData(response.data.result);
          setIsLoading(false);
        }
        return { error: null, response };
      })
      .catch((error) => {
        if (error.response) {
          setIsLoading(false);
          setError(error.response.message | "Something went wrong...");
          return { error: error.response };
        }
      });
  };

  const getContactDetails = (body) => {
    setIsDetailLoading(true);
    return axios
      .get(
        ApiEndpoints.CONTACT.getContactDetails.replace(
          ":leadAddressId",
          body.leadId
        ),
        body
      )

      .then((response) => {
        if (response.data.success) {
          setSelectedContact(response.data.result[0]);
        }
        return { error: null, response };
      })
      .catch((error) => {
        if (error.response) {
          return { error: error.response };
        }
      })
      .finally(() => {
        setIsDetailLoading(false);
      });
  };

  const ContactManagementSchedule = (body) => {
    return axios
      .post(ApiEndpoints.CONTACT.contactManagementSchedule, body)

      .then((response) => {
        if (response.data.success) {
          setContactManagementSchedule(response.data.success);
        }
        return { error: null, response };
      })
      .catch((error) => {
        if (error.response) {
          return { error: error.response };
        }
      });
  };
  const AddContactManagementProperty = (body) => {
    setRequestLoading(true);
    setAddContactmanagement(false);
    return axios
      .post(ApiEndpoints.CONTACT.addContactManagementProperty, body)

      .then((response) => {
        if (response.data.success) {
          setAddContactmanagement(response.data.success);
          toast.success(response.data.result.message);
        }
        return { error: null, response };
      })
      .catch((error) => {
        if (error.response) {
          return { error: error.response };
        }
      })
      .finally(() => {
        setRequestLoading(false);
      });
  };

  const AddNewSvcRequestToProperty = (propertyId, body) => {
    setRequestLoading(true);
    setAddNewSvc(false);
    return axios
      .post(
        ApiEndpoints.CONTACT.addNewSvcRequestToProperty.replace(
          ":propertyId",
          propertyId
        ),
        body
      )

      .then((response) => {
        if (response.data.success) {
          setRequestLoading(false);
          getContactList();
          setAddNewSvc(response.data.success);
          toast.success(
            body.type === "service"
              ? "Successfully added new service"
              : "You will be redirected to calendar page soon"
          );
        }
        return { error: null, response };
      })
      .catch((error) => {
        if (error.response) {
          setRequestLoading(false);

          return { error: error.response };
        }
      });
  };

  const ContactManagementSearch = (query) => {
    return axios
      .get(ApiEndpoints.CONTACT.getContactList, {
        params: query,
      })

      .then((response) => {
        if (response.data.success) {
          setContactManagement(response.data.result);
        }
        return { error: null, response };
      })
      .catch((error) => {
        if (error.response) {
          return { error: error.response };
        }
      });
  };

  const editContactManagementProperty = (id, body) => {
    setRequestLoading(true);
    return axios
      .patch(
        ApiEndpoints.CONTACT.editContact.replace(":leadAddressId", id),
        body
      )

      .then((response) => {
        if (response.data.success) {
          getContactList();
          setEditContactManagement(response.data.result.message);
          toast.success(response.data.result.message);
        }

        if (!response.data.result.success) {
          return { error: response.data.result.error };
        }

        return { error: null, response };
      })
      .catch((error) => {
        if (error.response) {
          return { error: error.response };
        }
      })
      .finally(() => {
        setRequestLoading(false);
      });
  };

  const getCompanyDetails = (body) => {
    return axios
      .post(ApiEndpoints.CONTACT.getCompanyDetails)

      .then((response) => {
        if (response.data.success) {
          setCompanyData(response.data.result);
          return { error: null, response };
        }
        return { error: null, response };
      })
      .catch((error) => {
        if (error.response) {
          return { error: error.response };
        }
      });
  };

  return {
    addContactmanagement,
    setAddContactmanagement,
    sendEmail,
    contactManagement,
    AddContactManagementProperty,
    ContactManagementSchedule,
    ContactManagementSearch,
    contactManagementSchedule,
    isLoading,
    error,
    getContactDetails,
    isDetailLoading,
    success: success,
    selectedContact,
    setSelectedContact,
    setContactManagement,
    editContactManagementProperty,
    editContactManagement,
    contactManagementData,
    addNewSvc,
    setAddNewSvc,
    AddNewSvcRequestToProperty,
    selectedLeadId,
    setSelectedLeadId,
    requestLoading,
    getCompanyDetails,
    companyData,
    getContactList,
  };
};
export default useContactData;
