import React from "react";
import { Button, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { ShowModalGutter } from "../../redux/gutter-metric-collection/gutter-metric.action";
import { useSelector } from "react-redux";
import { calculateTotalGutterMetric, getTotalValue, gutterCategoryMappings } from "utils/utils";

// Define styles at the top
const rowStyle = (width, btnsLength) => ({
  margin: "1em 0",
  width: width ? `${btnsLength * 200}px` : "100%",
  justifyContent: btnsLength > 3 ? "space-between" : 'null',
});

const divStyle = {
  margin: "0 1em 1em 0",
  maxWidth: "170px",
  minWidth: "150px",
};

const mainSection = {
    paddingLeft: '1.5rem'
}

const buttonStyle = {
  margin: "0 0 1em 0",
  width: "100%",
  height: "45px",
  padding: "0",
  fontWeight: "700",
};

const formControlStyle = {
  textAlign: "center",
  height: "45px",
  fontWeight: "600",
};


const labelStyle = {
  margin: "0px 0px 1em",
  width: "100%",
  fontWeight: "bold",
  textAlign: "center"
}

export default function GutterDisplayBtnsTab({ btns = [], width = false }) {

  console.log({ btns });
  const dispatch = useDispatch();

  const gutterMetrics = useSelector((state) => state.gutterMetric?.metrics);

  function onClickedTab(currentBtn) {
    const data = {
      showModal: true,
      data: currentBtn
    }
    dispatch(ShowModalGutter(data))
  }

  // function getValue(currentBtn) {
  //   const currentCategoryValue = currentBtn ? metric?.[currentBtn?.categoryName][currentBtn?.key] : [];
  //   const total = currentCategoryValue?.length ? getTotalValue(currentCategoryValue) : 0;
  //   return total ? total : 'Total';
  // }

  const renderButton = (btn) => {
    const mappedCategory = gutterCategoryMappings[btn?.categoryName];
    const value = gutterMetrics?.[mappedCategory]?.[btn?.key];

    console.log({
      value,
      gutterMetrics,
      mappedCategory,
      btn
    });

    return (
      <div style={mainSection}>
        <Button
          onClick={() => onClickedTab(btn)}
          style={buttonStyle} /* onClick={btn.onClick} disabled={btn.disable} */
        >
          {btn.name}
        </Button>

        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
          {value?.options &&
            Object.keys(value.options).map(optionKey => {
              const option = value.options[optionKey];
              return (
                <label
                  key={optionKey}
                  style={labelStyle}
                >
                  {option.name}
                </label>
                // <span key={optionKey}>
                //     {option.name}
                // </span>
              );
            })
          }

          {
            value?.values?.length ? (
              <Form.Control
                style={formControlStyle}
                type="text"
                disabled
                // value={btn.total ? numeral(btn.total).format("0,0") : ""}
                value={calculateTotalGutterMetric(value, btn.unitKey)}
                placeholder="Total"
              />
            ) : null}

        </div>

      </div>
    );
  }

  return (
    <Row style={rowStyle(width, btns?.length)}>
      {btns.map((btn) => (
        <div style={divStyle} key={btn.name}>
          {renderButton(btn)}
          <div>

          </div>
          {/* {typeof btn.total !== "undefined" && ( */}
          {/* <Form.Control
            style={formControlStyle}
            type="text"
            disabled
            // value={btn.total ? numeral(btn.total).format("0,0") : ""}
            // value={getValue(btn)}
            placeholder="Total"
          /> */}
          {/* )} */}
        </div>
      ))}
    </Row>
  );
}
