import React, { useEffect, useLayoutEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Row, Col, Button, Form, Modal } from "react-bootstrap";
import Components, {
  PriceInput,
  ModalTemplate,
  LoadingLoader,
} from "components";
import { useDispatch, useSelector } from "react-redux";
import styles from "lib/config/baseStyles";

import { toast } from "react-toastify";
import DebrisRemoval from "./components/EstimatorTabs/Carting";
import Material from "./components/EstimatorTabs/Material";
import RoofingLabor from "./components/EstimatorTabs/Labor";
import PercentageModal from "./ProposalTab/PercentageModal";
import NewQuoteModal from "./components/NewQuoteModal";
import { QuoteNamePopup } from "./components/QuoteNameInput";
import SectionListModal from "./components/SectionListModal";
import { getMetrics } from "screens/metric/redux/metrics-v2/metrics.actions";
import { generateQuotes } from "./redux/proposals/proposals.actions";
import Gutters from "./components/EstimatorTabs/Gutters";
import TotalQuote from "./components/EstimatorTabs/TotalQuote";

export default function Estimator({ requestId }) {
  const [selectedTab, setSelectedTab] = useState();
  const [showNewQuote, setShowNewQuote] = useState(false);
  const [altProposalName, setAltProposalName] = useState("");
  const [QPDeleteModal, setQPDeleteModal] = useState(false);

  const [tabs, setTab] = useState(["Total"]);
  const [showEditProposalNameModal, setshowEditProposalNameModal] =
    useState(false);

  const [showMetricSelectionModal, setShowMetricSelectionModal] =
    useState(false);
  const dispatch = useDispatch();
  console.log({ selectedTab });
  const proposalData = useSelector((state) => state.proposal);
  const { isLoadingProposal, selectedProposal } = proposalData;

  // Check type of request and set the tabs
  useEffect(() => {
    if (selectedProposal?.requestFor) {
      const { hasGutters, hasRoof } = selectedProposal.requestFor;

      setTab((prevState) => {
        let newTabs = [];

        // If hasRoof is true, add "Material" and "Labor"
        if (hasRoof) {
          newTabs = ["Material", "Labor"];
        }

        // If hasGutters is true, add "Gutter"
        if (hasGutters) {
          newTabs.push("Gutter");
        }

        // Always add "Carting" as the last tab
        newTabs.push("Carting");

        // Combine newTabs with prevState, avoiding duplicates
        const updatedTabs = [...newTabs, ...prevState];
        const uniqueTabs = [...new Set(updatedTabs)];

        // Set the default selectedTab based on the available options
        if (!hasRoof && hasGutters) {
          setSelectedTab("Gutter");
        } else if (hasRoof) {
          setSelectedTab("Material");
        }

        return uniqueTabs;
      });
    }
  }, [selectedProposal?.requestFor]);

  const changeTab = (tab) => (selectedTab !== tab ? setSelectedTab(tab) : {});

  const hideNQModal = (setShowModal) => {
    setShowNewQuote(false);
    setShowModal(true);
  };

  const displayBuildingsOrSections = () => {
    if (showBuilding) {
      hideNQModal(setBuildingModal);
    } else {
      hideNQModal(setSectionModal);
    }
  };

  const switchTab = () => {
    switch (selectedTab) {
      case "Material":
        return <Material />;

      case "Labor":
        return <RoofingLabor />;

      case "Gutter":
        return <Gutters requestId={requestId} />;
      case "Carting":
        return <DebrisRemoval requestId={requestId} />;
      case "Total":
        return <TotalQuote />;

      default:
        return (
          <div style={{ paddingTop: "50px" }}>
            <div className="__msg">
              Please selecte one of the above tab to continue.
            </div>
          </div>
        );
    }
  };

  const changeTabKey = (key) => setKey(key);

  const LinkBtn = ({ to }) => (
    <Link rel="noreferrer" to={to}>
      <Button variant="outline-secondary">
        <strong>Open Metric</strong>
      </Button>
    </Link>
  );

  const showAlternateQuoteModal = () => {
    setShowNewQuote(true);
    // setName("");
  };

  const getMetricLink = () => {
    const { requestFor } = selectedProposal;
    let metricLink = "";
    if (requestFor?.hasGutters && requestFor?.hasRoof) {
      metricLink = `/dashboard/metrics/roof/${requestId}`;
    } else if (requestFor?.hasGutters) {
      metricLink = `/dashboard/metrics/gutters/${requestId}`;
    } else {
      metricLink = `/dashboard/metrics/roof/${requestId}`;
    }

    console.log({ metricLink });

    return metricLink;
  };

  const SupplierInfo = () => (
    <div className="_cont_tab __cont_quote">
      <div className="_quote_cont">
        <Row
          style={{
            justifyContent: "space-between",
          }}
          className="__quote_estimator_head"
        >
          <Col style={styles.reduceWidthCol}>
            <div className="__quote_top_btns">
              <Button
                // disabled={isLockQuoteData?.isLocked}
                variant="danger"
                // onClick={() => setQLockedModal(true)}
              >
                {/* <strong>
                  {isLockQuoteData?.isLocked ? "Locked" : "Lock Quote"}
                </strong> */}
                Lock Quote
              </Button>
              <Button
                variant="danger"
                // onClick={() => setQPDeleteModal(true)}
                // disabled={isLockQuoteData?.isLocked}
              >
                <strong>Delete Quote</strong>
              </Button>
              <Button
                variant="outline-secondary"
                onClick={() => {
                  setshowEditProposalNameModal(true);
                }}
              >
                <strong>Edit Name</strong>
              </Button>
              <LinkBtn to={getMetricLink()} />
              {/* {!type && (
                <Button
                  variant="outline-secondary"
                  onClick={showAlternateQuoteModal}
                >
                  <strong>Alternate Quote</strong>
                </Button>
              )} */}
              <Button
                variant="outline-secondary"
                onClick={showAlternateQuoteModal}
              >
                <strong>Alternate Quote</strong>
              </Button>

              <Button
              // onClick={changeTabProposal}
              >
                <strong>Write Proposal</strong>
              </Button>
            </div>
          </Col>
        </Row>
      </div>
      &nbsp;&nbsp;
      {/* {isLockQuoteData?.isLocked && (
        <span style={{ fontWeight: "bold", fontSize: "larger" }}>
          {moment(isLockQuoteData?.isLockedTime).format("lll")}
        </span>
      )} */}
      {!isLoadingProposal ? (
        <div className="__quote_new_tab">
          <ul>
            {tabs?.map((tab) => {
              // if (hideTabMappig[tab]) return null;

              return (
                <li
                  key={tab}
                  role="button"
                  onClick={() => changeTab(tab)}
                  className={selectedTab === tab ? "active" : ""}
                >
                  {tab}
                </li>
              );
            })}
          </ul>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#01010101",
          }}
        >
          <LoadingLoader />
        </div>
      )}
      {switchTab()}
    </div>
  );

  const onHideModal = () => {
    setShowNewQuote(false);
  };

  const onHide = () => {
    setShowMetricSelectionModal(false);
  };

  const handleChangeNewAltProposal = (event) => {
    const alterProposalName = event.target.value;
    setAltProposalName(alterProposalName);
  };
  console.log({
    altProposalName,
  });
  const onSaveNewProposalName = () => {
    setShowNewQuote(false);
    setShowMetricSelectionModal(true);
    dispatch(getMetrics(requestId));
  };

  const onSaveNewQuote = (sections) => {
    const body = {
      requestId: requestId,
      alternativeProposal: {
        name: altProposalName,
        sections: sections,
      },
    };
    dispatch(generateQuotes(body, history));
  };

  const changeQuoteName = (ee) => {
    console.log({
      ee,
    });
  };
  return (
    <div className="__quote_estimator">
      <NewQuoteModal
        show={showNewQuote}
        onHide={onHideModal}
        name={altProposalName}
        handleChange={(e) => handleChangeNewAltProposal(e)}
        onSaveProposal={onSaveNewProposalName}
        // confirm={displayBuildingsOrSections}
        // disableSave={!name.trim()}
      />

      {/* {showEditProposalNameModal && (
       
      )} */}

      <QuoteNamePopup
        currentName={"sete"}
        setDisplayInp={setshowEditProposalNameModal}
        displayInp={showEditProposalNameModal}
        changeQuoteName={changeQuoteName}
      />

      {SupplierInfo()}

      <SectionListModal
        // showBuilding={showBuilding}
        altProposalName={altProposalName}
        // metrics={metrics}
        show={showMetricSelectionModal}
        onHide={onHide}
        onSaveNewQuote={onSaveNewQuote}
        // toggleModals={toggleBuildingAndSectionModal}
        // selectedBuilding={selectedBuilding}
        // selectedBuildingName={selectedBuildingName}
        // setSelectedBuilding={setSelectedBuilding}
        // setSelectedBuildingName={setSelectedBuildingName}
        // excludedBuildings={excludedBuildings}
        // setExcludedBuildings={setExcludedBuildings}
        // excludedSections={excludedSections}
        // setExcludedSections={setExcludedSections}
        // onSaveNewQuote={onSaveNewQuote}
        // isAllSectionRoof={isAllSectionRoof}
        // setIsAllSectionRoof={setIsAllSectionRoof}
        // isAllSectionGutter={isAllSectionGutter}
        // setIsAllSectionGutter={setIsAllSectionGutter}
      />
    </div>
  );
}
