import React, { useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import PropTypes from "prop-types";
import DimensionModal from "../DimensionModal";
import { formatNumber, sumDimensonTotal } from "utils/utils";
import { useSelector } from "react-redux";
import DeleteModal from "components/DeleteModal";

const Dimensions = ({
  disabled,
  dimensions,
  isQuoteLock,
  lwfData = [],
  addDimensionData,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedDimension, setSelectedDimension] = useState("");
  const roofData = useSelector((state) => state.metric.metrics);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState({});

  const [dimension, setDimension] = useState({
    length: 0,
    width: 0,
    factor: 0,
    total: 0,
    id: "",
  });

  const setDataAndShowModal = (value) => {
    console.log({
      value,
    });

    setShowDeleteModal(true);
    setDeleteData(value);
  };

  const deleteDimension = () => {
    addDimensionData(deleteData, "delete");
    setShowDeleteModal(false);
  };

  const calculateTotal = (dimensionObj) => {
    return Object.entries(dimensionObj)
      .filter(([key]) => key !== "total" && key !== "_id")
      .reduce((acc, [key, value]) => acc * (Number(value) || 1), 1);
  };

  const handleDimensionData = (selectedDimension, num) => {
    console.log({ selectedDimension, num, dimension });
    setDimension((prevDimension) => {
      // Determine the new value for the dimension, allowing 0 as a valid input
      const newValue =
        num !== undefined ? (prevDimension[selectedDimension] || "") + num : "";

      const updatedDimension = {
        ...prevDimension,
        [selectedDimension]: newValue,
      };

      let updatedTotal = 0;
      if (
        updatedDimension.factor &&
        updatedDimension.length &&
        updatedDimension.width
      ) {
        updatedTotal = calculateTotal(updatedDimension);
      }
      return {
        ...updatedDimension,
        total: updatedTotal,
      };
    });
  };

  const handleAddDimensionData = () => {
    addDimensionData(dimension);
    setDimension({});
  };

  const getTotalDimension = () => {
    return sumDimensonTotal(dimensions)
      ? formatNumber(sumDimensonTotal(dimensions))
      : 0;
  };
  return (
    <Col>
      <label className="btnheading">Dimensions</label>
      <Col style={{ margin: 0, padding: "1px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "min-content",
          }}
        >
          <button
            className={`ClickableBtn ${
              dimensions.length > 0 ? "selected" : ""
            }`}
            disabled={disabled}
            style={
              isQuoteLock && lwfData.length > 0
                ? {
                    backgroundColor: "#60abfb",
                    borderColor: "#60abfb",
                    height: "52.4px",
                    width: "fit-content",
                  }
                : { height: "52.4px", width: "fit-content" }
            }
            onClick={() => {
              setModalOpen(!modalOpen);
              setSelectedDimension("length");
            }}
          >
            Dimensions
          </button>
          <Form.Control
            style={{
              textAlign: "center",
              width: "calc(100% - 5px)",
              margin: "1px 0 0 5px",
            }}
            type="text"
            placeholder="Total"
            value={getTotalDimension() || "Total"}
            disabled={true}
          />
        </div>
      </Col>
      <DimensionModal
        deleteCurrentValue={setDataAndShowModal}
        dimensions={dimensions}
        show={modalOpen}
        hide={() => setModalOpen(!modalOpen)}
        label="Dimension"
        selectDimension={selectedDimension}
        setSelectedDimension={setSelectedDimension}
        lwfData={lwfData}
        addDimensionData={handleDimensionData}
        length={dimension.length}
        width={dimension.width}
        factor={dimension.factor}
        setDimension={setDimension}
        total={dimension.total}
        editDimensionName={""}
        handleAddDimensionData={handleAddDimensionData}
      />
      <DeleteModal
        deleteData={deleteDimension}
        hideDeleteModal={() => setShowDeleteModal(false)}
        onCancel={() => setShowDeleteModal(false)}
        heading="Section Delete Confirmation"
        showDeleteModal={showDeleteModal}
      />
    </Col>
  );
};

Dimensions.propTypes = {
  installTypes: PropTypes.any,
  dimensions: PropTypes.array.isRequired,
  isQuoteLock: PropTypes.bool.isRequired,
  lwfData: PropTypes.array,
  addDimensionData: PropTypes.func.isRequired,
};

export default Dimensions;
