import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, Spinner } from "react-bootstrap";
import RequiredText from "components/required-text/required-text";
import { useSelector, useDispatch } from "react-redux";
import { getDDData } from "lib/redux/drop-down/drop-down.action";
import { styles } from "../styles";

const displayLeadSourceTypes = (data) => {
  return data.map((item) => (
    <option key={item._id} value={item._id}>
      {item.leadSource}
    </option>
  ));
};

const showLeadSourceText = (leadSourcesType, leadSource) => {
  if (leadSource !== "") {
    const index = leadSourcesType.findIndex(
      (leadItem) => leadItem._id === leadSource
    );
    return index > -1 ? leadSourcesType[index].leadSourceText : false;
  }
  return false;
};

export default function LeadSourcesFields({
  selectedContact,
  editContactManagementProperty,
  leadSourcesType,
  editLeadLoading,
}) {
  const dispatch = useDispatch();
  const { dataList, gettingDDData, listErr } = useSelector(
    (state) => state.ddList
  );
  const [sources, addSources] = useState([]);
  const [isEditLeadSource, setIsEditLeadSource] = useState(false);
  const [leadSource, setLeadSource] = useState("");
  const [leadSourceText, setLeadSourceText] = useState("");

  const handleSaveLeadSource = () => {
    editContactManagementProperty(selectedContact.primary_data[0]._id, {
      leadSource: leadSource,
      leadSourceText: leadSourceText,
    }).then(() => {
      setIsEditLeadSource(false);
    });
  };

  const loadFieldData = (lead) => {
    setLeadSource(lead.leadSource);
    setLeadSourceText(lead.leadSourceText);
  };

  useEffect(() => {
    dispatch(getDDData("leadSources"));
  }, []);

  useEffect(() => {
    loadFieldData(selectedContact.primary_data[0]);

    return () => {
      setIsEditLeadSource(false);
    };
  }, [selectedContact]);

  useEffect(() => {
    if (dataList) {
      addSources(dataList);
    }
  }, [dataList]);

  return (
    <Row style={{ padding: "10px 0", marginTop: "25px" }}>
      <Col md="3" sm="6">
        <Form.Group style={styles.formInput}>
          <Form.Label>
            Lead Source<RequiredText>*</RequiredText>
          </Form.Label>
          <Form.Control
            as="select"
            name="leadSource"
            value={leadSource}
            onChange={(event) => {
              setLeadSource(event.target.value);
              setIsEditLeadSource(true);
            }}
          >
            <option value="">Choose...</option>

            {displayLeadSourceTypes([...sources, ...leadSourcesType])}
          </Form.Control>
        </Form.Group>
      </Col>

      {showLeadSourceText([...sources, ...leadSourcesType], leadSource) && (
        <Col md="3" sm="6">
          <Form.Group style={styles.formInput}>
            <Form.Label>
              <RequiredText>*</RequiredText>
            </Form.Label>
            <Form.Control
              type="text"
              name="leadSourceText"
              value={leadSourceText}
              placeholder="Enter Name"
              onChange={(e) => {
                setLeadSourceText(e.target.value);
                setIsEditLeadSource(true);
              }}
            />
          </Form.Group>
        </Col>
      )}

      <Col>
        {isEditLeadSource ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Button
              variant="success"
              disabled={editLeadLoading}
              className="mt-3 btn btn-success ms-4"
              onClick={() => handleSaveLeadSource()}
            >
              {editLeadLoading ? (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{" "}
                  Saving...
                </>
              ) : (
                "Save"
              )}
            </Button>
          </div>
        ) : (
          <></>
        )}
      </Col>
    </Row>
  );
}
