import React from "react";
import { Form, Modal, Button } from "react-bootstrap";
import PopupModal, { ModalFooter } from "components/popup-modal/popup-modal";

export default function NewQuoteModal({
  show,
  onHide,
  onSaveProposal,
  handleChange,
  name,
  disableSave = false,
}) {
  return (
    <PopupModal
      show={show}
      heading="Alternate Quote & Proposal"
      onHide={onHide}
      bold
      centered={false}
      styles={{ border: "none" }}
    >
      <div style={{ padding: "0 1em 1em 1em" }}>
        <Form.Group>
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={name}
            onChange={handleChange}
          />
        </Form.Group>
        <i>Examples: 'House Only' or 'Garage Only'</i>
      </div>

      <ModalFooter
        onCancel={onHide}
        onSubmit={onSaveProposal}
        submitDisabled={disableSave}
        submitText="Save"
      />
    </PopupModal>
  );
}
