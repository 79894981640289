// import {
//   getRoofData,
//   updateBuilding,
//   addMetric,
// } from "lib/redux/metrics/metrics.actions";
// import { changeStage } from "lib/redux/sales/sales.actions";

// export const mapStateToProps = ({
//   metricsData: { isLoading, data, errors },
//   addBuilding: { addingBuilding, buildingErrors, buildingSuccess },
//   addMetric: { addingMetric, metricErrors, metricSuccess },
// }) => ({
//   isLoading,
//   data,
//   errors,
//   addingBuilding,
//   buildingErrors,
//   buildingSuccess,
//   addingMetric,
//   metricErrors,
//   metricSuccess,
// });

// export const mapDispatchToProps = (dispatch) => ({
//   getRoofData: (id) => dispatch(getRoofData(id)),
//   updateBuilding: (data) => dispatch(updateBuilding({ ...data })),
//   addMetric: (data) => dispatch(addMetric({ ...data })),
//   changeStage: (data) => dispatch(changeStage({ ...data })),
// });

import { addMetric, getMetrics } from "./redux/metrics-v2/metrics.actions";

export const mapStateToProps = ({
  metricsData: { isLoading, data, errors },
}) => ({
  isLoading,
  data,
  errors,
});

export const mapDispatchToProps = (dispatch) => ({
  addMetric: (data) => dispatch(addMetric({ ...data })),
  getMetrics: (id) => dispatch(getMetrics(id)),
});
