import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  faSyncAlt,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function QuoteUpdateLoader({ loading, error, retry }) {
  useEffect(() => {
    // If any post-loading or error handling is needed, it can be done here
  }, [loading]);

  const tryAgain = () => {
    retry();
  };

  if (loading) {
    return (
      <span className="sm-btn" title="Retrying. Please wait!">
        <FontAwesomeIcon
          color="#fff"
          className="ico spinner"
          icon={faSyncAlt}
        />
      </span>
    );
  }

  if (error) {
    return (
      <span
        style={{ fontSize: "1.5rem", cursor: "pointer" }}
        role="button"
        title="Error while saving data. Please click to retry!"
        onClick={tryAgain}
      >
        <FontAwesomeIcon
          color="red"
          className="ico"
          icon={faExclamationTriangle}
        />
      </span>
    );
  }

  return null;
}

// Adding prop-types validation
QuoteUpdateLoader.propTypes = {
  loading: PropTypes.bool.isRequired, // Loading must be a boolean and is required
  error: PropTypes.bool, // Error is a boolean and is optional
  retry: PropTypes.func.isRequired, // Retry is a required function callback
};

// Default props in case they are not provided
QuoteUpdateLoader.defaultProps = {
  error: false, // Default value for error if not provided
};
