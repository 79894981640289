import { salesActionTypes } from "./sales.types";

const INITIAL_STATE = {
  isLoading: false,
  data: null,
  errors: null,
  salesData: {
    scheduledData: [],
    proposalsDueData: [],
    sentFollowUpData: [],
    wonData: [],
    futureFollowUpData: [],
    lostData: [],
    canceledData: [],
    statusCounts: [],
  },
  notActedUpon: [],
  repairsData: {
    scheduledData: [],
    completeUnpaid: [],
    sentFollowUpData: [],
    statusCounts: [],
    canceledData: [],
    paidClosedData: [],
  },
  repairNotActedUpon: [],
};

export const StageReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case salesActionTypes.STAGE_START:
      return {
        ...state,
        isLoading: true,
        data: INITIAL_STATE.data,
        errors: null,
      };

    case salesActionTypes.STAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.result,
        errors: null,
      };

    case salesActionTypes.STAGE_COUNT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errors: null,
        salesData: {
          ...state.salesData,
          statusCounts: action.payload.statusCount,
        },
      };

    case salesActionTypes.STAGE_SCHEDULED_SUCCESS:
      const existingSchedule = state.salesData.scheduledData;
      const isSchedule = (newProposal) => {
        return existingSchedule.some(
          (proposal) => proposal._id === newProposal._id
        );
      };
      const newSchedules = action.payload.scheduledData.filter(
        (newProposal) => !isSchedule(newProposal)
      );

      return {
        ...state,
        isLoading: false,
        errors: null,
        salesData: {
          ...state.salesData,
          scheduledData: [...existingSchedule, ...newSchedules],
        },
      };

    case salesActionTypes.STAGE_PROPOSALS_DUE_SUCCESS:
      const existingProposals = state.salesData.proposalsDueData;
      const isProposalExisting = (newProposal) => {
        return existingProposals.some(
          (proposal) => proposal._id === newProposal._id
        );
      };

      const newProposals = action.payload.proposalsDueData.filter(
        (newProposal) => !isProposalExisting(newProposal)
      );

      return {
        ...state,
        isLoading: false,
        errors: null,
        salesData: {
          ...state.salesData,
          proposalsDueData: [...existingProposals, ...newProposals],
        },
      };

    case salesActionTypes.STAGE_SENT_FOLLOW_UP_SUCCESS:
      const existingFollowUp = state.salesData.sentFollowUpData;
      const isFollowup = (newProposal) => {
        return existingFollowUp.some(
          (proposal) => proposal._id === newProposal._id
        );
      };

      const newFollowup = action.payload.sentFollowUpData.filter(
        (newProposal) => !isFollowup(newProposal)
      );
      return {
        ...state,
        isLoading: false,
        errors: null,
        salesData: {
          ...state.salesData,
          sentFollowUpData: [...existingFollowUp, ...newFollowup],
        },
      };

    case salesActionTypes.STAGE_WON_SUCCESS:
      const existingOwn = state.salesData.wonData;
      const isOwn = (newProposal) => {
        return existingOwn.some((proposal) => proposal._id === newProposal._id);
      };

      const newOwn = action.payload.wonData.filter(
        (newProposal) => !isOwn(newProposal)
      );
      return {
        ...state,
        isLoading: false,
        errors: null,
        salesData: {
          ...state.salesData,
          wonData: [...existingOwn, ...newOwn],
        },
      };

    case salesActionTypes.STAGE_FUTURE_FOLLOW_UP_SUCCESS:
      const existingFutureFollowUp = state.salesData.futureFollowUpData;
      const isFutureFollowUp = (newProposal) => {
        return existingFutureFollowUp.some(
          (proposal) => proposal._id === newProposal._id
        );
      };

      const newFutureFollowUp = action.payload.futureFollowUpData.filter(
        (newProposal) => !isFutureFollowUp(newProposal)
      );
      return {
        ...state,
        isLoading: false,
        errors: null,
        salesData: {
          ...state.salesData,
          futureFollowUpData: [...existingFutureFollowUp, ...newFutureFollowUp],
        },
      };

    case salesActionTypes.STAGE_LOST_SUCCESS:
      const existingLost = state.salesData.lostData;
      const isLost = (newProposal) => {
        return existingLost.some(
          (proposal) => proposal._id === newProposal._id
        );
      };
      const newLost = action.payload.lostData.filter(
        (newProposal) => !isLost(newProposal)
      );
      return {
        ...state,
        isLoading: false,
        errors: null,
        salesData: {
          ...state.salesData,
          lostData: [...existingLost, ...newLost],
        },
      };

    case salesActionTypes.STAGE_CANCELED_SUCCESS:
      const existingCancel = state.salesData.canceledData;
      const isCancel = (newProposal) => {
        return existingCancel.some(
          (proposal) => proposal._id === newProposal._id
        );
      };
      const newCancel = action.payload.canceledData.filter(
        (newProposal) => !isCancel(newProposal)
      );
      return {
        ...state,
        isLoading: false,
        errors: null,
        salesData: {
          ...state.salesData,
          canceledData: [...existingCancel, ...newCancel],
        },
      };

    case salesActionTypes.STAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        data: INITIAL_STATE.data,
        errors: action.payload,
      };

    case salesActionTypes.STAGE_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errors: null,
        salesData: {
          ...state.salesData,
          ...action.payload,
        },
      };

    case salesActionTypes.STAGE_DEFAULT:
      return {
        ...state,
        isLoading: false,
        errors: action.payload,
        salesData: {
          ...state.salesData,
          scheduledData: [],
        },
      };

    case salesActionTypes.STAGE_NOT_ACTED_UPON_SUCCESS:
      const existingNotActedUpon = state.repairNotActedUpon;
      const isRepairNotActedUpon = (newProposal) => {
        return existingNotActedUpon.some(
          (proposal) => proposal._id === newProposal._id
        );
      };
      const newRepairNotActedUpon = action.payload.notActedUponData.filter(
        (newProposal) => !isRepairNotActedUpon(newProposal)
      );
      return {
        ...state,
        isLoading: false,
        errors: null,
        salesData: {
          ...state.salesData,
        },
        notActedUpon: [...existingNotActedUpon, ...newRepairNotActedUpon],
      };

    case salesActionTypes.STAGE_REPAIR_COUNT_SUCCESS:
      console.log("STAGE_REPAIR_COUNT_SUCCESS", action.payload.statusCount);
      return {
        ...state,
        isLoading: false,
        errors: null,
        repairsData: {
          ...state.repairsData,
          statusCounts: action.payload.statusCount,
        },
      };

    case salesActionTypes.STAGE_REPAIR_SCHEDULED_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errors: null,
        repairsData: {
          ...state.repairsData,
          scheduledData: action.payload.scheduledData,
        },
      };

    case salesActionTypes.STAGE_REPAIR_COMPLETE_UNPAID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errors: null,
        repairsData: {
          ...state.repairsData,
          completeUnpaid: action.payload.completeUnpaid,
        },
      };

    case salesActionTypes.STAGE_REPAIR_CANCELED_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errors: null,
        repairsData: {
          ...state.repairsData,
          canceledData: action.payload.canceledData,
        },
      };

    case salesActionTypes.STAGE_REPAIR_PAID_CLOSED_SUCCESS:
      const existingRepairPaidClosed = state.repairsData.paidClosedData;
      const isRepairPaidClosed = (newProposal) => {
        return existingRepairPaidClosed.some(
          (proposal) => proposal._id === newProposal._id
        );
      };
      const newRepairPaidClosed = action.payload.paidClosedData.filter(
        (newProposal) => !isRepairPaidClosed(newProposal)
      );
      return {
        ...state,
        isLoading: false,
        errors: null,
        repairsData: {
          ...state.repairsData,
          paidClosedData: [...existingRepairPaidClosed, ...newRepairPaidClosed],
        },
      };

    case salesActionTypes.STAGE_REPAIR_DEFAULT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errors: null,
        repairsData: {
          ...state.repairsData,
          scheduledData: [],
          completeUnpaid: [],
          sentFollowUpData: [],
          statusCounts: [],
          canceledData: [],
          paidClosedData: [],
        },
      };

    case salesActionTypes.STAGE_REPAIR_NOT_ACTED_UPON_SUCCESS:
      const existingRepairNotActedUpon = state.notActedUpon;
      const isNotActedUpon = (newProposal) => {
        return existingNotActedUpon.some(
          (proposal) => proposal._id === newProposal._id
        );
      };
      const newNotActedUpon = action.payload.notActedUponData.filter(
        (newProposal) => !isNotActedUpon(newProposal)
      );
      return {
        ...state,
        isLoading: false,
        errors: null,
        repairNotActedUpon: [...existingNotActedUpon, ...newNotActedUpon],
      };
    default:
      return state;
  }
};

const INITIAL_STATE_CHANGE = {
  isChanging: false,
  changeSuccess: null,
  changeFail: null,
};

export const ChangeStageReducer = (state = INITIAL_STATE_CHANGE, action) => {
  switch (action.type) {
    case salesActionTypes.CHANGE_STAGE_START:
      return {
        ...state,
        isChanging: true,
        changeSuccess: null,
        changeFail: null,
      };

    case salesActionTypes.CHANGE_STAGE_SUCCESS:
      return {
        ...state,
        isChanging: false,
        changeSuccess: action.payload.result.message,
        changeFail: null,
      };

    case salesActionTypes.CHANGE_STAGE_FAILURE:
      return {
        ...state,
        isChanging: false,
        changeSuccess: null,
        changeFail: action.payload,
      };

    case salesActionTypes.CHANGE_STAGE_RESET:
      return {
        ...INITIAL_STATE_CHANGE,
      };

    default:
      return state;
  }
};

const INITIAL_STATE_UPDATE_FOLLOWUP = {
  isUpdating: false,
  updateSuccess: null,
  updateFail: null,
};

export const UpdateFollowUpReducer = (
  state = INITIAL_STATE_UPDATE_FOLLOWUP,
  action
) => {
  switch (action.type) {
    case salesActionTypes.UPDATE_FOLLOWUP_START:
      return {
        ...state,
        isUpdating: true,
        updateSuccess: null,
        updateFail: null,
      };

    case salesActionTypes.UPDATE_FOLLOWUP_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        updateSuccess: action.payload.result.message,
        updateFail: null,
      };

    case salesActionTypes.UPDATE_FOLLOWUP_FAILURE:
      return {
        ...state,
        isUpdating: false,
        updateSuccess: null,
        updateFail: action.payload,
      };

    default:
      return state;
  }
};

const INITIAL_STATE_UPDATE_FOLLOWUPDATA = {
  isUpdatingData: false,
  updateSuccessData: null,
  updateFailData: null,
};

export const UpdateFollowUpDataReducer = (
  state = INITIAL_STATE_UPDATE_FOLLOWUPDATA,
  action
) => {
  switch (action.type) {
    case salesActionTypes.UPDATE_FOLLOWUP_DATA_START:
      return {
        ...state,
        isUpdatingData: true,
        updateSuccessData: null,
        updateFailData: null,
      };

    case salesActionTypes.UPDATE_FOLLOWUP_DATA_SUCCESS:
      return {
        ...state,
        isUpdatingData: false,
        updateSuccessData: action.payload.result.message,
        updateFailData: null,
      };

    case salesActionTypes.UPDATE_FOLLOWUP_DATA_FAILURE:
      return {
        ...state,
        isUpdatingData: false,
        updateSuccessData: null,
        updateFailData: action.payload,
      };

    default:
      return state;
  }
};
