import React, { useState } from "react";
import PropTypes from "prop-types";
import { Row } from "react-bootstrap";
import { RemoveTypes, ShiTypes } from "lib/constants/const";
import { DisplayOtherModal } from "..";

const WorkTypeMetric = ({
  pitch,
  isQuoteLock,
  workType,
  onHandleClick,
  handleClickTypeData,
  handleOtherClickTypeData,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      <label className="btnheading">Work Type</label>
      <Row
        style={{
          marginLeft: "-3px",
        }}
      >
        <button
          disabled={!pitch?.roofPitch || isQuoteLock}
          style={
            isQuoteLock && workType?.name === "New"
              ? {
                  maxWidth: "20%",
                  backgroundColor: "#60abfb",
                  borderColor: "#60abfb",
                }
              : { maxWidth: "20%" }
          }
          className={`ClickableBtn ${
            workType?.name === "New" ? "selected" : ""
          } `}
          onClick={() => onHandleClick("New")}
        >
          New
        </button>
        <button
          disabled={!pitch?.roofPitch || isQuoteLock}
          style={
            isQuoteLock && workType?.name === "Overlay"
              ? {
                  maxWidth: "20%",
                  backgroundColor: "#60abfb",
                  borderColor: "#60abfb",
                }
              : { maxWidth: "20%" }
          }
          className={`ClickableBtn ${
            workType?.name === "Overlay" ? "selected" : ""
          } `}
          onClick={() => onHandleClick("Overlay")}
        >
          Overlay
        </button>
        <button
          disabled={!pitch?.roofPitch || isQuoteLock}
          style={
            isQuoteLock && workType?.removalTypes?.type
              ? {
                  maxWidth: "20%",
                  backgroundColor: "#60abfb",
                  borderColor: "#60abfb",
                }
              : { maxWidth: "20%" }
          }
          className={`ClickableBtn ${
            workType?.removalTypes?.type ? "selected" : ""
          } `}
          onClick={() => {
            onHandleClick("Remove");
            setModalOpen(!modalOpen);
          }}
        >
          Remove
        </button>
      </Row>
      <DisplayOtherModal
        show={modalOpen}
        hide={() => setModalOpen(!modalOpen)}
        label="Select Removal Types"
        displayOtherModalData={RemoveTypes}
        displayOtherModalOtherData={ShiTypes}
        displayOtherTypeArr={["Shingles", "Flat"]}
        handleClickTypeData={handleClickTypeData}
        workType={workType}
        handleOtherClickTypeData={handleOtherClickTypeData}
      />
    </>
  );
};

WorkTypeMetric.propTypes = {
  workType: PropTypes.string,
  isQuoteLock: PropTypes.bool,
  onHandleClick: PropTypes.func,
  pitch: PropTypes.any,
  handleClickTypeData: PropTypes.func.isRequired,
  handleOtherClickTypeData: PropTypes.func.isRequired,
};

export default WorkTypeMetric;
