import React, { useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { LoadingLoader, PopupModal } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { PricingList } from "screens/Suppliers/Material";
import {
  deleteLabourMiscQuote,
  getSeriesTypes,
  updateLabourQuote,
  updateProposalInfo,
} from "screens/QuotesProposals/redux/proposals/proposals.actions";
import { useSelector } from "react-redux";
import LabourListItem from "./LabourListItem.jsx";

export default function LabourQuoteList({
  loadingData,
  dataError,
  selectedSupplier,
  metricId,
  saveSectionDataToDB,
  deleteMiscItem,
  changeQuantiy,
  LoadingSkeleton,
  displayMsg,
  quoteMaterialSectionData,
  isLockQuoteData,
  selectedProposal,
  isLoading,
  tabData,
}) {
  console.log({
    tabData,
    l: tabData,
  });

  if (dataError) {
    return displayMsg({ msg: "No data available." });
  }

  if (loadingData) {
    return new Array(10).fill(1).map((_, i) => <LoadingSkeleton key={i} />);
  }

  if (!tabData?.length) {
    return displayMsg({ msg: "No data available." });
  }

  return (
    <div>
      {tabData.map((labour, idx) => (
        <LabourLstItemWrapper
          key={`${labour?._id}-${labour?.category}`}
          idx={idx}
          pricing={labour}
          selectedSupplier={selectedSupplier}
          metricId={metricId}
          tabData={tabData}
          saveSectionDataToDB={saveSectionDataToDB}
          quoteMaterialSectionData={quoteMaterialSectionData}
          isLockQuoteData={isLockQuoteData}
          isLoading={isLoading}
          editItem={(dataItem) => changeQuantiy(dataItem)}
          selectedProposal={selectedProposal}
          changeQty={changeQuantiy}
        />
      ))}
    </div>
  );
}

function LabourLstItemWrapper({
  pricing,
  selectedSupplier,
  editItem,
  isLockQuoteData,
  selectedProposal,
}) {
  const {
    field,
    type,
    uomPrice,
    uom,
    subType,
    series,
    additionalDetail,
    original_list,
    qtyAndPrice,
    workType,
    roofPitch,
  } = pricing;

  const dispatch = useDispatch();

  const onChangeQty = ({
    labourId,
    quantity = "",
    workType = "",
    uom = "",
    price = "",
    skylightTypeOfWork = "",
  }) => {
    // changeQty(qty, materialId, price, miscSeries);
    console.log({
      labourId,
      quantity,
      workType,
      uom,
      price,
    });

    const data = {
      requestId: selectedProposal?.requestId,
      proposalId: selectedProposal._id,
      labourQuoteId: selectedProposal?.quotes?.labours?._id,
      labourId: labourId,
      skylightTypeOfWork: skylightTypeOfWork,
    };

    if (price) {
      data.uomPrice = price;
    }

    if (quantity) {
      data.uomQty = quantity;
    }

    if (workType) {
      data.workType = workType;
    }

    if (uom) {
      data.uom = uom;
    }

    dispatch(updateLabourQuote(data));
  };

  const typeText = `${type}${additionalDetail ? ` - ${additionalDetail}` : ""}`;
  console.log({
    pricing,
  });

  const onDeleteMiscQuote = () => {
    const body = {
      supplierId: selectedProposal?.quotes?.labours?.supplier?._id,
      proposalId: selectedProposal._id,
      labourQuoteId: selectedProposal?.quotes?.labours?._id,
      labourId: pricing._id,
    };
    dispatch(deleteLabourMiscQuote(body));
  };

  return series ? (
    <SkylightLaborListData
      selectedSupplier={selectedSupplier}
      data={pricing}
      onChangeQty={onChangeQty}
      editItem={editItem}
      isLockQuoteData={isLockQuoteData}
    />
  ) : (
    <LabourListItem
      field={field}
      subType={subType}
      type={typeText}
      qty={qtyAndPrice?.quantity}
      total={qtyAndPrice?.totalPrice}
      uom={uom}
      roofPitch={roofPitch}
      workType={workType}
      price={uomPrice}
      onChangeQty={onChangeQty}
      original_list={original_list}
      isLockQuoteData={isLockQuoteData}
      labour={pricing}
      deleteMiscItem={onDeleteMiscQuote}
    />
  );
}
