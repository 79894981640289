import React from "react";
import PropTypes from "prop-types";
import InputWithSymbol from "components/InputWithSymbol";

export default function PriceInput({
  disabled,
  style,
  price = 0,
  currency = "$",
  onChange,
  input = "",
}) {
  const styles = style
    ? { textAlign: "right", ...style }
    : input === "Total"
    ? { textAlign: "right" }
    : { textAlign: "center" };

  const changePrice = (e) => {
    const changedPrice = removeCurrency(e.target.value);
    onChange(changedPrice);
  };

  const removeCurrency = (value) => value.replace(currency, "").trim();

  const onBlur = (e) => {
    const changedPrice = removeCurrency(e.target.value);
    const newPrice =
      isNaN(changedPrice) || changedPrice === ""
        ? Number(0).toFixed(2)
        : Number(changedPrice).toFixed(2);
    onChange(newPrice);
  };

  const displayPrice = price ? Number(price).toFixed(2) : Number(0).toFixed(2);
  return (
    <InputWithSymbol
      onChange={changePrice}
      disabled={disabled}
      value={currency + displayPrice}
      styles={styles}
      onBlur={onBlur}
      changeValue={changePrice}
    />
  );
}

// Adding prop-types validation
PriceInput.propTypes = {
  disabled: PropTypes.bool,
  style: PropTypes.object,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currency: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  input: PropTypes.string,
};

// Default props in case they are not passed
PriceInput.defaultProps = {
  disabled: false,
  style: null,
  price: 0,
  currency: "$",
  input: "",
};
