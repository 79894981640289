import { axios, axiosInstance } from "services";
import { staticAllTypes } from "./static-all.types";
import { getData, saveData } from "services/storageManager";
import { ApiEndpoints } from "lib/config/baseSettings";

export const fetchStaticAllSuccess = (data) => ({
  type: staticAllTypes.STATIC_ALL_SUCCESS,
  payload: data,
});

export const fetchStaticAllFailure = (errors) => ({
  type: staticAllTypes.STATIC_ALL_FAILURE,
  payload: errors,
});

export const fetchStaticAllStart = () => ({
  type: staticAllTypes.STATIC_ALL_START,
});

export const fetchStaticAll = () => {
  return async (dispatch) => {
    dispatch(fetchStaticAllStart());

    try {
      const response = await axios.get(ApiEndpoints.STATIC_ALL.STATIC_ALL);
      const data = response.data;

      if (data.success) {
        saveData("staticAll", data.result);
        dispatch(fetchStaticAllSuccess(data));
      } else {
        dispatch(fetchStaticAllFailure(data));
      }
    } catch (err) {
      dispatch(fetchStaticAllFailure(err));
    }
  };
};

// For state taxes
export const fetchStateTaxesSuccess = (data) => ({
  type: staticAllTypes.STATE_TAXES_ALL_SUCCESS,
  payload: data,
});

export const fetchStateTaxesFailure = (errors) => ({
  type: staticAllTypes.STATE_TAXES_ALL_FAILURE,
  payload: errors,
});

export const fetchStateTaxesStart = () => ({
  type: staticAllTypes.STATE_TAXES_ALL_START,
});

export const getStateTaxes = () => {
  return async (dispatch) => {
    dispatch(fetchStateTaxesStart());

    try {
      const response = await axiosInstance.get(
        ApiEndpoints.PROPOSALS.STATE_TAXES
      );
      const data = response?.data;
      console.log({
        datass: data,
        response,
      });
      dispatch(fetchStateTaxesSuccess(data));
      // if (data.success) {
      //   dispatch(fetchStateTaxesSuccess(data));
      // } else {
      //   dispatch(fetchStateTaxesFailure(data));
      // }
    } catch (err) {
      dispatch(fetchStateTaxesFailure(err));
    }
  };
};
