import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import "components/common.scss";
import mstyles from "./contact-table.module.scss";
import { Row, Col, Button } from "react-bootstrap";
import { propertyTableHeaderContact } from "lib/constants/ui-const";
import { Link, useHistory } from "react-router-dom";
import DisplayTable from "components/DisplayTable";
import { RepairModal } from "./RepairModal";

export default function ContactTable({
  staticData,
  selectedContact,
  propertyList,
  setIsPropertySelected,
  setPropertyId,
  propertyTypess,
  setRequestsDeatails,
  setPropertyList,
  requestsDeatails,
  schedulling,
  setChosenProperty,
  scrollToBottom,
  setAddProperty,
  setAddService,
}) {
  const history = useHistory();

  //#region states
  const [tableData, setTableData] = useState([]);
  const [isCheck, setIsCheck] = useState(false);
  const [isCheckRequest, setIsCheckRequest] = useState(false);
  const [isChosenProperty, setIsChosenProperty] = useState("");
  const [isChosenRequest, setIsChosenRequest] = useState("");
  const [isShow, setIsShow] = useState(false);
  const [repairRequest, setRepairRequest] = useState(null);

  //#endregion

  useEffect(() => {
    setIsChosenProperty("");
    setIsChosenRequest("");
    setIsCheck(false);
    setAddService(false);
    setAddProperty(false);
  }, [selectedContact]);

  useEffect(() => {
    const newTableData = updatePropertyTable();
    setTableData(newTableData);
  }, [propertyList]);

  //#region functions
  const displaySrequestText = (rating, staticData) => {
    const { newRepairs, newInstallations } = staticData;
    let text = ``;
    if (rating.isRepairRequest) {
      text = `${text ? `${text}\n` : ""}Repair `;

      if (newRepairs.length) {
        let newText = ``;
        const res = newRepairs.filter((repair) =>
          rating.requestFor.includes(repair.repairTypeId)
        );
        res.map(
          (repair, i) =>
            (newText = `${newText}${
              i === 0
                ? `${repair.repairTypeName}`
                : `, ${repair.repairTypeName}`
            }`)
        );
        text = `${text}${newText}`;
      }
      if (rating.requestForOther) {
        return (text = `${text ? `${text}: ` : text}${rating.requestForOther}`);
      }
    } else {
      text = "New ";
      if (rating.requestForOther) {
        return (text = `${text}${rating.requestForOther}`);
      } else {
        if (newInstallations.length) {
          let newText = ``;
          const res = newInstallations.filter((newInstallation) =>
            rating.requestFor.includes(newInstallation.newInstallTypeId)
          );
          res.map(
            (newInstallation, i) =>
              (newText = `${newText}${
                i === 0
                  ? `${newInstallation.newInstallTypeName}`
                  : `, ${newInstallation.newInstallTypeName}`
              }`)
          );
          text = `${text}${newText}`;
        }
      }
    }
    return text;
  };

  const getPtypes = (pId) => {
    if (propertyTypess?.propertydata?.length > 0) {
      const idx = propertyTypess?.propertydata?.findIndex(
        (type) => type._id?.toString() === pId?.toString()
      );
      return propertyTypess?.propertydata[idx]?.roleType;
    }
    return null;
  };

  const handleCheckContact = (e, lead, i, requestarray) => {
    if (e.target.checked === true) {
      setIsChosenProperty(i);
    } else {
      setIsChosenProperty("");
    }

    lead.requests.forEach((res) => {
      requestarray.push(res._id);
    });
    setIsCheck(e.target.checked);
    let new_property_list = propertyList.map((elem) => {
      if (lead._id === elem._id) {
        // let requests = elem.requests.map((req) => {
        //   return {...req, isCheck: !req.isCheck}
        // })
        setRequestsDeatails("");
        return {
          ...elem,
          isCheck: !elem.isCheck,
          requests: elem.requests.map((elem) => {
            return { ...elem, isCheckScheduleCbox: false };
          }),
        };
      }

      return {
        ...elem,
        isCheck: false,
        requests: elem.requests.map((elem) => {
          return { ...elem, isCheckScheduleCbox: false };
        }),
      };
    });
    setPropertyList(new_property_list);
  };
  const updatePropertyTable = () => {
    const staticPassData = {
      newRepairs: staticData.repairType,
      newInstallations: staticData.newInstallationType,
    };
    let data = [];
    let requestRows = [];
    let firstRow = {};
    let requestarray = [];
    propertyList.map((property, i, self) => {
      setPropertyId(property.property_address[0]._id);

      if (property.requests.length) {
        // First row for 1st property request row
        firstRow = property.requests[0];
        let request_id = property.requests[0]._id;
        let newRequests = property.requests.slice(1, property.requests.length);
        let text_arr = displaySrequestText(firstRow, staticPassData).split(",");
        let text_obj = {};
        text_arr.forEach((text) => {
          if (text.split(" ").length > 1) {
            text_obj[text] = text.split(" ")[1].toLowerCase();
          } else {
            text_obj[text] = text.toLowerCase();
          }
        });

        // First of of propety list
        let property_row = {
          Cbox: (
            <input
              type="checkbox"
              checked={property.isCheck}
              onChange={(e) => handleCheckContact(e, property, i, requestarray)}
              value={isCheck}
            />
          ),
          id: (
            <div style={{ textAlign: "center" }}>
              {`${i + 1} (${newRequests.length + 1})`}
            </div>
          ),
          address: (
            <a
              href={`https://www.google.com/maps/place?q=${`${property.property_address[0].propertyAddress1} ${property.property_address[0].propertyCity} ${property.property_address[0].propertyState} ${property.property_address[0].propertyZip}`}`}
              className={mstyles.address}
              target="_blank"
            >
              <span>{`${property.property_address[0].propertyAddress1}`}</span>
              <br />
              <span>{`${property.property_address[0].propertyCity}, ${property.property_address[0].propertyState}`}</span>
            </a>
          ),
          pType: (
            <div style={{ textAlign: "center" }}>
              {getPtypes(property.property_address[0].propertyType)}
            </div>
          ),

          Cbox2: (
            <input
              type="checkbox"
              checked={
                firstRow?.isCheckScheduleCbox === undefined
                  ? false
                  : firstRow?.isCheckScheduleCbox
              }
              disabled={
                property?.isCheck === undefined ? true : !property?.isCheck
              }
              onChange={(e) => {
                handleCheckRequest(e, property.requests[0], requestarray);
              }}
              value={isCheckRequest}
            />
          ),
          sRequest: displaySrequestText(firstRow, staticPassData)
            .split(",")
            .map((request) => {
              if (firstRow.currentStatus === "Unscheduled") return `${request}`;
              return (
                <a
                  style={{
                    textDecoration: "underline",
                  }}
                  className="p-0"
                  href={
                    text_obj[request] === "roof" ||
                    text_obj[request] === "gutters"
                      ? `metrics/${text_obj[request]}/${request_id}`
                      : null
                  }
                  target="_blank"
                  onClick={() => {
                    setIsShow(true);
                    setRepairRequest(request_id);
                  }}
                >
                  {`${request}`}
                </a>
              );
            }),

          srRep: (
            <div style={{ textAlign: "center" }}>
              {firstRow.schedules.length !== 0
                ? firstRow.schedules[0].personDetails[0].firstName +
                  " " +
                  firstRow.schedules[0].personDetails[0].lastName
                : ""}
            </div>
          ),

          sStage: firstRow.currentStatus,
          sDate: (
            <div style={{ textAlign: " center" }}>
              {
                new Date(firstRow.createdAt)
                  .toLocaleString("en-US")
                  .split(",")[0]
              }
            </div>
          ),

          propertyId: property._id,
          actions: (
            <>
              <Button
                variant="warning"
                className="edit-btn"
                disabled={!property.requests[0].isCheckScheduleCbox}
                onClick={() => {
                  scrollToBottom();
                  setAddProperty(false);
                  setAddService(false);
                  setChosenProperty((prev) => ({
                    property: i,
                    request: property.requests[0],
                  }));
                }}
              >
                Edit
              </Button>
            </>
          ),
        };

        requestRows = property.isCheck
          ? newRequests.map((request) => {
              let text_arr1 = displaySrequestText(
                request,
                staticPassData
              ).split(",");
              let text_obj1 = {};
              text_arr1.forEach((text) => {
                if (text.split(" ").length > 1) {
                  text_obj1[text] = text.split(" ")[1].toLowerCase();
                } else {
                  text_obj1[text] = text.toLowerCase();
                }
              });
              return {
                Cbox: null,
                // <input
                //   type="checkbox"
                //   checked={property.isCheck}
                //   onChange={(e) =>
                //     handleCheckContact(e, property, i, requestarray)
                //   }
                //   value={isCheck}
                // />
                id: "",
                address: null,

                pType: "",

                Cbox2: (
                  <input
                    type="checkbox"
                    checked={
                      request?.isCheckScheduleCbox === undefined
                        ? false
                        : request?.isCheckScheduleCbox
                    }
                    disabled={
                      property?.isCheck === undefined
                        ? true
                        : !property?.isCheck
                    }
                    onChange={(e) =>
                      handleCheckRequest(e, request, requestarray)
                    }
                    value={isCheckRequest}
                  />
                ),
                sRequest: displaySrequestText(request, staticPassData)
                  .split(",")
                  .map((req) => {
                    if (request.currentStatus === "Unscheduled")
                      return `${req}`;
                    return (
                      <a
                        style={{
                          textDecoration: "underline",
                        }}
                        href={
                          text_obj1[req] === "roof" ||
                          text_obj1[req] === "gutters"
                            ? `metrics/${text_obj1[req]}/${request._id}`
                            : null
                        }
                        target="_blank"
                        onClick={() => {
                          setIsShow(true);
                          setRepairRequest(request._id);
                        }}
                      >
                        {`${req}`}
                      </a>
                    );
                  }),

                srRep:
                  request.schedules.length !== 0
                    ? request.schedules[0].personDetails[0].firstName +
                      " " +
                      request.schedules[0].personDetails[0].lastName
                    : "",

                sStage: request.currentStatus,
                sDate: new Date(request.createdAt)
                  .toLocaleString("en-US")
                  .split(",")[0],

                isCheck: false,
                actions: (
                  <Button
                    variant="warning"
                    className="edit-btn"
                    disabled={!request.isCheckScheduleCbox}
                    onClick={() => {
                      scrollToBottom();
                      setChosenProperty((prev) => ({
                        property: i,
                        request: request,
                      }));
                      // onEditLead(property, request);
                      // setServiceRequestModalHeading("editProperty");
                    }}
                  >
                    Edit
                  </Button>
                ),
              };
            })
          : [];

        return data.push(property_row, ...requestRows);
      }
    });

    let isSelectedAnyProperty =
      propertyList.filter((ele) => {
        return ele.isCheck === true;
      }) || [];

    setIsPropertySelected(isSelectedAnyProperty.length ? true : false);

    return data;
  };

  const handleCheckRequest = (e, request, requestarray) => {
    if (e.target.checked === false) {
      setIsChosenRequest("");
    } else {
      setIsChosenRequest(request);
    }

    let new_property_list = propertyList.map((property) => {
      return {
        ...property,
        requests: property.requests.map((elem) => {
          if (request._id === elem._id) {
            setRequestsDeatails(!elem.isCheckScheduleCbox ? elem : false);
            return { ...elem, isCheckScheduleCbox: !elem.isCheckScheduleCbox };
          }
          return { ...elem, isCheckScheduleCbox: false };
        }),
      };
    });
    setPropertyList(new_property_list);
  };
  //#endregion
  return (
    <Col md="12">
      <h4 style={{ padding: "25px 0", fontWeight: "bold" }}>
        {selectedContact?.primary_data?.[0]?.companyName ||
          `${selectedContact?.primary_data?.[0]?.primaryFirstName} ${selectedContact?.primary_data?.[0]?.primaryLastName}`}
      </h4>
      <p>Property List: ({selectedContact?.property_list.length})</p>
      <div style={{ width: "100%" }} className="text-nowrap">
        <DisplayTable
          headerWrapperClasses="thead"
          bodyClasses="_body_table1"
          data={tableData}
          columns={propertyTableHeaderContact}
          rigtTab={true}
        />
      </div>
      <Row className="mt-2">
        <Col>
          <Button
            style={{
              padding: ".8em 1em",
              float: "right",
              marginLeft: "25px",
              borderColor: "rgb(13, 110, 253)",
            }}
            disabled={
              (!isChosenProperty && isChosenProperty !== 0) ||
              isChosenProperty === "" ||
              isChosenRequest !== ""
            }
            onClick={() => {
              scrollToBottom();
              setChosenProperty({
                property: isChosenProperty,
                request: isChosenRequest,
              });
              setAddService(true);
              setAddProperty(false);
            }}
          >
            <strong>New Svc Request</strong>
          </Button>
          <Button
            id="schedule"
            style={{ padding: ".8em 2em", float: "right" }}
            onClick={() => {
              //addSvcRequest("Upperschedule");
              // setServiceRequestModalType("Schedule");
              // setServiceRequestModalHeading("Schedule");
              // ContactManagementSchedule(isChosenRequest);
              history.push("/dashboard/calendar");
              // setTimeout(() => , 3000);
            }}
            disabled={
              !requestsDeatails ||
              requestsDeatails.currentStatus === "Scheduled"
            }
          >
            <strong>{schedulling ? "Schedulling" : "Schedule"}</strong>
          </Button>
          <Button
            style={{
              padding: ".8em 1em",
              float: "right",
              marginRight: "25px",
              borderColor: "rgb(13, 110, 253)",
            }}
            disabled={isCheck}
            onClick={() => {
              scrollToBottom();
              setChosenProperty({ property: "", request: "" });
              setAddProperty(true);
              setAddService(false);
              //setEditLead(false);
              //setServiceRequestModalHeading("addNewProperty");
            }}
          >
            <strong>Add New Property</strong>
          </Button>
        </Col>
      </Row>
      {/* {isShow && repairRequest && (
        <RepairModal
          requestId={repairRequest}
          show={isShow}
          setShow={setIsShow}
        />
      )} */}
    </Col>
  );
}
