import React, { memo } from "react";
import "./tabs.scss";

const Tabs = (props) => {
  const { height, active, count, tabs, setTab, allData, countData } = props;

  const getCountData = (tab) => {
    const currentTabData = allData.find(lead => lead?.status === tab);
    return countData[tab];
  };

  console.log({allData123: allData});

  const displayTabs = (active) => {
    return tabs?.map((tab, i) => (
      <button
        style={{ height }}
        key={`${tab}`}
        onClick={() => setTab(tab, i)}
        className={`_tabItem ${active === tab ? "active" : null}`}
      >
        {tab}
        {getCountData(tab)
          ? `(${getCountData(tab)})`
          : "(0)"}
      </button>
    ));
  };
  return (
    <div
      className="_tab"
      style={{
        background: "rgb(245 243 239)",
      }}
    >
      {displayTabs(active)}
    </div>
  );
};

export default memo(Tabs);

