import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { PriceInput, PopupModal, LoadingLoader } from "components";

import { useDispatch, useSelector } from "react-redux";

import styles from "lib/config/baseStyles";
import displayMsg from "components/displayMsg";

import SelectSuppliers, {
  SelectOption,
  SupplierItem,
  SelectSuppliersSection,
} from "../../SelectSupliers";
import LoadingSkeleton from "../../LoadingSkeleton";

import QuoteDataList from "./QuoteDataList";
import SmRetryBtn from "../../../../../components/common/loaders/SmRetryBtn";
import userDetails from "hooks/user/userDetails";
import {
  addMiscMaterialQuote,
  getMaterialSuppliers,
  changeMaterialSupplier,
  updateProposalInfo,
} from "screens/QuotesProposals/redux/proposals/proposals.actions";
import {
  hasFieldChanged,
  hasSupplierFieldChanged,
  supplierEmptyText,
} from "screens/QuotesProposals/utils";
import QuoteUpdateLoader from "components/common/loaders/QuoteUpdateLoader";
//new commit
export default function Material({
  mateSuppliers,
  loadingSuppliers,
  metricId,
  isLockQuoteData,
}) {
  const {
    gettingMaterialSectionData,
    quoteMaterialSectionData,
    quoteMaterialSectionDataErr,
  } = useSelector((state) => state.quoteMaterialSectionData);

  const proposalData = useSelector((state) => state.proposal);
  const dispatch = useDispatch();

  const {
    selectedProposal,
    isLoadingMaterialSuppliers,
    materialSuppliers: suppliers,
    materialSuppliersError,
    isLoadingProposal,
    isLoadingUpdateMaterialSupplier,
    updateProposalError,
    isLoadingUpdateInfoProposal,
    isLoadingAddMiscMaterial,
    isLoadingDeleteMaterialMisc,
  } = proposalData;

  const selectedSupplier = selectedProposal?.quotes?.materials?.supplier;

  console.log({
    selectedProposal: selectedProposal,
  });

  const [error, setError] = useState(false);
  const { companyId } = userDetails();

  useEffect(() => {
    if (companyId && !suppliers?.length && !isLoadingMaterialSuppliers) {
      dispatch(getMaterialSuppliers(companyId));
    }
  }, [companyId]);

  const MiscItemComponent = ({ onAddMisc, isLockQuoteData }) => (
    <Row>
      <Col style={styles.reduceWidthCol} lg="2">
        <Button onClick={onAddMisc} disabled={isLoadingAddMiscMaterial}>
          Add Misc Item
        </Button>
      </Col>
    </Row>
  );

  const retry = () => {
    // const dataArr = quoteMaterialSectionData.sectionData.slice();
    // const data = getSubmissionData(dataArr);
    // //data = { ...data, proposalId: data.proposalId._id };
    // debounceSaveData(data);
  };

  const onSelectSupplier = (e) => {
    const supplierId = e.target.value;
    // changeSupplier(e.target.value, setSelectedSupplier);
    console.log({
      supplierId,
      e,
    });
    const body = {
      requestId: selectedProposal?.requestId,
      supplierId: supplierId,
      proposalId: selectedProposal._id,
    };

    console.log({
      bodyupdate: body,
    });
    dispatch(changeMaterialSupplier(body));
  };

  const changeQuantiy = (quantity, materialId, price, miscSeries) => {
    console.log({
      quantity,
      materialId,
      price,
    });
    // Dispatch action to get series list
    const data = {
      requestId: selectedProposal?.requestId,
      // proposalId,
      proposalId: selectedProposal._id,
      materialQuoteId: selectedProposal?.quotes?.materials?._id,
      materialId: materialId,
      // stateTaxId: taxId,
      // materialQuoteId
      // uomQty: quantity,
    };

    if (price) {
      data.uomPrice = price;
    }

    if (quantity) {
      data.uomQty = quantity;
    }

    if (miscSeries) {
      data.miscSeries = miscSeries;
    }

    dispatch(updateProposalInfo(data));
  };

  const onAddMisc = () => {
    const body = {
      supplierId: selectedSupplier?.supplier?._id,

      proposalId: selectedProposal._id,
      materialQuoteId: selectedProposal.quotes.materials._id,
      series: "",
      uomPrice: "",
      uomQty: "",
    };
    dispatch(addMiscMaterialQuote(body));
  };

  return (
    <div style={{ margin: "1em" }}>
      <section>
        <Row>
          <Col style={styles.reduceWidthCol}>
            <Row lg="12">
              <Col>
                <h4 style={{ marginBottom: "-.5rem" }}>Material </h4>
              </Col>
              <Col className="d-flex justify-content-end">
                {/* {isLoadingProposal ? null : error ? (
                  <SmRetryBtn retry={retry} />
                ) : null} */}
                <QuoteUpdateLoader
                  loading={
                    isLoadingUpdateMaterialSupplier ||
                    isLoadingUpdateInfoProposal ||
                    isLoadingAddMiscMaterial ||
                    isLoadingDeleteMaterialMisc
                  }
                  retry={retry}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        {selectedSupplier?._id ? (
          <SelectSuppliers
            selectedSupplier={selectedSupplier?._id}
            onSelectSupplier={onSelectSupplier}
            loading={loadingSuppliers}
            suppliers={suppliers}
            emptyText={supplierEmptyText(suppliers?.length)}
            SupplierItem={SupplierItem}
            isLockQuoteData={isLockQuoteData}
            isOriginalSupplier={hasSupplierFieldChanged(
              selectedProposal.originalQuoteSupplier,
              "materialSupplierId",
              selectedSupplier?._id
            )}
          />
        ) : null}
        <SelectSuppliersSection>
          <Col>
            <Form.Label style={{ marginTop: "15%" }}>Manufacturer</Form.Label>
          </Col>
          <Col>
            <Form.Label style={{ marginTop: "15%" }}>Material</Form.Label>
          </Col>
          <Col>
            <Form.Label style={{ marginLeft: "170%", marginTop: "15%" }}>
              Series
            </Form.Label>
          </Col>

          <Col></Col>
          <Col></Col>
        </SelectSuppliersSection>

        <QuoteDataList
          isLoading={isLoadingProposal}
          metricId={metricId}
          loadingData={proposalData?.isLoadingProposal}
          selectedProposal={selectedProposal}
          dataError={quoteMaterialSectionDataErr}
          // saveSectionDataToDB={saveMaterialSectionDataToDB}

          // deleteMiscItem={deleteMiscItem}
          changeQuantiy={changeQuantiy}
          tabData={selectedProposal?.quotes?.materials?.quotes}
          selectedSupplier={selectedSupplier}
          LoadingSkeleton={LoadingSkeleton}
          displayMsg={displayMsg}
          quoteMaterialSectionData={quoteMaterialSectionData}
          isLockQuoteData={isLockQuoteData}
        />

        <MiscItemComponent
          onAddMisc={onAddMisc}
          // quoteMaterialSectionData={quoteMaterialSectionData}
          // isLockQuoteData={isLockQuoteData}
        />
        {selectedProposal ? (
          <MaterialQuoteBillingInfo
            selectedProposal={selectedProposal}
            dispatch={dispatch}
          />
        ) : null}
      </section>
      {isLoadingProposal && (
        <div
          style={{
            display: "flex",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#01010101",
          }}
        >
          <LoadingLoader />
        </div>
      )}
    </div>
  );
}

function MaterialQuoteBillingInfo({
  selectedProposal,
  isLockQuoteData,
  dispatch,
}) {
  const [showTaxExemptId, setShowTaxExempt] = useState(false);
  const [taxExemptId, setTaxExemptId] = useState("");
  const [stateTaxId, setStateTaxId] = useState("");
  const stateTaxes = useSelector((state) => state.staticAll.stateTaxes);
  const { quotes } = selectedProposal;
  console.log({
    stateTaxes,
  });

  const selectedTax = quotes?.materials?.tax?.stateTax;

  const selectedExemptId = quotes?.materials?.tax?.exemptId;

  useEffect(() => {
    if (selectedExemptId && !selectedTax) {
      setStateTaxId("exempt");
      setTaxExemptId(selectedExemptId);
      setShowTaxExempt(true);
    } else {
      setTaxExemptId("");
      setShowTaxExempt(false);
    }
  }, [selectedExemptId]);

  useEffect(() => {
    if (selectedTax && !selectedExemptId) {
      setStateTaxId(selectedTax);
    } else {
      setStateTaxId("");
    }
  }, [selectedTax]);

  const onChangeTax = (taxId) => {
    if (taxId === "exempt") {
      setStateTaxId("exempt");
      setShowTaxExempt(true);
      return;
    }
    console.log({
      here: taxId,
    });
    // Dispatch action to get series list
    const data = {
      requestId: selectedProposal?.requestId,
      // proposalId,
      proposalId: selectedProposal._id,
      materialQuoteId: selectedProposal?.quotes?.materials?._id,

      stateTaxId: taxId,
      taxExempt: null,
      // materialQuoteId
    };
    dispatch(updateProposalInfo(data));
  };

  const onChangeTaxExempt = (event) => {
    const value = event.target.value;
    console.log({
      value,
    });
    setTaxExemptId(value);

    const data = {
      requestId: selectedProposal?.requestId,
      // proposalId,
      proposalId: selectedProposal._id,
      materialQuoteId: selectedProposal?.quotes?.materials?._id,

      taxExempt: value,
      stateTaxId: null,
      // materialQuoteId
    };
    dispatch(updateProposalInfo(data));
  };
  return (
    <>
      <Row>
        <Col
          style={{
            ...styles.reduceWidthCol,
            flex: "77%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Form.Label style={{ marginTop: "2%" }}>
            Material Sub Total:
          </Form.Label>
        </Col>
        <Col
          style={{
            ...styles.reduceWidthCol,
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          <PriceInput input="Total" price={quotes?.materials?.total} disabled />
        </Col>
      </Row>
      <Row>
        <Col style={styles.reduceWidthCol} lg="6" />
        <Col style={styles.reduceWidthCol} lg="3">
          {showTaxExemptId && (
            <Form.Control
              value={taxExemptId}
              onChange={onChangeTaxExempt}
              type="text"
              style={{ textAlign: "center" }}
              placeholder="Tax Exempt ID#"
            />
          )}
        </Col>
        <Col style={styles.reduceWidthCol} lg="3">
          <Row>
            <Col style={{ textAlign: "right" }}>
              <Form.Label>State Tax:</Form.Label>
            </Col>
            <Col>
              <Form.Group>
                <Form.Control
                  as="select"
                  style={
                    !stateTaxId
                      ? {
                          borderColor: "red",
                          color: "red",
                        }
                      : {}
                  }
                  value={stateTaxId}
                  onChange={(event) => onChangeTax(event.target.value)}
                  disabled={isLockQuoteData?.isLocked}
                >
                  <option value="">Select</option>
                  {stateTaxes &&
                    stateTaxes.length > 0 &&
                    stateTaxes.map((taxData) => (
                      <option
                        value={taxData._id}
                      >{`${taxData.per}% (${taxData.country})`}</option>
                    ))}
                  <option value={"exempt"}>Exempt</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col style={styles.reduceWidthCol} lg="6" />
        <Col style={styles.reduceWidthCol} />
        <Col style={styles.reduceWidthCol} />
        <Col style={{ ...styles.reduceWidthCol, textAlign: "right" }}>
          <Form.Label style={{ marginTop: "10%" }}>Total:</Form.Label>
        </Col>
        <Col
          style={{
            ...styles.reduceWidthCol,
            display: "flex",
            alignItems: "center",
          }}
        >
          <PriceInput
            // price={tax ? total : 0}
            price={stateTaxId || taxExemptId ? quotes?.materials?.subtotal : 0}
            disabled
            style={{ background: "#28a745", color: "#fff" }}
          />
        </Col>
      </Row>
    </>
  );
}

function TaxOption({ taxData }) {
  return (
    <SelectOption
      value={taxData._id}
      display={`${taxData.per}% (${taxData.country})`}
    />
  );
}
