import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import RequiredText from "components/required-text/required-text";
import validator from "validator";
import { checkPassword } from "utils/utils";
import { useSelector, useDispatch } from "react-redux";
import { fetchStaticAll } from "lib/redux/static-all/static-all.actions";
import {
  getUsers,
  updateUser,
  addUser,
  deleteUser,
  toggleLock,
  getUserRoles,
} from "lib/redux/user/user.actions";
import { toast } from "react-toastify";
import DeleteModal from "components/DeleteModal";
import InitialLoader from "components/InitilalLoader";
import { useHistory } from "react-router-dom";

const basePrice = 15;
const DeleteUserTitle =
  "The selected user will be deleted and no longer have access to the system. However, any data entered will still be available. Proceed with delete?";

const displayText = (text) => (
  <div style={{ textAlign: "center", padding: "0em" }}>
    <p style={{ margin: "0", fontWeight: "bold" }}>{text}</p>
  </div>
);

const LoadingLoader = () => (
  <div className="loading">
    <div className="icon" />
  </div>
);

function RemainingUser({
  USER_ROLES,
  data,
  addNew,
  updateAddUser,
  idx,
  deleteAddUser,
  showDeleteBtn,
}) {
  const [edit, setEdit] = useState(false);
  const [_id, setId] = useState("");
  const [newData, setNewData] = useState(addNew);
  const [lock, setLock] = useState(false);
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [role, setRole] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState({});
  const [processing, setProcessing] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userEmplId, setUserEmplId] = useState("");
  const [filterData, setFilterData] = useState([]);

  const { user } = useSelector((state) => state.login);
  const { userList, listErr, gettingUsers } = useSelector(
    (state) => state.userList
  );

  useEffect(() => {
    setUserEmplId(user.userData.email);
  }, [user]);

  useEffect(() => {
    if (userList && userList.length > 0) {
      const filterData = userList.filter((elem) => {
        return elem.email == userEmplId;
      });
      setFilterData(filterData);
    }
  }, [userList, userEmplId]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (filterData) {
      filterData.map((elem) => {
        setId(elem._id);
        setFirst(elem.firstName);
        setLast(elem.lastName);
        setRole(elem.role[0]._id);
        setEmail(elem.email);
        setLock(elem.isLocked);
      });
    }
  }, [filterData]);

  const hideDeleteModal = () => setShowDeleteModal(false);
  const deleteData = () => {
    onDeleteUser(_id);
    // deleteAddUser(dltIndex)
    setShowDeleteModal(false);
  };

  const deleteModal = DeleteModal.bind(this, {
    showDeleteModal,
    deleteData: () => deleteData(),
    processing: false,
    heading: "Delete User Confirmation",
    hideDeleteModal,
    dltSubHead: DeleteUserTitle,
  });

  const validate = () => {
    const newErr = {};
    const msg = "Please fill this field.";

    if (!role) {
      newErr.role = msg;
    }
    if (!first.trim()) {
      newErr.first = msg;
    }
    if (!last.trim()) {
      newErr.last = msg;
    }
    if (!email.trim()) {
      newErr.email = msg;
    }

    if (!validator.isEmail(email)) {
      newErr.email = "Please provide valid email.";
    }

    if (newData && !password) {
      newErr.password =
        "Password must be at least 6 characters and must contain one letter and one number.";
    }

    if (password && (!password.match(checkPassword) || password.length < 6)) {
      newErr.password =
        "Password must be at least 6 characters and must contain one letter and one number.";
    }

    if (password && confirmPassword !== password) {
      newErr.confirm = "Password didn't match.";
    }

    if (JSON.stringify(newErr) === "{}") {
      return false;
    }
    setError(newErr);
    return true;
  };

  const onEdit = () => {
    setProcessing(true);
    dispatch(
      updateUser(data._id, {
        role: [role],
        firstName: first,
        lastName: last,
        email,
        password,
      })
    )
      .then((res) => res.data)
      .then((res) => {
        if (res.success) {
          setEdit(false);
          toast.success("Updated Successfully!");
          setProcessing(false);
          setPassword("");
          setConfirmPassword("");
        } else {
          toast.error(res.error);
          setProcessing(false);
        }
      })
      .catch((err) => {
        toast.error(err.meesage);
        setProcessing(false);
      });
  };

  const saveData = () => {
    if (!validate()) {
      if (newData) {
        setProcessing(true);
        dispatch(
          addUser({
            role: [role],
            firstName: first,
            lastName: last,
            email,
            password,
          })
        )
          .then((res) => res.data)
          .then((res) => {
            if (res.success) {
              setRole("");
              setFirst("");
              setLast("");
              setEmail("");
              setEdit(false);
              toast.success("User Added Successfully!");
              setProcessing(false);
              updateAddUser(idx, {
                _id: res.result.userid,
                role: [role],
                firstName: first,
                lastName: last,
                email,
                password: "",
                confirmPassword: "",
                addNew: false,
              });
              setPassword("");
              setConfirmPassword("");
            } else {
              toast.error(res.error);
              setProcessing(false);
            }
          })
          .catch((err) => {
            toast.error(err.meesage);
            setProcessing(false);
          });
      } else {
        setProcessing(true);
        dispatch(
          updateUser(_id, {
            role: [role],
            firstName: first,
            lastName: last,
            email,
            password,
          })
        )
          .then((res) => res.data)
          .then((res) => {
            if (res.success) {
              setPassword("");
              setConfirmPassword("");
              setEdit(false);
              toast.success("Updated Successfully!");
              setProcessing(false);
            } else {
              toast.error(res.error);
              setProcessing(false);
            }
          })
          .catch((err) => {
            toast.error(err.meesage);
            setProcessing(false);
          });
      }
    }
  };

  const handelChange = (e, callback) => {
    if (error[e.target.name]) {
      setError({
        ...error,
        [e.target.name]: "",
      });
    }
    callback(e.target.value);
  };

  const displayRoleTypes = (types) => {
    const filterData = types.filter((type) => {
      return type.isDeleted !== true;
    });

    return filterData.map(
      (type) => (
        //  type.visible ? (
        <option key={type._id} value={type._id}>
          {type.roleName}
        </option>
      )
      //  ) : null
    );
  };

  const lockOrUnlock = () => {
    setProcessing(true);
    dispatch(toggleLock(_id))
      .then((res) => res.data)
      .then((res) => {
        if (res.success) {
          setLock(res.result.isLocked);
          toast.success(
            `User ${res.result.isLocked ? "locked" : "unlocked"} Successfully!`
          );
          setProcessing(false);
        } else {
          toast.error(res.error);
          setProcessing(false);
        }
      })
      .catch((err) => {
        toast.error(err.meesage);
        setProcessing(false);
      });
  };

  const onDeleteUser = (id) => {
    setProcessing(true);
    dispatch(deleteUser(id))
      .then((res) => res.data)
      .then((res) => {
        if (res.success) {
          toast.success("User deleted Successfully!");
          setProcessing(false);
          deleteAddUser(id);
        } else {
          toast.error(res.error);
          setProcessing(false);
        }
      })
      .catch((err) => {
        toast.error(err.meesage);
        setProcessing(false);
      });
  };

  return (
    <>
      {/* {!newData && (
        <Col lg="1">
          {lock ? (
            <Button
              variant="success"
              disabled={processing}
              onClick={() => lockOrUnlock()}
            >
              Unlock
            </Button>
          ) : (
            <Button
              variant="danger"
              disabled={processing}
              onClick={() => lockOrUnlock()}
              className="buttn_widthh"
            >
              Lock
            </Button>
          )}
        </Col>
      )} */}
      <Col className="_newCol">
        <Form.Group>
          <Form.Control
            disabled={true}
            as="select"
            value={role}
            name="role"
            isInvalid={error && error.role}
            onChange={(event) => handelChange(event, setRole)}
          >
            <option value="">Choose...</option>
            {displayRoleTypes(USER_ROLES)}
          </Form.Control>
        </Form.Group>
        {error && error.role && <p className="err">{error.role}</p>}
      </Col>
      <Col className="_newCol">
        <Form.Group>
          <Form.Control
            disabled={true}
            type="text"
            name="first"
            value={first}
            placeholder="Enter first name"
            onChange={(event) => handelChange(event, setFirst)}
            isInvalid={error && error.first}
          />
        </Form.Group>
        {error && error.first && <p className="err">{error.first}</p>}
      </Col>
      <Col className="_newCol">
        <Form.Group>
          <Form.Control
            disabled={true}
            type="text"
            name="last"
            value={last}
            placeholder="Enter last name"
            onChange={(event) => handelChange(event, setLast)}
            isInvalid={error && error.last}
          />
        </Form.Group>
        {error && error.last && <p className="err">{error.last}</p>}
      </Col>
      <Col className="_newCol">
        <Form.Group>
          <Form.Control
            disabled={true}
            type="text"
            name="email"
            value={email}
            placeholder="Enter Email or Username"
            onChange={(event) => handelChange(event, setEmail)}
            isInvalid={error && error.email}
          />
          {error && error.email && <p className="err">{error.email}</p>}
        </Form.Group>
      </Col>
      <Col className="_newCol">
        <Form.Group>
          <Form.Control
            disabled={!edit}
            type="password"
            name="password"
            value={password}
            placeholder="Enter Password"
            onChange={(event) => handelChange(event, setPassword)}
            isInvalid={error && error.password}
          />
          {error && error.password && <p className="err">{error.password}</p>}
        </Form.Group>
      </Col>
      <Col className="_newCol">
        <Form.Group>
          <Form.Control
            disabled={!edit}
            type="password"
            name="confirm"
            value={confirmPassword}
            placeholder="Confirm password"
            onChange={(event) => handelChange(event, setConfirmPassword)}
            isInvalid={error && error.confirm}
          />
        </Form.Group>
        {error && error.confirm && <p className="err">{error.confirm}</p>}
      </Col>
      <Col style={{ maxWidth: "7%" }}>
        {edit ? (
          <Button
            disabled={!confirmPassword || !password}
            onClick={() => saveData()}
            variant="success"
            className="buttn_widthh"
          >
            Save
          </Button>
        ) : (
          <Button onClick={() => setEdit(true)} className="buttn_widthh">
            Update
          </Button>
        )}
      </Col>
      {showDeleteBtn && (
        <Col style={{ maxWidth: "6.333333%" }}>
          <Button
            disabled={!edit}
            variant="danger"
            onClick={() => {
              setPassword("");
              setConfirmPassword("");
              setEdit(false);
              setProcessing(false);
            }}
          >
            Cancel
          </Button>
        </Col>
      )}
      {deleteModal()}
    </>
  );
}

export default function Subscription() {
  const [currency, setCurrency] = useState("USD");
  const [monthlySubs, setMonthlySubs] = useState(basePrice);
  const [baseUser, setBaseUser] = useState(0);
  const [allUsers, setAllUsers] = useState([]);

  const [remainingUsr, setRemainingUsr] = useState([]);
  const [processing, setProcessing] = useState(false);

  const [additionalUser, setAdditionalUser] = useState(baseUser);
  const [addUsers, setAddUsers] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [dltIndex, setDltIndex] = useState("");
  const [permission, setPermission] = useState({});
  const [userRoles, setUserRoles] = useState([]);
  const [newUsers, setNewUsers] = useState([]);
  const [baseUserCount, setBaseUserCount] = useState([]);
  const [admin, setAdmin] = useState(null);
  const { data, errors, isLoading } = useSelector((state) => state.staticAll);
  const { user } = useSelector((state) => state.login);

  const history = useHistory();
  const dispatch = useDispatch();

  const { userList, listErr, gettingUsers } = useSelector(
    (state) => state.userList
  );
  const { userRoleList, UserRoleslistErr, BasePlanlistErr } = useSelector(
    (state) => state.userRoles
  );

  const loadData = () => {
    dispatch(fetchStaticAll());
    dispatch(getUsers());
    dispatch(getUserRoles());
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (userList && userList.length > 0) {
      setAllUsers(userList);
    }
  }, [userList]);

  useEffect(() => {
    categorizeUsers();
  }, [allUsers, baseUserCount]);

  useEffect(() => {
    if (listErr) {
      if (permission?.write) {
        toast.error("Users list could not be fetched. Please try again later.");
      }
    }
  }, [listErr]);

  useEffect(() => {
    if (data) {
      setBaseUserCount(data.basePlanUser);
    }
  }, [data]);

  useEffect(() => {
    if (userRoleList) {
      setUserRoles(userRoleList);
    }
  }, [userRoleList]);
  useEffect(() => {
    if (errors) {
      toast.error(errors);
    }
  }, [errors]);

  const categorizeUsers = () => {
    if (allUsers?.length > 0) {
      const aUsers = [];
      const remainUsers = [];
      allUsers?.map((user, i) => {
        baseUserCount?.map((counts) => {
          if (user?.role[0]?.roleName?.toLowerCase()?.includes("admin")) {
            return setAdmin(user);
          }
          if (i < 1) {
            return remainUsers.push(user);
          }
          return aUsers.push(user);
        });
      });
      if (remainUsers?.length > 0) {
        setRemainingUsr(remainUsers);
        setRemainingUsr([
          ...remainUsers,
          ...new Array(1 - remainUsers?.length)?.fill({
            newUser: true,
          }),
        ]);
      } else {
        setRemainingUsr(
          new Array(1)?.fill({
            newUser: true,
          })
        );
      }
      if (aUsers?.length > 0) {
        setAddUsers(aUsers);
        setBaseUser(aUsers?.length + newUsers?.length);
        setAdditionalUser(aUsers?.length + newUsers?.length);
      } else {
        setAddUsers([]);
        setBaseUser(newUsers?.length);
        setAdditionalUser(newUsers?.length);
      }
    }
  };

  useEffect(() => {
    setMonthlySubs(basePrice + additionalUser * 5);
  }, [additionalUser]);

  const updateAddUser = (index, data) => {
    newUsers.splice(index, 1);
    setNewUsers(newUsers);
    allUsers.push(data);
    setAllUsers(allUsers);
    categorizeUsers();
  };

  const deleteAddUser = (id) => {
    const temp = [...allUsers];
    const idx = allUsers.findIndex((user) => user._id === id);
    temp.splice(idx, 1);
    setAllUsers([...temp]);
  };

  const getCommonHeader = (hideLast = false) => (
    <Row
      className="remain_user_label"
      style={{
        textAlign: "center",
        maxHeight: "56px",
      }}
    >
      {!hideLast && <Col style={{ maxWidth: "2.333333%" }} />}
      <Col>
        <Form.Label>
          User Role
          <RequiredText>*</RequiredText>
        </Form.Label>
      </Col>
      <Col>
        <Form.Label>
          First
          <RequiredText>*</RequiredText>{" "}
        </Form.Label>
      </Col>
      <Col>
        <Form.Label>
          Last
          <RequiredText>*</RequiredText>
        </Form.Label>
      </Col>
      <Col>
        <Form.Label>
          User Name/Email
          <RequiredText>*</RequiredText>{" "}
        </Form.Label>
      </Col>
      <Col>
        <Form.Label>
          Password
          <RequiredText>*</RequiredText>{" "}
        </Form.Label>
      </Col>
      <Col style={{ position: "relative", right: "10px" }}>
        <Form.Label>
          Confirm Password
          <RequiredText>*</RequiredText>
        </Form.Label>
      </Col>
      <Col style={{ maxWidth: "3.333333%" }} />
      {!hideLast && <Col style={{ maxWidth: "6.333333%" }} />}
    </Row>
  );

  const displayUserDetails = () => (
    <>
      {remainingUsr.length > 0 && getCommonHeader()}
      {remainingUsr.map((user, i) => (
        <Row
          className="_totals_div"
          key={user._id}
          style={{ alignItems: "baseline" }}
        >
          <RemainingUser
            USER_ROLES={userRoles}
            data={user}
            key={user._id}
            updateAddUser={updateAddUser}
            idx={i}
            deleteAddUser={deleteAddUser}
            showDeleteBtn
            addNew={user.newUser}
          />
        </Row>
      ))}
    </>
  );

  if (gettingUsers || errors || listErr)
    return (
      <div className="">
        <InitialLoader error={listErr} onReload={loadData} />
      </div>
    );

  return (
    <div className="_container_right _user_page">
      <Row className="_scrollable_users">
        <Col>{displayUserDetails()}</Col>
      </Row>
    </div>
  );
}
