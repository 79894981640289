import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import DisplayBtnsTab from "./DisplayBtnsTab";
import AccessoriesTabModal from "./AccessoriesTabModal";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  HideModal,
  ShowModal,
} from "../redux/roof-metric-collection/metric.action";

const MetricTab = ({ sectionsData, requestId }) => {
  const [activeTab, setActiveTab] = useState("accessories");

  const {
    modalData: { showModal = false, data },
  } = useSelector((state) => state.metric);

  const dispatch = useDispatch();

  console.log({
    sectionsData,
    requestId,
  });

  const metric = useSelector((state) => state.metric);

  console.log({ metric });

  const renderTabDimenData = (currentTab) => {
    return DisplayBtnsTab({ btns: currentTab.subTabs });
  };

  const hideModal = () => {
    dispatch(HideModal());
  };

  return (
    <>
      <Tabs
        id="controlled-tab-example"
        activeKey={activeTab}
        onSelect={(key) => {
          setActiveTab(key);
        }}
        style={{ marginBottom: "3%" }}
      >
        {metric?.metricOptions?.map((currentTab) => (
          <Tab
            key={currentTab.key}
            eventKey={currentTab.key}
            title={currentTab.name}
            tabClassName="tabposition"
          >
            {renderTabDimenData(currentTab)}
          </Tab>
        ))}
      </Tabs>

      <AccessoriesTabModal
        showTabModal={showModal}
        onHide={hideModal}
        section={sectionsData?.length}
        modalInfo={data}
        requestId={requestId}
      />
    </>
  );
};

MetricTab.propTypes = {
  sectionsData: PropTypes.array.isRequired,
};

export default MetricTab;
