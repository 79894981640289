import React from "react";

import { Row, Col, Button, Form, Modal, Alert } from "react-bootstrap";
import "./calendar.scss";
import PopupModal from "components/popup-modal/popup-modal";

import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";

export const RequestDetailModal = ({ ...props }) => {
  const {
    isOpen,
    date,
    address,
    type,
    msg,
    msgFor,
    start,
    isAllDay,
    requestFor,
    sRep,
    end,
    toggleModal,
    clearAllFields,
    clearAvailablePersons,
  } = props;

  const renderRequestsFor = (requestsFor) => {
    return requestsFor.map((reqData) => {
      return "  " + reqData.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "");
    });
  };

  const onHide = () => {
    clearAllFields();
    clearAvailablePersons();
    toggleModal(false);
  };

  return (
    <PopupModal
      show={isOpen}
      onHide={onHide}
      heading={`Appointment Detaill - ${date}`}
      subHeading={`${address} - ${type} - ${renderRequestsFor(requestFor)}`}
      bold={true}
      styles={{ background: "#8a0007e1", color: "#fff" }}
    >
      <Col>
        {msg && msgFor === "modal" && (
          <Col>
            <Alert variant="danger">{msg}</Alert>
          </Col>
        )}
        <Form>
          <Row>
            <Col md="4" sm="12">
              <Form.Group style={styles.formInput}>
                <Form.Label>Start Time</Form.Label>
                <Form.Control
                  type="text"
                  name="start"
                  value={start}
                  disabled={isAllDay}
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col md="4" sm="12">
              <Form.Group style={styles.formInput}>
                <Form.Label>End Time</Form.Label>
                <Form.Control
                  type="text"
                  name="end"
                  disabled={isAllDay}
                  value={end}
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col style={{ alignSelf: "center" }} md="4" sm="12">
              <Form.Group>
                <Form.Check
                  name="isAllDay"
                  defaultChecked={isAllDay}
                  value={isAllDay}
                  label="All Day"
                  style={{ pointerEvents: "none" }}
                />
              </Form.Group>
            </Col>
          </Row>

          {sRep ? (
            <Row>
              <Col md="12" sm="12" style={{ padding: "1em" }}>
                <label>Select Sales Rep:</label>
                <Select
                  styles={{ pointerEvents: "none" }}
                  isMulti
                  name="sRep"
                  value={sRep}
                  options={sRep}
                  style={{ pointerEvents: "none" }}
                />
              </Col>
            </Row>
          ) : (
            <div className="_message"> No person is available.</div>
          )}
        </Form>
      </Col>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={onHide}>
          Cancel
        </Button>
      </Modal.Footer>
    </PopupModal>
  );
};

const styles = {
  container: {
    background: "#fff",
    padding: "2em .5em",
  },
  heading: {
    flex: 1,
  },
  header: {
    padding: "25px 20px",
  },
  formInput: {
    marginBottom: "15px",
  },
  formStyle: {
    // marginTop: "5%"
  },
  tabItem: { padding: "1em" },
};

export default RequestDetailModal;
