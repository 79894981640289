import { staticAllTypes } from "./static-all.types";

const INITIAL_STATE = {
  isLoading: false,
  data: null,
  errors: null,

  isStateTaxesLoading: false,
  stateTaxes: [],
  stateTaxesError: null,
};

export function staticAllReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case staticAllTypes.STATIC_ALL_START:
      return {
        ...state,
        isLoading: true,
      };

    case staticAllTypes.STATIC_ALL_SUCCESS:
      return {
        ...state,
        data: action.payload.result,
        isLoading: false,
        errors: null,
      };

    case staticAllTypes.STATIC_ALL_FAILURE:
      return {
        ...state,
        data: null,
        isLoading: false,
        errors: action.payload.message,
      };

    // Get state taxes
    case staticAllTypes.STATE_TAXES_ALL_START:
      return {
        ...state,
        isStateTaxesLoading: true,
      };

    case staticAllTypes.STATE_TAXES_ALL_SUCCESS:
      console.log({
        payload: action,
      });

      return {
        ...state,
        stateTaxes: action.payload,
        isStateTaxesLoading: false,
        stateTaxesError: null,
      };

    case staticAllTypes.STATE_TAXES_ALL_FAILURE:
      return {
        ...state,
        stateTaxes: null,
        isStateTaxesLoading: false,
        stateTaxesError: action.payload.message,
      };

    default:
      return state;
  }
}
