import React, { useCallback, useState } from "react";
import { Row, Col, Form, Button, Modal, Alert } from "react-bootstrap";
import "./repairs-activity.scss";
import SearchField from "components/search-field/search-field";
import moment from "moment";
import DatePicker from "react-date-picker";
import Clock from "components/clock/clock";
import Tabs from "components/tabs/RepairsTabs";
import "react-datepicker/dist/react-datepicker.css";
import InitialLoader from "components/InitilalLoader";

import Select from "react-select";
import PopupModal from "components/popup-modal/popup-modal";
import MonthPickerInput from "react-month-picker-input";
import "react-month-picker-input/dist/react-month-picker-input.css";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { ApiEndpoints } from "lib/config/baseSettings";
import { axiosInstance } from "services";
import { RepairCard } from "./RepairCard";
import { useDispatch } from "react-redux";
import { fetchTask, getRepairRequest } from "lib/redux/repairs/repairs.actions";
import { useSelector } from "react-redux";
import { RepairTasks } from "./repair-tasks";
import { RepairInfo } from "./RepairInfo";
import { RepairPersons } from "lib/redux/calendar/calendar.selector";
import { toast } from "react-toastify";
import { convertMonthYearToDate } from "utils/dateTimeUtils";
import _ from "lodash";

const RepairsActivity = (props) => {
  const [modal, setModal] = useState(false);
  const [requestId, setRequestId] = useState("");
  const [msgType, setMsgType] = useState("");
  const [msgFor, setMsgFor] = useState("!modal");

  const [msg, setMsg] = useState("");
  const [searchString, setSearchString] = useState("");
  const [selectPerson, setSelectPerson] = useState([]);
  const [showServiceRequestModal, setShowServiceRequestModal] = useState(false);

  const [countData, setCountData] = useState({});
  const [isLoadingLeads, setIsLoadingLeads] = useState(false);
  const [isLoadingLeadUpdate, setIsLoadingUpdate] = useState(false);

  const [selectedScheduleDate, setSelectedScheduleDate] = useState(new Date());
  const [dateForMonthStages, setDateForMonthSatges] = useState(new Date());
  const [notActedLeads, setNotActedLeads] = useState([]);

  const [repairStage, setRepairStage] = useState([
    "Scheduled",
    "Complete Unpaid",
    "Paid/Closed",
    "Canceled",
    "Tasks"
  ]);

  const [selectedRequest, setSelectedRequest] = useState({});
  const [totalPrice, setTotalPrice] = useState(0);

  const { isLoading: isLoadingTasks, data: allTasks, errors } = useSelector(state => state.tasks);

  const initializeData = (salesStages) =>
    salesStages.reduce((acc, st) => [...acc, { status: st, data: [] }], []);

  const [allData, setAllData] = useState(() => initializeData(repairStage));
  const dispatch = useDispatch();
  const [repairUpdateType, setRepairUpdateType] = useState(false);

  const [activeTab, setActiveTab] = useState({
    name: "",
    index: 0,
    data: [],
    count: 0
  });


  const repairPersons = useSelector(state => {
    return RepairPersons(state);
  })

  console.log({
    repairPersons
  });


  useEffect(() => {
    // dispatch(fetchForCalendar())
    fetchLeadsByStatusCount('', selectedScheduleDate);
    fetchNotActedOpenData();
    // getAllLeads();    

    dispatch(fetchTask());

  }, []);


  useEffect(() => {

    if (allTasks?.length && !isLoadingTasks) {
      setCountData(prevState => ({
        ...prevState, // Keep the previous state
        Tasks: allTasks?.length // Add the Tasks key with a value of 2

      }))
    }


  }, [allTasks]);


  const updateRepairData = (key, newValue) => {
    setSelectedRequest(prevRequest => ({ ...prevRequest, [key]: newValue }))
  }


  const fetchNotActedOpenData = async (persons = []) => {

    try {
      const body = {
        "date": new Date().toISOString(),
        "isRepair": false,
        "page": 1,
        "currentStatus": 'Scheduled',
        "persons": persons,
        "searchString": ""
      }

      const response = await axiosInstance.post(ApiEndpoints.STAGE.REPAIR_NOT_ACTED_OPEN, body);
      const { data } = response;
      setNotActedLeads(data?.result)
      // setIsLoadingLeads(false);
      return data;
    } catch (error) {
      // setIsLoadingLeads(false);
    }
  }


  // Fetch counts of all status
  const fetchLeadsByStatusCount = async (status = "Scheduled", date = "", persons = [], searchString = "", isUpdate = false) => {
    try {
      console.log("Coming....", date);

      if (date) {
        date = new Date(date).toISOString();
      }
      setIsLoadingLeads(true)

      const body = {
        "date": date,
        "isRepair": false,
        "page": 1,
        "persons": persons,
        "searchString": searchString,
        "currentStatus": status
      }

      console.log({
        countBody: body
      });

      const response = await axiosInstance.post(ApiEndpoints.STAGE.REPAIR_STAGE_COUNT, body);
      const { data } = response;
      console.log({
        countResponse: data
      })
      if (data?.counts) {
        setCountData(prevState => ({
          ...prevState, // Keep the previous state
          ...data.counts, // Update with new counts
        }));
      }

      // return data;
      // const cDate = selectedScheduleDate.toISOString();
      if (date && !isUpdate) {
        const leadData = await fetchLeadsByStatus('Scheduled', date)
        console.log({
          leadData
        });

        if (!activeTab?.name) {
          console.log({
            leadDataResult: leadData?.result
          });

          setActiveTab({
            data: leadData?.result || [],
            index: 0,
            name: 'Scheduled',
            // count: data?.counts
          })
        }
      }

      setIsLoadingLeads(false)

    } catch (error) {
      setIsLoadingLeads(false)
    }
  }



  // Fetch data by status
  const fetchLeadsByStatus = async (status, date = "", persons = [], searchString = "") => {

    setMsg("");
    setMsgType("");

    try {

      setIsLoadingLeads(true);
      const body = {
        "date": date,
        "isRepair": false,
        "page": 1,
        "currentStatus": status,
        "persons": persons,
        "searchString": searchString
      }

      let response = await axiosInstance.post(ApiEndpoints.STAGE.GET_REPAIR_LEADS, body);

      console.log({
        response
      });

      const { data } = response;
      setIsLoadingLeads(false)
      return data;
    } catch (error) {
      setIsLoadingLeads(false)

    }
  }



  const updateLeadStatus = async (status) => {
    setIsLoadingUpdate(true);
    const persons = selectPerson?.map((person) => person?.value)

    try {
      console.log({ status });

      // Construct request body
      const body = {
        requestId,
        currentStatus: status
      };

      // Update status via API
      const response = await axiosInstance.put(ApiEndpoints.STAGE.REPAIR_UPDATE_STATUS, body);
      const { data } = response;
      setModal(false);

      console.log({ data });

      const getDate = () => {
        if (status === "Scheduled") {
          return selectedScheduleDate;
        } else if (["Paid/Closed", "Canceled"].includes(status)) {
          return dateForMonthStages;
        } else {
          return new Date();
        }
      };


      if (data?.result) {
        // Fetch and update leads based on the new status
        const responseData = await fetchLeadsByStatus(status, getDate(), persons);

        setActiveTab((prevState) => ({
          ...prevState,
          name: status,
          data: responseData?.result || []
        }));

        // Determine the date based on the status


        const date = getDate();
        console.log({ otherTab: date });

        // Fetch and update lead count based on status and date
        await fetchLeadsByStatusCount("", date, [], '', true);
      }

    } catch (error) {
      console.error('Error updating lead status:', error);
      toast.error('Something went wrong!');
    } finally {
      setIsLoadingUpdate(false);
    }
  };


  console.log({
    countData,
    activeTab
  });

  const displayNewRepairOptions = () => {
    return (
      <RepairInfo selectedRequest={selectedRequest} updateRepairData={updateRepairData} setTotalPrice={setTotalPrice} totalPrice={totalPrice} />
    );
  };

  const updateServiceRequest = async () => {
    try {
      setIsLoadingUpdate(true);

      const persons = selectPerson?.map((person) => person?.value)

      // Construct request body
      const body = {
        originalWorkDone: selectedRequest?.originalWorkDone,
        originalWorkYear: selectedRequest?.originalWorkYear,
        underWarrenty: selectedRequest?.underWarrenty,
        taxRate: selectedRequest?.taxRate,
        totalPrice: totalPrice,
        servicePrice: selectedRequest?.servicePrice,
        repairDescreption: selectedRequest?.repairDescreption,
        requestFor: selectedRequest?.requestFor
      };

      const url = `${ApiEndpoints.STAGE.REPAIR_LEAD_UPDATE}?requestId=${selectedRequest?._id}`
      // Update status via API
      const response = await axiosInstance.put(url, body);
      const { data } = response;
      setModal(false);

      console.log({ data });

      const getDate = () => {
        if (activeTab.name === "Scheduled") {
          return selectedScheduleDate;
        } else if (["Paid/Closed", "Canceled"].includes(status)) {
          return dateForMonthStages;
        } else {
          return new Date();
        }
      };

      if (repairUpdateType === "not-acted-upon") {
        await fetchNotActedOpenData(persons);
      } else {
        const newData = await fetchLeadsByStatus(activeTab?.name, getDate(), persons);
        if (newData?.result) {
          setActiveTab({
            name: activeTab?.name,
            index: activeTab?.index,
            data: newData.result || []
          });
  
        }
      }


      setIsLoadingUpdate(false);
      setMsg("Service request is updated successfully.");
      setMsgType("success");
      hideSRModal()
      setTotalPrice(0);

    } catch (error) {
      console.error('Error updating repair data:', error);
      toast.error('Something went wrong!');
    } finally {
      setIsLoadingUpdate(false);
      hideSRModal();
      setTotalPrice(0);
    }
  }

  const displayFooter = () => {
    const styles = {
      display: "flex",
      justifyContent: "flex-end",
    };
    return (
      <Modal.Footer>
        <div style={{ ...styles, width: "100%" }}>
          <div style={styles}>
            <div style={{ marginRight: "5px" }}>
              <Button
                variant="outline-danger"
                onClick={hideSRModal}
              >
                Cancel
              </Button>
            </div>
            <div style={{ marginLeft: "5px" }}>
              <Button
                variant={"success"}
                disabled={isLoadingLeadUpdate}
                onClick={updateServiceRequest}
              >
                {isLoadingLeadUpdate ? "Saving..." : "Save Changes"}
                {
                  isLoadingLeadUpdate ? (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  ) : null
                }

              </Button>
            </div>
          </div>
        </div>
      </Modal.Footer>
    );
  };

  const hideSRModal = () => {
    setShowServiceRequestModal(false);
    setSelectedRequest({});
    setMsg("");
    setMsgType("");
  };


  const dateChange = async (date) => {
    const persons = selectPerson?.map((person) => person?.value)

    if (typeof date === "string") {
      date = convertMonthYearToDate(date).toISOString();
      setDateForMonthSatges(new Date(date))
    }

    if (typeof date === "object") {
      setSelectedScheduleDate(date)
      date = new Date(date).toISOString();
      // Without waiting call the count
      fetchLeadsByStatusCount(activeTab.name, date, persons, '', true)
      console.log({
        date
      });
    }

    const filterResult = await fetchLeadsByStatus(activeTab.name, date, persons);

    const newAllData = allData.map((lead) => {
      if (lead.status === activeTab.name) {
        lead.data = filterResult;
      }
      return lead;
    });


    // Update only the 'data' property
    setActiveTab(prevState => ({
      ...prevState,
      data: filterResult?.result
    }));


    setAllData(newAllData);

  }

  const changeTab = async (tab, i) => {
    console.log({
      tttt: tab
    });

    if (tab === "Tasks") {
      // setActiveTab({
      //   name: tab,
      //   index: i,
      //   data: newData.result || []
      // });

      setCountData(prevData => ({ ...prevData, Tasks: allTasks?.length }))
    }

    const todaysDate = new Date();
    setSelectedScheduleDate(todaysDate);
    setDateForMonthSatges(todaysDate);
    const persons = selectPerson?.map(sp => sp?.value);
    try {

      const newData = await fetchLeadsByStatus(tab, todaysDate.toISOString(), persons);
      console.log({
        newData
      });

      if (newData?.result) {
        setActiveTab({
          name: tab,
          index: i,
          data: newData.result || []
        });

      }
    } catch (error) {
      toast.error("Error fetching leads by status");
    }
  };




  const renderEmptyTab = () => {
    let msg = "";
    console.log({
      activeTab: activeTab.name
    });
    switch(activeTab.name) {
      case "Scheduled":
        msg = "No Scheduled Visits";
        break;
      case "Complete Unpaid":
        msg = "No Complete Unpaid";
        break;
      case "Paid/Closed":
        msg = "No Paid/Closed";
        break;
      case "Canceled":
        msg = "No Canceled Projects";
        break;
      default:
        msg = "No data found";
    }
    return (
      <div className="empty-_tab">
        <p>{msg}</p>
      </div>
    );
  }

  const onHide = () => {
    setModal(false);
    setRequestId("");
  };

  const getRequest = (requestId, type) => {
    const leads = type === "not-acted-upon" ? notActedLeads : activeTab.data;
    setRepairUpdateType(type);

    console.log({
      leads,
      requestId,
      type
    });

    const selectedRequest = leads.find((request) => requestId === request._id);
    setSelectedRequest(selectedRequest)
    setShowServiceRequestModal(true);
    setTotalPrice(selectedRequest?.totalPrice)
  };


  const updateStaus = (id, type) => {
    console.log({
      id, type
    });

    if (type === 'no-acted-upon') {
      setRepairStage(prevStage => ['Unscheduled', ...prevStage])
    }
    // if (paymentStatus !== "Frozen 1") {
    setModal(true);
    setRequestId(id);
    // }
  };

  const displayTabItem = (data, type = '') => {
    console.log({
      data123: data
    });
    let visitDate = "";

    if (data?.month) {
      visitDate += `${data?.month}/`;
    }

    if (data?.day) {
      visitDate += `${data?.day}/`;
    }

    if (data?.year) {
      visitDate += `${data?.year}`;
    }

    const cardData = {
      type: type,
      workTypes: data?.repair,
      leadAddress: data?.leadAddress,
      updateStaus: updateStaus,
      leadRequest: {
        _id: data?._id,
        createdOn: data?.createdAt,
        startTime: data?.schedules?.startTime,
        endTime: data?.schedules?.startTime,
        hasGutters: data?.hasGutters,
        hasRoof: data?.hasRoof,
        // followUpDate: followUpDate,
        type: data?.type,
        personDetails: {
          firstName: data?.schedules?.personDetails?.firstName,
          lastName: data?.schedules?.personDetails?.lastName,
        },
        currentStatus: data?.currentStatus,
        scheduledDate: data?.scheduledDate
      },
      activeI: activeTab.index,
      getRequest: getRequest
    };

    console.log({
      cardData,
      activeTabupdated: activeTab
    });

    // if (activeTab?.name === "Tasks") {
    //   return (
    //     <div>
    //       <RepairTasks />
    //     </div>
    //   )
    // }
    return (
      <div>
        <RepairCard {...cardData} />
      </div>
    )
  }

  console.log({
    allData
  });

  const renderFilter = () => {
    if (activeTab.name !== "Scheduled" && (activeTab.name !== "Tasks" && activeTab.name !== "Complete Unpaid")) {
      return (
        <Row style={{ marginRight: '5px' }}>
          <Col
            style={{
              margin: "1em auto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <div>
              <h6>Filter By Month</h6>
              <MonthPickerInput
                year={dateForMonthStages.getFullYear()}
                month={dateForMonthStages.getMonth()}
                onChange={dateChange}
              />
            </div>
          </Col>
        </Row>
      );
    }

    if (activeTab.name === "Scheduled")

      return (
        <div className="date_time">
          <div className="time">
            <Clock />
          </div>
          <div className="day_date">
            {selectedScheduleDate && (
              <div className="day">
                {moment(selectedScheduleDate).format("dddd")}
              </div>
            )}
            <div className="date-picker">
              <DatePicker
                value={selectedScheduleDate}
                onChange={dateChange}
                showLeadingZeros={true}
              />
            </div>
          </div>
        </div>
      )

  };


  async function handleChangeRepairPerson(value, persons = []) {
    console.log({
      value
    });
    setSelectPerson(value);
    const responseData = await fetchLeadsByStatus(activeTab.name, selectedScheduleDate.toISOString(), persons)
    fetchLeadsByStatusCount("", selectedScheduleDate, persons, '', value?.length ? true : false)
    fetchNotActedOpenData(persons);
    console.log({
      responseData
    });

    setActiveTab((prevState) => ({
      ...prevState,
      name: activeTab?.name,
      data: responseData?.result || []
    }));

  }

  // Create a debounced function
  const debouncedFunction = useCallback(
    _.debounce((value) => {
      // Handle the debounced logic here, e.g., API call
      const persons = repairPersons?.map((person) => person?.value)
      fetchLeadsByStatus(activeTab.name, '', persons, value);
      fetchLeadsByStatusCount("", selectedScheduleDate, persons, value, true)
    }, 600), // Adjust the debounce delay as needed
    []
  );


  // Handle input change
  const handleChange = (e) => {
    const { value } = e.target;
    setSearchString(value);
    debouncedFunction(value);
  };



  const displayPersons = (persons) => {
    return persons?.map((rp) => { return { value: rp?.personId, label: rp?.personName } })
  };

  return (
    <div style={{ margin: "2em 1.5em", position: "relative" }}>
      <Row>
        {msg && msgFor === "!modal" && (
          <Col>
            <Alert variant={msgType}>{msg}</Alert>
          </Col>
        )}
      </Row>
      <Row style={{ alignItems: "center" }}>
        <Col md="2">
          <h3 className="_title">
            Repairs Activity &nbsp;
            <Link
              to={{
                pathname: "/dashboard/help",
                search: "Activity Repair",
              }}
              target="_blank"
              rel="noreferrer"
              style={{ cursor: "pointer" }}
            >
              <span style={{ color: "red" }}>?</span>
            </Link>
          </h3>
        </Col>
        <Col md="6">
          <SearchField
            type="search"
            placeholder="Search for repair"
            value={searchString}
            name="searchString"
            onChange={(e) => handleChange(e)}
            sm
          // paymentStatus={paymentStatus}
          />
        </Col>
        <Col md="4">
          <Form.Group style={{ margin: "0 4em" }}>
            {repairPersons && repairPersons.length > 0 && (
              <Select
                isMulti
                placeholder="View activity by repairs persons"
                name="selectPerson"
                // value={selectPerson}
                onChange={
                  (value) => {
                    const persons = value?.map((person) => person?.value)
                    handleChangeRepairPerson(value, persons);
                  }
                  // setState(() => ({ selectPerson: value }))
                }
                options={
                  displayPersons(repairPersons)}
              />
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          {/* {repairStage && repairsTabs.length > 0 && ( */}
          <>
            <Tabs
              tabs={repairStage}
              countData={countData}
              active={activeTab.name}
              setTab={changeTab}
              allData={allData}
            />
            <div
              className="_tab-content">
              {renderFilter()}

              {activeTab?.name !== "Tasks" ? <>
                {!isLoadingLeads ? (
                  <>
                    {/* {activeTab?.data?.map((data) => (
                    displayTabItem(data)
                  ))} */}

                    {
                      activeTab?.data?.length && !isLoadingLeads ? (
                        activeTab?.data?.map((data) => (
                          displayTabItem(data)
                        ))
                      ) : (
                        renderEmptyTab()
                      )
                    }


                  </>
                ) : (
                  // <InitialLoader onReload={getAllLeads} />
                  <InitialLoader />
                )}
              </> : <RepairTasks />}


              {
                activeTab.name === "Scheduled" ? (
                  <div>
                    <h5 h5 style={{ margin: "7% 2% 3%" }}>Appointments Not Acted Upon</h5>
                    {
                      (!isLoadingLeads && notActedLeads?.length) ? (
                        notActedLeads?.map((data) => (
                          displayTabItem(data, 'not-acted-upon')
                        ))

                      ) : null
                    }
                  </div>
                ) : null
              }


            </div>
          </>
          {/* )} */}
        </Col>
      </Row>

      <PopupModal show={modal} onHide={onHide} heading="Select Sales Stage">
        <Row style={{ margin: "1em" }}>

          <div style={{ textAlign: 'center', marginBottom: '1rem' }}>
            {
              isLoadingLeadUpdate ? (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              ) : null
            }
          </div>

          {repairStage &&
            repairStage.map((stage) =>
              stage !== "Tasks" && (
                <button
                  disabled={activeTab.name === stage}
                  key={stage}
                  onClick={() => updateLeadStatus(stage)}
                  className="stage"
                >
                  {stage}
                </button>
              )
            )}
        </Row>
        <Modal.Footer>
          <Button variant="danger" onClick={onHide}>
            Cancel
          </Button>
        </Modal.Footer>
      </PopupModal>

      <PopupModal
        // size="lg"
        show={showServiceRequestModal}
        onHide={hideSRModal}
        heading="Repair Request"
        bold={true}
      >
        <Row>
          {msg && msgFor === "modal" && (
            <Col>
              <Alert variant={msgType}>{msg}</Alert>
            </Col>
          )}
        </Row>
        {displayNewRepairOptions()}
        {displayFooter()}
      </PopupModal>
    </div>
  );
};

export default RepairsActivity;
