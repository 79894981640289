import React from "react";
import "../metric.scss";
import { Col } from "react-bootstrap";

export default function MetricButton({
  label,
  array,
  data,
  prevMetric,
  icon,
  exec = false,
  onClickBtn,
  size,
  isQuoteLock = false,
}) {
  console.log("array", array);
  return data.map((item) => (
    <Col md="auto">
      <button
        disabled={prevMetric !== undefined ? !prevMetric : false}
        key={item.id}
        // style={
        //   isQuoteLock && array === item.name
        //     ? {
        //         width: size,
        //         backgroundColor: "#60abfb",
        //         borderColor: "#60abfb",
        //       }
        //     : { width: size }
        // }
        // style={{
        //   width: size,
        // }}
        className={`ClickableBtn ${array === item.name ? "selected" : ""} `}
        onClick={() => onClickBtn(label, item.id, item.name, exec)}
      >
        {item.name} {icon || ""}
      </button>
    </Col>
  ));
}
