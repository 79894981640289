import React from "react";
import PropTypes from "prop-types";
import PopupModal, { ModalFooter } from "components/popup-modal/popup-modal";
import { Row } from "react-bootstrap";

const DisplayInstallTypeModal = ({
  show,
  label,
  hide,
  heading1,
  installTypeShingle,
  installTypeFlat,
  handleClickData,
  installTypes,
  heading2,
  pitch,
}) => {
  console.log("installTypeShingle", {
    pitch,
    installTypeShingle,
    installTypeFlat,
  });
  return (
    <PopupModal
      show={show}
      heading={label}
      onHide={hide}
      bold
      styles={{ padding: "1em 1em 0 1em", border: "none" }}
    >
      {heading1 && <label className="btnheading">{heading1}</label>}
      <Row style={{ margin: "0.2em .5em .5em .5em", alignItems: "center" }}>
        {installTypeShingle?.map((item) => {
          return (
            <button
              style={
                item.name === "Architechtural"
                  ? { maxWidth: "20%", padding: "0" }
                  : { maxWidth: "15%", padding: "0" }
              }
              key={item.id}
              className={`ClickableBtnModal ${
                installTypes?.shingles?.type === item?.name ? "selected" : ""
              }`}
              onClick={() => handleClickData("Shingles", item.name)}
              disabled={pitch?.roofPitch === "Flat"}
            >
              {item.name}
            </button>
          );
        })}
      </Row>

      {heading2 && <label className="btnheading">{heading2}</label>}
      <Row style={{ margin: "0.2em .5em .5em .5em", alignItems: "center" }}>
        {installTypeFlat?.map((item) => {
          return (
            <button
              style={{ maxWidth: "15%", padding: "0" }}
              key={item?.id}
              className={`ClickableBtnModal ${
                installTypes?.flat?.type === item?.name ? "selected" : ""
              }`}
              onClick={() => handleClickData("Flat", item.name)}
              disabled={
                pitch?.roofPitch === "Walkie" ||
                pitch?.roofPitch === "Non-Walkie"
              }
            >
              {item.name}
            </button>
          );
        })}
      </Row>
      <ModalFooter
        onCancel={hide}
        onSubmit={() => {
          hide();
        }}
      />
    </PopupModal>
  );
};

DisplayInstallTypeModal.propTypes = {
  show: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  hide: PropTypes.func.isRequired,
  installTypeShingle: PropTypes.array.isRequired,
  installTypeFlat: PropTypes.array.isRequired,
  handleClickData: PropTypes.func.isRequired,
  installTypes: PropTypes.any.isRequired,
  heading1: PropTypes.string,
  heading2: PropTypes.string,
};

export default DisplayInstallTypeModal;
