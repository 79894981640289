import moment from 'moment';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { convertMilitaryToStandard } from 'utils/dateTimeUtils';

export const RepairCard = ({

  workTypes,
  leadAddress,
  leadRequest,
  updateStaus,
  typeOfCard,
  activeI,
  taxRates,
  getRequest,
  type = '',

  optionalVisible,
  optionalField,
  optionalValue,

}) => {

  const formatAddress = (addressData) => {
    // Destructure and default to empty strings
    const {
      primaryState = "",
      primaryAddress1 = "",
      primaryAddress2 = "",
      primaryCity = "",
      primaryZip = ""
    } = addressData || {};

    // Create an array of non-empty address components
    const addressComponents = [

      primaryAddress1,
      primaryAddress2,
      primaryCity,
      primaryState,
      primaryZip
    ].filter(component => component); // Remove any empty strings

    // Join components with a comma and space
    return addressComponents.join(", ");
  };

  return (
    <>
      {/* {name?.length > 0 && ( */}
      <div className="_sales-card">
        <div className="p-details" style={{ maxWidth: "300px" }}>
          {leadAddress?.companyName && (
            <div className="names"> {leadAddress.companyName}</div>
          )}
          <div className="names">{leadAddress?.primaryFirstName || ""} {leadAddress?.primaryLastName || ""}</div>

          <div className="tel">
            {leadAddress?.primaryPhone1}
          </div>


          <div className="tel">
            {leadAddress?.primaryPhone2}
          </div>
          <a
            href={`https://www.google.com/maps/place?q=${formatAddress(leadAddress)}`}
            className="address"
            style={{ textDecoration: "underline" }}
            target="_blank"
          >
            {formatAddress(leadAddress)}
          </a>
        </div>

        <div className="o-details">
          {activeI === 0 && (
            <div className="bold">
              Time:  <span className="bold">
                {' '} {convertMilitaryToStandard(leadRequest?.startTime) || convertMilitaryToStandard(leadRequest?.endTime)
                  ? `${convertMilitaryToStandard(leadRequest?.startTime)} - ${convertMilitaryToStandard(leadRequest?.endTime)}` : "N/A"}
              </span>
            </div>
          )}
          <div className="bold">
            {activeI === 0 ? "Visit" : "Visited"}:{" "}
            <span className="bold">
              {moment(leadRequest?.scheduledDate).format("MMM D, YYYY")}
              {/* {moment(visit).format("LL")} */}
            </span>
          </div>

          <div>
            <label>Repair:</label> <span>{workTypes}</span>
          </div>
          <div>
            <label>Type:</label> <span>{leadRequest?.type}</span>
          </div>
          {optionalVisible && (
            <div>
              <label>{optionalField}:</label>{" "}
              <span>
                {optionalValue === "Unpaid"
                  ? optionalValue
                  : moment(optionalValue).format("LL")}
              </span>
            </div>
          )}
          {leadRequest?.personDetails && (
            <div>
              <label>Repair Rep.:</label>{" "}
              <span>
                {leadRequest?.personDetails
                  ? `${leadRequest?.personDetails?.firstName} ${leadRequest?.personDetails?.lastName}`
                  : "N/A"}
              </span>
            </div>
          )}
          <div>
            <label>Created:</label>{" "}
            <span>{moment(leadRequest?.createdOn).format("LL")}</span>
          </div>
        </div>
        <div className="_btns">
          <Row style={{ flexDirection: "column" }}>
            <Col style={{ marginBottom: "1em" }}>
              <Button
                className="_btn_modified"
                onClick={() => updateStaus(leadRequest?._id, typeOfCard)}
                disabled={
                  false
                  // !allowStageChange || isChanging || paymentStatus === "Frozen 1"
                }
              >
                Change Stage
              </Button>
            </Col>
            <Col style={{ marginTop: "1em" }}>
              <Button className="_btn_modified">
                <a
                  href={`https://www.google.com/maps/place?q=${formatAddress(leadAddress)}`}
                  className="address"
                  style={{ textDecoration: "none" }}
                  target="_blank"
                >
                  Directions
                </a>
              </Button>
            </Col>
          </Row>
        </div>
        <div className="_btns">
          <Button
            className="_btn_modified"
            // disabled={!repairs || isLoadingRequest}
            onClick={() => getRequest(leadRequest?._id, type)}
          >
            Open Repairs
          </Button>
        </div>
      </div>
      {/* )} */}
    </>
  );
};