import { Row, Col } from "react-bootstrap";
import useContactData from "hooks/useContactManagement";
import { getEndPoints } from "api/api";
const url = getEndPoints(process.env.REACT_APP_MODE);
import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import SearchField from "components/search-field/search-field";
import "../calendar/calendar.scss";
import "react-datepicker/dist/react-datepicker.css";
import "./contact.scss";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

import { Link } from "react-router-dom";
import RightTab from "./RightTab";
import { SideContactList } from "./SideContactList";
import InitialLoader from "components/InitilalLoader";
import { useLocation } from "react-router-dom";
import { LoadingLoader } from "components";
import _ from "lodash";

const ContactManagement = () => {
  const {
    contactManagement: contactM,
    AddContactManagementProperty,
    ContactManagementSchedule,
    ContactManagementSearch,
    contactManagementSchedule,
    isLoading,
    error,
    getContactDetails,
    success,
    selectedContact,
    setSelectedContact,
    editContactManagementProperty,
    editContactManagement,
    contactManagementData,
    AddNewSvcRequestToProperty,
    setAddNewSvc,
    addNewSvc,
    selectedLeadId,
    setSelectedLeadId,
    requestLoading,
    getCompanyDetails,
    isDetailLoading,
    companyData,
    getContactList,
  } = useContactData();
  const location = useLocation();
  const contactScrollDivRef = useRef(null);
  const [requestsDeatails, setRequestsDeatails] = useState("");
  const [contactManagement, setContactManagement] = useState([]);

  const handleOnChangeSearch = useCallback(
    _.debounce((e) => {
      // Simulate an API call
      ContactManagementSearch({ q: e.target.value });
    }, 500),
    []
  );

  const handleContactSelect = (contact) => () => {
    setSelectedLeadId(contact.leadAddressId);
    setRequestsDeatails("");
    scrollTo({
      top: 0,
    });
    contactScrollDivRef.current.scrollTo({ top: 0 });
  };

  useEffect(() => {
    setContactManagement(contactM);
  }, [contactM]);

  // useEffect(() => {
  //   const leadAddressId = location?.state?.leadAddressId;
  //   if (!leadAddressId || !contactM || contactM.length === 0) return;
  //   // getContactDetails(leadAddressId);
  //   setSelectedLeadId(leadAddressId);
  //   getContactDetails({
  //     leadId: leadAddressId,
  //   });
  //   setRequestsDeatails("");

  //   //find index
  //   let index = 0;

  //   contactM.map((value, id) => {
  //     if (value.leadAddressId === leadAddressId) {
  //       index = id;
  //     }
  //   });
  //   let chosenContact = contactM[index];
  //   let tmp_arr = contactM;
  //   tmp_arr.splice(index, 1);
  //   setContactManagement((prev) => [chosenContact, ...tmp_arr]);
  // }, [contactM]);

  if (!contactManagement) return <LoadingLoader />;

  return (
    <>
      <Row style={{ position: "relative" }}>
        <h5 className="mt-4 mb-3">
          <strong>
            {" "}
            Contact Management &nbsp; &nbsp;{" "}
            <Link
              to={{
                pathname: "/dashboard/help",
                search: "Dashboard Contact Management",
                hash: "#the-hash",
                state: "Dashboard New Lead",
              }}
              target="_blank"
              rel="noreferrer"
              style={{ cursor: "pointer" }}
            >
              <span style={{ color: "red" }}>?</span>
            </Link>
          </strong>
        </h5>
        <Col lg="3" md="4" sm="12">
          <Col>
            <SearchField
              type="search"
              placeholder={`Search for a contact or property`}
              onChange={handleOnChangeSearch}
              paymentStatus={
                companyData &&
                companyData.length &&
                companyData[0]?.paymentStatus
              }
            />
          </Col>
          <div
            style={{
              overflow: "hidden",
              height: "calc(120vh)",
              overflowY: "auto",
            }}
            ref={contactScrollDivRef}
          >
            {isLoading && (
              <InitialLoader error={error} onReload={getContactDetails} />
            )}
            {contactManagement.length > 0 && (
              <>
                {selectedContact && (
                  <Col className="cntct_mngmnt">
                    {
                      <SideContactList
                        contact={selectedContact}
                        selectedContact={selectedContact}
                        editContactManagement={editContactManagement}
                        editContactManagementProperty={
                          editContactManagementProperty
                        }
                        setRequestsDeatails={setRequestsDeatails}
                        selectedLeadId={selectedLeadId}
                        addNewSvc={addNewSvc}
                        getContactDetails={getContactDetails}
                      />
                    }
                  </Col>
                )}
                {contactManagement
                  .filter((c) => c?._id !== selectedContact?._id)
                  .map((contact) => {
                    return (
                      <Col
                        className="cntct_mngmnt"
                        onClick={handleContactSelect(contact)}
                      >
                        {
                          <SideContactList
                            contact={contact}
                            selectedContact={selectedContact}
                            editContactManagement={editContactManagement}
                            editContactManagementProperty={
                              editContactManagementProperty
                            }
                            setRequestsDeatails={setRequestsDeatails}
                            selectedLeadId={selectedLeadId}
                            addNewSvc={addNewSvc}
                            getContactDetails={getContactDetails}
                          />
                        }
                      </Col>
                    );
                  })}
              </>
            )}
          </div>
        </Col>

        <Col lg="9" md="8" sm="12">
          <Col style={{ marginTop: "7px" }}>
            {
              // ((companyData && companyData[0]?.paymentStatus !== "Frozen 1") ) &&
              selectedLeadId ? (
                <RightTab
                  // ={}
                  isDetailLoading={isDetailLoading}
                  AddContactManagementProperty={AddContactManagementProperty}
                  selectedContact={selectedContact}
                  ContactManagementSchedule={ContactManagementSchedule}
                  contactManagementSchedule={contactManagementSchedule}
                  editContactManagementProperty={editContactManagementProperty}
                  contactManagementData={contactManagementData}
                  AddNewSvcRequestToProperty={AddNewSvcRequestToProperty}
                  addNewSvc={addNewSvc}
                  setAddNewSvc={setAddNewSvc}
                  setRequestsDeatails={setRequestsDeatails}
                  requestsDeatails={requestsDeatails}
                  requestLoading={requestLoading}
                  selectedLeadId={selectedLeadId}
                  getContactList={getContactList}
                />
              ) : (
                <h3 style={{ textAlign: "center" }}>
                  <strong>Select a contact to view details...</strong>
                </h3>
              )
            }
          </Col>
        </Col>
      </Row>
    </>
  );
};

export default ContactManagement;
