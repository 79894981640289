import { SAVE_VALUE_GUTTER, SHOW_NUMPAD_GUTTER, HIDE_MODAL_GUTTER, EDIT_VALUE_GUTTER, 
    REMOVE_VALUE_GUTTER, TOGGLE_INPUT_SELECTED_GUTTER, TOGGLE_OPTION_SELECTED_GUTTER, RESET_OPTIONS_SELECTED_GUTTER, SET_FIELD_GUTTER, UPDATE_FIELD_GUTTER, UPDATE_METRIC_FIELD_GUTTER, UPDATE_METRICS_GUTTER, UPDATE_OTHER_OPTION_METRIC_GUTTER, RESET_ALL_METRICS_GUTTER, SET_CURRENT_LEAD_DATA_GUTTER } from "./gutter-metric.constants";

export const SaveValueGutter = (data) => ({
    type: SAVE_VALUE_GUTTER,
    payload: data,
});

export const ShowModalGutter = (data) => ({
    type: SHOW_NUMPAD_GUTTER,
    payload: data,
});

export const HideModalGutter = (data) => ({
    type: HIDE_MODAL_GUTTER,
    payload: data,
});

export const EditValueGutter = (data) => ({
    type: EDIT_VALUE_GUTTER,
    payload: data,
});

export const RemoveValueGutter = (data) => ({
    type: REMOVE_VALUE_GUTTER,
    payload: data,
});

export const toggleInputSelectedGutter = (data) => ({
    type: TOGGLE_INPUT_SELECTED_GUTTER,
    payload: data
});


export const toggleOptionSelectedGutter = (data) => ({
    type: TOGGLE_OPTION_SELECTED_GUTTER,
    payload: data
});

// Example action creator for resetting options
export const resetOptionsSelectedGutter = (data) => ({
    type: RESET_OPTIONS_SELECTED_GUTTER,
    payload: data
});

export const resetAllMetricsGutter = () => ({
    type: RESET_ALL_METRICS_GUTTER,
    payload: {}
});

// To update the buttons inside the button
export const updateOtherOptionValueGutter = (data) => ({
    type: UPDATE_OTHER_OPTION_METRIC_GUTTER,
    payload: data
});

// categoryName,
// subTabName,
// inputKey,
// optionKey,
// optionValueName



export const updateFieldGutter = (field, value) => ({
    type: UPDATE_FIELD_GUTTER,
    payload: { field, value }
  });


  export const updateMetricsGutter = (metrics) => {
    return {
      type: UPDATE_METRICS_GUTTER,
      payload: metrics,
    };
  };


  export const setCurrentLeadDataGutter = (data) => ({
    type: SET_CURRENT_LEAD_DATA,
    payload: data
});
