import { getUserLocal } from 'lib/constants/localbase';
import { useState, useEffect } from 'react';

const userDetails = () => {
  const [companyId, setCompanyId] = useState(null);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoading(true);
        const userDb = await getUserLocal();
        if (userDb && userDb.userData) {
          setCompanyId(userDb.userData.companyId);
          setUser(userDb.userData);
        } else {
          throw new Error('User data is not available');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  return { companyId, loading, error, user };
};

export default userDetails;
