import React, { useState, useEffect, useLayoutEffect } from "react";
import { Button, Modal, Col, Row } from "react-bootstrap";
import { PopupModal, ArrowPagination } from "components";
import { getRoofingSectionData, getGuttersSectionData } from "utils/utils";

import staticTexts from "./staticTexts";
import { useSelector } from "react-redux";
import { CancelPresentationOutlined } from "@material-ui/icons";
import { generateQuotes } from "../redux/proposals/proposals.actions";

const MetricTypes = {
  roof: "Switch to Roofing",
  gutter: "Switch to  Gutter",
};

const RoofingTableHeadsAndDataMapping = {
  sec: "Sec",
  floor: "Floor",
  elevation: "Elevation",
  pitch: "Pitch",
  workType: "Work Type",
  install: "Install",
  l: "L",
  w: "W",
  f: "F",
  sq: "Sq Ft(S)",
  flat: "Sq Ft(F)",
};

const GutterTableHeadsAndDataMapping = {
  sec: "Sec",
  level: "Level",
  elevation: "Elevation",
  gutters: "Gutters",
  leaders: "Leaders",
  screens: "Screens",
  fascia: "Fascia",
  cap: "Cap",
  heads: "Heads",
};

const style = {
  includeAllBtn: {
    width: "250px",
    height: "50px",
    margin: "0 1em 1em 0",
    float: "right",
    fontWeight: "bold",
  },
};
const SectionTable = ({
  metricSections,
  startI,
  lastI,
  tableDataMapping,
  toggleSectionSelection,
}) =>
  metricSections.length > 0 ? (
    <div className="__quote_tbl_wrapper">
      <table className="__quote_tbl">
        <thead>
          <tr>
            {Object.keys(tableDataMapping).map((key) => (
              <th key={key}>{tableDataMapping[key]}</th>
            ))}
            <th />
          </tr>
        </thead>
        <tbody>
          {metricSections.slice(startI, lastI).map((section, index) => (
            <>
              <tr>
                <td>{index + 1}</td>
                <td>{section.floor}</td>
                <td>{section.elevation}</td>
                <td>{section?.pitch?.type?.type}</td>
                <td>{section?.workType?.name}</td>
                <td>{section?.flat?.type || section?.shingles?.type} </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <Button
                    variant={section.included ? "danger" : "primary"}
                    onClick={() => toggleSectionSelection(section._id)}
                  >
                    {!section.included ? "Include" : "Exclude"}
                  </Button>
                </td>
              </tr>

              <tr key={section._id}>
                {/* {Object.keys(tableDataMapping).map((key) => (
      <td style={{ fontWeight: "500" }} key={key}>
        {section[key]}
      </td>
    ))} */}

                {/* <td>
      {excludedSections.includes(info.id) ? (
        <Button
          onClick={() =>
            excludeItem(
              excludedSections,
              setExcludedSections,
              info.id
            )
          }
        >
          Include
        </Button>
      ) : (
        <Button
          variant="danger"
          onClick={() =>
            includeItem(
              excludedSections,
              setExcludedSections,
              info.id
            )
          }
        >
          Exclude
        </Button>
      )}
    </td> */}
              </tr>
            </>
          ))}
        </tbody>
      </table>
    </div>
  ) : (
    <div
      style={{
        textAlign: "center",
        margin: "3em 0 1em 0",
        color: "#999",
        fontWeight: "bold",
      }}
    >
      No section data available.
    </div>
  );
const SectionTableHeader = ({
  selectedBuildingName,
  currentMetricType,
  MetricTypes,
  isAllSectionRoof,
  isAllSectionGutter,
  selectedSections,
  toggleAllSelection,
}) => {
  const hyphen = selectedBuildingName ? " - " : "";
  console.log({
    selectedSections,
  });
  return (
    <Row>
      <Col>
        <h4 style={{ textAlign: "center", margin: "8px 0" }} className="my-2  ">
          {selectedBuildingName + hyphen + "Roofing"}
          {/* {currentMetricType === MetricTypes.roof
            ? selectedBuildingName + hyphen + "Roofing"
            : selectedBuildingName + hyphen + "Gutters"} */}
        </h4>
      </Col>
      <Col lg="3">
        {isAllSectionRoof ? (
          <Button
            className={`${style.includeAllBtn} ${
              selectedSections ? "btn btn-danger" : "btn btn-primary"
            }`} // Conditionally apply class
            onClick={toggleAllSelection}
          >
            {selectedSections ? "Exclude All Sections" : "Include All Sections"}{" "}
            {/* Conditionally change button label */}
          </Button>
        ) : null}
      </Col>
    </Row>
  );
};
const GutterSection = ({
  list,
  startI,
  lastI,
  includeItem,
  excludeItem,
  excludedSections,
  setExcludedSections,
}) =>
  list.length > 0 ? (
    <div className="__quote_tbl_wrapper">
      <table className="__quote_tbl">
        <thead>
          <tr>
            <th>Sec</th>
            <th>Level</th>
            <th>Elevation</th>
            <th>Gutters</th>
            <th>Leaders</th>

            <th>Screens</th>
            <th>Fascia</th>
            <th>Cap</th>
            <th>Heads</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {list.slice(startI, lastI).map((info) => (
            <tr key={info.id}>
              <td>{info.sec}</td>
              <td>{info.level}</td>
              <td>{info.elevation}</td>
              <td>{info.gutters}</td>

              <td>{info.leaders}</td>
              <td>{info.screens}</td>
              <td>{info.fascia}</td>
              <td>{info.cap}</td>
              <td>{info.heads}</td>
              <td>
                {excludedSections.includes(info.id) ? (
                  <Button
                    onClick={() =>
                      excludeItem(
                        excludedSections,
                        setExcludedSections,
                        info.id
                      )
                    }
                  >
                    Include
                  </Button>
                ) : (
                  <Button
                    variant="danger"
                    onClick={() =>
                      includeItem(
                        excludedSections,
                        setExcludedSections,
                        info.id
                      )
                    }
                  >
                    Exclude
                  </Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  ) : (
    <div
      style={{
        textAlign: "center",
        margin: "3em 0 1em 0",
        color: "#999",
        fontWeight: "bold",
      }}
    >
      No section data available.
    </div>
  );

const getSectionList = (metricData = [], getSectionData, selectedBuilding) => {
  const sectionList = [];
  metricData.map((section, i) => {
    if (selectedBuilding && selectedBuilding !== section.building.toString())
      return;
    return sectionList.push(getSectionData(section, i));
  });
  return sectionList;
};

function SectionListModal({ show, onHide, altProposalName, onSaveNewQuote }) {
  const metricData = useSelector((state) => state.metricV2Data);

  const [metrics, setMetrics] = useState([]);

  const [pageNum, setPageNum] = useState(1);
  const [maxItem] = useState(5);
  const [address, setAddress] = useState(5);

  const [showDifferentMetric, setShowDifferentMetric] = useState(false);
  const [currentMetricType, setCurrentMetricType] = useState(
    metrics?.roof?.hasRoof ? MetricTypes?.roof : MetricTypes?.gutter
  );
  const [excludeDisable, setExcludeDisable] = useState(false);
  const changeShowDifferentMetric = (value) => {
    setShowDifferentMetric(value);
  };

  console.log({
    metricData,
  });

  useEffect(() => {
    if (!metricData.isLoading && metricData?.data?.sections?.length) {
      setMetrics(
        metricData?.data?.sections.map((section) => {
          return { ...section, included: false };
        })
      );
    }
  }, [metricData.isLoading]);

  const prevPage = () => setPageNum(pageNum - 1);

  const nextPage = () => setPageNum(pageNum + 1);

  const lastI = pageNum * maxItem;
  const startI = lastI - maxItem;

  const excludeItem = (array, setArray, item) => {
    const temp = [...array];
    if (temp.includes(item)) {
      temp.splice(temp.indexOf(item), 1);
    }
    setArray(temp);
  };

  const includeItem = (array, setArray, item) => {
    // setExcludeDisable(item);
    setExcludeDisable(true);

    const temp = [...array];
    temp.push(item);
    setArray(temp);
  };

  const excludeAllSectionRoof = () => {
    setExcludeDisable(true);
    setIsAllSectionRoof(true);
  };
  const includeAllSectionRoof = () => {
    setExcludeDisable(false);

    setIsAllSectionRoof(false);
  };

  // const excludeAllSectionGutter = () => {
  //   setIsAllSectionGutter(true);
  //   setExcludeDisable(true);

  //   const arrayofexcludeId = list.map((a) => a.id);
  //   const conncatExclude = excludedSections.concat(arrayofexcludeId);
  //   const allExcludeSection = conncatExclude.filter(
  //     (item, index) => conncatExclude.indexOf(item) === index
  //   );
  //   setExcludedSections(allExcludeSection);
  // };

  // const includeAllSectionGutter = () => {
  //   setExcludeDisable(false);

  //   setIsAllSectionGutter(false);
  //   const arrayofid = list.map((a) => a.id);
  //   const temp = [...excludedSections];
  //   arrayofid.map((val) => {
  //     if (temp.includes(val) === true) {
  //       let duplicateValue = temp.indexOf(val);
  //       temp.splice(duplicateValue, 1);
  //     }
  //   });
  //   setExcludedSections(temp);
  // };

  // const toggleMetric = () => {
  //   setList([]);
  //   if (currentMetricType === MetricTypes.roof) {
  //     setCurrentMetricType(MetricTypes.gutter);
  //   } else {
  //     setCurrentMetricType(MetricTypes.roof);
  //   }
  // };

  const toggleSectionSelection = (sectionId) => {
    setMetrics((prevMetrics) =>
      prevMetrics.map((section) => {
        if (section._id === sectionId) {
          console.log({
            sectionId,
            in: !section.included,
          });
          return { ...section, included: !section.included }; // Toggle `included`
        }
        return section;
      })
    );
  };

  const toggleAllSelection = () => {
    setMetrics((prevMetrics) =>
      prevMetrics.map((section) => {
        return { ...section, included: !section.included };
      })
    );
  };

  const selectedSections = metrics.some((section) => section.included === true);

  const handleGenerateProposal = () => {
    const sections = metrics.map((section) => section._id);
    onSaveNewQuote(sections);
  };
  return (
    <PopupModal
      show={show}
      heading=""
      onHide={() => onHide()}
      bold
      centered={false}
      styles={{ border: "none" }}
      size="2xl"
    >
      <Row style={{ marginTop: "-1.5em" }}>
        <Col>
          <h3 style={{ fontWeight: "bold" }}>
            {staticTexts.sectionHeading} - &nbsp;
            {address}
          </h3>
        </Col>
      </Row>
      <p
        style={{
          fontWeight: "500",
          // marginTop: '1rem',
          lineHeight: "1.1rem",
          // marginTop: '-1.5em'
        }}
      >
        {staticTexts.sectionSubHead}
      </p>
      <p
        style={{
          fontWeight: "500",
          marginTop: "-0.9rem",
          lineHeight: "1.1rem",
        }}
      >
        {`To remove all ${
          currentMetricType === MetricTypes.roof ? "roofing" : "gutter"
        }` + staticTexts.sectionSubHead2}
      </p>

      <SectionTableHeader
        selectedBuildingName={altProposalName}
        currentMetricType={currentMetricType}
        MetricTypes={MetricTypes}
        isAllSectionRoof={true}
        isAllSectionGutter={false}
        // includeAllSectionGutter={includeAllSectionGutter}
        // excludeAllSectionGutter={excludeAllSectionGutter}
        selectedSections={selectedSections}
        toggleAllSelection={toggleAllSelection}
      />
      <SectionTable
        metricSections={metrics}
        toggleSectionSelection={toggleSectionSelection}
        // excludedSections={excludedSections}
        // setExcludedSections={setExcludedSections}
        includeItem={includeItem}
        excludeItem={excludeItem}
        startI={startI}
        lastI={lastI}
        tableDataMapping={RoofingTableHeadsAndDataMapping}
      />

      <Modal.Footer
        style={{
          justifyContent: "space-between",
          border: "none",
          padding: "0 .75rem",
          marginTop: "20px",
        }}
      >
        <div>
          {showDifferentMetric && (
            <Button
              style={{
                width: "200px",
                height: "50px",
                margin: "0 1em 1em 0",
                float: "left",
                fontWeight: "bold",
              }}
              onClick={toggleMetric}
            >
              {currentMetricType === MetricTypes.roof
                ? MetricTypes.gutter
                : MetricTypes.roof}
            </Button>
          )}
        </div>
        <ArrowPagination
          totalItem={metrics.length}
          pageNum={pageNum}
          prevPage={prevPage}
          nextPage={nextPage}
          maxItem={maxItem}
        />
        <div>
          <Button
            style={{
              width: "130px",
              height: "50px",
              margin: "0 1em 1em 0",
              fontWeight: "bold",
            }}
            variant="danger modified-btn"
            onClick={onHide}
          >
            Cancel
          </Button>
          {/* {showBuilding && (
            <Button
              style={{
                width: "200px",
                height: "50px",
                margin: "0 1em 1em 0",
                fontWeight: "bold",
              }}
              onClick={() => toggleModals(false, true)}
            >
              Show Building List
            </Button>
          )} */}
          <Button
            style={{
              width: "130px",
              height: "50px",
              margin: "0 1em 1em 0",
              fontWeight: "bold",
            }}
            onClick={handleGenerateProposal}
            // disabled={!excludeDisable}
          >
            Generate
          </Button>
        </div>
      </Modal.Footer>
    </PopupModal>
  );
}

export default SectionListModal;
