import React from "react";
import { Col, Row } from "react-bootstrap";
// import "../metric.scss";

const style = {
  button: {
    width: "24%",
  },
};
function Button({
  label,
  array,
  data,
  prevMetric,
  icon,
  exec = false,
  onClickBtn,
  size,
}) {
  console.log("array", array);
  return data.map((item) => (
    // <Col md="6" lg className="p-0">
    <button
      disabled={prevMetric !== undefined ? !prevMetric : false}
      key={item.id}
      className={`ClickableBtn ${array === item.name ? "selected" : ""} `}
      style={style.button}
      onClick={() => onClickBtn(label, item.id, item.name, exec)}
    >
      {item.name} {icon || ""}
    </button>
    // </Col>
  ));
}

const FloorMetric = ({
  label,
  array,
  data,
  prevMetric,
  size,
  icon,
  width,
  styles,
  showLabel = true,
  optional = false,
  displayLabel,
  exec = false,
  addFloor,
  isQuoteLock = false,
}) => {
  console.log("DA", data);
  return (
    <>
      {showLabel && (
        <label
          style={
            typeof styles !== "undefined"
              ? { width: "100%", textAlign: "center" }
              : {}
          }
          className="btnheading"
        >
          {typeof displayLabel !== "undefined" ? displayLabel : label}
          {optional && "(Optional)"}
        </label>
      )}
      <Row className="d-flex flex-row flex-wrap">
        {Button({
          label,
          array,
          data,
          prevMetric,
          size,
          icon,
          width,
          styles,
          exec,
          onClickBtn: (label, id, name, exec) => {
            addFloor(label?.toLowerCase(), id, name, exec);
          },
          isQuoteLock,
        })}
      </Row>
    </>
  );
};

export default FloorMetric;
