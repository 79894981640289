import { Form } from "react-bootstrap";
import React, { useState, useEffect, useRef } from "react";
import { CSSTransition } from "react-transition-group";
// import "../../css/date.scss";
import "../css/date.scss";
import Select from "react-select";
import { Container, Row, Col } from "react-bootstrap";
import useProjectData from "hooks/useProjectManagement";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { pm_details_obj } from "../../../utils/projectManagement";
import InstallmentData from "./InstallmentData";
import ChangeOrder from "./ChangeOrder";

const styles = {
  container: {
    boxShadow: "0 0 10px #99999996",
    background: "#fff",
    fontWeight: "bold",
  },
  heading: {
    flex: 1,
  },
  header: {
    padding: "25px 0",
  },
  formInput: {
    marginBottom: "25px",
  },
};

const PMdetails = (props) => {
  let { supplier, projectId, proposalId, onReload, type } = props;
  const [roofingFinishDate, setRoofingFinishDate] = useState("");
  const [roofingLaborExpirationDate, setRoofingLaborExpirationDate] =
    useState("");
  const [shinglesWarranty, setShinglesWarranty] = useState("");
  const [flatWarranty, setFlatWarranty] = useState("");
  const [gutterFinishDate, setGutterFinishDate] = useState("");
  const [gutterLaborExpirationDate, setGutterLaborExpirationDate] =
    useState("");
  const [addInstallments, setAddInstallments] = useState(false);
  const [addChangeOrder, setAddChangeOrder] = useState(false);
  const [changeOrder, setChangeOrder] = useState();
  const [changeOrderSign, setChangeOrderSign] = useState();
  const [changeOrderNotes, setChangeOrderNotes] = useState();
  const [installmentPrice, setInstallmentPrice] = useState();
  const [installmentPayment, setInstallmentPayment] = useState();
  const [installmentReceived, setInstallmentReceived] = useState();
  const [paidInFullDate, setPaidInFullDate] = useState("");

  const [startPEdit, setStartPEdit] = useState(false);
  const [changeOrderEdit, setChangeOrderEdit] = useState(false);
  const [editDeposit, setEditDeposit] = useState(false);
  const [sPPrice, setSPPrice] = useState();
  const [changedDate, setChangedDate] = useState("");
  const [laborTypesRoof, setLaborTypesRoof] = useState([]);
  const [laborTypesGutter, setLaborTypesGutter] = useState([]);
  const [multiLabor, setMultiLabor] = useState();
  const [multiRMaterials, setMultiRMaterials] = useState();
  const [materialTypes, setMaterialTypes] = useState([]);
  const [cartingTypes, setCartingTypes] = useState([]);
  const [multiCarting, setMultiCarting] = useState([]);
  const [multiGutter, setMultiGutter] = useState([]);
  const [sPPaymentMethod, setSPPaymentMethod] = useState();
  const [sPPaymentReceived, setSPPaymentReceived] = useState();
  const [editChangeOrder, setEditChangeOrder] = useState(false);
  const [depositValue, setDepositValue] = useState("");
  const [depositPaymentMethodValue, setDepositPaymentMethodValue] = useState();
  const [depositReceivedDate, setDepositReceivedDate] = useState();
  const [delNotes, setDelNotes] = useState(false);

  const {
    getNotesForPM,
    notes,
    saveProjectNotes,
    saveNotes,
    deleteNotes,
    updateProjectScheduleDetails,
    updateProjectSupplier,
    changeOrderUpdate,
    changeOrderData,
    projectInstallmentDetails,
    installment,
    updateDepositData,
    getInstallmentEdit,
    installmentEditOnly,
    editChangeOrderForSingleOrder,
  } = useProjectData({ proposalId: proposalId });

  let { labor_supplier, carting_supplier, material_supplier } = supplier;

  let filtered_data_roof = props?.scheduledProject.filter(
    (project) =>
      project.leadRequestId == props.leadReqId && project.type === "roof"
  );
  let filtered_data_gutter = props?.scheduledProject.filter(
    (project) =>
      project.leadRequestId == props.leadReqId && project.type === "gutter"
  );
  let filtered_unScheduled_roof = props?.unscheduledProject.filter(
    (project) =>
      project.leadRequestId == props.leadReqId && project.type === "roof"
  );
  let filtered_unScheduled_gutter = props?.unscheduledProject.filter(
    (project) =>
      project.leadRequestId == props.leadReqId && project.type === "gutter"
  );

  let isScheduled =
    props?.scheduledProject.filter((project) => project._id === projectId)
      .length > 0
      ? props?.scheduledProject.filter(
          (project) => project._id === projectId
        )[0]?.isScheduled
      : props?.unscheduledProject.filter(
          (project) => project._id === projectId
        )[0]?.isScheduled;

  let obj = pm_details_obj(
    filtered_data_roof,
    filtered_data_gutter,
    supplier,
    isScheduled,
    filtered_unScheduled_roof,
    filtered_unScheduled_gutter
  );


  useEffect(() => {
    let labor_arr = [];
    labor_supplier?.map((labor, i) => {
      let { name } = labor;
      if (labor.services.includes("roofing")) {
        return labor_arr.push({ label: name.toUpperCase(), value: name });
      }
    });

    if (isScheduled) {
      setMultiLabor(obj.labor_supplier_name);
    } else {
      setMultiLabor(labor_arr);
    }
    setLaborTypesRoof(labor_arr);
  }, []);

  useEffect(() => {
    let labor_arr = [];
    labor_supplier?.map((labor, i) => {
      let { name } = labor;
      if (labor.services.includes("gutters")) {
        return labor_arr.push({ label: name.toUpperCase(), value: name });
      }
    });

    if (isScheduled) {
      setMultiGutter(obj.gutter_supplier_name);
    } else {
      setMultiGutter(labor_arr);
    }
    setLaborTypesGutter(labor_arr);
  }, []);

  useEffect(() => {
    onReload();
  }, [
    projectId,
    multiLabor,
    multiCarting,
    multiGutter,
    multiRMaterials,
    installment,
    changeOrderData,
  ]);

  let material_arr = [];

  useEffect(() => {
    material_arr = material_supplier.map((Material, i) => {
      let { name } = Material;
      return { label: name.toUpperCase(), value: name };
    });

    setMultiRMaterials(obj.material_supplier_name);
    setMaterialTypes(material_arr);
  }, []);

  let carting_arr = [];

  useEffect(() => {
    carting_arr = carting_supplier.map((Carting, i) => {
      let { name } = Carting;
      return { label: name.toUpperCase(), value: name };
    });

    setMultiCarting(obj.carting_supplier_name);
    setCartingTypes(carting_arr);
  }, []);

  useEffect(() => {
    // if (notes || saveNotes || deleteNotes)
    // saveProjectNotes();
    // projectInstallmentDetails();
    // changeOrderUpdate();
  }, [notes, saveNotes, deleteNotes]);

  useEffect(() => {
    getNotesForPM({ proposalId: proposalId });
  }, [saveNotes, delNotes]);

  const display_payment_method = () => {
    return ["Chek", "Cash", "Credit Card", "Money Order"].map((val) => {
      return (
        <option key={val} value={val}>
          {val}
        </option>
      );
    });
  };

  let roofing_finish_date = (e) => {
    updateProjectScheduleDetails({
      proposalId: proposalId,
      roofingFinishDate: e.target.value,
    });
    setRoofingFinishDate(e.target.valueAsDate?.toISOString().split("T")[0]);
  };

  let roofing_labor_expiration_date = (e) => {
    updateProjectScheduleDetails({
      proposalId: proposalId,
      roofingLaborExpirationDate: e.target.value,
    });
    setRoofingLaborExpirationDate(
      e.target.valueAsDate.toISOString().split("T")[0]
    );
  };

  let update_shingle_warranty = (e) => {
    updateProjectScheduleDetails({
      proposalId: proposalId,
      shingleManufacturerWarranty: e.target.value,
    });
    setShinglesWarranty(e.target.value);
  };

  let update_flat_warranty = (e) => {
    updateProjectScheduleDetails({
      proposalId: proposalId,
      flatManufacturerWarranty: e.target.value,
    });
    setFlatWarranty(e.target.value);
  };

  let gutter_finish_date = (e) => {
    updateProjectScheduleDetails({
      proposalId: proposalId,
      gutterFinishDate: e.target.value,
    });
    setGutterFinishDate(e.target.valueAsDate.toISOString().split("T")[0]);
  };

  let gutter_labor_expiration_date = (e) => {
    updateProjectScheduleDetails({
      proposalId: proposalId,
      gutterLaborExpirationDate: e.target.value,
    });
    setGutterLaborExpirationDate(
      e.target.valueAsDate.toISOString().split("T")[0]
    );
  };

  const roof_labor = (e) => {
    let arr_id = [];
    let arr_value = [];
    e.forEach((element) => {
      arr_id.push(
        supplier.labor_supplier.filter(
          (labor) => labor.name === element.value
        )[0]?._id
      );
      arr_value.push({
        value: element.value,
        label: element.value.toUpperCase(),
      });
    });

    updateProjectSupplier({
      crewSupplierId: arr_id, //WARNING LACK OF SUBCONTRACTOR
      proposalId: proposalId,
    });
    setMultiLabor(arr_value);
  };

  let roof_material = (e) => {
    let R_material_id = [];
    let R_material_Value = [];
    e.forEach((element) => {
      R_material_id.push(
        supplier.material_supplier.filter(
          (material) => material.name === element.value
        )[0]._id
      );
      R_material_Value.push({
        value: element.value,
        label: element.value.toUpperCase(),
      });
    });

    updateProjectSupplier({
      proposalId: proposalId,
      materialSupplierId: R_material_id,
    });
    setMultiRMaterials(R_material_Value);
  };

  let roof_carting = (e) => {
    let R_carting_id = [];
    let R_carting_Value = [];
    e.forEach((element) => {
      R_carting_id.push(
        supplier.carting_supplier.filter(
          (carting) => carting.name === element.value
        )[0]._id
      );
      R_carting_Value.push({
        value: element.value,
        label: element.value.toUpperCase(),
      });
    });
    updateProjectSupplier({
      proposalId: proposalId,
      cartingSupplierId: R_carting_id,
    });
    setMultiCarting(R_carting_Value);
  };

  let gutter_labor = (e) => {
    let R_carting_id = [];
    let R_carting_Value = [];
    e.forEach((element) => {
      R_carting_id.push(
        supplier.labor_supplier.filter(
          (carting) => carting.name === element.value
        )[0]._id
      );
      R_carting_Value.push({
        value: element.value,
        label: element.value.toUpperCase(),
      });
    });
    updateProjectSupplier({
      proposalId: proposalId,
      gutterSupplierId: R_carting_id,
    });
    setMultiGutter(R_carting_Value);
  };

  let paid_in_full_date = (e) => {
    updateProjectScheduleDetails({
      proposalId: proposalId,
      paidFullDate: e.target.value,
    });
    setPaidInFullDate(e.target.valueAsDate.toISOString().split("T")[0]);
  };

  let change_order = (e) => {
    setChangeOrder(e.target.value);
  };

  let change_order_notes = (e) => {
    setChangeOrderNotes(e.target.value);
  };

  const handleMultiSelect = (value) => {
    if (!value) return setTypes([]);
    setTypes(value);
  };

  useEffect(() => {
    setSPPrice(obj.start_payment ? `${obj.start_payment.toFixed(2)}` : "");
  }, [obj.start_payment ? obj.start_payment : ""]);

  useEffect(() => {
    setChangedDate(
      gutterLaborExpirationDate
        ? gutterLaborExpirationDate
        : obj.gutter_labor_expiration_date
    );
  }, [
    gutterLaborExpirationDate
      ? gutterLaborExpirationDate
      : obj.gutter_labor_expiration_date,
  ]);

  return (
    <Container
      fluid="true"
      className="p-3"
      style={styles.container}
      block="true"
    >
      <Row>
        <Col>
          <Form.Group style={styles.formInput}>
            <Form.Label>
              Roofing Start<br></br> Date/Time
            </Form.Label>
            <Form.Control
              type="text"
              value={
                obj.roof_start_time && obj.roof_start_date
                  ? `${obj.roof_start_date}-${obj.roof_start_time}`
                  : ""
              }
              name={PMdetails}
            ></Form.Control>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group style={styles.formInput}>
            <Form.Label>
              Roofing Completion <br></br> Date
            </Form.Label>
            <Form.Control
              type="date"
              value={
                roofingFinishDate ? roofingFinishDate : obj.roofing_finish_date
              }
              onChange={roofing_finish_date}
              placeholderText=""
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group style={styles.formInput}>
            <Form.Label>
              Roofing Labor<br></br> Expiration Date
            </Form.Label>
            <Form.Control
              type="date"
              disabled={
                roofingFinishDate || obj.roofing_finish_date ? false : true
              }
              value={
                roofingLaborExpirationDate
                  ? roofingLaborExpirationDate
                  : obj.labor_expiration_date
              }
              dateFormat="MM-DD-YYYY"
              onChange={roofing_labor_expiration_date}
            ></Form.Control>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group style={styles.formInput}>
            <Form.Label>
              Shingle Manufacturer <br></br>Warranty
            </Form.Label>
            <Form.Control
              as="select"
              name="warranty"
              disabled={
                roofingFinishDate || obj.roofing_finish_date ? false : true
              }
              value={
                shinglesWarranty !== ""
                  ? shinglesWarranty
                  : obj.shingle_manufacturer_warranty
              }
              onChange={update_shingle_warranty}
            >
              <option value="Select">Select... </option>
              <option value="10 Year">10 Year</option>
              <option value="15 Year">15 Year</option>
              <option value="20 Year">20 Year</option>
              <option value="25 Year">25 Year</option>
              <option value="30 Year">30 Year</option>
              <option value="50 Year">50 Year</option>
              <option value="Lifetime">Lifetime</option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group style={styles.formInput}>
            <Form.Label>
              Flat Manufacturer<br></br> Warranty
            </Form.Label>
            <Form.Control
              as="select"
              disabled={
                roofingFinishDate || obj.roofing_finish_date ? false : true
              }
              value={
                flatWarranty ? flatWarranty : obj.flat_manufacturer_warranty
              }
              onChange={update_flat_warranty}
            >
              <option value="Select">Select...</option>
              <option value="10 Year">10 Year</option>
              <option value="15 Year">15 Year</option>
              <option value="20 Year">20 Year</option>
              <option value="25 Year">25 Year</option>
              <option value="30 Year">30 Year</option>
              <option value="50 Year">50 Year</option>
              <option value="Lifetime">Lifetime</option>
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>
      <Row className="text-wrap">
        <Col>
          <Form.Group style={styles.formInput}>
            <Form.Label>Gutter Start Date/Time</Form.Label>
            <Form.Control
              type="text"
              value={
                obj.gutter_start_time && obj.gutter_start_date
                  ? `${obj.gutter_start_date}-${obj.gutter_start_time}`
                  : ""
              }
              name={PMdetails}
            ></Form.Control>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group style={styles.formInput}>
            <Form.Label>Gutter Completion Date</Form.Label>
            <Form.Control
              type="date"
              value={
                gutterFinishDate ? gutterFinishDate : obj.gutter_finish_date
              }
              onChange={gutter_finish_date}
            ></Form.Control>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group style={styles.formInput}>
            <Form.Label>Gutter Labor Expiration Date</Form.Label>
            <Form.Control
              type="date"
              disabled={
                gutterFinishDate || obj.gutter_finish_date ? false : true
              }
              value={
                gutterLaborExpirationDate
                  ? gutterLaborExpirationDate
                  : obj.gutter_labor_expiration_date
              }
              onChange={gutter_labor_expiration_date}
            ></Form.Control>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group style={styles.formInput}>
            <Form.Label>Roofing Sub/Crew</Form.Label>
            <Select
              options={laborTypesRoof}
              value={multiLabor}
              isMulti
              name="services"
              onChange={roof_labor}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group style={styles.formInput}>
            <Form.Label>Roofing Material Supplier</Form.Label>

            <Select
              options={materialTypes}
              value={multiRMaterials}
              isMulti
              name="services"
              onChange={roof_material}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group style={styles.formInput}>
            <Form.Label>Roofing Carting Supplier</Form.Label>

            <Select
              options={cartingTypes}
              value={multiCarting}
              isMulti
              name="services"
              onChange={roof_carting}
            />
          </Form.Group>
        </Col>{" "}
      </Row>
      <Row>
        <Col lg="4" md="6" sm="12">
          <Form.Group style={styles.formInput}>
            <Form.Label>Gutter Sub/Crew</Form.Label>

            <Select
              options={laborTypesGutter}
              value={multiGutter}
              isMulti
              name="services"
              onChange={gutter_labor}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <h3 style={{ marginBottom: "2rem", marginTop: "2rem" }}>Payments</h3>
      </Row>
      <Row>
        <Col md="2" sm="12" style={{ marginTop: "1.8rem" }}>
          <span>Total</span>
        </Col>
        <Col md="2" sm="12">
          <Form.Group style={styles.formInput}>
            <Form.Label></Form.Label>
            <Form.Control
              type="Float"
              value={obj.total ? obj.total : ""}
              name={PMdetails}
              disabled={true}
            ></Form.Control>
          </Form.Group>
        </Col>

        <Col md="3" sm="12" style={{ marginTop: "-.5rem" }}>
          <Form.Group style={styles.formInput}>
            <Form.Label>Paid In Full Date</Form.Label>
            <Form.Control
              type="date"
              value={paidInFullDate ? paidInFullDate : obj.paid_in_full_date}
              onChange={paid_in_full_date}
            ></Form.Control>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md="2" sm="12" style={{ marginTop: "1.8rem" }}>
          <span>Deposit</span>
        </Col>
        <Col md="2" sm="12" style={{ right: "7rem" }}>
          <Form.Group style={styles.formInput}>
            <Form.Label></Form.Label>
            <Form.Control
              type="Float"
              value={
                depositValue ? depositValue : obj.deposit ? obj.deposit : ""
              }
              disabled={true}
              name={PMdetails}
              onChange={(e) => setDepositValue(e.target.value)}
            ></Form.Control>
          </Form.Group>
        </Col>
        <Col md="3" sm="12" style={{ marginTop: "-.5rem" }}>
          <Form.Group style={styles.formInput}>
            <Form.Label>Payment Method</Form.Label>
            <Form.Control
              as="select"
              value={
                depositPaymentMethodValue
                  ? depositPaymentMethodValue
                  : obj.deposit_payment_method
                  ? obj.deposit_payment_method
                  : ""
              }
              disabled={!editDeposit}
              onChange={(e) => setDepositPaymentMethodValue(e.target.value)}
            >
              <option value="">Choose...</option>
              {display_payment_method()}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col style={{ marginTop: "-.5rem" }}>
          <Form.Group style={styles.formInput}>
            <Form.Label>Received</Form.Label>
            <Form.Control
              type="date"
              value={
                depositReceivedDate
                  ? depositReceivedDate
                  : obj.deposit_received_date
                  ? obj.deposit_received_date
                  : ""
              }
              disabled={!editDeposit}
              onChange={(e) => setDepositReceivedDate(e.target.value)}
            ></Form.Control>
          </Form.Group>
        </Col>
        <Col>
          {!editDeposit ? (
            <button
              type="button"
              className="btn btn-primary"
              style={{ marginTop: "1.5rem" }}
              onClick={() => {
                setEditDeposit(true);
              }}
            >
              Edit
            </button>
          ) : (
            <>
              <button
                type="button"
                className="btn btn-primary"
                style={{ marginTop: "1.5rem" }}
                onClick={() => {
                  setEditDeposit(false);
                  updateDepositData({
                    proposalId: proposalId,
                    deposit: depositValue ? depositValue : obj.deposit,
                    paymentMethod: depositPaymentMethodValue
                      ? depositPaymentMethodValue
                      : obj.deposit_payment_method,
                    receivedDate: depositReceivedDate
                      ? depositReceivedDate
                      : obj.deposit_received_date,
                  });
                }}
                disabled={!depositPaymentMethodValue || !depositReceivedDate}
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-warning"
                style={{ marginTop: "1.5rem", marginLeft: "1.5rem" }}
                onClick={() => {
                  setDepositPaymentMethodValue("");
                  setDepositReceivedDate("");
                  setEditDeposit(false);
                }}
              >
                Cancel
              </button>
            </>
          )}
        </Col>
      </Row>
      <Row>
        <Col md="2" sm="12" style={{ marginTop: "1.8rem" }}>
          <span>Start Payment</span>
        </Col>
        <Col md="2" sm="12" style={{ right: "4.5rem" }}>
          <Form.Group style={styles.formInput}>
            <Form.Label></Form.Label>
            <Form.Control
              type="Float"
              value={sPPrice ? sPPrice : obj.start_payment}
              name={PMdetails}
              disabled={true}
              onChange={(e) => setSPPrice(e.target.value)}
            ></Form.Control>
          </Form.Group>
        </Col>
        <Col md="3" sm="12" style={{ marginTop: "-.5rem" }}>
          <Form.Group style={styles.formInput}>
            <Form.Label>Payment Method</Form.Label>
            <Form.Control
              as="select"
              value={
                sPPaymentMethod
                  ? sPPaymentMethod
                  : obj.start_payment_payment_method
                  ? obj.start_payment_payment_method
                  : ""
              }
              disabled={
                filtered_data_roof[0]?.Payment_Details[0]?.total === 0 ||
                !startPEdit
              }
              onChange={(e) => setSPPaymentMethod(e.target.value)}

              // name={smWaranty}
            >
              <option value="">Choose...</option>
              {display_payment_method()}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col style={{ marginTop: "-.5rem" }}>
          <Form.Group style={styles.formInput}>
            <Form.Label>Received</Form.Label>
            <Form.Control
              value={
                sPPaymentReceived
                  ? sPPaymentReceived
                  : obj.start_payment_payment_date
                  ? obj.start_payment_payment_date
                  : ""
              }
              type="date"
              disabled={
                filtered_data_roof[0]?.Payment_Details[0]?.total === 0 ||
                !startPEdit
              }
              onChange={(e) => setSPPaymentReceived(e.target.value)}
            ></Form.Control>
          </Form.Group>
        </Col>
        <Col>
          {!startPEdit ? (
            <button
              type="button"
              className="btn btn-primary"
              style={{ marginTop: "1.5rem" }}
              onClick={() => {
                setStartPEdit(true);
              }}
              disabled={
                filtered_data_roof[0]?.Payment_Details[0]?.total === 0 ||
                obj.start_payment === 0
              }
            >
              Edit
            </button>
          ) : (
            <>
              <button
                type="button"
                className="btn btn-primary"
                style={{ marginTop: "1.5rem" }}
                onClick={() => {
                  setStartPEdit(false);
                  updateDepositData({
                    proposalId: proposalId,
                    startPaymentPaymentMethod: sPPaymentMethod,
                    startPaymentReceivedDate: sPPaymentReceived,
                  });
                }}
                disabled={!sPPaymentReceived || !sPPaymentMethod}
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-warning"
                style={{ marginTop: "1.5rem", marginLeft: "1.5rem" }}
                onClick={() => {
                  setSPPaymentReceived(null);
                  setSPPaymentMethod(null);
                  setStartPEdit(false);
                }}
              >
                Cancel
              </button>
            </>
          )}
        </Col>
      </Row>
      <Row style={{ marginBottom: "7rem" }}>
        <Col lg="6" md="2" sm="12">
          <span>Change Order</span>
          <Row>
            <Col>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => setAddChangeOrder(true)}
                style={{ marginBottom: "-2rem", marginTop: "-.5rem" }}
              >
                Add
              </button>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row style={{ marginTop: "-8rem", marginBottom: "3rem" }}>
        {obj?.change_order_data.length > 0
          ? obj?.change_order_data.map((order) => {
              return (
                <ChangeOrder
                  styles={styles}
                  type={type}
                  filtered_data_roof={filtered_data_roof}
                  filtered_data_gutter={filtered_data_gutter}
                  filtered_unScheduled_roof={filtered_unScheduled_roof}
                  filtered_unScheduled_gutter={filtered_unScheduled_gutter}
                  order={order}
                  change_order_notes={change_order_notes}
                  changeOrderUpdate={changeOrderUpdate}
                  editChangeOrderForSingleOrder={editChangeOrderForSingleOrder}
                  onReload={onReload}
                />
              );
            })
          : []}

        <CSSTransition in={addChangeOrder} timeout={300} unmountOnExit>
          <Row>
            <Col lg="2" md="3" sm="3" xs="3" style={{ marginLeft: "14.5rem" }}>
              <Form.Control
                type="number"
                placeholder="$"
                name={PMdetails}
                onChange={change_order}
                disabled={editChangeOrder}
              ></Form.Control>
            </Col>
            <Col md="3" sm="12" style={{ marginLeft: ".5rem" }}>
              <Form.Control
                as="textarea"
                placeholder="C/O Notes"
                rows="2"
                onChange={change_order_notes}
                disabled={editChangeOrder}
              ></Form.Control>
            </Col>
            <Col style={{ marginTop: "-1.5rem" }}>
              <Form.Group style={styles.formInput}>
                <Form.Label>Signed</Form.Label>
                <Form.Control
                  type="date"
                  onChange={(e) => setChangeOrderSign(e.target.value)}
                  disabled={editChangeOrder}
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col>
              {!editChangeOrder ? (
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={
                    !changeOrderSign || !changeOrderNotes || !changeOrder
                  }
                  style={{ marginTop: ".5rem" }}
                  onClick={() => {
                    changeOrderUpdate({
                      proposalId: proposalId,
                      changeOrderPrice: changeOrder,
                      changeOrderNotes: changeOrderNotes,
                      signature: changeOrderSign,
                    });
                    setEditChangeOrder(false);
                    setAddChangeOrder(false);
                    onReload();
                  }}
                >
                  Save
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  style={{ marginTop: ".5rem" }}
                  onClick={() => {
                    setEditChangeOrder(true);
                    setAddChangeOrder(false);
                  }}
                >
                  Edit
                </button>
              )}
            </Col>
            <Col>
              {" "}
              {!editChangeOrder ? (
                <button
                  type="button"
                  className="btn btn-warning"
                  style={{ marginTop: ".5rem" }}
                  onClick={() => setAddChangeOrder(false)}
                >
                  Cancel
                </button>
              ) : (
                ""
              )}
            </Col>
          </Row>
        </CSSTransition>
      </Row>

      <Row>
        <Col lg="6" md="2" sm="12" style={{ marginBottom: "-2.6rem" }}>
          <span>Installments</span>
          <Row>
            <Col>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => setAddInstallments(true)}
                style={{ marginBottom: "-2rem", marginTop: "-.5rem" }}
                disabled={obj.balance && obj.balance > 0 ? false : true}
              >
                Add
              </button>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        {obj.installments_data
          ? obj.installments_data.map((installment) => {
              return (
                <InstallmentData
                  styles={styles}
                  installment={installment}
                  display_payment_method={display_payment_method}
                  getInstallmentEdit={getInstallmentEdit}
                  installmentEditOnly={installmentEditOnly}
                  installmentId={installment._id}
                  onReload={onReload}
                />
              );
            })
          : []}
        <CSSTransition in={addInstallments} timeout={300} unmountOnExit>
          <>
            <Col lg="2" md="2" sm="12" style={{ marginLeft: "1.7rem" }}></Col>
            <Col lg="2" md="3" sm="12">
              <Form.Group style={styles.formInput}>
                <Form.Label></Form.Label>
                <Form.Control
                  type="number"
                  name={PMdetails}
                  onChange={(e) => setInstallmentPrice(e.target.value)}
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col lg="3" md="2" sm="12" style={{ right: "5rem" }}>
              <Form.Group style={styles.formInput}>
                <Form.Label></Form.Label>
                <Form.Control
                  as="select"
                  onChange={(e) => {
                    setInstallmentPayment(e.target.value);
                  }}
                >
                  <option value="">Choose...</option>
                  {display_payment_method()}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col lg="2" style={{ marginTop: "-.5rem" }}>
              <Form.Group style={styles.formInput}>
                <Form.Label>Received</Form.Label>
                <Form.Control
                  type="date"
                  onChange={(e) => {
                    setInstallmentReceived(e.target.value);
                  }}
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <button
                type="button"
                className="btn btn-primary"
                style={{ marginTop: "1.5rem" }}
                onClick={() => {
                  projectInstallmentDetails({
                    projectsPaymentDetailsId: obj.installment_id,
                    installmentAmount: installmentPrice,
                    paymentMethod: installmentPayment,
                    installmentDate: installmentReceived,
                  });
                  setAddInstallments(false);
                  onReload();
                }}
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-warning"
                style={{ marginTop: "1.5rem", marginLeft: "2rem" }}
                onClick={() => {
                  setAddInstallments(false);
                }}
              >
                Cancel
              </button>
            </Col>
          </>
        </CSSTransition>
      </Row>
      <Row>
        <Col style={{ marginTop: "1.9rem" }}>
          <span></span>
        </Col>
      </Row>

      <Row style={{ marginTop: "1rem" }}>
        <Col md="2" sm="12" style={{ marginTop: "1.8rem" }}>
          <span>Balance</span>
        </Col>
        <Col md="2" sm="12">
          <Form.Group style={styles.formInput}>
            <Form.Label></Form.Label>
            <Form.Control
              disabled={true}
              type="Float"
              value={obj.balance ? Number(obj.balance).toFixed(2) : 0}
              name={PMdetails}
            ></Form.Control>
          </Form.Group>
        </Col>
      </Row>
    </Container>
  );
};

export default PMdetails;
