import React, { useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { LoadingLoader, PopupModal } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { PricingList } from "screens/Suppliers/Material";
import {
  deleteGutterMiscQuote,
  getSeriesTypes,
  updateGutterQuote,
  updateProposalInfo,
} from "screens/QuotesProposals/redux/proposals/proposals.actions";
import { useSelector } from "react-redux";
import GutterListItem from "./GutterListItem";

export default function GutterQuoteList({
  loadingData,
  dataError,
  selectedSupplier,
  metricId,
  saveSectionDataToDB,
  deleteMiscItem,
  changeQuantiy,
  LoadingSkeleton,
  displayMsg,
  quoteMaterialSectionData,
  isLockQuoteData,
  selectedProposal,
  isLoading,
  tabData,
}) {
  if (dataError) {
    return displayMsg({ msg: "No data available." });
  }

  if (loadingData) {
    return new Array(10).fill(1).map((_, i) => <LoadingSkeleton key={i} />);
  }

  if (!tabData?.length) {
    return displayMsg({ msg: "No data available." });
  }

  console.log({
    tabDataGutter: tabData,
  });
  return (
    <div>
      {tabData.map((sectionPricing, idx) =>
        sectionPricing.isCustom ? (
          <MiscItemComponent
            key={`${sectionPricing._id}-${sectionPricing.category}`}
            data={sectionPricing}
            deleteMiscItem={() => deleteMiscItem(idx)}
            changeQuantiy={(dataItem) => changeQuantiy(dataItem)}
            quoteMaterialSectionData={quoteMaterialSectionData}
            isLockQuoteData={isLockQuoteData}
            isLoading={isLoading}
          />
        ) : (
          <GutterLists
            key={`${sectionPricing._id}-${sectionPricing.category}`}
            idx={idx}
            pricing={sectionPricing}
            selectedSupplier={selectedSupplier}
            metricId={metricId}
            tabData={tabData}
            selectedProposal={selectedProposal}
            // saveSectionDataToDB={saveSectionDataToDB}
            // quoteMaterialSectionData={quoteMaterialSectionData}
            // isLockQuoteData={isLockQuoteData}
            // isLoading={isLoading}
            // editItem={(dataItem) => changeQuantiy(dataItem)}
            // selectedProposal={selectedProposal}
            // changeQty={changeQuantiy}
          />
        )
      )}
    </div>
  );
}

function GutterLists({
  pricing,
  editItem,
  isLockQuoteData,
  isLoading,
  selectedProposal,
  changeQty,
}) {
  // const { _id, category, subType, uomPrice, qty, total } = pricing;
  const {
    _id: materialId,
    category,
    series,
    uomPrice,
    uom,
    supplierId,
    subType,
    categoryType,
    pricingCategory,
    qtyAndPrice,
    manufacturer,
    fromMetric,
    manufacturers,
    qty,
    order,
    total,
    original_list,
  } = pricing;

  const {
    seriesResponse: { data: seriesList = [] },
    isLoadingSeries,
  } = useSelector((state) => state?.proposal);

  console.log({
    seriesList,
    isLoadingSeries,
  });
  const dispatch = useDispatch();
  const [showSeriesModal, setShowSeriesModal] = useState(false);
  const [allData, setAllData] = useState([]);

  const handleEdit = (newData) => editItem(newData);

  const handleChangeQty = (e) => {
    const { value } = e.target;
    changeQty(value);
  };

  const handlePriceChange = (value) => {
    const newTotal = calculateTotal(qty, value);
    handleEdit({ ...pricing, uomPrice: value, total: newTotal });
  };

  const calculateTotal = (qty, uomPrice) =>
    isNumeric(qty) && isNumeric(uomPrice)
      ? Number(qty * uomPrice).toFixed(2)
      : total;

  const isNumeric = (value) => /^\d+$/.test(value);

  const openSeriesListModal = () => setShowSeriesModal(true);
  const closeModal = () => setShowSeriesModal(false);
  const callSeriesApiCallack = (
    category,
    categoryType,
    manufacturer,
    supplierId
  ) => {
    console.log({ category, categoryType, manufacturer });
    const data = { category, categoryType, manufacturer, supplierId };
    // Dispatch action to get series list
    dispatch(getSeriesTypes(data));
  };

  const changeManufacturer = (manufacturer, supplierId) => {
    // Dispatch action to get series list
    const data = {
      requestId: selectedProposal?.requestId,
      supplierId: supplierId,
      // proposalId,
      materialId: materialId,
      proposalId: selectedProposal._id,
      materialQuoteId: selectedProposal?.quotes?.materials?._id,
      manufacturer: manufacturer,
      series: series,
      // materialQuoteId
    };
    dispatch(updateProposalInfo(data));
  };

  const changeSeries = (selectedSerieItem) => {
    // Dispatch action to get series list
    const data = {
      requestId: selectedProposal?.requestId,
      supplierId: supplierId,
      // proposalId,
      materialId: materialId,
      proposalId: selectedProposal._id,
      materialQuoteId: selectedProposal?.quotes?.materials?._id,
      manufacturer: manufacturer,
      series: selectedSerieItem,
      // materialQuoteId
    };
    dispatch(updateProposalInfo(data));
  };

  const onChangeQty = ({
    gutterId,
    quantity = "",
    style = "",
    uom = "",
    price = "",
  }) => {
    // changeQty(qty, materialId, price, miscSeries);
    console.log({
      gutterId,
      quantity,
      style,
      uom,
      price,
      selectedProposal,
    });

    const data = {
      requestId: selectedProposal?.requestId,
      proposalId: selectedProposal._id,
      gutterQuoteId: selectedProposal?.quotes?.gutters?._id,
      gutterId: gutterId,
    };

    if (price) {
      data.uomPrice = price;
    }

    if (quantity) {
      data.uomQty = quantity;
    }

    if (style) {
      data.style = style;
    }

    if (uom) {
      data.uom = uom;
    }

    dispatch(updateGutterQuote(data));
  };

  const deleteMiscItem = () => {
    const body = {
      supplierId: selectedProposal?.quotes?.gutters?.supplier,
      proposalId: selectedProposal._id,
      gutterQuoteId: selectedProposal?.quotes?.gutters?._id,
      gutterId: pricing._id,
    };
    dispatch(deleteGutterMiscQuote(body));
  };

  const PricingListData = useMemo(() => {
    if (seriesList.length === 0) {
      return <p>No series available.</p>; // You can return any fallback content here
    }

    return seriesList.map((serieItem) => (
      <li key={serieItem}>
        <button
          onClick={() => changeSeries(serieItem)}
          disabled={serieItem === series}
          className="rounded-pill justify-content-center"
          style={{ border: "1px gray solid", marginTop: "15px" }}
        >
          {serieItem}
          {serieItem === series && (
            <FontAwesomeIcon color="#0979fa" icon={faCheck} />
          )}
        </button>
      </li>
    ));
  }, [seriesList, series]);

  return (
    <>
      <GutterListItem
        quote={pricing}
        deleteMiscItem={deleteMiscItem}
        onChangeQty={onChangeQty}
      />

      <PopupModal
        show={showSeriesModal}
        onHide={closeModal}
        bold
        centered={false}
        styles={{ border: "none" }}
      >
        {isLoadingSeries ? (
          <LoadingLoader />
        ) : (
          <div className="__quote_pricing_list">
            <h5>Select one of the following:</h5>
            {seriesList?.length ? <ul>{PricingListData}</ul> : null}
          </div>
        )}
      </PopupModal>
    </>
  );
}

const MiscItemComponent = ({ onAddMisc, isLockQuoteData }) => (
  <Row>
    <Col lg="2">
      <Button onClick={onAddMisc} disabled={isLockQuoteData?.isLocked}>
        Add Misc Item
      </Button>
    </Col>
  </Row>
);
