const moment = require('moment');

export function convertMilitaryToStandard(militaryTime) {
    // Parse the military time in 24-hour format
    const time = moment(militaryTime, 'HH:mm');

    // Format the time in 12-hour format with AM/PM
    return time.format('h:mm A');
}


export function convertMonthYearToDate(monthYear) {
    const [month, year] = monthYear.split('/');
    // Default to the 1st day of the month
    return new Date(`${year}-${month}-01T00:00:00.000Z`);
  }

  export function removeTime(date) {
    // Ensure the input is a valid Date object
    if (!(date instanceof Date) || isNaN(date.getTime())) {
      throw new Error('Invalid Date object');
    }
  
    // Extract the year, month, and day from the original date
    const year = date.getFullYear();
    const month = date.getMonth(); // Month is zero-based
    const day = date.getDate();
  
    // Create a new Date object with time set to midnight in local time
    const newDate = new Date(year, month, day);
  
    return newDate;
  }
  

  export function convertToUSTime(date) {
    return moment(date).format("MM/DD/YYYY");
  }