import React from "react";
import { Row, Col, Form, Button, Alert, Modal } from "react-bootstrap";
import BasePlanTextBox from "components/base-plan-textbox/base-plan-text-box";
import MonthlySubscriptionTotal from "components/monthly-subscription-total/monthly-subscription-total";
import AdditionalUserInput from "components/additional-user-input/additional-user-input";
import PopupModal from "components/popup-modal/popup-modal";
import StripePaymentPage from "components/Stripe/StripePaymentPage";
import OneTimeSetUp from "./onetime-setup";
import StateTax from "./stateTax";
import Total from "./total";
import Cards from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/lib/styles.scss';
import { axiosInstance } from "services";
import { ApiEndpoints } from "lib/config/baseSettings";
import './pricing-detail.css'
import CreditCardIcon from 'assets/blank-credit-card.png';
import { getActivePaymentMethod } from "utils/utils";

const newStyle = {
  formStyle: {
    // marginTop: "5%"
  },
  formInput: {
    marginBottom: "15px",
  },
  boxContainer: {
    padding: "75px 0",
  },
  basePlanTextBox: {
    color: "#fff",
    backgroundColor: "#111",
    padding: "10px 20px",
    textAlign: "center",
    marginTop: "20px",
    borderRadius: "10px",
  },
  displayPrice: {
    maxWidth: "150px",
  },
  pricingCol: {
    display: "flex",
    justifyContent: "center",
  },
};

class PricingDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      package: "Base",
      modal: false,
      months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      years: [
        2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011,
        2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023,
        2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032,
      ],
      errMsg: "",
      errAt: "",
      errType: "warning", //danger, success, info, warning,
      tax_rate: "",
      card: {
        number: '*',
        expiry: '*',
        cvc: '*',
        name: '*',
        focus: '',
        issuer: ''
      },
      loadingCard: false,
      errorInCard: null,
      cardResponse: null,
      cardAvailable: true
    };
  }



  componentDidMount() {
    const { company } = this.props;

    this.setState({
      currency: "USD"
    })

    if (company && company.paymentMethods && company.paymentMethods.length) {
      this.setState({
        cardAvailable: true
      })
      const paymentIfo = getActivePaymentMethod(company.paymentMethods);
      this.getPaymentMethodById(paymentIfo.paymentMethodId);
    } else {
      this.setState({
        cardAvailable: false
      })
    }

    this.getTotalTax();
    console.log({props: this.props});
  }


  componentDidUpdate(prevProps) {
    const { company: prevCompany } = prevProps;
    const { company } = this.props;

    // Check if company has changed
    if (company !== prevCompany) {
      this.getTotalTax()
      if (company && company.paymentMethods && company.paymentMethods.length) {
        this.setState({
          cardAvailable: true
        })

        const paymentInfo = getActivePaymentMethod(company.paymentMethods);
        this.getPaymentMethodById(paymentInfo.paymentMethodId);
        
      } else {
        this.setState({
          cardAvailable: false
        })
      }
    }
  }


  getTotalTax = () => {
    console.log("calling here");
    let tax = {
      totalPriceWithTax: 0,
      stateTaxAmount: 0
    }
    const stateTaxPercentage = this.props?.company?.currentCounty?.county_tax;
    if (stateTaxPercentage) {
      const { monthlySubscription, oneTimeCharge, additionalUser, additionalPricePerUser } = this.props;
      // Calculate the total price before tax
      const totalPriceBeforeTax = Number(monthlySubscription) + Number(oneTimeCharge) + (additionalUser * additionalPricePerUser);

      // Calculate the state tax amount
      tax.stateTaxAmount = (stateTaxPercentage / 100) * totalPriceBeforeTax;

      // Calculate the total price including state tax
      tax.totalPriceWithTax = totalPriceBeforeTax + tax.stateTaxAmount;

   
    }

    console.log({ tax, stateTaxPercentage, ms: this.props.monthlySubscription });
    return tax;
  }



  isValidated = () => {
    return true;
  };

  onSubmit = (e) => {
    e.preventDefault();
  };

  onHide = () => {
    // this.isValidated();
    this.props.hideCheckoutModal();
    // this.setState({ modal: false });
  };

  displayNumbers = (data) =>
    data.map((value) => <option key={value}>{value}</option>);

  padZero(num) {
    if (num < 10) {
      return `0${num}`;
    } else if (num >= 100) {
      return num.toString().slice(-2);
    } else {
      return num.toString();
    }
  }

  getPaymentMethodById = (pID) => {
    this.setState({ loadingCard: true, errorInCard: null }); // Reset error state

    axiosInstance.get(ApiEndpoints.USER.get_payment_method(pID))
      .then((response) => {
        const { result } = response.data;

        this.setState({ loadingCard: false, cardResponse: result });
        if (result && result.card) {

          const card = result.card;
          // Handle card information if needed

          const maskedCardNumber = `**** **** **** ${card.last4}`;

          const cradInfo = {
            number: maskedCardNumber,
            expiry: `${this.padZero(card.exp_month)}/${this.padZero(card.exp_year)}`,
            name: '*',
            cvc: '***',
            issuer: card.display_brand
          };
          this.setState({
            card: { ...cradInfo }
          })
        }
      })
      .catch((error) => {
        console.error("Error fetching payment method:", error);

        if (error.response) {
          this.setState({ loadingCard: false, errorInCard: error.response });
        }
      });
  }


  render() {
    const {
      additionalUser,
      monthlySubscription,
      increaseAdditionalUser,
      decreaseAddditionalUser,
      handleChange,
      basePrice,
      currency,
      additionalPricePerUser,
      baseUsers,
      type,
      company_id,
      aEmail,
      from,
      checkoutModalDisplay = false,
      oneTimeCharge,
    } = this.props;

    const {
      errType,
      errMsg,
      errAt,
    } = this.state;


    return (
      <Form noValidate onSubmit={this.onSubmit} style={newStyle.formStyle}>
        <Row>
          <p className="font-bold text-xs mt-3">
            Organized Roofer is a monthly subscription, the base plan includes (
            {baseUsers}) users. Additional users can be added or removed within the application after completing the
            subscription process.
          </p>
          {!checkoutModalDisplay && (
            <Col xs="12">
              {errMsg && <Alert variant={errType}>{errMsg}</Alert>}
            </Col>
          )}
          <PopupModal
            heading={
              from
                ? "Edit Credit Card Information"
                : "Add card for the payment"
            }
            show={checkoutModalDisplay}
            onHide={this.onHide}
            payment={"payment"}
          >
            <StripePaymentPage
              company_id={company_id}
              email={aEmail}
              type={type}
              onHide={this.onHide}
              from={from}
              additionalUser={additionalUser}
            />
          </PopupModal>

          <Col xs="12">
            <Row>
              <Col xs="12" md="6">
                <Col>
                  <BasePlanTextBox
                    baseplanPriceAdmin={basePrice}
                    currency={currency}
                    basePlanUsers={baseUsers}
                  />
                </Col>
                <Col style={{ ...newStyle.boxContainer, padding: "20px 0" }}>
                  <Col md="9">
                    <AdditionalUserInput
                      currency={currency}
                      additionalPricePerUser={additionalPricePerUser}
                      type="number"
                      name="additionalUser"
                      readOnly
                      value={additionalUser}
                      placeholder="Enter here"
                      onChange={(event) => handleChange(event)}
                      isInvalid={errAt === "additionalUser"}
                    >
                      <Button
                        style={{ margin: "0 10px" }}
                        variant="outline-dark"
                        onClick={decreaseAddditionalUser}
                      >
                        -
                      </Button>

                      <Button
                        variant="outline-dark"
                        onClick={increaseAdditionalUser}
                      >
                        +
                      </Button>
                    </AdditionalUserInput>
                  </Col>

                  <Col md="4">
                    <MonthlySubscriptionTotal
                      currency={currency}
                      type="text"
                      name="monthlySubscription"
                      readOnly
                      value={`${currency ? (currency === "USD" ? "$" : currency) : ""
                        }${monthlySubscription.toFixed(2)}`}
                      onChange={(event) => handleChange(event)}
                      isInvalid={errAt === "additionalUser"}
                    />
                  </Col>

                  <Col md="4">
                    <OneTimeSetUp
                      currency={currency}
                      type="text"
                      name="monthlySubscription"
                      readOnly
                      value={
                        oneTimeCharge ? `$${oneTimeCharge.toFixed(2)}` : '$0'
                      }

                      onChange={(event) => handleChange(event)}
                      isInvalid={errAt === "additionalUser"}
                    />
                  </Col>

                  <Col md="4">
                    <StateTax
                      currency={currency}
                      type="text"
                      name="monthlySubscription"
                      readOnly
                      value={`$${this.getTotalTax().stateTaxAmount.toFixed(2)}`}
                      onChange={(event) => handleChange(event)}
                      isInvalid={errAt === "additionalUser"}
                    />
                  </Col>

                  <Col md="4">
                    <Total
                      currency={currency}
                      type="text"
                      name="monthlySubscription"
                      readOnly
                      value={`$${this.getTotalTax().totalPriceWithTax.toFixed(2)}`}
                      onChange={(event) => handleChange(event)}
                      isInvalid={errAt === "additionalUser"}
                    />
                  </Col>
                </Col>
              </Col>
              <Col xs="12" md="6">
                <Row>
                  <Col style={newStyle.boxContainer}>
                    <Button
                      onClick={() => this.props.showCheckoutModal()}
                      className="add-payment-btn"
                      variant="outline-primary"
                    >
                      {this.state.cardAvailable ? 'Edit' : 'Add'} Payment Method
                    </Button>


                    <div className="mt-4">
                      {this.state.cardAvailable ?
                        <Cards
                          issuer={this.state.card.issuer}
                          number={this.state.card.number}
                          preview={true}
                          expiry={this.state.card.expiry}
                          cvc={this.state.card.cvc}
                          name={this.state.card.name}
                          focused={this.state.card.focus}
                        />
                        : <div className="custom-ccard">
                          <div className="custom-card-content">
                            <img src={CreditCardIcon} alt="Empty credit card" />
                            <p>Card Not Added Yet!</p>

                          </div>
                        </div>

                      }
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    );
  }
}


export default PricingDetails;
