// error.actions.js
import { errorTypes } from "./error.types";

// Global error handler
export const setGlobalError = (error) => {
    let errorMessage;

    // Check if the error has a response and message
    if (error?.response && error?.response?.data && error.response?.data?.message) {
        errorMessage = error.response.data.message;
    } else {
        errorMessage = "An unexpected error occurred"; // Default error message
    }
    return {
        type: errorTypes.SET_GLOBAL_ERROR,
        payload: errorMessage,
    };
};


export const clearGlobalError = () => ({
    type: errorTypes.CLEAR_GLOBAL_ERROR,
});