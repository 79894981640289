import { clearGlobalError } from 'lib/redux/utilities/error/error.actions';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
// import { handleGlobalError } from './globalError'; // Optional: If you want to clear errors

const GlobalErrorHandler = () => {
    const error = useSelector((state) => state.globalError.errorMessage);
    const dispatch = useDispatch();


    console.log({
        globalError: error
    });

    useEffect(() => {
        if (error) {
            toast.error(error); // Display the toast notification
            dispatch(clearGlobalError()); // Optionally clear the error after showing it
        }
    }, [error, dispatch]);

    return null; // This component doesn't render anything
};

export default GlobalErrorHandler;
