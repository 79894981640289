import React from "react";
import PropTypes from "prop-types";

const Numpad = ({
  selectDimen,
  editDimen,
  handleAdd,
  onHandleEdit,
  disableAddOrSave,
  addIntoDimen,
  saveText = "Save",
}) => {
  return (
    <div>
       <div className="numpad">
      <button
        onClick={() => addIntoDimen(selectDimen, 1)}
        disabled={!selectDimen}
        className="numpad-btn"
      >
        1
      </button>
      <button
        onClick={() => addIntoDimen(selectDimen, 2)}
        disabled={!selectDimen}
        className="numpad-btn"
      >
        2
      </button>
      <button
        onClick={() => addIntoDimen(selectDimen, 3)}
        disabled={!selectDimen}
        className="numpad-btn"
      >
        3
      </button>
      <button
        onClick={() => addIntoDimen(selectDimen, 4)}
        disabled={!selectDimen}
        className="numpad-btn"
      >
        4
      </button>
      <button
        onClick={() => addIntoDimen(selectDimen, 5)}
        disabled={!selectDimen}
        className="numpad-btn"
      >
        5
      </button>
      <button
        onClick={() => addIntoDimen(selectDimen, 6)}
        disabled={!selectDimen}
        className="numpad-btn"
      >
        6
      </button>
      <button
        onClick={() => addIntoDimen(selectDimen, 7)}
        disabled={!selectDimen}
        className="numpad-btn"
      >
        7
      </button>
      <button
        onClick={() => addIntoDimen(selectDimen, 8)}
        disabled={!selectDimen}
        className="numpad-btn"
      >
        8
      </button>
      <button
        onClick={() => addIntoDimen(selectDimen, 9)}
        disabled={!selectDimen}
        className="numpad-btn"
      >
        9
      </button>
      <button
        onClick={() => addIntoDimen(selectDimen)}
        disabled={!selectDimen}
        className="numpad-btn clear"
      >
        Clear
      </button>
      <button
        onClick={() => addIntoDimen(selectDimen, 0)}
        disabled={!selectDimen}
        className="numpad-btn zero"
      >
        0
      </button>
      {editDimen === "" ? (
        <button
          onClick={() => handleAdd()}
          className="numpad-btn add"
          disabled={disableAddOrSave}
        >
          {saveText}
        </button>
      ) : (
        <button
          onClick={() => onHandleEdit()}
          className="numpad-btn add"
          disabled={disableAddOrSave}
        >
          {saveText}
        </button>
      )}
    </div>
    </div>
  );
};

Numpad.propTypes = {
  selectDimen: PropTypes.any,
  editDimen: PropTypes.string,
  handleAdd: PropTypes.func.isRequired,
  onHandleEdit: PropTypes.func.isRequired,
  disableAddOrSave: PropTypes.bool.isRequired,
  addIntoDimen: PropTypes.func.isRequired,
  saveText: PropTypes.string,
};

export default Numpad;
