import React from 'react';

const DollarInputWrapper = ({ children }) => {
    return (
        <div style={{ position: 'relative' }}>
            <span style={{
                position: 'absolute',
                top: '50%',
                left: '10px',
                transform: 'translateY(-50%)',
                color: '#555'
            }}>$</span>
            {React.cloneElement(children, {
                style: { paddingLeft: '25px', ...children.props.style }
            })}
        </div>
    );
};

export default DollarInputWrapper;
