import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Alert,
  Form,
  Modal,
  InputGroup,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { getDDData } from "lib/redux/drop-down/drop-down.action";
import "./new-lead.scss";
import { connect } from "react-redux";
import RequiredText from "components/required-text/required-text";
import validator from "validator";

import MaskedInput from "react-maskedinput";

import Color from "lib/constants/color";
import PopupModal from "components/popup-modal/popup-modal";
import { checkPhone } from "utils/utils";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { fetchStaticAll } from "lib/redux/static-all/static-all.actions";
import { GrRefresh } from "react-icons/gr";
import {
  SaveNewLead,
  FetchLeads,
  DeleteLead,
  EditLead,
  ScheduleLead,
  GetEmailType,
  GetPhoneType,
  GetPropertyType,
  GetRelationType,
  SaveNewLeadRemove,
  scheduleLeadStop
} from "lib/redux/new-lead/new-lead.actions";
import SelectState from "components/select-state/select-state";
import Building from "components/add-building/add-building";
import InitialLoader from "components/InitilalLoader";
import DisplayTable from "components/DisplayTable";
import { getAllPermissions } from "../../lib/redux/permission/permission.actions";
import userPermission from "hooks/usePermission";
import { Link } from "react-router-dom";
import { useMemo } from "react";
import { SearchBox } from "./SearchBox/SearchBox";
const columns = [
  {
    dataField: "id",
    text: "Line Item",
  },
  {
    dataField: "address",
    text: "Address",
  },
  {
    dataField: "pType",
    text: "Property Types",
  },
  {
    dataField: "sRequest",
    text: "Service Request",
  },
  {
    dataField: "actions",
    text: "Actions",
  },
];

const styles = {
  container: {
    boxShadow: "0 0 10px #99999996",
    background: "#fff",
    padding: "2em 1em",
    position: "relative",
  },
  heading: {
    flex: 1,
  },
  header: {
    padding: "25px 0",
  },
  formInput: {
    marginBottom: "15px",
  },
  formStyle: {
    // marginTop: "5%"
  },
};

const BoldText = ({ children, ...rest }) => <b {...rest}>{children}</b>;

const SubScriptText = ({ children }) => (
  <sub style={{ color: "#999" }}>({children})</sub>
);

const Btns = ({ children, type, ...rest }) => (
  <button
    className={`addRemoveBtn ${type === "add" ? "add" : "remove"}`}
    {...rest}
  >
    {children}
  </button>
);
const serviceRequests = [
  {
    name: "New Installation",
    state: "newInstallation",
  },
  {
    name: "Repair",
    state: "repair",
  },
];

const YesNo = [
  {
    name: "YES",
    value: true,
  },
  {
    name: "NO",
    value: false,
  },
];

const NewLead = (props) => {
  const loadData = () => {
    const {
      FetchLeads,
      GetEmailType,
      GetPhoneType,
      GetPropertyType,
      GetRelationType,
      getAllPermissions,
      ScheduleLead,
    } = props;

    FetchLeads();
    GetEmailType();
    GetPhoneType();
    GetPropertyType();
    GetRelationType();
    getAllPermissions();
    // ScheduleLead();
  };

  useEffect(() => {
    dispatch(fetchStaticAll());
  }, []);

  const [primaryContact, setPrimaryContact] = useState({
    pcFname: "",
    pcLname: "",
    pcCity: "",
    pcState: "",
    pcZip: "",
    pcAddress1: "",
    pcAddress2: "",
    pcEmailType: "",
    pcEmail: "",
    pcEmail2: "",
    showEmail3: "",
    pcEmailType3: "",
    pcEmail3: "",
    pcPhoneType: "",
    pcPhone3: "",
    pcEmailType2: "",
    pcPhone: "",
    pcPhoneType2: "",
    pcPhone2: "",
    pcPhoneType3: "",
    pcPhone3: "",
  });

  const [errMsg, setErrMsg] = useState("");
  const [errAt, setErrAt] = useState("");
  const [errType, setErrType] = useState("warning"); //danger, success, info, warning
  const [showCompanyFields, setShowCompanyFields] = useState(false);
  const [cName, setCName] = useState("");
  const [cCity, setCCity] = useState("");
  const [cState, setCState] = useState("");
  const [cZip, setCZip] = useState("");
  const [cAddress1, setCAddress1] = useState("");
  const [cAddress2, setCAddress2] = useState("");
  const [pcLname, setPcLname] = useState("");
  const [pcCity, setPcCity] = useState("");
  const [pcState, setPcState] = useState("");
  const [pcZip, setPcZip] = useState("");
  const [pcAddress1, setPcAddress1] = useState("");
  const [pcAddress2, setPcAddress2] = useState("");
  const [pcEmailType, setPcEmailType] = useState("");
  const [pcEmail, setPcEmail] = useState("");
  const [showEmail2, setShowEmail2] = useState(false);
  const [pcEmailType2, setPcEmailType2] = useState("");
  const [pcEmail2, setPcEmail2] = useState("");
  const [showEmail3, setShowEmail3] = useState(false);
  const [pcEmailType3, setPcEmailType3] = useState("");
  const [pcEmail3, setPcEmail3] = useState("");
  const [pcPhoneType, setPcPhoneType] = useState("");
  const [pcPhone, setPcPhone] = useState("");
  const [showPhone2, setShowPhone2] = useState(false);
  const [pcPhoneType2, setPcPhoneType2] = useState("");
  const [pcPhone2, setPcPhone2] = useState("");
  const [showPhone3, setShowPhone3] = useState(false);
  const [pcPhoneType3, setPcPhoneType3] = useState("");
  const [pcPhone3, setPcPhone3] = useState("");
  const [leadSource, setLeadSource] = useState("");
  const [leadSourceText, setLeadSourceText] = useState("");
  const [scRelationship, setScRelationship] = useState("");
  const [scLname, setScLname] = useState("");
  const [scFname, setScFname] = useState("");
  const [editBtn, setEditBtn] = useState("");
  const [scCity, setScCity] = useState("");
  const [scState, setScState] = useState("");
  const [scZip, setScZip] = useState("");
  const [scAddress1, setScAddress1] = useState("");
  const [scAddress2, setScAddress2] = useState("");
  const [scEmailType, setScEmailType] = useState("");
  const [scEmail, setScEmail] = useState("");
  const [scEmail2Show, setScEmail2Show] = useState(false);
  const [scEmailType2, setScEmailType2] = useState("");
  const [scEmail2, setScEmail2] = useState("");
  const [scEmail3Show, setScEmail3Show] = useState(false);
  const [scEmailType3, setScEmailType3] = useState("");
  const [scEmail3, setScEmail3] = useState("");
  const [scPhoneType, setScPhoneType] = useState("");
  const [scPhone, setScPhone] = useState("");
  const [scPhone2Show, setScPhone2Show] = useState(false);
  const [scPhoneType2, setScPhoneType2] = useState("");
  const [scPhone2, setScPhone2] = useState("");
  const [scPhone3Show, setScPhone3Show] = useState(false);
  const [scPhoneType3, setScPhoneType3] = useState("");
  const [scPhone3, setScPhone3] = useState("");
  const [pName, setPName] = useState("");
  const [pCity, setPCity] = useState("");
  const [pState, setPState] = useState("");
  const [pZip, setPZip] = useState("");
  const [pAddress1, setPAddress1] = useState("");
  const [pAddress2, setPAddress2] = useState("");
  const [pType, setPType] = useState("");
  const [pNotes, setPNotes] = useState("");
  const [siteContactRelationship, setSiteContactRelationship] = useState("");
  const [siteContactFname, setSiteContactFname] = useState("");
  const [siteContactLname, setSiteContactLname] = useState("");
  const [siteContactPhoneType, setSiteContactPhoneType] = useState("");
  const [siteContactPhone, setSiteContactPhone] = useState("");
  const [siteContactPhone2Show, setSiteContactPhone2Show] = useState(false);
  const [siteContactPhoneType2, setSiteContactPhoneType2] = useState("");
  const [siteContactPhone2, setSiteContactPhone2] = useState("");
  const [siteContactPhone3Show, setSiteContactPhone3Show] = useState("");
  const [siteContactPhoneType3, setSiteContactPhoneType3] = useState("");
  const [siteContactPhone3, setSiteContactPhone3] = useState("");
  const [siteContactEmailType, setSiteContactEmailType] = useState("");
  const [siteContactEmail, setSiteContactEmail] = useState("");
  const [siteContactEmail2Show, setSiteContactEmail2Show] = useState(false);
  const [siteContactEmailType2, setSiteContactEmailType2] = useState("");
  const [siteContactEmail2, setSiteContactEmail2] = useState("");

  const [siteContactEmail3Show, setSiteContactEmail3Show] = useState(false);
  const [siteContactEmailType3, setSiteContactEmailType3] = useState("");
  const [siteContactEmail3, setSiteContactEmail3] = useState("");
  const [showServiceRequest, setShowServiceRequest] = useState(false);
  const [primaryAddressChecked, setPrimaryAddressChecked] = useState(false);
  const [companyAddressChecked, setCompanyAddressChecked] = useState(false);
  const [showSecondaryContactInfo, setShowSecondaryContactInfo] =
    useState(false);
  const [showSiteContactInfo, setShowSiteContactInfo] = useState(false);
  const [showServiceRequestModal, setShowServiceRequestModal] = useState(false);
  const [newInstallation, setNewInstallation] = useState(false);
  const [newInstallationForOther, setNewInstallationForOther] = useState(false);
  const [currentStatus, setCurrentStatus] = useState("");
  const [repair, setRepair] = useState(false);
  const [showNext, setShowNext] = useState(false);
  const [sales01, setSales01] = useState(false);
  const [sales02, setSales02] = useState(false);
  const [repair01, setRepair01] = useState(false);
  const [repair02, setRepair02] = useState(false);
  const [repair03, setRepair03] = useState(false);
  const [repair04, setRepair04] = useState(false);
  const [repair05, setRepair05] = useState(false);
  const [repair06, setRepair06] = useState(false);
  const [repair06Name, setRepair06Name] = useState("");
  const [originalWorkPerformed, setOriginalWorkPerformed] = useState("");
  const [WorkPerformedYear, setWorkPerformedYear] = useState("");
  const [isUnderWarrenty, setIsUnderWarrenty] = useState("");
  const [repairServicePrice, setRepairServicePrice] = useState(0);
  const [taxRate, setTaxRate] = useState("");
  const [nrTotal, setNrTotal] = useState(0);
  const [repairDescription, setRepairDescription] = useState("");
  const [serviceChargePolicy, setServiceChargePolicy] = useState(false);
  const [showCoopModal, setShowCoopModal] = useState(false);
  const [coopUnits, setCoopUnits] = useState({});
  const [newBuildingName, setNewBuildingName] = useState("");
  const [tempNewBuildingName, setTempNewBuildingName] = useState("");
  const [errFor, setErrFor] = useState("");
  const [phoneTypes, setPhoneTypes] = useState([]);
  const [emailTypes, setEmailTypes] = useState([]);
  const [relationships, setRelationships] = useState([]);
  const [propertyTypes, setPropertyTypes] = useState([]);
  const [newInstallations, setNewInstallations] = useState([]);
  const [newRepairs, setNewRepairs] = useState([]);
  const [leadSourcesType, setLeadSourcesType] = useState([]);
  const [taxRates, setTaxRates] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [lead_id, setLead_id] = useState("");
  const [leadId, setLeadId] = useState("");
  const [editLeads, setEditLead] = useState(false);
  const [editLeadId, setEditLeadId] = useState("");
  const [isCancel, setIsCancel] = useState(false);
  const [primaryFirstName, setPrimaryFirstName] = useState("");
  const [primaryLastName, setPrimaryLastName] = useState("");
  const [propertyId, setPropertyId] = useState("");
  const [isPrimaryDetailsPresent, setIsPrimaryDetailsPresent] = useState(false);
  const [addressId, setAddressId] = useState("");
  const [requestId, setRequestId] = useState([]);
  const [request_detail, setRequest_detail] = useState([]);
  const [propertyListLentgh, setPropertyListLentgh] = useState(0);
  const [allLeadStore, setAllLeadStore] = useState();
  const [leadProprtyLength, setLeadProprtyLength] = useState(0);
  const [_id, set_Id] = useState("");
  const [state, setState] = useState({
    newInstallation: "",
    repair: "",
  });
  console.log("propertyId", propertyId);

  console.log("allLeadStore ", allLeadStore);

  const toggleCompanyFields = () => {
    setShowCompanyFields(!showCompanyFields);
  };

  useEffect(() => {
    const existingLeadDetails = props.leads.leadsData?.filter((val) => {
      return (
        val.primary_data[0].primaryFirstName === primaryContact.pcFname &&
        val.primary_data[0].primaryLastName === pcLname &&
        val.primary_data[0].primaryAddress1 === pcAddress1 &&
        val.primary_data[0].primaryAddress2 === pcAddress2
      );
    });
    if (existingLeadDetails?.length > 0) {
      setIsPrimaryDetailsPresent(true);
      setAddressId(existingLeadDetails[0].leadAddressId);
      console.log("existingLeadDetails", existingLeadDetails);
      setPrimaryFirstName(
        existingLeadDetails[0]?.primary_data[0]?.primaryFirstName
      );
      setPrimaryLastName(
        existingLeadDetails[0]?.primary_data[0]?.primaryLastName
      );
    } else {
      setIsPrimaryDetailsPresent(false);
    }
  }, [
    primaryContact.pcFname,
    pcLname,
    pcAddress1,
    pcAddress2,
    props.leads.leadsData,
  ]);

  const { gettingPermission, permission, permissionErr } = useSelector(
    (state) => state.allPermissions
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllPermissions());
    dispatch(GetPropertyType());
    dispatch(GetEmailType());
    dispatch(GetRelationType());
    dispatch(GetPhoneType());
    dispatch(FetchLeads());
  }, []);

  const { newleadPointerEvent } = userPermission(permission);

  const toggleSecondaryContactFields = () => {
    setShowSecondaryContactInfo(!showSecondaryContactInfo);
  };

  const toggleSiteContactInfo = () => {
    setShowSiteContactInfo(!showSiteContactInfo);
  };

  const isValidated = () => {
    if (primaryContact.pcFname === "") {
      setErrMsg("First Name is required.");
      setErrAt("pcFname");
      setErrType("danger");

      return false;
    } else if (pcLname === "") {
      setErrMsg("Last Name is required.");
      setErrAt("pcLname");
      setErrType("danger");
      return false;
    } else if (pcEmail === "") {
      setErrMsg("Email is required.");
      setErrAt("pcEmail");
      setErrType("danger");
      return false;
    } else if (pcEmail !== "" && !validator.isEmail(pcEmail)) {
      setErrMsg("A Valid Email is required.");
      setErrAt("pcEmail");
      setErrType("danger");
      return false;
    } else if (showEmail2 && pcEmail2 === "") {
      setErrMsg("Second Email cannot be left empty.");
      setErrAt("pcEmail2");
      setErrType("danger");
      return false;
    } else if (showEmail2 && pcEmail2 !== "" && !validator.isEmail(pcEmail2)) {
      setErrMsg("A Valid Second Email is required.");
      setErrAt("pcEmail2");
      setErrType("danger");

      return false;
    } else if (showEmail3 && pcEmail3 === "") {
      setErrMsg("Third Email cannot be left empty.");
      setErrAt("pcEmail3");
      setErrType("danger");
      return false;
    } else if (showEmail3 && pcEmail3 !== "" && !validator.isEmail(pcEmail3)) {
      setErrMsg("A Valid Third Email is required.");
      setErrAt("pcEmail3");
      setErrType("warning");
      return false;
    } else if (pcAddress1 === "") {
      setErrMsg("Primary Contact Address 1 is required.");
      setErrAt("pcAddress1");
      setErrType("danger");

      return false;
    } else if (pcCity === "") {
      setErrMsg("Primary Contact City is required.");
      setErrAt("pcCity");
      setErrType("danger");
      return false;
    } else if (pcState === "") {
      setErrMsg("Primary Contact State is required.");
      setErrAt("pcState");
      setErrType("danger");

      return false;
    } else if (pcZip === "") {
      setErrMsg("Primary Contact Zip is required");
      setErrAt("pcZip");
      setErrType("danger");

      return false;
    } else if (!validator.isNumeric(pcZip)) {
      setErrMsg("Primary Contact Zip should have only numbers.");
      setErrAt("pcZip");
      setErrType("warning");

      return false;
    } else if (pcZip !== "" && pcZip.length < 5) {
      setErrMsg("Primary Contact Zip should have 5 digits.");
      setErrAt("pcZip");
      setErrType("warning");
      return false;
    } else if (leadSource === "") {
      setErrMsg("Lead Source is required.");
      setErrAt("leadSource");
      setErrType("danger");
      return false;
    } else if (
      showLeadSourceText(leadSourcesType, leadSource) &&
      leadSourceText === ""
    ) {
      setErrMsg("Referrer Name is required.");
      setErrAt("leadSourceText");
      setErrType("danger");

      return false;
    } else if (pAddress1 === "") {
      setErrMsg("Service Property Address 1 is required.");
      setErrAt("pAddress1");
      setErrType("danger");

      return false;
    } else if (pCity === "") {
      setErrMsg("Service Property City is required.");
      setErrAt("pCity");
      setErrType("danger");
      return false;
    } else if (pState === "") {
      setErrMsg("Service Property State is required.");
      setErrAt("pState");
      setErrType("danger");

      return false;
    } else if (pZip === "") {
      setErrMsg("Service Property Zip is required.");
      setErrAt("pZip");
      setErrType("danger");

      return false;
    } else if (!validator.isNumeric(pZip)) {
      setErrMsg("Service Property Zip should have only numbers.");
      setErrAt("pZip");
      setErrType("warning");

      return false;
    } else if (pZip !== "" && pZip.length < 5) {
      setErrMsg("Service Property Zip should have 5 digits.");
      setErrAt("pZip");
      setErrType("warning");

      return false;
    } else if (pType === "") {
      setErrMsg("Please Select property type.");
      setErrAt("pType");
      setErrType("danger");

      return false;
    } else if (!newInstallation && !repair) {
      setErrMsg("Please Select a service request..");
      setErrAt("");
      setErrType("danger");

      return false;
    } else {
      setErrMsg("");
      setErrAt("");
      setErrType("");
      setErrFor("");
      return true;
    }
  };

  const showAdditionalField = (name) => {
    if (name === "showPhone2") {
      setShowPhone2(true);
    } else if (name === "showEmail2") {
      setShowEmail2(true);
    } else if (name === "showPhone3") {
      setShowPhone3(true);
    } else if (name === "showEmail3") {
      setShowEmail3(true);
    } else if (name === "scPhone2Show") {
      setScPhone2Show(true);
    } else if (name === "scPhone3Show") {
      setScPhone3Show(true);
    } else if (name === "scEmail2Show") {
      setScEmail2Show(true);
    } else if (name === "scEmail3Show") {
      setScEmail3Show(true);
    } else if (name === "siteContactPhone2Show") {
      setSiteContactPhone2Show(true);
    } else if (name === "siteContactPhone3Show") {
      setSiteContactPhone3Show(true);
    } else if (name === "siteContactEmail2Show") {
      setSiteContactEmail2Show(true);
    } else if (name === "siteContactEmail3Show") {
      setSiteContactEmail3Show(true);
    }
  };
  const removeAdditionalField = (name, fieldName, fieldTypeName) => {
    if (name === "showPhone2") {
      setShowPhone2(false);
      setPcPhone2("");
      setPcPhoneType2("");
    } else if (name === "showEmail2") {
      setShowEmail2(false);
      setPcEmail2("");
      setPcEmailType2("");
    } else if (name === "showPhone3") {
      setShowPhone3(false);
      setPcPhone3("");
      setPcPhoneType3("");
    } else if (name === "showEmail3") {
      setShowEmail3(false);
      setPcEmail3("");
      setPcEmailType3("");
    } else if (name === "scPhone2Show") {
      setScPhone2Show(false);
      setScPhone2("");
      setScPhoneType2("");
    } else if (name === "scPhone3Show") {
      setScPhone3Show(false);
      setScPhone3("");
      setScPhoneType3("");
    } else if (name === "scEmail2Show") {
      setScEmail2Show(false);
      setScEmail2("");
      setScEmailType2("");
    } else if (name === "scEmail3Show") {
      setScEmail3Show(false);
      setScEmailType3("");
      setScEmail3("");
    } else if (name === "siteContactPhone2Show") {
      setSiteContactPhone2Show(false);
      setSiteContactPhone2("");
      setSiteContactPhoneType2("");
    } else if (name === "siteContactPhone3Show") {
      setSiteContactPhone3Show(false);
      setSiteContactPhone3("");
      setSiteContactPhoneType3("");
    } else if (name === "siteContactEmail2Show") {
      setSiteContactEmail2Show(false);
      setSiteContactEmail2("");
      setSiteContactEmailType2("");
    } else if (name === "siteContactEmail3Show") {
      setSiteContactEmail3Show(false);
      setSiteContactEmail3("");
      setSiteContactEmailType3("");
    }
  };

  const commonContactFields = (
    relationship,
    relationshipName,
    setRelationship,
    fname,
    fnameName,
    setFname,
    lname,
    lnameName,
    setLname,
    phoneType,
    phoneTypeName,
    setPhoneType,
    pNumber,
    pNumberName,
    setPNumber,
    phoneType2,
    phoneTypeName2,
    setPhoneType2,
    pNumber2,
    pNumberName2,
    setPNumber2,
    phoneType3,
    phoneTypeName3,
    setPhoneType3,
    pNumber3,
    pNumberName3,
    setPNumber3,
    emailType,
    emailTypeName,
    setEmailType,
    email,
    emailName,
    setEmail,
    emailType2,
    emailTypeName2,
    setEmailType2,
    email2,
    emailName2,
    setEmail2,
    emailType3,
    emailTypeName3,
    setEmailType3,
    email3,
    emailName3,
    setEmail3,
    showPhone2,
    showPhone2Name,
    showPhone3,
    showPhone3Name,
    showEmail2,
    showEmail2Name,
    showEmail3,
    showEmail3Name,
    required = false
  ) => {
    const { relationTypess } = props;
    return (
      <Col md="12" sm="12">
        <Row>
          <Col md="6" sm="12">
            {relationTypess && (
              <Row>
                <Col md="6" sm="12">
                  <Form.Group style={styles.formInput}>
                    <Form.Label>
                      Relationship<RequiredText>*</RequiredText>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      value={relationship}
                      name={relationshipName}
                      autoComplete="off"
                      onChange={(e) => setRelationship(e.target.value)}
                    >
                      <option value="">Choose...</option>
                      {displayRelationshipTypes()}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md="6" sm="12">
                  <Form.Group style={styles.formInput}>
                    <Form.Label disabled={!relationship}>
                      First Name {required && <RequiredText>*</RequiredText>}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name={fnameName}
                      value={fname}
                      disabled={!relationship}
                      onChange={(event) => setFname(event.target.value)}
                      isInvalid={errAt === fnameName}
                      autoComplete="off"
                    />
                  </Form.Group>
                </Col>
              </Row>
            )}
          </Col>

          <Col sm="12" md="6">
            <Form.Group style={styles.formInput}>
              <Form.Label>
                Last Name {required && <RequiredText>*</RequiredText>}
              </Form.Label>
              <Form.Control
                type="text"
                name={lnameName}
                value={lname}
                disabled={!relationship}
                onChange={(event) => setLname(event.target.value)}
                isInvalid={errAt === lnameName}
                autoComplete="off"
              />
            </Form.Group>
          </Col>
          {commonPhoneAndEmailFields(
            phoneType,
            phoneTypeName,
            setPhoneType,
            pNumber,
            pNumberName,
            setPNumber,
            phoneType2,
            phoneTypeName2,
            setPhoneType2,
            pNumber2,
            pNumberName2,
            setPNumber2,
            phoneType3,
            phoneTypeName3,
            setPhoneType3,
            pNumber3,
            pNumberName3,
            setPNumber3,
            emailType,
            emailTypeName,
            setEmailType,
            email,
            emailName,
            setEmail,
            emailType2,
            emailTypeName2,
            setEmailType2,
            email2,
            emailName2,
            setEmail2,
            emailType3,
            emailTypeName3,
            setEmailType3,
            email3,
            emailName3,
            setEmail3,
            showPhone2,
            showPhone2Name,
            showPhone3,
            showPhone3Name,
            showEmail2,
            showEmail2Name,
            showEmail3,
            showEmail3Name,
            required
          )}
        </Row>
      </Col>
    );
  };

  const commonPhoneAndEmailFields = (
    phoneType,
    phoneTypeName,
    setPhoneType,
    pNumber,
    pNumberName,
    setPNumber,
    phoneType2,
    phoneTypeName2,
    setPhoneType2,
    pNumber2,
    pNumberName2,
    setPNumber2,
    phoneType3,
    phoneTypeName3,
    setPhoneType3,
    pNumber3,
    pNumberName3,
    setPNumber3,
    emailType,
    emailTypeName,
    setEmailType,
    email,
    emailName,
    setEmail,
    emailType2,
    emailTypeName2,
    setEmailType2,
    email2,
    emailName2,
    setEmail2,
    emailType3,
    emailTypeName3,
    setEmailType3,
    email3,
    emailName3,
    setEmail3,
    showPhone2,
    showPhone2Name,
    showPhone3,
    showPhone3Name,
    showEmail2,
    showEmail2Name,
    showEmail3,
    showEmail3Name,
    required = false
  ) => {
    const { phoneTypess, emailTypess } = props;
    return (
      <Col md="12" sm="12">
        <Row>
          {phoneTypess && (
            <Col md="6" sm="12">
              <Row>
                <Col md="6" sm="12">
                  <Form.Group style={styles.formInput}>
                    <Form.Label>Phone Type</Form.Label>
                    <Form.Control
                      as="select"
                      value={phoneType}
                      name={phoneTypeName}
                      isInvalid={errAt === phoneTypeName}
                      onChange={(event) => setPhoneType(event.target.value)}
                    >
                      <option value="">Choose...</option>
                      {displayPhoneTypes()}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md="6" sm="12">
                  <Form.Group style={styles.formInput}>
                    <Form.Label>
                      Phone Number
                      <Btns
                        type="add"
                        disabled={
                          showPhone2 ||
                          showPhone3 ||
                          pNumber === "" ||
                          checkPhone(pNumber)
                        }
                        onClick={() => showAdditionalField(showPhone2Name)}
                      >
                        Add +
                      </Btns>
                    </Form.Label>
                    <InputGroup>
                      <MaskedInput
                        className="form-control"
                        mask="(111) 111-1111"
                        type="text"
                        name={pNumberName}
                        value={pNumber}
                        disabled={!phoneType}
                        placeholder=""
                        onChange={(event) => setPNumber(event.target.value)}
                        required="required"
                        maxLength={10}
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>

              {showPhone2 && (
                <Row>
                  <Col md="6" sm="12">
                    <Form.Group style={styles.formInput}>
                      <Form.Label>Phone Type</Form.Label>
                      <Form.Control
                        as="select"
                        value={phoneType2}
                        name={phoneTypeName2}
                        onChange={(event) => setPhoneType2(event.target.value)}
                      >
                        <option value="">Choose...</option>
                        {displayPhoneTypes(phoneTypes)}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md="6" sm="12">
                    <Form.Group style={styles.formInput}>
                      <Form.Label>
                        Phone Number
                        <Btns
                          type="add"
                          disabled={
                            showPhone3 ||
                            pNumber2 === "" ||
                            checkPhone(pNumber2)
                          }
                          onClick={() => showAdditionalField(showPhone3Name)}
                        >
                          Add +
                        </Btns>
                        <Btns
                          type="remove"
                          onClick={() =>
                            removeAdditionalField(
                              showPhone2Name,
                              pNumberName2,
                              phoneTypeName2
                            )
                          }
                        >
                          Remove -
                        </Btns>
                      </Form.Label>
                      <InputGroup>
                        <MaskedInput
                          className="form-control"
                          mask="(111) 111-1111"
                          type="text"
                          name={pNumberName2}
                          value={pNumber2}
                          disabled={!phoneType2}
                          placeholder=""
                          onChange={(event) => setPNumber2(event.target.value)}
                          required="required"
                          maxLength={10}
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
              )}

              {showPhone3 && (
                <Row>
                  <Col md="6" sm="12">
                    <Form.Group style={styles.formInput}>
                      <Form.Label>Phone Type</Form.Label>
                      <Form.Control
                        as="select"
                        value={phoneType3}
                        name={phoneTypeName3}
                        onChange={(event) => setPhoneType3(event.target.value)}
                      >
                        <option value="">Choose...</option>
                        {displayPhoneTypes(phoneTypes)}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md="6" sm="12">
                    <Form.Group style={styles.formInput}>
                      <Form.Label>
                        Phone Number
                        <Btns
                          type="add"
                          disabled={
                            showPhone2 ||
                            pNumber3 === "" ||
                            checkPhone(pNumber3)
                          }
                          onClick={() => showAdditionalField(showPhone2Name)}
                        >
                          Add +
                        </Btns>
                        <Btns
                          type="remove"
                          onClick={() =>
                            removeAdditionalField(
                              showPhone3Name,
                              pNumberName3,
                              phoneTypeName3
                            )
                          }
                        >
                          Remove -
                        </Btns>
                      </Form.Label>
                      <InputGroup>
                        <MaskedInput
                          className="form-control"
                          mask="(111) 111-1111"
                          type="text"
                          name={pNumberName3}
                          value={pNumber3}
                          disabled={!phoneType3}
                          placeholder=""
                          onChange={(event) => setPNumber3(event.target.value)}
                          required="required"
                          maxLength={10}
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
              )}
            </Col>
          )}

          {emailTypess && (
            <Col md="6" sm="12">
              <Row>
                <Col md="6" sm="12">
                  <Form.Group style={styles.formInput}>
                    <Form.Label>
                      Email Type{" "}
                      {required && emailName === "pcEmail" && (
                        <RequiredText>*</RequiredText>
                      )}
                    </Form.Label>
                    <Form.Control
                      as="select"
                      value={emailType}
                      name={emailTypeName}
                      onChange={(event) => setEmailType(event.target.value)}
                    >
                      <option value="">Choose...</option>
                      {displayEmailTypes()}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md="6" sm="12">
                  <Form.Group style={styles.formInput}>
                    <Form.Label>
                      Email
                      {required && emailName === "pcEmail" && (
                        <RequiredText>*</RequiredText>
                      )}
                    </Form.Label>
                    <Btns
                      disabled={
                        !email ||
                        !validator.isEmail(email) ||
                        showEmail2 ||
                        showEmail3
                      }
                      type="add"
                      onClick={() => showAdditionalField(showEmail2Name)}
                    >
                      Add +
                    </Btns>
                    <Form.Control
                      type="text"
                      name={emailName}
                      disabled={!emailType}
                      value={email}
                      isInvalid={errAt === emailName}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>

              {showEmail2 && (
                <Row>
                  <Col md="6" sm="12">
                    <Form.Group style={styles.formInput}>
                      <Form.Label>Email Type</Form.Label>
                      <Form.Control
                        as="select"
                        value={emailType2}
                        name={emailTypeName2}
                        onChange={(event) => setEmailType2(event.target.value)}
                      >
                        <option value="">Choose...</option>
                        {displayEmailTypes(emailTypes)}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md="6" sm="12">
                    <Form.Group style={styles.formInput}>
                      <Form.Label>
                        Email
                        <Btns
                          disabled={
                            !email2 || !validator.isEmail(email2) || showEmail3
                          }
                          type="add"
                          onClick={() => showAdditionalField(showEmail3Name)}
                        >
                          Add +
                        </Btns>
                        <Btns
                          type="remove"
                          onClick={() =>
                            removeAdditionalField(
                              showEmail2Name,
                              emailName2,
                              emailTypeName2
                            )
                          }
                        >
                          Remove -
                        </Btns>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name={emailName2}
                        disabled={!emailType2}
                        value={email2}
                        isInvalid={errAt === emailName2}
                        onChange={(event) => setEmail2(event.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              )}

              {showEmail3 && (
                <Row>
                  <Col md="6" sm="12">
                    <Form.Group style={styles.formInput}>
                      <Form.Label>Email Type</Form.Label>
                      <Form.Control
                        as="select"
                        value={emailType3}
                        name={emailTypeName3}
                        onChange={(event) => setEmailType3(event.target.value)}
                      >
                        <option value="">Choose...</option>
                        {displayEmailTypes(emailTypes)}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md="6" sm="12">
                    <Form.Group style={styles.formInput}>
                      <Form.Label>
                        Email
                        <Btns
                          disabled={
                            !email3 || !validator.isEmail(email3) || showEmail2
                          }
                          type="add"
                          onClick={() => showAdditionalField(showEmail2Name)}
                        >
                          Add +
                        </Btns>
                        <Btns
                          type="remove"
                          onClick={() =>
                            removeAdditionalField(
                              showEmail3Name,
                              emailName3,
                              emailTypeName3
                            )
                          }
                        >
                          Remove -
                        </Btns>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name={emailName3}
                        disabled={!emailType3}
                        value={email3}
                        isInvalid={errAt === emailName3}
                        onChange={(event) => setEmail3(event.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              )}
            </Col>
          )}
        </Row>
      </Col>
    );
  };

  const commonAddressFields = (
    address1,
    address1Name,
    setAdress1,
    address2,
    address2Name,
    setAdress2,
    city,
    cityName,
    setCity,
    state,
    stateName,
    setState,
    zip,
    zipName,
    setZip,
    required = false,
    allowDisabling = false
  ) => {
    return (
      <Col md="12" sm="12">
        <Row>
          <Col xs="12" md="6">
            <Form.Group style={styles.formInput}>
              <Form.Label>
                Address 1 {required && <RequiredText>*</RequiredText>}
              </Form.Label>
              <Form.Control
                as="textarea"
                rows="2"
                name={address1Name}
                value={address1}
                disabled={allowDisabling}
                onChange={(event) => setAdress1(event.target.value)}
                isInvalid={errAt === address1Name}
                autoComplete="off"
                role="presentation"
              />
            </Form.Group>
          </Col>
          <Col xs="12" md="6">
            <Form.Group style={styles.formInput}>
              <Form.Label>Address 2</Form.Label>
              <Form.Control
                as="textarea"
                rows="2"
                name={address2Name}
                value={address2}
                disabled={allowDisabling}
                onChange={(event) => setAdress2(event.target.value)}
                autoComplete="off"
                role="presentation"
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md="6" sm="12">
            <Form.Group style={styles.formInput}>
              <Form.Label>
                City {required && <RequiredText>*</RequiredText>}
              </Form.Label>
              <Form.Control
                type="text"
                name={cityName}
                value={city}
                disabled={allowDisabling}
                onChange={(event) => setCity(event.target.value)}
                isInvalid={errAt === cityName}
              />
            </Form.Group>
          </Col>

          <Col sm="6" md="3">
            <SelectState
              label="State"
              required={required}
              name={stateName}
              value={state}
              onChange={(event) => setState(event.target.value)}
              disabled={allowDisabling}
              errAt={errAt}
            />
          </Col>

          <Col sm="6" md="3">
            <Form.Group style={styles.formInput}>
              <Form.Label>
                Zip Code {required && <RequiredText>*</RequiredText>}
              </Form.Label>
              <Form.Control
                type="text"
                name={zipName}
                value={zip}
                maxLength={5}
                disabled={allowDisabling}
                onChange={(event) => setZip(event.target.value)}
                isInvalid={errAt === zipName}
              />
            </Form.Group>
          </Col>
        </Row>
      </Col>
    );
  };

  const displayRelationshipTypes = () => {
    const { relationTypess } = props;
    const filterData = relationTypess?.relationdata?.filter((elem) => {
      return elem.isDeleted !== true;
    });
    return filterData?.map((item) => (
      <option key={item?._id} value={item?._id}>
        {item?.roleType}
      </option>
    ));
  };

  const displayTaxRate = () => {
    return taxRates.map((item) => (
      <option key={item.taxId} value={item.taxPercentage}>
        {item.taxDescription}
      </option>
    ));
  };

  const displayPhoneTypes = () => {
    const { phoneTypess } = props;
    const filterData = phoneTypess?.phonedata?.filter((elem) => {
      return elem.isDeleted !== true;
    });
    return filterData?.map((item) => (
      <option key={item?._id} value={item?._id}>
        {item?.roleType}
      </option>
    ));
  };

  const displayEmailTypes = () => {
    const { emailTypess } = props;
    const filterData = emailTypess?.emaildata?.filter((elem) => {
      return elem.isDeleted !== true;
    });
    return filterData?.map((item) => (
      <option key={item?._id} value={item?._id}>
        {item?.roleType}
      </option>
    ));
  };

  const displaypTypeOptions = () => {
    const { propertyTypess } = props;
    const filterData = propertyTypess?.propertydata?.filter((elem) => {
      return elem.isDeleted !== true;
    });
    return filterData?.map((item) => (
      <option key={item?._id} value={item?._id}>
        {item?.roleType}
      </option>
    ));
  };

  const makeSelection = (name) => {
    if (name === "newInstallation") {
      setNewInstallation(!newInstallation);
    } else if (name === "repair") {
      setRepair(!repair);
    }
    if (name === "sales01") {
      setSales01(!sales01);
    } else if (name === "sales02") {
      setSales02(!sales02);
    }

    if (name === "repair01") {
      setRepair01(!repair01);
    } else if (name === "repair02") {
      setRepair02(!repair02);
    } else if (name === "repair03") {
      setRepair03(!repair03);
    } else if (name === "repair04") {
      setRepair04(!repair04);
    } else if (name === "repair05") {
      setRepair05(!repair05);
    } else if (name === "repair06") {
      setRepair06(!repair06);
    }
    // return setState((state) => ({ [name]: !state[name] }));
    // setState({ ...state, [name]: !name });
  };

  const displayYesNo = (items) => {
    return items.map((item) => (
      <option key={item.name} value={item.value}>
        {item.name}
      </option>
    ));
  };

  const onSubmit = (e) => {
    e.preventDefault();
  };

  const handleChange = (event) => {
    const { name, value } = event.target.value;
    // setState({ [name]: value });
    setState({ ...state, [name]: value });

    clearErrorMsg(name, value);
  };

  const clearErrorMsg = (name, value) => {
    if (value !== "" && name === state.errAt) {
      clearMessage();
    }
  };

  const handleChecked = (event) =>
    // setState({ [event.target.name]: event.target.checked });
    setState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.checked,
    }));

  const onHide = () => {
    clearMessage();
    // setState({ showServiceRequestModal: false, showNext: false });
    setShowServiceRequestModal(false);
    setShowNext(false);
  };

  const getPtypes = (pId) => {
    const { propertyTypess } = props;
    if (propertyTypess?.propertydata?.length) {
      const idx = propertyTypess?.propertydata?.findIndex(
        (type) => type._id?.toString() === pId?.toString()
      );
      return propertyTypess?.propertydata[idx]?.roleType;
    }
    return null;
  };
  const scrollToTop = () => {
    return window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const onEditLead = (lead, res) => {
    setPropertyId(res.property_address[0]._id);
    const {
      companyAddress1,
      companyAddress2,
      companyCity,
      companyName,
      companyState,
      companyZip,
      primaryFirstName,
      primaryLastName,
      primaryPhone1Type,
      primaryPhone1,
      primaryPhone2Type,
      primaryPhone2,
      primaryPhone3Type,
      primaryPhone3,
      primaryEmail1Type,
      primaryEmail1,
      primaryEmail2Type,
      primaryEmail2,
      primaryEmail3Type,
      primaryEmail3,
      primaryAddress1,
      primaryAddress2,
      primaryCity,
      primaryState,
      primaryZip,
      leadSource,
      secondaryRelationship,
      secondaryFirstName,
      secondaryLastName,
      secondaryPhone1Type,
      secondaryPhone1,
      secondaryPhone2Type,
      secondaryPhone2,
      secondaryEmail1Type,
      secondaryEmail1,
      secondaryEmail2Type,
      secondaryEmail2,
      secondaryAddress1,
      secondaryAddress2,
      secondaryCity,
      secondaryState,
      secondaryZip,
    } = lead.primary_data[0];
    set_Id(lead.primary_data[0]._id);
    console.log("companyZip", companyZip);

    const {
      leadId,
      currentStatus,
      requests,
      propertyName,
      propertyAddress1,
      propertyAddress2,
      propertyCity,
      propertyState,
      propertyZip,
      propertyType,
      propertyNotes,
      doNewInstallation,
      newInstallationFor,
      newInstallationForOther,
      doRepair,
      repairFor,
      repairForOther,
      originalWorkDone,
      originalWorkYear,
      underWarrenty,
      servicePrice,
      taxRate,
      totalPrice,
      repairDescreption,
      propertyBuildings,
    } = res.property_address[0];

    let coopUnits = {};
    if (propertyBuildings?.length > 0) {
      propertyBuildings?.map((building) => {
        return (coopUnits = {
          ...coopUnits,
          [building.bname]: {
            editBtn: false,
            deleteModal: false,
          },
        });
      });
    }

    let request_detail = [];

    res.requests.forEach((rating) => {
      request_detail.push(rating._id);
      if (rating.isRepairRequest) {
        setRepair01(rating.requestFor?.includes("repair01"));
        setRepair02(rating.requestFor?.includes("repair02"));
        setRepair03(rating.requestFor?.includes("repair03"));
        setRepair04(rating.requestFor?.includes("repair04"));
        setRepair05(rating.requestFor?.includes("repair05"));
        setRepair06(rating.requestFor?.includes("repair06"));
        setRepair(rating.isRepairRequest);
        setRepair06Name(rating.requestForOther);
        setOriginalWorkPerformed(rating.originalWorkDone);
        setWorkPerformedYear(rating.originalWorkYear);
        setIsUnderWarrenty(rating.underWarrenty);
        setRepairServicePrice(
          rating.servicePrice === undefined
            ? ""
            : rating.servicePrice?.replace("$", "")
        );
        setTaxRate(rating.taxRate);
        setNrTotal(rating.totalPrice?.replace("$", ""));
        setRepairDescription(rating.repairDescreption);
      } else {
        setNewInstallation(true);

        setNewInstallationForOther(rating.requestForOther);
        setSales01(rating.requestFor?.includes("sales01"));
        setSales02(rating.requestFor?.includes("sales02"));
        setOriginalWorkPerformed(rating.originalWorkDone);
        setWorkPerformedYear(rating.originalWorkYear);
        setIsUnderWarrenty(rating.underWarrenty);
        setRepairServicePrice(
          rating.servicePrice === undefined
            ? ""
            : rating.servicePrice?.replace("$", "")
        );
        setTaxRate(rating.taxRate);
        setNrTotal(rating.totalPrice?.replace("$", ""));
        setRepairDescription(rating.repairDescreption);
      }
    });
    setRequest_detail(request_detail);

    setCAddress1(companyAddress1);
    setCAddress2(companyAddress2);
    setCCity(companyCity);
    setCName(companyName);
    setCState(companyState);
    setCZip(companyZip);
    setCZip(companyZip);
    setPrimaryContact(primaryFirstName);
    setPcLname(primaryLastName);
    setPcPhoneType(primaryPhone1Type);
    setPcPhone(primaryPhone1);
    setPcPhoneType2(primaryPhone2Type);
    setPcPhone2(primaryPhone2);
    setPcPhoneType3(primaryPhone3Type);
    setPcPhone3(primaryPhone3);
    setPcEmailType(primaryEmail1Type);
    setPcEmail(primaryEmail1);
    setPcEmailType2(primaryEmail2Type);
    setPcEmail2(primaryEmail2);
    setPcEmailType3(primaryEmail3Type);
    setPcEmail3(primaryEmail3);
    setPcAddress1(primaryAddress1);
    setPcAddress2(primaryAddress2);
    setPcCity(primaryCity);
    setPcState(primaryState);
    setPcZip(`${primaryZip}`);
    setScRelationship(secondaryRelationship);
    setScFname(secondaryFirstName);
    setScLname(secondaryLastName);
    setScPhoneType(secondaryPhone1Type);
    setScPhone(secondaryPhone1);
    setScPhoneType2(secondaryPhone2Type);
    setScPhone2(secondaryPhone2);
    setScEmailType(secondaryEmail1Type);
    setScEmail(secondaryEmail1);
    setScEmailType2(secondaryEmail2Type);
    setScEmail2(secondaryEmail2);
    setScAddress1(secondaryAddress1);
    setScAddress2(secondaryAddress2);
    setScCity(secondaryCity);
    setScState(secondaryState);
    setScZip(secondaryZip);
    setLeadSource(leadSource);
    setLeadSourceText(leadSourceText);
    setPName(propertyName);
    setPAddress1(propertyAddress1);
    setPAddress2(propertyAddress2);
    setPCity(propertyCity);
    setPState(propertyState);
    setPZip(`${propertyZip}`);
    setPType(propertyType);
    setCoopUnits(coopUnits);
    setPNotes(propertyNotes);
    setShowEmail2(primaryEmail2 !== "" ? true : false);
    setShowEmail3(primaryEmail3 !== "" ? true : false);
    setShowPhone2(primaryPhone2 !== "" ? true : false);
    setShowPhone3(primaryPhone3 !== "" ? true : false);
    setScEmail2Show(secondaryEmail2 !== "" ? true : false);
    setScPhone2Show(secondaryPhone2 !== "" ? true : false);
    setShowSecondaryContactInfo(secondaryFirstName !== "" ? true : false);
    setEditLead(true);
    setEditLeadId(leadId);
    scrollToTop();
  };

  const displaySrequestText = (
    doNewInstallation,
    doRepair,
    newInstallationFor,
    repairFor,
    repairForOther,
    newInstallationForOther,
    res,
    leadIdStore
  ) => {
    // const { newInstallations, newRepairs } = state;
    let text = ``;
    res.requests.forEach((rating) => {
      console.log({ rating });
      leadIdStore.push(rating._id);
      setAllLeadStore(leadIdStore);
      if (rating.isRepairRequest) {
        //  if (doRepair) {
        text = `${text ? `${text}\n` : ""}Repair `;

        if (newRepairs.length) {
          let newText = ``;
          const res = newRepairs.filter((repair) =>
            rating.requestFor.includes(repair.repairTypeId)
          );
          res.map(
            (repair, i) =>
              (newText = `${newText}${
                i === 0
                  ? `${repair.repairTypeName}`
                  : `, ${repair.repairTypeName}`
              }`)
          );
          text = `${text}${newText}`;
        }
        if (rating.requestForOther) {
          return (text = `${text ? `${text}: ` : text}${
            rating.requestForOther
          }`);
        }
        // }
      } else {
        // if (doNewInstallation) {
        text = "New ";
        if (rating.requestForOther) {
          return (text = `${text}${rating.requestForOther}`);
        } else {
          if (newInstallations.length) {
            let newText = ``;
            const res = newInstallations.filter((newInstallation) =>
              rating.requestFor.includes(newInstallation.newInstallTypeId)
            );
            res.map(
              (newInstallation, i) =>
                (newText = `${newText}${
                  i === 0
                    ? `${newInstallation.newInstallTypeName}`
                    : `, ${newInstallation.newInstallTypeName}`
                }`)
            );
            text = `${text}${newText}`;
          }
        }
        // }
      }
    });
    return text;
  };

  const {
    staticAll,
    saveLead,
    leads,
    deletingLead: { deleteSuccess, deleteErrors },
    editLead,
    schedule,
    propertyTypess,
    tasksRate,
  } = props;

  //WARNING: PROBLEM FRONT END IMPLEMENTATION FOR BACKEND ISSUES
  const checkIfCoop = (pType) => {
    const searchId = propertyTypess.propertydata.filter((value) => {
      if (value.roleType === "Condo/Coop" && value._id === pType) {
        return true;
      }
      return false;
    });

    return searchId.length > 0;
  };

  useEffect(() => {
    if (pcPhoneType === "") {
      resetDropDown("pcPhone");
    }
  }, [pcPhoneType]);

  useEffect(() => {
    if (pcPhoneType2 === "") {
      resetDropDown("pcPhone2");
    }
  }, [pcPhoneType2]);

  useEffect(() => {
    if (pcPhoneType3 === "") {
      resetDropDown("pcPhone3");
    }
  }, [pcPhoneType3]);
  useEffect(() => {
    if (pcEmailType === "") {
      resetDropDown("pcEmail");
    }
  }, [pcEmailType]);
  useEffect(() => {
    if (pcEmailType2 === "") {
      resetDropDown("pcEmail2");
    }
  }, [pcEmailType2]);

  useEffect(() => {
    if (pcEmailType3 === "") {
      resetDropDown("pcEmail3");
    }
  }, [pcEmailType3]);

  useEffect(() => {
    if (scRelationship === "") {
      resetDropDown("scFname");
      resetDropDown("scLname");
    }
  }, [scRelationship]);

  useEffect(() => {
    if (scPhoneType === "") {
      resetDropDown("scPhone");
    }
  }, [scPhoneType]);

  useEffect(() => {
    if (scPhoneType2 === "") {
      resetDropDown("scPhone2");
    }
  }, [scPhoneType2]);

  useEffect(() => {
    if (scPhoneType3 === "") {
      resetDropDown("scPhone3");
    }
  }, [scPhoneType3]);

  useEffect(() => {
    if (scEmailType === "") {
      resetDropDown("scEmail");
    }
  }, [scEmailType]);
  useEffect(() => {
    if (scEmailType2 === "") {
      resetDropDown("scEmail2");
    }
  }, [scEmailType2]);

  useEffect(() => {
    if (scEmailType3 === "") {
      resetDropDown("scEmail3");
    }
  }, [scEmailType3]);

  useEffect(() => {
    if (siteContactRelationship === "") {
      resetDropDown("siteContactFname");
      resetDropDown("siteContactLname");
    }
  }, [siteContactRelationship]);

  useEffect(() => {
    if (siteContactPhoneType === "") {
      resetDropDown("siteContactPhone");
    }
  }, [siteContactPhoneType]);

  useEffect(() => {
    if (siteContactPhoneType2 === "") {
      resetDropDown("siteContactPhone2");
    }
  }, [siteContactPhoneType2]);

  useEffect(() => {
    if (siteContactPhoneType3 === "") {
      resetDropDown("siteContactPhone3");
    }
  }, [siteContactPhoneType3]);

  useEffect(() => {
    if (siteContactEmailType === "") {
      resetDropDown("siteContactEmail");
    }
  }, [siteContactEmailType]);

  useEffect(() => {
    if (siteContactEmailType2 === "") {
      resetDropDown("siteContactEmail2");
    }
  }, [siteContactEmailType2]);

  useEffect(() => {
    if (siteContactEmailType3 === "") {
      resetDropDown("siteContactEmail2");
    }
  }, [siteContactEmailType3]);
  useEffect(() => {
    if (
      originalWorkPerformed === "false" ||
      originalWorkPerformed === false ||
      originalWorkPerformed === ""
    ) {
      setIsUnderWarrenty("");
      setWorkPerformedYear("");
    }
  }, [originalWorkPerformed]);

  useEffect(() => {
    if (isUnderWarrenty === "true" || isUnderWarrenty === true) {
      setNrTotal(0);
      setTaxRate("");
      setRepairServicePrice(0);
      setServiceChargePolicy(false);
    }
  }, [isUnderWarrenty]);

  useEffect(() => {
    if (leads?.leadsData) {
      let data = [];
      let count = 0;
      let leadIdStore = [];
      console.log("leadIdStore", leadIdStore);

      leads.leadsData.map((lead, i) => {
        if (lead.property_list.length > 0) {
          setLeadProprtyLength(lead.property_list.length);
        }
        lead.property_list.map((res, index) => {
          count = count + 1;
          setPropertyListLentgh(count);
          return data.push({
            id: count,
            address: `${
              lead.primary_data[0].propertyName !== ""
                ? `${lead.primary_data[0].primaryAddress1} ${lead.primary_data[0].primaryAddress2}`
                : `${lead.primary_data[0].primaryAddress1}`
            }`,
            pType: getPtypes(res.property_address[0]?.propertyType),
            sRequest: displaySrequestText(
              res.property_address[0]?.doNewInstallation,
              res.property_address[0]?.doRepair,
              res.property_address[0]?.newInstallationFor,
              res.property_address[0]?.repairFor,
              res.property_address[0]?.repairForOther,
              res.property_address[0]?.newInstallationForOther,
              res,
              leadIdStore
            ),
            actions: (
              <>
                <Button
                  variant="warning"
                  className="edit-btn"
                  onClick={() => onEditLead(lead, res)}
                >
                  Edit
                </Button>
                <Button
                  variant="danger"
                  className="dlt-btn"
                  onClick={() => openDeleteLeadModal(propertyId, res)}
                >
                  Del
                </Button>
              </>
            ),
          });
        });
      });
      setTableData(data);
    }
  }, [leads?.leadsData]);

  useEffect(() => {
    if (leads?.leadsErrors) {
      setDeleteModal(false);
      setErrType("danger");
      setErrMsg(leads.leadsErrors);
      dismissMessage();
    }
  }, [leads?.leadsErrors]);

  useEffect(() => {
    if (schedule?.schedule) {
      setDeleteModal(false);
      setErrType("danger");
      setErrMsg(schedule.errorsSchedule);
      dismissMessage();
    }
  }, [schedule?.schedule]);
  const successSchedule = useMemo(
    () => schedule?.successSchedule,
    [schedule?.successSchedule]
  );
  useEffect(() => {
    if (successSchedule) {
      setErrType("success");
      setErrMsg("You will be soon redirected to calendar screen.");

      setTimeout(() => props.history.push("/dashboard/calendar"), 3000);
      dispatch(scheduleLeadStop());
    }
  }, [successSchedule]);
  useEffect(() => {
    if (staticAll?.data) {
      const { data } = props.staticAll;
      setPhoneTypes(data["phoneType"]);
      setEmailTypes(data["emailType"]);
      setPropertyTypes(data["propertyType"]);
      setNewInstallations(data["newInstallationType"]);
      setNewRepairs(data["repairType"]);
      setLeadSourcesType(data["leadSourceType"]);
      setRelationships(data["relationshipType"]);
    }
  }, [staticAll?.data]);

  useEffect(() => {
    setTaxRates(tasksRate?.taskratedata);
  }, [tasksRate]);

  console.log({
    taxRates
  });
  
  useEffect(() => {
    if (saveLead?.saveLeaderrors) {
      setErrType("danger");
      setErrMsg(saveLead.saveLeaderrors);

      dismissMessage();
    }
  }, [saveLead?.saveLeaderrors]);

  useEffect(() => {
    if (deleteSuccess) {
      setErrType("success");
      setErrMsg(deleteSuccess);
      setDeleteModal(false);

      dismissMessage();
      props.FetchLeads();
      setLeadProprtyLength(0);
    }
  }, [deleteSuccess]);

  useEffect(() => {
    if (deleteErrors) {
      setErrType("danger");
      setErrMsg(deleteErrors);
      setDeleteModal(false);

      dismissMessage();
    }
  }, [deleteErrors]);

  useEffect(() => {
    if (saveLead?.saveLeadsuccess) {
      setErrType("success");
      setErrMsg(saveLead.saveLeadsuccess);

      setTimeout(() => clearAfterSavingToList(), 2000);
      dismissMessage();
      props.FetchLeads();
      dispatch(SaveNewLeadRemove());
    }
  }, [saveLead?.saveLeadsuccess]);

  useEffect(() => {
    if (editLead?.editLeadsuccess) {
      setErrType("success");
      setErrMsg(editLead.editLeadsuccess);

      dismissMessage();
      props.FetchLeads();
      onCancelEdit();
    }
  }, [editLead?.editLeadsuccess]);

  useEffect(() => {
    if (editLead?.editLeaderrors) {
      setErrType("danger");
      setErrMsg(editLead.editLeaderrors);

      dismissMessage();
      props.FetchLeads;
    }
  }, [editLead?.editLeaderrors]);

  useEffect(() => {
    if (errMsg && !showServiceRequestModal) {
      scrollToTop();
    }
  }, [errMsg, showServiceRequestModal]);

  useEffect(() => {
    if (primaryAddressChecked) {
      setPAddress1(pcAddress1);
    }
  }, [pcAddress1]);

  useEffect(() => {
    if (primaryAddressChecked) {
      setPAddress2(pcAddress2);
    }
  }, [pcAddress2]);

  useEffect(() => {
    if (primaryAddressChecked) {
      setPCity(pcCity);
    }
  }, [pcCity]);

  useEffect(() => {
    if (primaryAddressChecked) {
      setPState(pcState);
    }
  }, [pcState]);

  useEffect(() => {
    if (primaryAddressChecked) {
      setPCity(pcCity);
      setPState(pcState);
      setPAddress1(pcAddress1);
      setPZip(pcZip);
      setPAddress2(pcAddress2);
    } else {
      setPCity("");
      setPState("");
      setPAddress1("");
      setPZip("");
      setPAddress2("");
    }
  }, [primaryAddressChecked]);

  useEffect(() => {
    console.log({
      companyAddressCheckedUseEffect: companyAddressChecked,
      pcAddress1,
    });
    if (companyAddressChecked) {
      setPcCity(cCity);
      setPcState(cState);
      setPcAddress1(cAddress1);
      setPcZip(cZip);
      setPcAddress2(cAddress2);
    } else {
      setPcCity("");
      setPcState("");
      setPcAddress1("");
      setPcZip("");
      setPcAddress2("");
    }
  }, [companyAddressChecked]);

  useEffect(() => {
    if (!isNaN(Number(repairServicePrice))) {
      setNrTotal(
        Number(repairServicePrice) < 0
          ? 0
          : Number(repairServicePrice) +
              (Number(taxRate) / 100) * Number(repairServicePrice)
      );
    } else {
      setNrTotal(0);
    }
  }, [repairServicePrice, taxRate]);

  useEffect(() => {
    if (!isNaN(Number(repairServicePrice))) {
      setNrTotal(
        Number(repairServicePrice) < 0
          ? 0
          : Number(repairServicePrice) +
              (Number(taxRate) / 100) * Number(repairServicePrice)
      );
    } else {
      setNrTotal(Number(taxRate) / 100);
    }
  }, [repairServicePrice, taxRate]);

  useEffect(() => {
    if (!repair06) {
      setRepair06Name("");
    }
  }, [repair06]);

  // }

  const displayRequests = (requests) => {
    return requests?.map((request) => (
      <button
        id={request.state}
        key={request.state}
        className={`serviceRequestBtn ${
          (newInstallation && request.state.includes("newInstallation")) ||
          (repair && request.state.includes("repair"))
            ? "selected"
            : ""
        }`}
        onClick={() => makeSelection(request.state)}
      >
        {request.name}
      </button>
    ));
  };

  const displayNewInstallationType = (requests) => {
    return requests?.map((request) => (
      <button
        id={request.newInstallTypeId}
        key={request.newInstallTypeId}
        className={`serviceRequestBtn ${
          (sales01 && request.newInstallTypeId.includes("sales01")) ||
          (sales02 && request.newInstallTypeId.includes("sales02"))
            ? "selected"
            : ""
        }`}
        // className={`serviceRequestBtn ${
        //   [request.newInstallTypeId] && "selected"
        // }`}
        onClick={() => makeSelection(request.newInstallTypeId)}
      >
        {request.newInstallTypeName}
      </button>
    ));
  };

  const displayRepairType = (requests) => {
    return requests?.map((request) => (
      <button
        id={request.repairTypeId}
        key={request.repairTypeId}
        className={`serviceRequestBtn ${
          (repair01 && request.repairTypeId.includes("repair01")) ||
          (repair02 && request.repairTypeId.includes("repair02")) ||
          (repair03 && request.repairTypeId.includes("repair03")) ||
          (repair04 && request.repairTypeId.includes("repair04")) ||
          (repair05 && request.repairTypeId.includes("repair05")) ||
          (repair06 && request.repairTypeId.includes("repair06"))
            ? "selected"
            : ""
        }`}
        // className={`serviceRequestBtn ${[request.repairTypeId] && "selected"}`}
        onClick={() => makeSelection(request.repairTypeId)}
      >
        {request.repairTypeName}
      </button>
    ));
  };

  const displayServiceRequestOption = () => {
    // const { newInstallation, repair } = state;
    return (
      <div>
        <BoldText>
          Is this service request for a new installation and/or a repair?
        </BoldText>
        <div style={{ margin: "1em auto", width: "100%" }}>
          {displayRequests(serviceRequests)}
        </div>
        <Modal.Footer>
          <Button variant={"danger"} onClick={onServiceRequestModalCancel}>
            Cancel
          </Button>
          <Button
            id="nextBtn"
            disabled={!newInstallation && !repair}
            onClick={() => setShowNext(true)}
          >
            Next
          </Button>
        </Modal.Footer>
      </div>
    );
  };

  const displayFooter = () => {
    const styles = {
      display: "flex",
    };
    return (
      <Modal.Footer>
        <div style={{ ...styles, width: "100%" }}>
          <div style={{ flex: 1 }}>
            <Button onClick={() => setShowNext(false)}>Back</Button>
          </div>
          <div style={styles}>
            <div style={{ marginRight: "5px" }}>
              <Button
                variant="outline-danger"
                onClick={onServiceRequestModalCancel}
              >
                Cancel
              </Button>
            </div>
            <div style={{ marginLeft: "5px" }}>
              <Button
                variant={"success"}
                disabled={validateServiceRequestModalFields()}
                onClick={() => {
                  saveServiceRequest();
                  getAllFields();
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </Modal.Footer>
    );
  };

  const validateServiceRequestModalFields = () => {
    if (!newInstallation && !repair) {
      return true;
    }

    if (newInstallation && !sales01 && !sales02) {
      return true;
    }

    if (
      repair &&
      !repair01 &&
      !repair02 &&
      !repair03 &&
      !repair04 &&
      !repair05 &&
      !repair06
    ) {
      return true;
    }

    if (repair && repair06 && !repair06Name) {
      return true;
    }

    if (repair && (!originalWorkPerformed || !repairDescription)) {
      return true;
    }

    if (
      repair &&
      (originalWorkPerformed === "true" || originalWorkPerformed === true) &&
      (!WorkPerformedYear || !isUnderWarrenty)
    ) {
      return true;
    }

    if (
      repair &&
      (originalWorkPerformed === "false" ||
        originalWorkPerformed === false ||
        isUnderWarrenty === "false" ||
        isUnderWarrenty === false) &&
      (!nrTotal || !taxRate || !repairServicePrice || !serviceChargePolicy)
    ) {
      return true;
    }

    return false;
  };

  const validateServiceRequestFields = () => {
    if (
      repair &&
      (originalWorkPerformed === "true" || originalWorkPerformed === true) &&
      WorkPerformedYear < 0
    ) {
      setErrMsg("Year cannot be negative value.");
      setErrType("warning");
      setErrFor("sr-modal");

      return false;
    } else if (
      repair &&
      (originalWorkPerformed === "true" || originalWorkPerformed === true) &&
      !validator.isNumeric(WorkPerformedYear)
    ) {
      setErrMsg("A valid year is required.");
      setErrType("warning");
      setErrFor("sr-modal");
      setErrAt("WorkPerformedYear");

      return false;
    } else if (
      repair &&
      (originalWorkPerformed === "true" || originalWorkPerformed === true) &&
      validator.isNumeric(WorkPerformedYear) &&
      WorkPerformedYear === "0000"
    ) {
      setErrMsg("A valid year is required.");
      setErrType("warning");
      setErrFor("sr-modal");
      setErrAt("WorkPerformedYear");

      return false;
    } else if (
      repair &&
      (originalWorkPerformed === "true" || originalWorkPerformed === true) &&
      WorkPerformedYear.length < 4
    ) {
      setErrMsg("A valid year is required.");
      setErrType("warning");
      setErrFor("sr-modal");
      setErrAt("WorkPerformedYear");

      return false;
    } else if (repair && repairServicePrice !== "" && repairServicePrice < 0) {
      setErrMsg("Repair/Service Price cannot be negative value.");
      setErrType("warning");
      setErrFor("sr-modal");
      setErrAt("repairServicePrice");

      return false;
    } else {
      setErrMsg("");
      setErrType("");
      setErrFor("");
      setErrAt("");
      onHide();
      return true;
    }
  };

  const saveServiceRequest = () => {
    if (!newInstallation) {
      setSales01(false);
      setSales02(false);
    }
    if (!repair) {
      setRepair01(false);
      setRepair02(false);
      setRepair03(false);
      setRepair04(false);
      setRepair05(false);
      setRepair06(false);
      setRepair06Name("");
      setOriginalWorkPerformed("");
      setWorkPerformedYear("");
      setIsUnderWarrenty("");
      setRepairServicePrice(0);
      setTaxRate("");
      setNrTotal(0);
      setRepairDescription("");
      setServiceChargePolicy(false);
    }
    validateServiceRequestFields();
  };

  const openDeleteLeadModal = (id, res) => {
    console.log({ res });
    setPropertyId(res._id);
    let request_id = [];
    setDeleteModal(true);
    setLead_id(id);
    res.requests.forEach((data) => {
      request_id.push(data._id);
    });
    setRequestId(request_id);
    console.log("request_id", request_id);
  };

  const onDeleteModalHide = () => {
    setDeleteModal(false);
    setLead_id("");
  };

  const DeleteLeadModal = () => {
    const {
      DeleteLead,
      deletingLead: { deleting },
    } = props;
    return (
      <PopupModal
        show={deleteModal}
        heading="Proceed with the deletion of this property?"
        onHide={onDeleteModalHide}
        bold={true}
      >
        <p style={{ fontSize: "13px" }}>
          <i>Note:</i>{" "}
          <b>
            Pressing 'YES' will permanently delete this property, but it can be
            reentered.
          </b>
        </p>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={onDeleteModalHide}>
            No
          </Button>
          <Button
            variant="outline-success"
            disabled={deleting}
            onClick={() =>
              DeleteLead({
                leadAndPropertyId: propertyId,
                leadRequestId: requestId,
              })
            }
          >
            {deleting ? "Deleting..." : "Yes"}
          </Button>
        </Modal.Footer>
      </PopupModal>
    );
  };

  const clearMessage = () => {
    setErrAt("");
    setErrMsg("");
    setErrType("warning");
    setErrFor("");
  };

  const dismissMessage = () => setTimeout(() => clearMessage(), 30000);

  const displayNewInstallationOption = () => {
    return (
      <div>
        <BoldText>New Installation for:</BoldText>
        <div style={{ margin: "1em auto", width: "100%" }}>
          {displayNewInstallationType(newInstallations)}
        </div>
      </div>
    );
  };

  const checkIsUnderWarrenty = (originalWorkPerformed, isUnderWarrenty) => {
    if (
      (originalWorkPerformed === "true" || originalWorkPerformed === true) &&
      (isUnderWarrenty === true || isUnderWarrenty === "true")
    ) {
      return false;
    }
    return true;
  };

  const handleServicePriceChange = (event) =>
    setRepairServicePrice(event.target.value.replace("$", ""));

  const displayNewRepairOptions = () => {
    return (
      <div>
        <BoldText>New Repair is for:</BoldText>
        <div style={{ margin: "1em auto", width: "100%" }}>
          {displayRepairType(newRepairs)}

          <Row>
            <Col md="12" sm="12">
              {repair06 && (
                <Row>
                  <Col md="6" sm="6">
                    <Form.Group style={styles.formInput}>
                      <Form.Control
                        type="text"
                        name="repair06Name"
                        placeholder="Enter Other"
                        value={repair06Name}
                        onChange={(e) => setRepair06Name(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              )}
              <Row>
                <Col sm="8" md="6">
                  <Form.Group style={styles.formInput}>
                    <Form.Label>Did we perform original work?</Form.Label>
                    <Form.Control
                      as="select"
                      name="originalWorkPerformed"
                      value={originalWorkPerformed}
                      onChange={(e) => setOriginalWorkPerformed(e.target.value)}
                    >
                      <option value="">Choose...</option>
                      {displayYesNo(YesNo)}
                    </Form.Control>
                  </Form.Group>
                </Col>
                {(originalWorkPerformed === "true" ||
                  originalWorkPerformed === true) && (
                  <>
                    <Col sm="3" md="3">
                      <Form.Group style={styles.formInput}>
                        <Form.Label>Year</Form.Label>
                        <Form.Control
                          type="text"
                          name="WorkPerformedYear"
                          value={WorkPerformedYear}
                          onChange={(e) => setWorkPerformedYear(e.target.value)}
                          maxLength={4}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm="6" md="3">
                      <Form.Group style={styles.formInput}>
                        <Form.Label>Is it under Warranty?</Form.Label>
                        <Form.Control
                          as="select"
                          name="isUnderWarrenty"
                          value={isUnderWarrenty}
                          onChange={(e) => setIsUnderWarrenty(e.target.value)}
                        >
                          <option value="">Choose...</option>
                          {displayYesNo(YesNo)}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </>
                )}
              </Row>

              {checkIsUnderWarrenty(originalWorkPerformed, isUnderWarrenty) && (
                <Row>
                  <Col sm="6" md="6">
                    <Form.Group style={styles.formInput}>
                      <Form.Label>Repair/Service Price</Form.Label>
                      <Form.Control
                        type="text"
                        name="repairServicePrice"
                        placeholder="Pre Tax $"
                        value={`$${repairServicePrice}`}
                        onChange={(e) => handleServicePriceChange(e)}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="6" md="3">
                    <Form.Group style={styles.formInput}>
                      <Form.Label>Tax Rate</Form.Label>
                      <Form.Control
                        as="select"
                        name="taxRate"
                        value={taxRate}
                        onChange={(e) => setTaxRate(e.target.value)}
                      >
                        <option value="">Choose...</option>
                        {taxRates.length > 0 && displayTaxRate()}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col sm="6" md="3">
                    <Form.Group style={styles.formInput}>
                      <Form.Label>Total</Form.Label>
                      <Form.Control
                        type="text"
                        value={`$${nrTotal}`}
                        disabled={true}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              )}

              <Row>
                <Col>
                  <Form.Group style={styles.formInput}>
                    <Form.Label>Description of Repair</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows="2"
                      name="repairDescription"
                      value={repairDescription}
                      onChange={(e) => setRepairDescription(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>

              {checkIsUnderWarrenty(originalWorkPerformed, isUnderWarrenty) && (
                <Row>
                  <Col>
                    <BoldText>
                      <Form.Group>
                        <Form.Check
                          name="serviceChargePolicy"
                          checked={serviceChargePolicy}
                          onChange={(e) =>
                            setServiceChargePolicy(e.target.checked)
                          }
                          label="I have explained and customer agrees to the Service Charge Policy."
                        />
                      </Form.Group>
                    </BoldText>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  const onServiceRequestModalCancel = () => {
    setNewInstallation(false);
    setRepair(false);
    setShowNext(false);
    setSales01(false);
    setSales02(false);
    setRepair01(false);
    setRepair02(false);
    setRepair03(false);
    setRepair04(false);
    setRepair05(false);
    setRepair06(false);
    setRepair06("");
    setOriginalWorkPerformed("");
    setWorkPerformedYear("");
    setIsUnderWarrenty("");
    setRepairServicePrice("");
    setTaxRate("");
    setNrTotal(0);
    setRepairDescription("");
    setServiceChargePolicy(false);
    onHide();
  };

  const showLeadSourceText = (leadSourcesType, leadSource) => {
    if (leadSource !== "") {
      const index = leadSourcesType.findIndex(
        (leadItem) => leadItem._id === leadSource
      );
      return index > -1 ? leadSourcesType[index].leadSourceText : false;
    }
    return false;
  };

  const onCoopModalHide = () => setShowCoopModal(false);

  const addBuldings = (value) => {
    setCoopUnits({
      ...coopUnits,
      [value.trim()]: {
        editBtn: false,
        deleteModal: false,
      },
    });
    setNewBuildingName("");
    setEditBtn(false);
    setDeleteModal(false);
  };

  const deleteBuilding = (unit) => {
    let CoopUnits = coopUnits;
    CoopUnits[unit] = undefined;
    delete CoopUnits[unit];
    setCoopUnits(CoopUnits);
    setShowCoopModal(true);
  };

  const onEditBuilding = (unit, value = true) => {
    disableOtherBuildingsEditBtn();
    let CoopUnits = coopUnits;
    CoopUnits[unit].editBtn = value;
    setCoopUnits(CoopUnits);
    setTempNewBuildingName(unit);
    setIsCancel(value);
  };

  const saveEditBuilding = (unit) => {
    deleteBuilding(unit);
    return addBuldings(tempNewBuildingName);
  };

  const onDeleteBuilding = (unit, value = true) => {
    let CoopUnits = coopUnits;
    CoopUnits[unit].deleteModal = value;
    setCoopUnits(CoopUnits);
    setShowCoopModal(!value);
  };

  const disableOtherBuildingsEditBtn = () => {
    Object.keys(coopUnits)?.map((unit) => {
      let CoopUnits = coopUnits;
      CoopUnits[unit].editBtn = false;
      setCoopUnits(CoopUnits);
    });
  };

  const AddIntoArray = (array, item, itemName) => {
    if (item) {
      array.push(itemName);
    }
  };

  const getAllFields = () => {
    const phoneCode = "+1";

    let newInstallationFor = [];
    let repairFor = [];

    if (sales01) {
      newInstallationFor.push("sales01");
    }

    if (sales02) {
      newInstallationFor.push("sales02");
    }

    AddIntoArray(repairFor, repair01, "repair01");
    AddIntoArray(repairFor, repair02, "repair02");
    AddIntoArray(repairFor, repair03, "repair03");
    AddIntoArray(repairFor, repair04, "repair04");
    AddIntoArray(repairFor, repair05, "repair05");
    AddIntoArray(repairFor, repair06, "repair06");

    const allData = {
      companyAddress1: cAddress1,
      companyAddress2: cAddress2,
      companyCity: cCity,
      companyName: cName,
      companyState: cState,
      companyZip: cZip === null ? null : cZip,
      primaryFirstName: primaryContact.pcFname,
      primaryLastName: pcLname,
      primaryPhone1Type: pcPhoneType,
      primaryPhone1Code: phoneCode,
      primaryPhone1: pcPhone,
      primaryPhone2Type: pcPhoneType2,
      primaryPhone2Code: phoneCode,
      primaryPhone2: pcPhone2,
      primaryPhone3Type: pcPhoneType3,
      primaryPhone3Code: phoneCode,
      primaryPhone3: pcPhone3,
      primaryEmail1Type: pcEmailType,
      primaryEmail1: pcEmail,
      primaryEmail2Type: pcEmailType2,
      primaryEmail2: pcEmail2,
      primaryEmail3Type: pcEmailType3,
      primaryEmail3: pcEmail3,
      primaryAddress1: pcAddress1,
      primaryAddress2: pcAddress2,
      primaryCity: pcCity,
      primaryState: pcState,
      primaryZip: pcZip,
      secondaryRelationship: scRelationship,
      secondaryFirstName: scFname,
      secondaryLastName: scLname,
      secondaryPhone1Type: scPhoneType,
      secondaryPhone1Code: phoneCode,
      secondaryPhone1: scPhone,
      secondaryPhone2Code: phoneCode,
      secondaryPhone2Type: scPhoneType2,
      secondaryPhone2: scPhone2,

      secondaryEmail1Type: scEmailType,
      secondaryEmail1: scEmail,
      secondaryEmail2Type: scEmailType2,
      secondaryEmail2: scEmail2,

      secondaryAddress1: scAddress1,
      secondaryAddress2: scAddress2,
      secondaryCity: scCity,
      secondaryState: scState,
      secondaryZip: scZip,
      leadSource,
      leadSourceText,
      propertyContactRelationship: siteContactRelationship,
      propertyContactFirstName: siteContactFname,
      propertyContactLastName: siteContactLname,
      propertyContactPhone1Type: siteContactPhoneType,
      propertyContactPhone1Code: phoneCode,
      propertyContactPhone1: siteContactPhone,
      propertyContactPhone2Type: siteContactPhoneType2,
      propertyContactPhone2Code: phoneCode,
      propertyContactPhone2: siteContactPhone2,

      propertyContactEmail1Type: siteContactEmailType,
      propertyContactEmail1: siteContactEmail,
      propertyContactEmail2Type: siteContactEmailType2,
      propertyContactEmail2: siteContactEmail2,
      propertyAddressSameAsPrimaryAddress: primaryAddressChecked,
      propertyAddress1: pAddress1,
      propertyName: pName,
      propertyAddress2: pAddress2,
      propertyCity: pCity,
      propertyState: pState,
      propertyZip: pZip,
      propertyType: pType,
      propertyBuildings: Object.keys(coopUnits),
      propertyNotes: pNotes,
      doNewInstallation: newInstallation,
      newInstallationFor: newInstallationFor,
      newInstallationForOther: "",
      doRepair: repair,
      repairFor,
      repairForOther: repair06Name,
      originalWorkDone:
        originalWorkPerformed === "" ? false : originalWorkPerformed,
      originalWorkYear: WorkPerformedYear,
      underWarrenty: isUnderWarrenty === "" ? false : isUnderWarrenty,
      servicePrice: `${
        repairServicePrice === 0 ? "" : `$${repairServicePrice}`
      }`,
      taxRate: taxRate,
      totalPrice: `${nrTotal === 0 ? "" : `$${nrTotal}`}`,
      repairDescreption: repairDescription,
    };

    return allData;
  };
  const editLeadsAllData = () => {
    const phoneCode = "+1";

    let newInstallationFor = [];
    let repairFor = [];

    if (sales01) {
      newInstallationFor.push("sales01");
    }

    if (sales02) {
      newInstallationFor.push("sales02");
    }
    AddIntoArray(repairFor, repair01, "repair01");
    AddIntoArray(repairFor, repair02, "repair02");
    AddIntoArray(repairFor, repair03, "repair03");
    AddIntoArray(repairFor, repair04, "repair04");
    AddIntoArray(repairFor, repair05, "repair05");
    AddIntoArray(repairFor, repair06, "repair06");

    const allData = {
      lead: {
        leadId: _id,
        address: {
          companyAddress1: cAddress1,
          companyAddress2: cAddress2,
          companyCity: cCity,
          companyName: cName,
          companyState: cState,
          companyZip: cZip == null ? null : cZip,
          companyZip: cZip === null ? null : cZip,
          primaryFirstName: primaryContact.pcFname,
          primaryLastName: pcLname,
          primaryPhone1Type: pcPhoneType,
          primaryPhone1Code: phoneCode,
          primaryPhone1: pcPhone,
          primaryPhone2Type: pcPhoneType2,
          primaryPhone2Code: phoneCode,
          primaryPhone2: pcPhone2,
          primaryPhone3Type: pcPhoneType3,
          primaryPhone3Code: phoneCode,
          primaryPhone3: pcPhone3,
          primaryEmail1Type: pcEmailType,
          primaryEmail1: pcEmail,
          primaryEmail2Type: pcEmailType2,
          primaryEmail2: pcEmail2,
          primaryEmail3Type: pcEmailType3,
          primaryEmail3: pcEmail3,
          primaryAddress1: pcAddress1,
          primaryAddress2: pcAddress2,
          primaryCity: pcCity,
          primaryState: pcState,
          primaryZip: pcZip,
          secondaryRelationship: scRelationship,
          secondaryFirstName: scFname,
          secondaryLastName: scLname,
          secondaryPhone1Type: scPhoneType === "" ? null : scPhoneType,
          secondaryPhone1Code: phoneCode,
          secondaryPhone1: scPhone,
          secondaryPhone2Code: phoneCode,
          secondaryPhone2Type: scPhoneType2,
          secondaryPhone2: scPhone2,

          secondaryEmail1Type: scEmailType,
          secondaryEmail1: scEmail,
          secondaryEmail2Type: scEmailType2,
          secondaryEmail2: scEmail2,

          secondaryAddress1: scAddress1,
          secondaryAddress2: scAddress2,
          secondaryCity: scCity,
          secondaryState: scState,
          secondaryZip: scZip == null ? null : scZip,
          secondaryZip: scZip === null ? null : scZip,
        },
      },
      property: {
        propertyId: propertyId,
        address: {
          leadSource,
          leadSourceText,
          propertyContactRelationship: siteContactRelationship,
          propertyContactFirstName: siteContactFname,
          propertyContactLastName: siteContactLname,
          propertyContactPhone1Type: siteContactPhoneType,
          propertyContactPhone1Code: phoneCode,
          propertyContactPhone1: siteContactPhone,
          propertyContactPhone2Type: siteContactPhoneType2,
          propertyContactPhone2Code: phoneCode,
          propertyContactPhone2: siteContactPhone2,

          propertyContactEmail1Type: siteContactEmailType,
          propertyContactEmail1: siteContactEmail,
          propertyContactEmail2Type: siteContactEmailType2,
          propertyContactEmail2: siteContactEmail2,
          propertyAddressSameAsPrimaryAddress: primaryAddressChecked,
          propertyAddress1: pAddress1,
          propertyName: pName,
          propertyAddress2: pAddress2,
          propertyCity: pCity,
          propertyState: pState,
          propertyZip: pZip,
          propertyType: pType,
          propertyBuildings: Object.keys(coopUnits),
          propertyNotes: pNotes,
        },
      },
      requests: {
        primaryFirstName: primaryContact.pcFname,
        primaryLastName: pcLname,
        requestId: request_detail,
        doNewInstallation: newInstallation,
        newInstallationFor: newInstallationFor,
        newInstallationForOther: "",
        doRepair: repair,
        repairFor,
        repairForOther: repair06Name,
        originalWorkDone:
          originalWorkPerformed === "" ? false : originalWorkPerformed,
        originalWorkYear: WorkPerformedYear,
        underWarrenty: isUnderWarrenty === "" ? false : isUnderWarrenty,
        servicePrice: `${
          repairServicePrice === 0 ? "" : `$${repairServicePrice}`
        }`,
        taxRate: taxRate,
        totalPrice: `${nrTotal === 0 ? "" : `$${nrTotal}`}`,
        repairDescreption: repairDescription,
      },
    };

    return allData;
  };

  const propertyListData = () => {
    let newInstallationFor = [];
    let repairFor = [];

    if (sales01) {
      newInstallationFor.push("sales01");
    }

    if (sales02) {
      newInstallationFor.push("sales02");
    }

    AddIntoArray(repairFor, repair01, "repair01");
    AddIntoArray(repairFor, repair02, "repair02");
    AddIntoArray(repairFor, repair03, "repair03");
    AddIntoArray(repairFor, repair04, "repair04");
    AddIntoArray(repairFor, repair05, "repair05");
    AddIntoArray(repairFor, repair06, "repair06");

    const allData = {
      propertyAddressSameAsPrimaryAddress: primaryAddressChecked,
      propertyAddress1: pAddress1,
      propertyName: pName,
      propertyAddress2: pAddress2,
      propertyCity: pCity,
      propertyState: pState,
      propertyZip: pZip,
      propertyType: pType,
      propertyBuildings: Object.keys(coopUnits),
      propertyNotes: pNotes,
      doNewInstallation: newInstallation,
      newInstallationFor: newInstallationFor,
      newInstallationForOther: "",
      doRepair: repair,
      repairFor,
      repairForOther: repair06Name,
      originalWorkDone:
        originalWorkPerformed === "" ? false : originalWorkPerformed,
      originalWorkYear: WorkPerformedYear,
      underWarrenty: isUnderWarrenty === "" ? false : isUnderWarrenty,
      servicePrice: `${
        repairServicePrice === 0 ? "" : `$${repairServicePrice}`
      }`,
      taxRate: taxRate,
      totalPrice: `${nrTotal === 0 ? "" : `$${nrTotal}`}`,
      repairDescreption: repairDescription,
    };

    return allData;
  };


  console.log({
    ppppppp: propertyListData()
  });

  const clearAfterSavingToList = () => {
    // setState(() => ({
    setPName("");
    setPCity("");
    setPState("");
    setPZip("");
    setPAddress1("");
    setPAddress2("");
    setPType("");
    setPNotes("");
    setSiteContactRelationship("");
    setSiteContactFname("");
    setSiteContactLname("");
    setSiteContactPhoneType("");
    setSiteContactPhone("");
    setSiteContactPhone2Show(false);
    setSiteContactPhoneType2("");
    setSiteContactPhone2("");
    setSiteContactPhone3Show(false);
    setSiteContactPhoneType3("");
    setSiteContactPhone3("");
    setSiteContactEmailType("");
    setSiteContactEmail("");
    setSiteContactEmail2Show(false);
    setSiteContactEmailType2("");
    setSiteContactEmail2("");
    setSiteContactEmail3Show(false);
    setSiteContactEmailType3("");
    setSiteContactEmail3("");
    setShowServiceRequest(false);
    // setSrimaryAddressChecked(false);
    setShowSiteContactInfo(false);
    setShowServiceRequestModal(false);
    setNewInstallation(false);
    setRepair(false);
    setShowNext(false);
    setSales01(false);
    setSales02(false);
    setRepair01(false);
    setRepair02(false);
    setRepair03(false);
    setRepair04(false);
    setRepair05(false);
    setRepair06(false);
    setRepair06Name("");
    setOriginalWorkPerformed("");
    setWorkPerformedYear("");
    setIsUnderWarrenty("");
    setRepairServicePrice("");
    setTaxRate("");
    setNrTotal(0);
    setRepairDescription("");
    setServiceChargePolicy(false);
    setShowCoopModal(false);
    setCoopUnits({});
    setNewBuildingName("");
    setTempNewBuildingName("");
    setErrFor("");
    // }));
  };

  const handleSubmit = () => {
    if (isValidated()) {
      !isPrimaryDetailsPresent
        ? props.SaveNewLead({ ...getAllFields() })
        : props.SaveNewLead({
            leadAddressId: addressId,
            primaryFirstName: primaryFirstName,
            primaryLastName: primaryLastName,
            ...propertyListData(),
          });
    }
    dismissMessage();
  };

  const handleEditing = () => {
    if (isValidated()) {
      props.EditLead({
        // lead_id: editLeadId,
        // currentStatus: currentStatus,
        // ...getAllFields(),
        ...editLeadsAllData(),
      });
    }
    dismissMessage();
  };

  const resetDropDown = (name) =>
    setState((prevState) => ({ ...prevState, [name]: "" }));
  // setState(() => ({ [name]: "" }));

  const displayTable = () => {
    const { leadsLoading, leadsData, leadsErrors } = props.leads;

    if (leadsLoading || leadsErrors) {
      return <InitialLoader error={leadsErrors} onReload={loadData} />;
    }

    if (leadsData && leadsData.length > 0 && leadProprtyLength > 0) {
      return (
        <>
          <h5 style={{ padding: "25px 0" }}>
            Property List: ({propertyListLentgh})
          </h5>
          <div style={{ width: "100%", overflowX: "auto" }}>
            <DisplayTable
              headerWrapperClasse="thead"
              bodyClasses="_body_table1"
              data={tableData}
              columns={columns}
            />
          </div>
        </>
      );
    }
  };
  const onCancelEdit = () => {
    setEditLead(false);
    setEditLeadId("");
    clearAfterSavingToList();
  };

  const handlOnCLickScheduling = () => {
    props.ScheduleLead(allLeadStore);
  };
  const {
    saveLead: { saveLeadLoading },
    editLead: { editLeadLoading },
    leads: { leadsData },
    schedule: { schedulling },
    paymentStatus,
  } = props;

  return (
    <Container
      fluid="true"
      className="p-3"
      style={styles.container}
      block="true"
    >
      <Row>
        <Col md="12">
          <Row style={styles.header}>
            <Col md="4">
              <h4 className="mt-4 mb-3">
                <strong>
                  New Lead &nbsp; &nbsp; &nbsp;
                  <Link
                    to={{
                      pathname: "/dashboard/help",
                      search: "Dashboard New Lead",
                      hash: "#the-hash",
                      state: "Dashboard New Lead",
                    }}
                    target="_blank"
                    rel="noreferrer"
                    style={{ cursor: "pointer" }}
                  >
                    <span style={{ color: "red" }}>?</span>
                  </Link>
                </strong>
              </h4>
            </Col>
            <Col md="8 text-end">
              <BoldText
                onClick={toggleCompanyFields}
                style={{
                  color: Color.primaryColor,
                  float: "right",
                  cursor: "pointer",
                  position: "relative",
                  top: "4.2rem",
                }}
              >
                {showCompanyFields ? `Hide ` : `Show `}Company Fields
              </BoldText>
              {/* </Button> */}
            </Col>
          </Row>
        </Col>
        <Col style={newleadPointerEvent ? {} : { pointerEvents: "none" }}>
          <Col>
            <Form onSubmit={onSubmit} style={styles.formStyle}>
              {/* <div style={{ width: "99%", textAlign: "right" }}>
                <RequiredText>* Required</RequiredText>
              </div> */}
              <Row style={{ margin: "0 auto" }}>
                <Col xs="12">
                  {errMsg &&
                    !showServiceRequestModal &&
                    errFor !== "sr-modal" && (
                      <Alert variant={errType}>{errMsg}</Alert>
                    )}
                </Col>

                {true && (
                  <SearchBox
                    first_name={primaryContact.pcFname}
                    last_name={pcLname}
                    address={pcAddress1}
                    company_name={cName}
                  />
                )}
                <Col
                  xs="12"
                  md="12"
                  className={`div ${showCompanyFields && "_show"}`}
                >
                  <h5 style={{ padding: "25px 0" }}>
                    Company Contact Information
                  </h5>
                  <Row>
                    <Col xs="12" md="6">
                      <Form.Group style={styles.formInput}>
                        <Form.Label>Company Name </Form.Label>
                        <Form.Control
                          type="text"
                          name="cName"
                          value={cName}
                          placeholder="Enter company name"
                          onChange={(event) => setCName(event.target.value)}
                          isInvalid={errAt === "cName"}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    {commonAddressFields(
                      cAddress1,
                      "cAddress1",
                      setCAddress1,
                      cAddress2,
                      "cAddress2",
                      setCAddress2,
                      cCity,
                      "cCity",
                      setCCity,
                      cState,
                      "cState",
                      setCState,
                      cZip,
                      "cZip",
                      setCZip
                    )}
                  </Row>
                </Col>

                <Col xs="12" md="12">
                  <h5 style={{ padding: "25px 0" }}>
                    Primary Contact Information
                    <RequiredText>* Required</RequiredText>
                  </h5>
                  <BoldText>
                    <Form.Group>
                      <Form.Check
                        name="companyAddressChecked"
                        checked={companyAddressChecked}
                        onChange={(e) =>
                          setCompanyAddressChecked(e.target.checked)
                        }
                        label="Same as company address"
                        style={
                          cAddress1.length > 0 ||
                          cAddress2.length > 0 ||
                          cCity.length > 0
                            ? {}
                            : { display: "none" }
                        }
                      />
                    </Form.Group>
                  </BoldText>
                  <Row>
                    <Col xs="12" md="6">
                      <Form.Group style={styles.formInput}>
                        <Form.Label>
                          First Name <RequiredText>*</RequiredText>{" "}
                          <SubScriptText>Primary Contact</SubScriptText>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="pcFname"
                          value={primaryContact.pcFname}
                          placeholder="Enter First Name"
                          onChange={(event) =>
                            setPrimaryContact({ pcFname: event.target.value })
                          }
                          isInvalid={errAt === "pcFname"}
                          autoComplete="off"
                          role="presentation"
                        />
                      </Form.Group>
                    </Col>

                    <Col xs="12" md="6">
                      <Form.Group style={styles.formInput}>
                        <Form.Label>
                          Last Name <RequiredText>*</RequiredText>{" "}
                          <SubScriptText>Primary Contact</SubScriptText>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="pcLname"
                          value={pcLname}
                          placeholder="Enter Last Name"
                          onChange={(event) => setPcLname(event.target.value)}
                          isInvalid={errAt === "pcLname"}
                          autoComplete="off"
                          role="presentation"
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    {commonPhoneAndEmailFields(
                      pcPhoneType,
                      "pcPhoneType",
                      setPcPhoneType,
                      pcPhone,
                      "pcPhone",
                      setPcPhone,
                      pcPhoneType2,
                      "pcPhoneType2",
                      setPcPhoneType2,
                      pcPhone2,
                      "pcPhone2",
                      setPcPhone2,
                      pcPhoneType3,
                      "pcPhoneType3",
                      setPcPhoneType3,
                      pcPhone3,
                      "pcPhone3",
                      setPcPhone3,
                      pcEmailType,
                      "pcEmailType",
                      setPcEmailType,
                      pcEmail,
                      "pcEmail",
                      setPcEmail,
                      pcEmailType2,
                      "pcEmailType2",
                      setPcEmailType2,
                      pcEmail2,
                      "pcEmail2",
                      setPcEmail2,
                      pcEmailType3,
                      "pcEmailType3",
                      setPcEmailType3,
                      pcEmail3,
                      "pcEmail3",
                      setPcEmail3,
                      showPhone2,
                      "showPhone2",
                      showPhone3,
                      "showPhone3",
                      showEmail2,
                      "showEmail2",
                      showEmail3,
                      "showEmail3",
                      true
                    )}
                  </Row>

                  <Row>
                    {commonAddressFields(
                      pcAddress1,
                      "pcAddress1",
                      setPcAddress1,
                      pcAddress2,
                      "pcAddress2",
                      setPcAddress2,
                      pcCity,
                      "pcCity",
                      setPcCity,
                      pcState,
                      "pcState",
                      setPcState,
                      pcZip,
                      "pcZip",
                      setPcZip,
                      true,
                      companyAddressChecked
                    )}
                  </Row>

                  <Row>
                    <Col md="12" sm="12" className="left-Text">
                      <BoldText
                        id="secInfoBtn"
                        style={{
                          color: Color.primaryColor,
                          float: "right",
                          cursor: "pointer",
                        }}
                        onClick={toggleSecondaryContactFields}
                      >
                        {`${showSecondaryContactInfo ? "Hide " : "Show "}`}
                        Secondary Contact Information
                      </BoldText>
                    </Col>
                  </Row>

                  <Row className={`div ${showSecondaryContactInfo && "_show"}`}>
                    <Col>
                      <h5 style={{ padding: "25px 0" }}>
                        Secondary Contact Information
                      </h5>
                      <Row>
                        {commonContactFields(
                          scRelationship,
                          "scRelationship",
                          setScRelationship,
                          scFname,
                          "scFname",
                          setScFname,
                          scLname,
                          "scLname",
                          setScLname,
                          scPhoneType,
                          "scPhoneType",
                          setScPhoneType,
                          scPhone,
                          "scPhone",
                          setScPhone,
                          scPhoneType2,
                          "scPhoneType2",
                          setScPhoneType2,
                          scPhone2,
                          "scPhone2",
                          setScPhone2,
                          scPhoneType3,
                          "scPhoneType3",
                          setScPhoneType3,
                          scPhone3,
                          "scPhone3",
                          setScPhone3,
                          scEmailType,
                          "scEmailType",
                          setScEmailType,
                          scEmail,
                          "scEmail",
                          setScEmail,
                          scEmailType2,
                          "scEmailType2",
                          setScEmailType2,
                          scEmail2,
                          "scEmail2",
                          setScEmail2,
                          scEmailType3,
                          "scEmailType3",
                          setScEmailType3,
                          scEmail3,
                          "scEmail3",
                          setScEmail3,
                          scPhone2Show,
                          "scPhone2Show",
                          scPhone3Show,
                          "scPhone3Show",
                          scEmail2Show,
                          "scEmail2Show",
                          scEmail3Show,
                          "scEmail3Show"
                        )}
                      </Row>

                      <Row>
                        {commonAddressFields(
                          scAddress1,
                          "scAddress1",
                          setScAddress1,
                          scAddress2,
                          "scAddress2",
                          setScAddress2,
                          scCity,
                          "scCity",
                          setScCity,
                          scState,
                          "scState",
                          setScState,
                          scZip,
                          "scZip",
                          setScZip
                        )}
                      </Row>
                    </Col>
                  </Row>

                  <LeadSourcesInput
                    leadSource={leadSource}
                    leadSourceText={leadSourceText}
                    errAt={errAt}
                    setLeadSource={setLeadSource}
                    setLeadSourceText={setLeadSourceText}
                    showLeadSourceText={showLeadSourceText}
                    addErr={(err) => {
                      setDeleteModal(false);
                      setErrType("danger");
                      setErrMsg(err);
                      dismissMessage();
                    }}
                    leadSourcesType={leadSourcesType}
                  />
                </Col>

                <Col xs="12" md="12">
                  <h5 style={{ padding: "25px 0" }}>
                    Service Property Details
                  </h5>
                  <BoldText>
                    <Form.Group>
                      <Form.Check
                        name="primaryAddressChecked"
                        checked={primaryAddressChecked}
                        onChange={(e) =>
                          setPrimaryAddressChecked(e.target.checked)
                        }
                        label="Same as primary address"
                      />
                    </Form.Group>
                  </BoldText>
                  <Row>
                    <Col md="12" sm="12" className="left-Text">
                      <BoldText
                        id="siteBtn"
                        style={{
                          color: Color.primaryColor,
                          float: "right",
                          cursor: "pointer",
                        }}
                        onClick={toggleSiteContactInfo}
                      >
                        {`${showSiteContactInfo ? "Hide " : "Add "}`} Site
                        Contact Information
                      </BoldText>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" md="6">
                      <Form.Group style={styles.formInput}>
                        <Form.Label>
                          Property Name{" "}
                          <SubScriptText>If applicable</SubScriptText>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="pName"
                          value={pName}
                          placeholder="Enter property name"
                          onChange={(e) => setPName(e.target.value)}
                          isInvalid={errAt === "pName"}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className={`div ${showSiteContactInfo && "_show"}`}>
                    {commonContactFields(
                      siteContactRelationship,
                      "siteContactRelationship",
                      setSiteContactRelationship,
                      siteContactFname,
                      "siteContactFname",
                      setSiteContactFname,
                      siteContactLname,
                      "siteContactLname",
                      setSiteContactLname,
                      siteContactPhoneType,
                      "siteContactPhoneType",
                      setSiteContactPhoneType,
                      siteContactPhone,
                      "siteContactPhone",
                      setSiteContactPhone,
                      siteContactPhoneType2,
                      "siteContactPhoneType2",
                      setSiteContactPhoneType2,
                      siteContactPhone2,
                      "siteContactPhone2",
                      setSiteContactPhone2,
                      siteContactPhoneType3,
                      "siteContactPhoneType3",
                      setSiteContactPhoneType3,
                      siteContactPhone3,
                      "siteContactPhone3",
                      setSiteContactPhone3,
                      siteContactEmailType,
                      "siteContactEmailType",
                      setSiteContactEmailType,
                      siteContactEmail,
                      "siteContactEmail",
                      setSiteContactEmail,
                      siteContactEmailType2,
                      "siteContactEmailType2",
                      setSiteContactEmailType2,
                      siteContactEmail2,
                      "siteContactEmail2",
                      setSiteContactEmail2,
                      siteContactEmailType3,
                      "siteContactEmailType3",
                      setSiteContactEmailType3,
                      siteContactEmail3,
                      "siteContactEmail3",
                      setSiteContactEmail3,
                      siteContactPhone2Show,
                      "siteContactPhone2Show",
                      siteContactPhone3Show,
                      "siteContactPhone3Show",
                      siteContactEmail2Show,
                      "siteContactEmail2Show",
                      siteContactEmail3Show,
                      "siteContactEmail3Show"
                    )}
                  </Row>

                  <Row>
                    {commonAddressFields(
                      pAddress1,
                      "pAddress1",
                      setPAddress1,
                      pAddress2,
                      "pAddress2",
                      setPAddress2,
                      pCity,
                      "pCity",
                      setPCity,
                      pState,
                      "pState",
                      setPState,
                      pZip,
                      "pZip",
                      setPZip,
                      true,
                      primaryAddressChecked
                    )}
                  </Row>

                  <Row>
                    <Col md="6" sm="12">
                      <Row>
                        {propertyTypess && (
                          <Col md="4" sm="12">
                            <Form.Group style={styles.formInput}>
                              <Form.Label>
                                Property Type <RequiredText>*</RequiredText>
                              </Form.Label>
                              <Form.Control
                                as="select"
                                name="pType"
                                value={pType}
                                isInvalid={errAt === "pType"}
                                onChange={(e) => setPType(e.target.value)}
                              >
                                <option value="">Choose...</option>
                                {displaypTypeOptions()}
                              </Form.Control>
                            </Form.Group>
                          </Col>
                        )}

                        {propertyTypess?.propertydata?.length > 0 &&
                          checkIfCoop(pType) && (
                            <Col md="4" sm="6">
                              <Form.Group style={styles.formInput}>
                                <Form.Label>Buildings</Form.Label>
                                <div>
                                  <Button
                                    id="addBuilding"
                                    onClick={() => setShowCoopModal(true)}
                                    style={{ width: "100%" }}
                                    disabled={
                                      !pType ||
                                      !pAddress1 ||
                                      !pCity ||
                                      !pState ||
                                      !pZip
                                    }
                                  >
                                    Enter Building Names
                                  </Button>
                                </div>
                              </Form.Group>
                            </Col>
                          )}

                        <Col md="4" sm="6">
                          <Form.Group style={styles.formInput}>
                            <Form.Label>
                              Service Request <RequiredText>*</RequiredText>
                            </Form.Label>
                            <div>
                              <Button
                                id="serviceRequest"
                                onClick={() => setShowServiceRequestModal(true)}
                                style={{ width: "100%" }}
                                disabled={
                                  !pType ||
                                  !pAddress1 ||
                                  !pCity ||
                                  !pState ||
                                  !pZip
                                }
                              >
                                Select
                              </Button>
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>

                    <Col md="6" sm="12">
                      <Form.Group style={styles.formInput}>
                        <Form.Label>Property Notes</Form.Label>
                        <Form.Control
                          type="text"
                          name="pNotes"
                          value={pNotes}
                          onChange={(e) => setPNotes(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                {editLeads ? (
                  <Col md="12" sm="12">
                    <Button
                      variant="danger"
                      onClick={onCancelEdit}
                      style={{ float: "right", margin: "40px 20px" }}
                    >
                      Cancel
                    </Button>

                    <Button
                      variant="outline-success"
                      disabled={editLeadLoading}
                      onClick={handleEditing}
                      style={{ float: "right", margin: "40px 0" }}
                    >
                      {editLeadLoading ? "Saving..." : "Save Changes"}
                    </Button>
                  </Col>
                ) : (
                  <Col md="12" sm="12">
                    <Button
                      variant="outline-primary"
                      disabled={saveLeadLoading}
                      onClick={handleSubmit}
                      style={{ float: "right", margin: "40px 0" }}
                      // disabled={paymentStatus == "Frozen 1" ? true : false}
                    >
                      {saveLeadLoading ? "Saving..." : "Save to list"}
                      {/* Save to list */}
                    </Button>
                  </Col>
                )}
              </Row>
              <PopupModal
                show={showServiceRequestModal}
                onHide={onServiceRequestModalCancel}
                heading="Service Request"
                bold={true}
              >
                <Col xs="12">
                  {errMsg &&
                    showServiceRequestModal &&
                    errFor === "sr-modal" && (
                      <Alert variant={errType}>{errMsg}</Alert>
                    )}
                </Col>

                {!showNext && displayServiceRequestOption()}
                {showNext && newInstallation && displayNewInstallationOption()}
                {showNext && repair && displayNewRepairOptions()}
                {showNext && displayFooter()}
              </PopupModal>

              <Building
                onCoopModalHide={onCoopModalHide}
                handleChange={handleChange}
                newBuildingName={newBuildingName}
                coopUnits={coopUnits}
                onEditBuilding={onEditBuilding}
                saveEditBuilding={saveEditBuilding}
                tempNewBuildingName={tempNewBuildingName}
                setTempNewBuildingName={setTempNewBuildingName}
                onDeleteBuilding={onDeleteBuilding}
                deleteBuilding={deleteBuilding}
                addBuldings={addBuldings}
                showCoopModal={showCoopModal}
                setNewBuildingName={setNewBuildingName}
                isCancel={isCancel}
              />
            </Form>
          </Col>
          {DeleteLeadModal()}

          {displayTable()}

          {leadsData && leadsData.length > 0 && leadProprtyLength > 0 && (
            <Row>
              <Col>
                <Button
                  id="schedule"
                  style={{ padding: ".8em 2em", float: "right" }}
                  onClick={handlOnCLickScheduling}
                  disabled={schedulling}
                >
                  <BoldText>
                    {schedulling ? "Schedulling" : "Schedule"}
                    {/* Schedule */}
                  </BoldText>
                </Button>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Container>
  );
};
// }

function LeadSourcesInput({
  leadSource,
  leadSourceText,
  errAt,
  handleChange,
  showLeadSourceText,
  addErr,
  leadSourcesType,
  setLeadSource,
  setLeadSourceText,
}) {
  const { dataList, gettingDDData, listErr } = useSelector(
    (state) => state.ddList
  );
  const [sources, addSources] = useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDDData("leadSources"));
  }, []);
  useEffect(() => {
    if (dataList) {
      addSources(dataList);
    }
  }, [dataList]);

  useEffect(() => {
    if (listErr) {
      addErr(listErr);
    }
  }, [listErr]);

  const displayLeadSourceTypes = (data) => {
    return data.map((item) => (
      <option key={item._id} value={item._id}>
        {item.leadSource}
      </option>
    ));
  };

  let call_dispatch = () => {
    dispatch(getDDData("leadSources"));
  };

  return (
    <Row style={{ padding: "10px 0" }}>
      <Col md="3" sm="6">
        <Row style={{ display: "flex" }}>
          <Col>
            <Form.Group style={styles.formInput}>
              <Form.Label>
                Lead Source<RequiredText>*</RequiredText>
              </Form.Label>
              <Form.Control
                as="select"
                name="leadSource"
                value={leadSource}
                onChange={(event) => setLeadSource(event.target.value)}
                isInvalid={errAt === "leadSource"}
              >
                <option value="">Choose...</option>
                {displayLeadSourceTypes([...sources, ...leadSourcesType])}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col style={{ marginTop: "2rem" }}>
            <button
              onClick={call_dispatch}
              style={{ border: "none", background: "white" }}
            >
              <GrRefresh />
            </button>
          </Col>
        </Row>
      </Col>

      {showLeadSourceText([...sources, ...leadSourcesType], leadSource) && (
        <Col md="3" sm="6">
          <Form.Group style={styles.formInput}>
            <Form.Label>
              <RequiredText>*</RequiredText>
            </Form.Label>
            <Form.Control
              type="text"
              name="leadSourceText"
              value={leadSourceText}
              placeholder="Enter Name"
              onChange={(e) => setLeadSourceText(e.target.value)}
              isInvalid={errAt === "leadSourceText"}
            />
          </Form.Group>
        </Col>
      )}
    </Row>
  );
}

function mapStateToProps({
  emailTypess: { isLoadingEmailtype, emaildata, emailerrors },
  phoneTypess: { isLoadingPhonetype, phonedata, phoneerrors },
  propertyTypess: { isLoadingPropertytype, propertydata, propertyerrors },
  relationTypess: { isLoadingRelationtype, relationdata, relationerrors },
  allPermissions: { gettingPermission, permission, permissionErr },
  tasksRate: { isLoadingTasksRate, taskratedata, tasksrateerror },

  staticAll: { isLoading, data, errors },
  saveLead,
  fetchLeads,
  deleteLead: { isLoadingDelete, deleteSuccess, deleteErrors },
  editLead,
  scheduleLead,
  login,
}) {
  return {
    emailTypess: { isLoadingEmailtype, emaildata, emailerrors },
    phoneTypess: { isLoadingPhonetype, phonedata, phoneerrors },
    propertyTypess: { isLoadingPropertytype, propertydata, propertyerrors },
    relationTypess: { isLoadingRelationtype, relationdata, relationerrors },
    allPermissions: { gettingPermission, permission, permissionErr },
    tasksRate: { isLoadingTasksRate, taskratedata, tasksrateerror },

    staticAll: {
      isLoading,
      data,
      errors,
    },
    saveLead: {
      saveLeadLoading: saveLead.isLoading,
      saveLeadsuccess: saveLead.success,
      saveLeaderrors: saveLead.errors,
    },
    leads: {
      leadsLoading: fetchLeads.isLoadingFetchLeads,
      leadsData: fetchLeads.data,
      leadsErrors: fetchLeads.errors,
    },
    deletingLead: {
      deleting: isLoadingDelete,
      deleteSuccess,
      deleteErrors,
    },
    editLead: {
      editLeadLoading: editLead.isLoadingEdit,
      editLeadsuccess: editLead.successEdit,
      editLeaderrors: editLead.errorsEdit,
    },
    schedule: {
      schedulling: scheduleLead.schedulling,
      successSchedule: scheduleLead.successSchedule,
      errorsSchedule: scheduleLead.errorsSchedule,
    },
    userRole: login.user ? login.user.userData.role : null,
    paymentStatus: login?.user?.userData?.paymentStatus
      ? login.user.userData.paymentStatus
      : null,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchStaticAll: () => dispatch(fetchStaticAll()),
    SaveNewLead: (data) => dispatch(SaveNewLead({ ...data })),
    FetchLeads: () => dispatch(FetchLeads()),
    GetTasksRate: () => dispatch(GetTasksRate()),
    DeleteLead: (data) => dispatch(DeleteLead({ ...data })),
    EditLead: (data) => dispatch(EditLead({ ...data })),
    ScheduleLead: (data) => dispatch(ScheduleLead(data)),
    GetEmailType: () => dispatch(GetEmailType()),
    GetPhoneType: () => dispatch(GetPhoneType()),
    GetPropertyType: () => dispatch(GetPropertyType()),
    GetRelationType: () => dispatch(GetRelationType()),
    getAllPermissions: () => dispatch(getAllPermissions()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewLead);
