// error.reducer.js
import { errorTypes } from "./error.types";

const INITIAL_STATE = {
    errorMessage: null,
};

const errorReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case errorTypes.SET_GLOBAL_ERROR:
            return {
                ...state,
                errorMessage: action.payload,
            };
        case errorTypes.CLEAR_GLOBAL_ERROR:
            return {
                ...state,
                errorMessage: null,
            };
        default:
            return state;
    }
};

export default errorReducer;
