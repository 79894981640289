import { createSelector } from "reselect";

const selectStageData = ({ getStage }) => getStage.data;
const salesData = ({ getStage }) => getStage.salesData;
const repairsData = ({ getStage }) => getStage.repairsData;

export const stageData = createSelector([salesData], (data) =>
  data ? data : null
);

export const count = createSelector([selectStageData], (data) =>
  data ? data.count : []
);

export const saleStatusCountData = createSelector([salesData], (data) =>
  data.statusCounts ? data.statusCounts : []
);

export const saleScheduleData = createSelector([salesData], (data) =>
  data.scheduledData ? data.scheduledData : []
);

export const saleProposalsDueData = createSelector([salesData], (data) =>
  data.proposalsDueData ? data.proposalsDueData : []
);

export const saleSentFollowUpData = createSelector([salesData], (data) =>
  data.sentFollowUpData ? data.sentFollowUpData : []
);

export const saleWonData = createSelector([salesData], (data) =>
  data.wonData ? data.wonData : []
);

export const saleFutureFollowUpData = createSelector([salesData], (data) =>
  data.futureFollowUpData ? data.futureFollowUpData : []
);

export const saleLostData = createSelector([salesData], (data) =>
  data.lostData ? data.lostData : []
);

export const saleCanceledData = createSelector([salesData], (data) =>
  data.canceledData ? data.canceledData : []
);

export const repairStatusCountData = createSelector([repairsData], (data) =>
  data.statusCounts ? data.statusCounts : []
);

export const repairPaidClosedData = createSelector([repairsData], (data) =>
  data.paidClosedData ? data.paidClosedData : []
);

export const repairCanceledData = createSelector([repairsData], (data) =>
  data.canceledData ? data.canceledData : []
);

export const repairSentFollowUpData = createSelector([repairsData], (data) =>
  data.sentFollowUpData ? data.sentFollowUpData : []
);

export const repairCompleteUnpaid = createSelector([repairsData], (data) =>
  data.completeUnpaid ? data.completeUnpaid : []
);

export const repairScheduledData = createSelector([repairsData], (data) =>
  data.scheduledData ? data.scheduledData : []
);
