import React, { memo } from "react";
import "./tabs.scss";

const RepairsTabs = (props) => {
  const { active, count, tabs, setTab, countData } = props;
  console.log("tabs", { tabs, count });

  const getCountData = (tab) => {
    return countData[tab];
  };

  const displayTabs = (active) => {
    return tabs?.map((tab, i) => (
      <button
        key={`${tab}`}
        onClick={() => setTab(tab, i)}
        className={`_tabItem ${active === tab && "active"}`}
      >
        {tab}
        {getCountData(tab)
          ? `(${getCountData(tab)})`
          : "(0)"}
      </button>
    ));
  };
  return (
    <div
      className="_tab"
      style={{
        background: "rgb(245 243 239)",
      }}
    >
      {displayTabs(active)}
    </div>
  );
};

export default memo(RepairsTabs);
