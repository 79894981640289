import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import DatePicker from "react-date-picker";
import { convertDate } from "utils/utils";
import "react-datepicker/dist/react-datepicker.css";
import { IoTrash } from "react-icons/io5";
import { IoCreateOutline } from "react-icons/io5";
import { IoCheckbox } from "react-icons/io5";
import { IoCheckmarkCircleOutline } from "react-icons/io5";

export const TaskCard = ({
  dateChoosen,
  task,
  taskName,
  editTask,
  cancelEdit,
  completeTask,
  deleteTask,
  updateValue,
  handleEdit
}) => {

  console.log({
    dateChoosen: new Date(dateChoosen)
  });

  const [editId, setEditId] = useState();
  const [edit, setEdit] = useState(false);


  console.log({
    edit, editId
  });


  return (
    <Row style={{
      alignItems: "flex-start", display: 'flex',
      alignItems: 'center', marginBottom: '1%'
    }}>
      <Col lg="2" md="2">
        <div className="_title">Created</div>
        <div className="created-date">{convertDate(Date.now())}</div>
      </Col>
      <Col lg="2" md="3">
        <div className="_title">Due Date</div>
        <Form.Group className="ml-1">
          <DatePicker
            disabled={!task?.edit}
            selected={new Date()}
            value={new Date(dateChoosen)}
            // onChange={(date) => handleDateChangesave(date)}
            onChange={(date) => updateValue(task?._id, 'dueDate', date)}
            // style={{border: 0 }}
            // className="__datePicker"
            style={{
              borderRadius: '1rem'
            }}
          />
        </Form.Group>
      </Col>
      <Col lg="5" md="4">
        <Form.Group className="ml-1">
          <div className="_title">Task Detail</div>
          <Form.Control
            as="textarea"
            rows="2"
            disabled={!task?.edit}
            name="taskName"
            value={taskName}
            onChange={(e) => updateValue(task?._id, 'details', e.target.value)}
            style={{
              textDecoration: task?.isComplete ? 'line-through' : 'none',
              color: task?.isComplete ? 'grey' : 'inherit',
            }}
          />
        </Form.Group>
      </Col>

      <Col
        lg="3"
        md="3"
        className="d-flex justify-start items-center"
        // style={{ paddingTop: "1.5em" }}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          paddingTop: "2rem",
        }}
      >


        <Row>
          {task?.edit ? (
            <>
              <Col lg="6" md="6">
                <Button
                  variant="danger"
                  onClick={() => cancelEdit(task?._id)}
                // style={{ width: "10rem" }}
                >
                  Cancel
                </Button>
              </Col>
              <Col lg="6" md="6">
                <Button
                  onClick={() =>
                    handleEdit(task?._id)
                  }
                  disabled={!taskName}
                  // style={{ margin: "auto 1em", width: "10rem" }}
                  variant="outline-success"
                >
                  {/* {isEditing ? "Saving..." : "Save"} */}
                  Save
                </Button>
              </Col>
            </>
          ) : (
            <>
              <Col lg="12" md="12">
                <Row className="d-flex">
                  <Col lg="4" md="4">
                    <Button variant="warning" 
                    disabled={task?.isComplete}
                    onClick={() => editTask(task?._id)}>
                      <IoCreateOutline size={20} />
                    </Button>
                  </Col>

                  <Col lg="4" md="4">
                    <Button
                      disabled={task?.isComplete}
                      variant="success"
                      onClick={() => completeTask(task?._id)}
                    >
                      <IoCheckmarkCircleOutline size={20} />

                    </Button>
                  </Col>

                  <Col lg="4" md="4">
                    <Button
                      variant="danger"
                      // disabled={isDeleting}
                      onClick={() => deleteTask(task?._id)}
                    >
                      <IoTrash size={20} />

                    </Button>
                  </Col>
                </Row>
              </Col>


            </>
          )}
        </Row>
      </Col>
    </Row>
  )
};