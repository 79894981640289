import React from "react";
import { Form, Row } from "react-bootstrap";
import PopupModal, { ModalFooter } from "components/popup-modal/popup-modal";

export default function DisplayModal({
  handleChange,
  checkOtherArrayValue,
  addAllData,
  show,
  label,
  hide,
  data,
  array,
  arrName,
  other,
  onSubmit,
  handleOtherChange,
}) {
  const checkOtherArray = (array, data) => {
    if (data.length > 0) {
      return array.some((key) => data.includes(key.name) && key.other);
    }
    return false;
  };

  return (
    <PopupModal
      styles={{ padding: "1em 1em 0 1em", border: "none" }}
      show={show}
      heading={label}
      onHide={hide}
      bold
    >
      <Row style={{ margin: "0 1em 1em 1em", alignItems: "center" }}>
        {data.map((item) => (
          <button
            key={item.id}
            className={`ClickableBtnModal ${
              array.includes(item.name) ? "selected" : ""
            } `}
            onClick={() => {
              handleChange(label, item.id, item.name, false);
            }}
            style={{ maxWidth: "12%", marginTop: "4px", padding: "0" }}
          >
            {item.name}
          </button>
        ))}
        {checkOtherArray(data, array) && (
          <div style={{ width: "150px", margin: "1em" }}>
            <Form.Control
              type="text"
              name={other.name}
              value={other.value}
              onChange={handleOtherChange}
            />
          </div>
        )}
      </Row>

      <ModalFooter onCancel={hide} onSubmit={onSubmit} />
    </PopupModal>
  );
}
