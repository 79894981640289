// Property Table Header For Contact Management
export const propertyTableHeaderContact = [
  {
    dataField: "Cbox",
    text: <></>,
  },
  {
    dataField: "id",
    text: (
      <span
        style={{}}
        //{ marginLeft: "-2rem" }
      >
        Line Item
      </span>
    ),
  },
  {
    dataField: "address",
    text: "Address",
  },
  {
    dataField: "pType",
    text: "Property Types",
  },
  // {
  //   dataField: "plusSymbol",
  //   text: " ",
  // },
  {
    dataField: "Cbox2",
    text: "",
  },
  {
    dataField: "sRequest",
    text: "Service Request",
  },
  {
    dataField: "srRep",
    text: "Sales/Repair Rep",
  },
  {
    dataField: "sStage",
    text: "Sales Stage",
  },
  {
    dataField: "sDate",
    text: "Stage Date",
  },
  {
    dataField: "actions",
    text: "Actions",
  },
];

export const serviceRequestsForContact = [
  {
    name: "New Installation",
    state: "newInstallation",
  },
  {
    name: "Repair",
    state: "repair",
  },
];

export const YesNoOptionsForContact = [
  {
    name: "YES",
    value: true,
  },
  {
    name: "NO",
    value: false,
  },
];

export const HeadingText =
  "Here you can modify and reorder proposal statements before finalizing. To modify text Tap/Click inside a text box, edit, then save. To reorder Tap/Click the hand, then drag and drop to desired location. Note: Any changes are for this proposal only.";

export const signCanavsHeight = 250;
export const signCanavsWidth = 1058;

export const FOOTER_TEXT = [
  {
    person: "Home Owners Signature:",
    signHolder: "_____________________",
    name: "John Doe",
    date: "Date:",
    type: 1,
  },
  {
    person: "Authorized Signature:",
    signHolder: "_____________________",
    name: "Robert Fallcara",
    date: "Date:",
    type: 2,
  },
];
