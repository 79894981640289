import React from "react";
import { Row } from "react-bootstrap";
import MetricButton from "./MetricButton";
import "../metric.scss";

const DisplayMetric = ({
  label,
  array,
  data,
  prevMetric,
  size,
  icon,
  width,
  styles,
  showLabel = true,
  optional = false,
  displayLabel,
  exec = false,
  addFloor,
  isQuoteLock = false,
}) => {
  console.log("DA", data);
  return (
    <>
      {showLabel && (
        <label
          style={
            typeof styles !== "undefined"
              ? { width: "100%", textAlign: "center" }
              : {}
          }
          className="btnheading"
        >
          {typeof displayLabel !== "undefined" ? displayLabel : label}
          {optional && "(Optional)"}
        </label>
      )}
      <Row className="justify-content-md-start">
        {MetricButton({
          label,
          array,
          data,
          prevMetric,
          size,
          icon,
          width,
          styles,
          exec,
          onClickBtn: (label, id, name, exec) => {
            addFloor(label?.toLowerCase(), id, name, exec);
          },
          isQuoteLock,
        })}
      </Row>
    </>
  );
};

export default DisplayMetric;
