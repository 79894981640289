import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import moment from "moment";
import PickerDate from "react-datepicker";
import { convertMilitaryToStandard } from "utils/dateTimeUtils";

export default function SalesCard(props) {
  const {
    id,
    name,
    typeOfCard,
    updateStaus,
    proposalSent,
    updatedVisible,
    updatedOn,
    updatedText,
    leadRequest,
    handleFollowDateChange,
    RedirectTo,
    data,
    paymentStatus,
    companyName,
    leadAddressData,
  } = props;


  const onClickRoof = (path) => {
    RedirectTo(path, data?._id);
  }

  const formatAddress = (addressData) => {
    // Destructure and default to empty strings
    const {
      primaryState = "",
      primaryAddress1 = "",
      primaryAddress2 = "",
      primaryCity = "",
      primaryZip = ""
    } = addressData || {};
  
    // Create an array of non-empty address components
    const addressComponents = [
  
      primaryAddress1,
      primaryAddress2,
      primaryCity,
      primaryState,
      primaryZip
    ].filter(component => component); // Remove any empty strings
  
    // Join components with a comma and space
    return addressComponents.join(", ");
  };
  
  // Usage in JSX
  <span>{formatAddress(leadAddressData)}</span>

  const getInstallationType = () => {
    if (leadRequest?.hasGutters && leadRequest?.hasRoof) {
        return "New Roof, Gutters";
    }
    if (leadRequest?.hasGutter) {
        return "New Gutters";
    }
    if (leadRequest?.hasRoof) {
        return "New Roof";
    }
    return "";
};

  return (
    <>
      {name && (
        <div className="_sales-card">
          <div className="p-details" style={{ maxWidth: "300px" }}>
            {companyName?.companyName && (
              <div className="names"> {companyName.companyName}</div>
            )}
            <div className="names">{leadAddressData?.primaryFirstName || ""} {leadAddressData?.primaryLastName || ""}</div>

            <div className="tels">
              {leadAddressData && leadAddressData?.primaryPhone1 && (
                <div className="tel">{leadAddressData?.primaryPhone1}</div>
              )}
              {leadAddressData && leadAddressData?.primaryPhone2 && (
                <div className="tel">{leadAddressData?.primaryPhone2 || ""}</div>
              )}
              {leadAddressData && leadAddressData?.secondaryPhone1 && (
                <div className="tel">{leadAddressData?.secondaryPhone1 || ""}</div>
              )}
              {leadAddressData && leadAddressData?.secondaryPhone2 && (
                <div className="tel">{leadAddressData?.secondaryPhone2 || ""}</div>
              )}
            </div>
            <a
              // href=  `` "https://www.google.com/maps/place/"
              href={`https://www.google.com/maps/place?q=${formatAddress(leadAddressData)}`}
              className="address"
              style={{ textDecoration: "underline" }}
              target="_blank"
            >
              {formatAddress(leadAddressData)}
            </a>
          </div>
          <div className="o-details" style={{ margin: "0 1em" }}>
            <div className="bold">
              Time:
              <span className="bold">
                {' '} {convertMilitaryToStandard(leadRequest?.startTime ) || convertMilitaryToStandard(leadRequest?.endTime) 
                  ? `${convertMilitaryToStandard(leadRequest?.startTime)} - ${convertMilitaryToStandard(leadRequest?.endTime)}` : "N/A"}
              </span>
            </div>
            <div className="bold">
              Visit:
              {data?.scheduledDate && (
                <span className="bold"> {' '}
                  {moment(data?.scheduledDate).format("MMM D, YYYY")}
                </span>
              )}
            </div>
            {data?.currentStatus === "Sent/Follow-Up" && (
              <div>
                <label>Proposal Sent:</label> <span>{proposalSent || "--/--/----"}</span>
              </div>
            )}
            {data?.currentStatus === "Sent/Follow-Up" && (
              <div>
                <label className="_label">Follow Up:</label>
                <PickerDate
                  value={leadRequest?.followUpDate ?? '--/--/----'}
                  onChange={(date) => handleFollowDateChange(leadRequest?._id, date)}
                  className="_date_picker2"
                />
              </div>
            )}
            {updatedVisible && (
              <div>
                <label>{updatedText}:</label> {moment(updatedOn).format("LL")}
              </div>
            )}

            <div>
              <label>Project:</label>
              <span>{' '}{getInstallationType()}</span>
            </div>
            {leadRequest?.personDetails && (
              <div>
                <label>Sales:</label>{" "}
                <span>
                  {leadRequest?.personDetails
                    ? `${leadRequest?.personDetails?.firstName} ${leadRequest?.personDetails?.lastName}`
                    : "N/A"}
                </span>
              </div>
            )}
            <div>
              <label>Created:</label>{" "}
              <span>{moment(leadRequest?.createdOn).format("LL")}</span>
            </div>
          </div>
          <div className="_btns">
            <Row style={{ flexDirection: "column" }}>
              <Col style={{ marginBottom: "1em" }}>
                <Button
                  className="_btn_modified"
                  onClick={() => updateStaus(leadRequest?._id, typeOfCard)}
                  disabled={
                    false
                    // !allowStageChange || isChanging || paymentStatus === "Frozen 1"
                  }
                >
                  Change Stage
                </Button>
              </Col>
              <Col style={{ marginTop: "1em" }}>
                <Button className="_btn_modified">
                  <a
                    // href=  `` "https://www.google.com/maps/place/"
                    href={`https://www.google.com/maps/place?q=${formatAddress(leadAddressData)}`}
                    className="address"
                    style={{ textDecoration: 'none' }}
                    target="_blank"
                  >
                    Directions
                  </a>
                </Button>
              </Col>
            </Row>
          </div>
          <div className="_btns">
            <Row style={{ flexDirection: "column" }}>
              <Col style={{ marginBottom: "1em" }}>
                <Button
                  onClick={() => onClickRoof("roof")}
                  className="_btn_modified"
                  disabled={!leadRequest?.hasRoof}
                >
                  Roof
                </Button>
              </Col>
              <Col style={{ marginTop: "1em" }}>
                <Button
                  onClick={() => onClickRoof("gutters")}
                  className="_btn_modified"
                  disabled={!leadRequest?.hasGutters}
                >
                  Gutters
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </>
  );
}
