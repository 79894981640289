import React, { useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import MaterialListItem from "./MaterialListItem";
import { LoadingLoader, PopupModal } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { PricingList } from "screens/Suppliers/Material";
import {
  deleteMaterialMiscQuote,
  getSeriesTypes,
  updateProposalInfo,
} from "screens/QuotesProposals/redux/proposals/proposals.actions";
import { useSelector } from "react-redux";

export default function QuoteDataList({
  loadingData,
  dataError,
  selectedSupplier,
  metricId,
  saveSectionDataToDB,
  deleteMiscItem,
  changeQuantiy,
  LoadingSkeleton,
  displayMsg,
  quoteMaterialSectionData,
  isLockQuoteData,
  selectedProposal,
  isLoading,
  tabData,
}) {
  if (dataError) {
    return displayMsg({ msg: "No data available." });
  }

  if (loadingData) {
    return new Array(10).fill(1).map((_, i) => <LoadingSkeleton key={i} />);
  }

  if (!tabData?.length) {
    return displayMsg({ msg: "No data available." });
  }

  return (
    <div>
      {tabData.map((sectionPricing, idx) => (
        <MaterialItemsWrapper
          key={`${sectionPricing._id}-${sectionPricing.category}`}
          idx={idx}
          pricing={sectionPricing}
          selectedSupplier={selectedSupplier}
          metricId={metricId}
          tabData={tabData}
          saveSectionDataToDB={saveSectionDataToDB}
          quoteMaterialSectionData={quoteMaterialSectionData}
          isLockQuoteData={isLockQuoteData}
          isLoading={isLoading}
          selectedProposal={selectedProposal}
          changeQty={changeQuantiy}
        />
      ))}
    </div>
  );
}

function MaterialItemsWrapper({
  pricing,
  editItem,
  isLockQuoteData,
  isLoading,
  selectedProposal,
  changeQty,
  changePrice,
}) {
  // const { _id, category, subType, uomPrice, qty, total } = pricing;
  const {
    _id: materialId,
    category,
    series,
    uomPrice,
    uom,
    supplierId,
    subType,
    categoryType,
    pricingCategory,
    qtyAndPrice,
    manufacturer,
    fromMetric,
    manufacturers,
    qty,
    order,
    total,
    original_list,
  } = pricing;

  const {
    seriesResponse: { data: seriesList = [] },
    isLoadingSeries,
  } = useSelector((state) => state?.proposal);

  console.log({
    seriesList,
    isLoadingSeries,
  });
  const dispatch = useDispatch();
  const [showSeriesModal, setShowSeriesModal] = useState(false);

  const onChangeQty = (qty, materialId, price, miscSeries) => {
    changeQty(qty, materialId, price, miscSeries);
  };

  const handlePriceChange = (value) => {
    changeQty("", materialId, value);
  };

  const openSeriesListModal = () => setShowSeriesModal(true);
  const closeModal = () => setShowSeriesModal(false);
  const callSeriesApiCallack = (
    category,
    categoryType,
    manufacturer,
    supplierId
  ) => {
    console.log({ category, categoryType, manufacturer });
    const data = { category, categoryType, manufacturer, supplierId };
    // Dispatch action to get series list
    dispatch(getSeriesTypes(data));
  };

  const changeManufacturer = (manufacturer, supplierId) => {
    // Dispatch action to get series list
    const data = {
      requestId: selectedProposal?.requestId,
      supplierId: supplierId,
      // proposalId,
      materialId: materialId,
      proposalId: selectedProposal._id,
      materialQuoteId: selectedProposal?.quotes?.materials?._id,
      manufacturer: manufacturer,
    };
    dispatch(updateProposalInfo(data));
  };

  const changeSeries = (selectedSerieItem) => {
    // Dispatch action to get series list
    const data = {
      requestId: selectedProposal?.requestId,
      supplierId: supplierId,
      // proposalId,
      materialId: materialId,
      proposalId: selectedProposal._id,
      materialQuoteId: selectedProposal?.quotes?.materials?._id,
      manufacturer: manufacturer,
      series: selectedSerieItem,
      // materialQuoteId
    };
    dispatch(updateProposalInfo(data));
  };
  const deleteMiscItem = () => {
    const body = {
      supplierId: selectedProposal?.quotes?.materials?.supplier?._id,
      proposalId: selectedProposal._id,
      materialQuoteId: selectedProposal?.quotes?.materials?._id,
      materialId: pricing._id,
    };
    dispatch(deleteMaterialMiscQuote(body));
  };

  const PricingListData = useMemo(() => {
    if (seriesList.length === 0) {
      return <p>No series available.</p>; // You can return any fallback content here
    }

    return seriesList.map((serieItem) => (
      <li key={serieItem}>
        <button
          onClick={() => changeSeries(serieItem)}
          disabled={serieItem === series}
          className="rounded-pill justify-content-center"
          style={{ border: "1px gray solid", marginTop: "15px" }}
        >
          {serieItem}
          {serieItem === series && (
            <FontAwesomeIcon color="#0979fa" icon={faCheck} />
          )}
        </button>
      </li>
    ));
  }, [seriesList, series]);

  return (
    <>
      <MaterialListItem
        disablePriceChange={category !== "Skylight"}
        handlePriceChange={handlePriceChange}
        manufacturerList={manufacturers}
        manufacturer={manufacturer}
        series={series}
        // category={category + subTypeDetails}
        materialId={materialId}
        category={category}
        categoryType={categoryType}
        qty={qtyAndPrice?.quantity}
        uom={uom}
        supplierId={supplierId}
        price={uomPrice}
        total={qtyAndPrice?.totalPrice}
        disabled={fromMetric}
        openSeriesListModal={openSeriesListModal}
        // PricingListModal={PricingListModal}
        changeManufacturer={changeManufacturer}
        material={pricing}
        onChangeQty={onChangeQty}
        original_list={original_list}
        isLockQuoteData={isLockQuoteData?.isLocked}
        isLoading={isLoading}
        callSeriesApiCallack={callSeriesApiCallack}
        deleteMiscItem={deleteMiscItem}
      />

      <PopupModal
        show={showSeriesModal}
        onHide={closeModal}
        bold
        centered={false}
        styles={{ border: "none" }}
      >
        {isLoadingSeries ? (
          <LoadingLoader />
        ) : (
          <div className="__quote_pricing_list">
            <h5>Select one of the following:</h5>
            {seriesList?.length ? <ul>{PricingListData}</ul> : null}
          </div>
        )}
      </PopupModal>
    </>
  );
}
