import React, { useCallback, useEffect, useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";

import { PriceInput } from "components";
import styles from "lib/config/baseStyles";
import { getOptions } from "../../SelectSupliers";
import QTYInput from "../../QTYInput";
import {
  hasFieldChanged,
  hasNestedFieldChanged,
} from "screens/QuotesProposals/utils";
import { useSelector } from "react-redux";
import { debounce } from "lodash";

export default function MaterialListItem({
  manufacturerList,
  manufacturer,
  category,
  allData,
  disabled,
  openSeriesListModal,
  series,
  uom,
  qty,
  setQty,
  handleChange,
  price,
  total,
  changeManufacturer,
  onChangeQty,
  disablePriceChange,
  original_list,
  isLockQuoteData,
  isLoading,
  callSeriesApiCallack,
  categoryType,
  supplierId,
  material,
  deleteMiscItem,
}) {
  const [materialQty, setMaterialQty] = useState();
  const [materialPrice, setMaterialPrice] = useState();
  const [miscSeriesPrice, setMiscSeriesPrice] = useState("");

  const proposalData = useSelector((state) => state.proposal);
  const { isLoadingDeleteMaterialMisc } = proposalData;
  const isSkylight = categoryType === "Skylight";
  useEffect(() => {
    if (qty) {
      setMaterialQty(qty);
    }
  }, [qty]);

  useEffect(() => {
    if (material?.miscSeries) {
      setMiscSeriesPrice(material?.miscSeries);
    }
  }, [material?.miscSeries]);

  useEffect(() => {
    if (price) {
      setMaterialPrice(price);
    }
  }, [material?.price]);

  const handleOpenModaAndGetSeries = () => {
    openSeriesListModal();
    callSeriesApiCallack(category, categoryType, manufacturer, supplierId);
  };

  const handleChangeManufacturer = (manufacturer) => {
    if (manufacturer) changeManufacturer(manufacturer, supplierId);
  };

  // Debounced version of handleChangeQty
  const debouncedHandleChangeQty = useCallback(
    debounce((value) => {
      if (value) onChangeQty(Number(value), material._id, "");
    }, 500), // Adjust the delay time as needed
    [material._id]
  );

  const handleChangeQty = (event) => {
    const value = event.target.value;
    setMaterialQty(Number(value));

    // Use the debounced function
    if (value) debouncedHandleChangeQty(value);
  };

  // Debounced version of handlePriceChange
  const debouncedHandlePriceChange = useCallback(
    debounce((value) => {
      if (value) onChangeQty("", material._id, Number(value));
    }, 500), // Adjust the delay time as needed
    [material._id] // Add dependencies if necessary
  );

  const handlePriceChange = (value) => {
    setMaterialPrice(value);
    console.log({ value });

    // Use the debounced function
    if (value) debouncedHandlePriceChange(value);
  };

  const handleChangeMiscSeries = (event) => {
    const value = event.target.value;
    setMiscSeriesPrice(value);
    console.log({
      value,
    });
    if (value) onChangeQty("", material._id, "", value);
  };

  const format = (value) => {
    const numericValue = parseFloat(value);

    if (!isNaN(numericValue)) {
      const formattedValue =
        numericValue % 1 === 0 ? `${numericValue}.00` : numericValue.toFixed(2);
      return formattedValue;
    }
  };
  useEffect(() => {
    const rr = hasFieldChanged(material, "manufacturer");
    console.log({
      rr,
    });
  }, []);

  const getMaterialName = () => {
    if (category === "Shingles") {
      return material.subType
        ? `${categoryType} - ${material.subType}`
        : categoryType;
    }

    if (category === "Nails") {
      return "Coil Nails";
    }
    return [
      "Rake",
      "Leak Barrier",
      "Eve",
      "Starter",
      "Hip & Ridge",
      "Valley",
      "Vent Pipe",
      "Step",
      "Ridge Vent",
      "Louvers",
      "Power Fan",
      "Hood/Goose Neck",
      "Plywood",
      "Rafter/Stud",
      "Furring Strips",
    ].includes(categoryType)
      ? categoryType === "Power Fan"
        ? material?.materialFor
        : categoryType
      : category;
  };
  return (
    <>
      <Row>
        <Col style={styles.reduceWidthCol}>
          {manufacturer &&
            manufacturerList?.length &&
            !["Valley", "Vent Pipe", "Step"].includes(categoryType) && (
              <Form.Group>
                <Form.Control
                  disabled={isLockQuoteData}
                  as="select"
                  value={manufacturer}
                  onChange={(e) => handleChangeManufacturer(e.target.value)}
                  style={
                    hasFieldChanged(material, "manufacturer")
                      ? {
                          backgroundColor: "#f6d46f",
                        }
                      : {}
                  }
                >
                  {manufacturer && getOptions(manufacturerList)}
                </Form.Control>
              </Form.Group>
            )}
        </Col>

        <Col style={styles.reduceWidthCol} lg="2">
          {category && !material.isMisc && (
            <Form.Group>
              <Form.Control
                disabled
                type="text"
                style={{ textAlign: "center" }}
                value={getMaterialName()}
              />
            </Form.Group>
          )}

          {material.isMisc && (
            <Button
              variant="danger"
              disabled={isLoadingDeleteMaterialMisc}
              onClick={deleteMiscItem}
            >
              Delete
            </Button>
          )}
        </Col>
        <Col style={styles.reduceWidthCol} lg="4">
          {!material.isMisc ? (
            <button
              disabled={isSkylight || isLoading}
              onClick={handleOpenModaAndGetSeries}
              className="__quote_series_txt"
              style={
                hasFieldChanged(material, "series")
                  ? {
                      backgroundColor: "#f6d46f",
                    }
                  : {}
              }
            >
              {series}
            </button>
          ) : (
            <Form.Group>
              <Form.Control
                // disabled
                type="text"
                style={{ textAlign: "center" }}
                value={miscSeriesPrice}
                onChange={handleChangeMiscSeries}
              />
            </Form.Group>
          )}
        </Col>
        <Col style={styles.reduceWidthCol}>
          {!material.isMisc && (
            <Form.Group>
              <Form.Control
                disabled
                type="text"
                style={{ textAlign: "center" }}
                value={uom}
              />
            </Form.Group>
          )}
        </Col>
        <Col style={styles.reduceWidthCol}>
          <QTYInput
            value={materialQty?.toString()}
            onChange={handleChangeQty}
            hasChanged={
              !material.isMisc &&
              hasNestedFieldChanged(material, "qtyAndPrice", "quantity")
            }
            disabled={isLockQuoteData || isLoading}
          />
        </Col>
        <Col style={styles.reduceWidthCol}>
          <PriceInput
            disabled={!material.isMisc && !isSkylight}
            price={
              !material.isMisc && !isSkylight
                ? format(materialPrice)
                : materialPrice
            }
            // price={materialPrice}
            onChange={(value) => handlePriceChange(value)}
          />
        </Col>
        <Col style={styles.reduceWidthCol}>
          <PriceInput
            disabled
            price={!material.isMisc && !isSkylight ? format(total) : total}
            input="Total"
          />
        </Col>
      </Row>
    </>
  );
}
