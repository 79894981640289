import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { PriceInput } from "components";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import styles from "lib/config/baseStyles";
import { debounce } from "lodash";

import { uuid } from "uuidv4";

import {
  getPricingCarting,
  resetPricingCarting,
} from "lib/redux/suppliers/suppliers.action";
import {
  editQuoteDebrisSectionData,
  saveEditedQuoteDebrisSectionData,
  updateTotalQuoteDebrisSectionData,
} from "lib/redux/quote-proposals/quotes-proposals.actions";
import displayMsg from "components/displayMsg";
import SelectSuppliers, { SupplierItemOnlyName } from "../../SelectSupliers";

import LoadingSkeleton from "../../LoadingSkeleton";
import CartingListData from "./CartingListData";
import userDetails from "hooks/user/userDetails";
import {
  getCartingSuppliers,
  updateCartingSupplier,
  getCartingTypes,
  addCarting,
} from "screens/QuotesProposals/redux/proposals/proposals.actions";
import {
  hasSupplierFieldChanged,
  supplierEmptyText,
} from "screens/QuotesProposals/utils";
import QuoteUpdateLoader from "components/common/loaders/QuoteUpdateLoader";

export default function DebrisRemoval({
  metricId,
  setSelectedSupplier,
  isLockQuoteData,
}) {
  const [stateDebrisData, setStateDebrisData] = useState([]);
  const [stateCartMaterialData, setStateCartMaterialData] = useState([]);

  const proposalData = useSelector((state) => state.proposal);
  const selectedProposal = proposalData?.selectedProposal;

  const selectedSupplier =
    proposalData?.selectedProposal?.quotes?.dumpsters?.supplier;

  console.log({
    selectedSupplierS: selectedSupplier,
  });

  const {
    isLoadingCartingSuppliers,
    cartingSuppliers: suppliers,
    cartingSuppliersError,

    isLoadingCartingTypes,
    cartingTypes,
    cartingTypesError,
    isLoadingUpdateCartingSupplier,
    isLoadingDeleteCarting,
    isLoadingUpdateCarting,
  } = proposalData;

  const dispatch = useDispatch();
  const { companyId } = userDetails();

  // const onChangeSupplier = (e) => handleChange(e, setSelectedSupplier);

  const onChangeSupplier = (e) => {
    const supplierId = e.target.value;
    console.log({
      supplierId,
      e,
    });
    const body = {
      requestId: selectedProposal?.requestId,
      supplierId: supplierId,
      proposalId: selectedProposal._id,
    };

    console.log({
      bodyupdate: body,
    });
    dispatch(updateCartingSupplier(body));
  };

  useEffect(() => {
    if (companyId && !suppliers?.length && !isLoadingCartingSuppliers) {
      dispatch(getCartingSuppliers(companyId));
    }
  }, [companyId]);

  useEffect(() => {
    if (
      selectedSupplier?._id &&
      suppliers?.length &&
      !cartingTypes.length &&
      !isLoadingCartingTypes
    ) {
      dispatch(getCartingTypes(selectedSupplier?._id));
    }
  }, [selectedSupplier?._id, suppliers]);

  const addAditional = () => {
    const {
      _id: proposalId,
      quotes: {
        dumpsters: {
          _id: dumpsterQuoteId,
          supplier: { _id: supplierId } = {},
        } = {},
      } = {},
    } = selectedProposal || {};

    // Basic validation check
    if (!supplierId || !proposalId || !dumpsterQuoteId) {
      console.error("Missing required fields. Cannot proceed with the action.");
      return;
    }

    const body = {
      supplierId,
      proposalId,
      dumpsterQuoteId,
      category: "", // Ensure these values are set properly
      pricingType: "", // Set the appropriate defaults if required
      uomPrice: "",
      size: "",
      qty: "",
    };

    dispatch(addCarting(body));
  };

  const retry = () => {};
  return (
    <div style={{ margin: "1em" }}>
      <section>
        <Row>
          <Col style={styles.reduceWidthCol}>
            <h4 style={{ marginBottom: "-.5rem" }}>Debris Removal</h4>
          </Col>
          <Col className="d-flex justify-content-end">
            {/* {isLoadingProposal ? null : error ? (
                  <SmRetryBtn retry={retry} />
                ) : null} */}
            <QuoteUpdateLoader
              loading={
                isLoadingCartingSuppliers ||
                isLoadingCartingTypes ||
                isLoadingUpdateCartingSupplier ||
                isLoadingDeleteCarting ||
                isLoadingUpdateCarting
              }
              retry={retry}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "2rem" }}>
          <SelectSuppliers
            selectedSupplier={selectedSupplier._id}
            onSelectSupplier={onChangeSupplier}
            loading={false}
            suppliers={suppliers}
            emptyText={supplierEmptyText(suppliers?.length)}
            SupplierItem={SupplierItemOnlyName}
            isOriginalSupplier={hasSupplierFieldChanged(
              selectedProposal.originalQuoteSupplier,
              "cartingSupplierId",
              selectedSupplier?._id
            )}
            isLockQuoteData={isLockQuoteData}
          />
        </Row>

        <DebrisSectionDataList
          quoteId={selectedProposal?.quotes?.dumpsters?._id}
          proposalId={selectedProposal?._id}
          supplierId={selectedProposal?.quotes?.dumpsters?.supplier?._id}
          // cartMaterialData={stateCartMaterialData}
          // loadingData={gettingDebrisSectionData || gettingCartMaterial}
          dumpsterQuotes={selectedProposal?.quotes?.dumpsters?.quotes}
          // dumpsterQuotes={
          //   stateDebrisData ? stateDebrisData.dumpsterQuotes : stateDebrisData
          // }
          cartingTypes={cartingTypes}
          // dataError={quoteDebrisSectionDataErr}
          // deleteItem={deleteItem}
          // editItem={editItem}
          // isLockQuoteData={isLockQuoteData}
        />

        <Row>
          <Col style={styles.reduceWidthCol} lg="2">
            <Button
              onClick={() => addAditional()}
              disabled={isLockQuoteData?.isLocked}
            >
              <strong>Add</strong>
            </Button>
          </Col>
        </Row>
        {selectedProposal && (
          <TotalSection
            total={selectedProposal?.quotes?.dumpsters?.total || 0}
          />
        )}
      </section>
    </div>
  );
}

function TotalSection({ total }) {
  return (
    <Row>
      <Col style={styles.reduceWidthCol} lg="6" />
      <Col style={{ ...styles.reduceWidthCol, ...{ flex: "77%" } }}>
        <Row>
          <Col
            style={{
              ...styles.reduceWidthCol,
              ...{ textAlign: "right", paddingRight: "none" },
            }}
          >
            <Form.Label>Debris Removal Total:</Form.Label>
          </Col>
        </Row>
      </Col>
      <Col style={styles.reduceWidthCol}>
        <PriceInput
          price={total}
          disabled
          style={{ background: "#28a745", color: "#fff" }}
        />
      </Col>
    </Row>
  );
}

function DebrisSectionDataList({
  loadingData,
  dumpsterQuotes,
  dataError,
  deleteItem,
  editItem,
  cartMaterialData,
  isLockQuoteData,

  cartingTypes,
  quoteId,
  proposalId,
  supplierId,
}) {
  // if (dataError) {
  //   console.error(dataError);
  //   return displayMsg({ msg: "No data available." });
  // }
  // console.log({
  //   sectionDataDeb: dumpsterQuotes,
  // });

  if (dumpsterQuotes && !dumpsterQuotes.length) {
    return displayMsg({ msg: "No data available." });
  }

  if (loadingData) {
    return new Array(10).fill(1).map((_, i) => <LoadingSkeleton key={i} />);
  }

  if (dumpsterQuotes) {
    return dumpsterQuotes.map((sectionPricing, idx) => (
      <CartingListData
        // listData={cartMaterialData.cartingPricing}
        // key={sectionPricing._id}
        data={sectionPricing}
        cartingTypes={cartingTypes}
        quoteId={quoteId}
        proposalId={proposalId}
        supplierId={supplierId}
        // deleteItem={() => deleteItem(idx)}
        // editItem={(dataItem) => editItem(idx, dataItem)}
        // isLockQuoteData={isLockQuoteData}
        // dumpsterQuotes={dumpsterQuotes}
      />
    ));
  }

  return null;
}
