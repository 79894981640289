import React from "react";
import { Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { getDataLabel, sumDimensonTotal } from "utils/utils";
import { DIFFICULTY } from "lib/constants/const";
import numeral from "numeral";


const DimensionModalHeader = ({
  num,
  section,
  difficulty,
  totalSectionF,
  dimensions,
  total
}) => {

  console.log({
    total
  });
  
  return (
    <Row>
      <Col>
        <div className="_lbl">Section</div>
        <p className="_txt">{section}</p>
      </Col>
      <Col>
        <div className="_lbl">Entries</div>
        <p className="_txt">{num}</p>
      </Col>
      <Col>
        <div className="_lbl">Total(S)</div>
        <p className="_txt">  {
          total?.key === "shingles" && (total?.total)
        }</p>
        {/* <p className="_txt">
          {getDataLabel(DIFFICULTY, difficulty) === "Flat"
            ? ""
            : numeral(sumTotal(dimensions)).format("0,0")}
        </p> */}
      </Col>
      <Col>
        <div className="_lbl">Total(F)</div>
        <p className="_txt">
        {
          total?.key === "flat" && (total?.total)
        }
          {/* {getDataLabel(DIFFICULTY, difficulty) === "Flat" && dimensions?.length
            ? numeral(sumTotal(dimensions)).format("0,0")
            : ""} */}
        </p>
      </Col>
    </Row>
  );
};

DimensionModalHeader.propTypes = {
  num: PropTypes.number,
  section: PropTypes.string,
  difficulty: PropTypes.any,
  dimensions: PropTypes.array
};

export default DimensionModalHeader;
