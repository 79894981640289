import React, { useEffect } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { PriceInput } from "components";

import { useDispatch, useSelector } from "react-redux";
import styles from "lib/config/baseStyles";
import displayMsg from "components/displayMsg";
import SelectSuppliers, {
  SupplierItemOnlyName,
  SelectSuppliersSection,
} from "../../SelectSupliers";
import LoadingSkeleton from "../../LoadingSkeleton";
import LabourQuoteList from "./LabourQuoteList";
import userDetails from "hooks/user/userDetails";
import {
  addMiscLabourQuote,
  changeLabourSupplier,
  getLabourSuppliers,
} from "screens/QuotesProposals/redux/proposals/proposals.actions";
import {
  hasSupplierFieldChanged,
  supplierEmptyText,
} from "screens/QuotesProposals/utils";
import QuoteUpdateLoader from "components/common/loaders/QuoteUpdateLoader";

export default function RoofingLabor({ metricId, isLockQuoteData }) {
  const dispatch = useDispatch();
  const { companyId } = userDetails();

  // New changes
  const proposalData = useSelector((state) => state.proposal);

  const {
    selectedProposal,
    isLoadingLabourSuppliers,
    labourSuppliers: suppliers,
    labourSuppliersError,
    isLoadingUpdateLabourQuote,
    isLoadingDeleteLabourMisc,
    isLoadingAddLabourMisc,
    isLoadingUpdateLabourSupplier,
  } = proposalData;

  console.log({
    proposalDataL: proposalData,
    suppliersl: suppliers,
  });

  const selectedSupplier =
    proposalData?.selectedProposal?.quotes?.labours?.supplier;

  console.log({
    selectedProposalLabour: selectedProposal,
  });

  const onChangeSupplier = (e) => {
    // changeSupplier(e.target.value, setSelectedSupplier);
    const supplierId = e.target.value;

    console.log({
      supplierId,
      e,
    });
    const body = {
      requestId: selectedProposal?.requestId,
      supplierId: supplierId,
      proposalId: selectedProposal._id,
    };

    console.log({
      bodyupdate: body,
    });
    dispatch(changeLabourSupplier(body));
  };

  const totalPrice = selectedProposal.quotes?.labours.total;

  console.log({
    totalPrice,
  });

  useEffect(() => {
    if (companyId && !suppliers?.length && !isLoadingLabourSuppliers) {
      dispatch(getLabourSuppliers(companyId));
    }
  }, [companyId]);

  const retry = () => {
    // saveLaborSectionDataToDB(quoteLaborSectionData);
  };

  const onAddMisc = () => {
    const body = {
      supplierId: selectedSupplier.supplier,
      proposalId: selectedProposal._id,
      labourQuoteId: selectedProposal.quotes.labours._id,
      workType: "",
      uomPrice: "",
      uomQty: "",
    };
    dispatch(addMiscLabourQuote(body));
  };

  return (
    <div style={{ margin: "1em" }}>
      <section>
        <Row lg="12">
          <Col style={styles.reduceWidthCol}>
            <h4 style={{ marginBottom: "-.5rem" }}>Roofing Labor</h4>
          </Col>
          <Col className="d-flex justify-content-end">
            {/* {isLoadingProposal ? null : error ? (
                  <SmRetryBtn retry={retry} />
                ) : null} */}
            <QuoteUpdateLoader
              loading={
                isLoadingUpdateLabourQuote ||
                isLoadingDeleteLabourMisc ||
                isLoadingLabourSuppliers ||
                isLoadingAddLabourMisc ||
                isLoadingUpdateLabourSupplier
              }
              retry={retry}
            />
          </Col>
        </Row>
        <Row>
          <SelectSuppliers
            selectedSupplier={selectedSupplier?._id}
            onSelectSupplier={onChangeSupplier}
            loading={false}
            suppliers={suppliers}
            emptyText={supplierEmptyText(suppliers?.length)}
            SupplierItem={SupplierItemOnlyName}
            isLockQuoteData={isLockQuoteData}
            isOriginalSupplier={hasSupplierFieldChanged(
              selectedProposal.originalQuoteSupplier,
              "labourSupplierId",
              selectedSupplier?._id
            )}
          />
        </Row>
        <SelectSuppliersSection>
          <Col
            lg="2"
            style={{
              ...styles.reduceWidthCol,
              textAlign: "center",
            }}
          >
            <Form.Label style={{ marginTop: "6%" }}>Difficulty</Form.Label>
          </Col>
          <Col
            lg="2"
            style={{
              ...styles.reduceWidthCol,
              textAlign: "center",
            }}
          >
            <Form.Label style={{ marginTop: "6%" }}>Work Type</Form.Label>
          </Col>
          <Col
            lg="2"
            style={{
              ...styles.reduceWidthCol,
              textAlign: "center",
            }}
          >
            <Form.Label style={{ marginTop: "6%" }}>Install</Form.Label>
          </Col>
        </SelectSuppliersSection>

        <LabourQuoteList
          selectedProposal={selectedProposal}
          tabData={selectedProposal?.quotes?.labours?.quotes}
          metricId={metricId}
          // MiscItemComponent={LaborMiscItem}
          LoadingSkeleton={LoadingSkeleton}
          // IndividualDataItem={LaborIndividualDataItem}
          displayMsg={displayMsg}
          isLockQuoteData={isLockQuoteData}
        />

        <MiscItemComponent
          onAddMisc={onAddMisc}
          isLockQuoteData={isLockQuoteData}
          isLoadingAddLabourMisc={isLoadingAddLabourMisc}
        />
        {totalPrice && <LaborTotalSection total={totalPrice} />}
      </section>
    </div>
  );
}

const MiscItemComponent = ({
  onAddMisc,
  isLockQuoteData,
  isLoadingAddLabourMisc,
}) => (
  <Row>
    <Col style={styles.reduceWidthCol} lg="2">
      <Button
        onClick={onAddMisc}
        disabled={isLoadingAddLabourMisc || isLockQuoteData?.isLocked}
      >
        Add Misc Item
      </Button>
    </Col>
  </Row>
);

function LaborTotalSection({ total }) {
  return (
    <Row>
      <Col style={styles.reduceWidthCol} lg="6" />
      <Col style={styles.reduceWidthCol} style={{ flex: "77%" }}>
        <Row>
          <Col style={styles.reduceWidthCol} style={{ textAlign: "right" }}>
            <Form.Label className="mt-3">Roofing Labor Sub Total:</Form.Label>
          </Col>
        </Row>
      </Col>
      <Col style={styles.reduceWidthCol}>
        <PriceInput
          price={total}
          disabled
          style={{ background: "#28a745", color: "#fff" }}
        />
      </Col>
    </Row>
  );
}
