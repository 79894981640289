import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, Spinner } from "react-bootstrap";

import Color from "lib/constants/color";

import RequiredText from "components/required-text/required-text";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

import { styles } from "../styles";
import CommonAddressFields from "components/CommonAddressFields/CommonAddressFields";
import CommonPhoneAndEmailFields from "components/CommonPhoneAndEmailFields/CommonPhoneAndEmailFields";

export default function SecondaryFields({
  selectedContact,
  editContactManagementProperty,
  contactTypes,
  relationTypess,
  openByDefault,
  editLeadLoading,
}) {
  const [isEditLeadP, setIsEditLeadP] = useState(false);

  //filed data
  const [pcAddress1, setPcAddress1] = useState("");
  const [pcAddress2, setPcAddress2] = useState("");
  const [pcCity, setPcCity] = useState("");
  const [pcState, setPcState] = useState("");
  const [pcZip, setPcZip] = useState("");

  const [phoneAndEmail, setPhoneAndEmail] = useState({
    phoneType: "",
    pNumber: "",
    phoneType2: "",
    pNumber2: "",
    phoneType3: "",
    pNumber3: "",
    emailType: "",
    email: "",
    emailType2: "",
    email2: "",
    emailType3: "",
    email3: "",
  });

  const [relationshipData, setRelationshipData] = useState({
    relationship: "",
    fname: "",
    lname: "",
  });

  const [showContact, setShowContact] = useState({
    showPhone2: false,
    showPhone3: false,
    showEmail2: false,
    showEmail3: false,
  });

  const [showSecondaryContactInfo, setShowSecondaryContactInfo] =
    useState(false);

  const changePhoneAndEmailDetail = (value) => {
    setPhoneAndEmail((prev) => ({ ...prev, ...value }));
  };

  const changeShowContact = (value) => {
    setShowContact((prev) => ({ ...prev, ...value }));
  };

  useEffect(() => {
    loadFieldData(selectedContact.primary_data[0]);
  }, [selectedContact]);

  const loadFieldData = (lead) => {
    const {
      secondaryPhone1Type,
      secondaryPhone1,
      secondaryPhone2Type,
      secondaryPhone2,
      secondaryPhone3Type,
      secondaryPhone3,
      secondaryEmail1Type,
      secondaryEmail1,
      secondaryEmail2Type,
      secondaryEmail2,
      secondaryEmail3Type,
      secondaryEmail3,
    } = lead;

    const {
      secondaryRelationship,
      secondaryFirstName,
      secondaryLastName,
      secondaryAddress1,
      secondaryAddress2,
      secondaryCity,
      secondaryState,
      secondaryZip,
    } = lead;

    setPcAddress1(secondaryAddress1);
    setPcAddress2(secondaryAddress2);
    setPcCity(secondaryCity);
    setPcState(secondaryState);
    setPcZip(secondaryZip);

    setRelationshipData({
      relationship: secondaryRelationship,
      lname: secondaryLastName,
      fname: secondaryFirstName,
    });

    setPhoneAndEmail({
      phoneType: secondaryPhone1Type,
      pNumber: secondaryPhone1,
      phoneType2: secondaryPhone2Type,
      pNumber2: secondaryPhone2,
      phoneType3: secondaryPhone3Type,
      pNumber3: secondaryPhone3,
      emailType: secondaryEmail1Type,
      email: secondaryEmail1,
      emailType2: secondaryEmail2Type,
      email2: secondaryEmail2,
      emailType3: secondaryEmail3Type,
      email3: secondaryEmail3,
    });
  };

  const handleAddressChange = (event, changeFunc) => {
    changeFunc(event.target.value);
    setIsEditLeadP(true);
  };

  const editCompanyData = () => {
    const {
      phoneType,
      pNumber,
      phoneType2,
      pNumber2,
      phoneType3,
      pNumber3,
      emailType,
      email,
      emailType2,
      email2,
      emailType3,
      email3,
    } = phoneAndEmail;

    const allData = {
      secondaryPhone1Type: phoneType,
      secondaryPhone1: pNumber,
      secondaryPhone2Type: phoneType2,
      secondaryPhone2: pNumber2,
      secondaryPhone3Type: phoneType3,
      secondaryPhone3: pNumber3,
      secondaryEmail1Type: emailType,
      secondaryEmail1: email,
      secondaryEmail2Type: emailType2,
      secondaryEmail2: email2,
      secondaryEmail3Type: emailType3,
      secondaryEmail3: email3,

      secondaryRelationship: relationshipData.relationship,
      secondaryFirstName: relationshipData.fname,
      secondaryLastName: relationshipData.lname,
      secondaryAddress1: pcAddress1,
      secondaryAddress2: pcAddress2,
      secondaryCity: pcCity,
      secondaryState: pcState,
      secondaryZip: pcZip,
    };

    return allData;
  };

  const handleOnClickEditContactInformaton = () => {
    editContactManagementProperty(selectedContact.primary_data[0]._id, {
      ...editCompanyData(),
    }).then(() => {
      setIsEditLeadP(false);
    });
  };

  const onChangeRel = (value) => {
    setRelationshipData((prev) => ({ ...prev, ...value }));
    setIsEditLeadP(true);
  };

  useEffect(() => {
    setShowSecondaryContactInfo(openByDefault);
  }, [openByDefault]);

  return (
    <Col style={{ marginTop: 25 }}>
      <Row>
        <Col md="12" sm="12" className="left-Text">
          <strong
            id="secInfoBtn"
            style={{
              color: Color.primaryColor,
              float: "right",
              cursor: "pointer",
            }}
            onClick={() => {
              setShowSecondaryContactInfo((prev) => !prev);
            }}
          >
            {`${showSecondaryContactInfo ? "Hide " : "Show "}`}
            Secondary Contact Information
          </strong>
        </Col>
      </Row>

      <Row className={`div ${showSecondaryContactInfo && "_show"}`}>
        <Col>
          <h5 style={{ padding: "25px 0", fontWeight: 600 }}>
            Secondary Contact Information
          </h5>
          <Row>
            {isEditLeadP && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="success"
                  disabled={editLeadLoading}
                  className="mb-4 btn btn-success ms-4"
                  onClick={() => handleOnClickEditContactInformaton()}
                >
                  {editLeadLoading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />{" "}
                      Saving...
                    </>
                  ) : (
                    "Save"
                  )}
                </Button>
                <button
                  type="button"
                  className="mb-4 btn btn-danger ms-4"
                  disabled={editLeadLoading}
                  onClick={() => {
                    setIsEditLeadP(false);
                    loadFieldData(selectedContact.primary_data[0]);
                  }}
                >
                  Cancel
                </button>
              </div>
            )}

            <RelationshipInfo
              relationTypess={relationTypess}
              relationshipData={relationshipData}
              onChangeRel={onChangeRel}
            />
            <CommonPhoneAndEmailFields
              changePhoneAndEmailDetail={changePhoneAndEmailDetail}
              neededData={contactTypes}
              showData={showContact}
              formData={phoneAndEmail}
              required={true}
              setIsEditLead={setIsEditLeadP}
            />
          </Row>

          <Row>
            <CommonAddressFields
              required={true}
              address1Name="pcAddress1"
              address1={pcAddress1}
              address2Name="pcAddress2"
              address2={pcAddress2}
              cityName="pcCity"
              city={pcCity}
              stateName="pcState"
              state={pcState}
              zipName="pcZip"
              zip={pcZip}
              setAddress1={setPcAddress1}
              setAddress2={setPcAddress2}
              setCity={setPcCity}
              setState={setPcState}
              setZip={setPcZip}
              handleChange={handleAddressChange}
            />
          </Row>
        </Col>
      </Row>
    </Col>
  );
}

const RelationshipInfo = ({
  relationTypess,
  relationshipData,
  onChangeRel,
  required = false,
}) => {
  const { relationship, fname, lname } = relationshipData;

  const displayRelationshipTypes = () => {
    const filterData = relationTypess?.relationdata?.filter((elem) => {
      return elem.isDeleted !== true;
    });
    return filterData?.map((item) => (
      <option key={item?._id} value={item?._id}>
        {item?.roleType}
      </option>
    ));
  };
  return (
    <Col md="12" sm="12">
      <Row>
        <Col md="6" sm="12">
          {relationTypess && (
            <Row>
              <Col md="6" sm="12">
                <Form.Group style={styles.formInput}>
                  <Form.Label>
                    Relationship<RequiredText>*</RequiredText>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={relationship}
                    onChange={(e) => {
                      onChangeRel({ relationship: e.target.value });
                    }}
                  >
                    <option value="">Choose...</option>
                    {displayRelationshipTypes()}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md="6" sm="12">
                <Form.Group style={styles.formInput}>
                  <Form.Label disabled={!relationship}>
                    First Name {required && <RequiredText>*</RequiredText>}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={fname}
                    disabled={!relationship}
                    onChange={(event) => {
                      onChangeRel({ fname: event.target.value });
                    }}
                    // isInvalid={errAt === fnameName}
                  />
                </Form.Group>
              </Col>
            </Row>
          )}
        </Col>

        <Col sm="12" md="6">
          <Form.Group style={styles.formInput}>
            <Form.Label>
              Last Name {required && <RequiredText>*</RequiredText>}
            </Form.Label>
            <Form.Control
              type="text"
              value={lname}
              disabled={!relationship}
              onChange={(event) => {
                onChangeRel({ lname: event.target.value });
              }}
              //isInvalid={errAt === lnameName}
            />
          </Form.Group>
        </Col>
      </Row>
    </Col>
  );
};
