import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { CSSTransition } from "react-transition-group";
import { Form } from "react-bootstrap";
import useProjectData from "hooks/useProjectManagement";
import NotesMap from "screens/project-management/components/notesMap";
import { LoadingLoader } from "components";

const styles = {
  container: {
    boxShadow: "0 0 10px #99999996",
    background: "#fff",
    fontWeight: "bold",
    // padding: "2em 1em",
  },
  heading: {
    flex: 1,
  },
  header: {
    padding: "25px 0",
  },
  formInput: {
    marginBottom: "25px",
  },
  formStyle: {
    // marginTop: "5%"
  },
};

export function JobNotes({ proposalId }) {
  const [delNotes, setDelNotes] = useState(false);
  const [addNotes, setAddNotes] = useState(false);
  const [onChangeNotes, setOnChangeNotes] = useState("");
  const [notes, setNotes] = useState();
  const [loading, setLoading] = useState(false);

  let {
    saveProjectNotes,
    getNotesForPM,
    getNotesEdit,
    deleteProjectNotes,
    isAddOrDelete,
  } = useProjectData({ proposalId: proposalId });


  useEffect(() => {
    let func = async () => {
      setLoading(true);
      let notes_company = await getNotesForPM({ proposalId: proposalId });
      setNotes(notes_company);
      setLoading(false);
    };
    if (!isAddOrDelete) {
      func();
    }
  }, [addNotes, delNotes, isAddOrDelete]);

  return (
    <Container style={{ margin: 0 }}>
      <Row>
        <Col style={{ marginTop: "1.6rem" }}>
          <button
            type="button"
            className="btn btn-primary"
            // style={{  bottom: "2rem" }}
            onClick={() => setAddNotes(true)}
          >
            Add Notes
          </button>
        </Col>
      </Row>

      <Row>
        {loading ? (
          <LoadingLoader />
        ) : !notes || notes.response.length == 0 ? (
          <div>No notes created yet</div>
        ) : (
          notes.response.map((note) => {
            return (
              <NotesMap
                styles={styles}
                notes={notes}
                addNotes={addNotes}
                setAddNotes={setAddNotes}
                getNotesEdit={getNotesEdit}
                note={note}
                deleteProjectNotes={deleteProjectNotes}
                setDelNotes={setDelNotes}
                delNotes={delNotes}
              />
            );
          })
        )}
        <CSSTransition
          in={addNotes}
          timeout={300}
          // classNames="list_item"
          unmountOnExit
        >
          <>
            <Col md="6" sm="12">
              <Form.Group style={styles.formInput}>
                <Form.Label></Form.Label>
                <Form.Control
                  as="textarea"
                  name={""}
                  onChange={(e) => setOnChangeNotes(e.target.value)}
                ></Form.Control>
              </Form.Group>
            </Col>

            <Col md="1" sm="12">
              <button
                type="button"
                className="btn btn-primary"
                style={{ marginTop: "2rem", marginLeft: "1rem" }}
                onClick={() => {
                  saveProjectNotes({
                    proposalId: proposalId,
                    notes: onChangeNotes,
                  });
                  setAddNotes(false);
                  setDelNotes(!delNotes);
                }}
              >
                Save
              </button>
            </Col>
            <Col md="5" sm="12">
              <button
                type="button"
                className="btn btn-warning"
                style={{ marginTop: "2rem", marginLeft: "2.5rem" }}
                onClick={() => {
                  setAddNotes(false);
                }}
              >
                Cancel
              </button>
            </Col>
            <Col></Col>
          </>
        </CSSTransition>
      </Row>
    </Container>
  );
}
