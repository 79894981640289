import React, { Component, useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Alert,
  Form,
  Modal,
  InputGroup,
  Spinner,
} from "react-bootstrap";

import RequiredText from "components/required-text/required-text";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

import { styles } from "../styles";
import CommonAddressFields from "components/CommonAddressFields/CommonAddressFields";
import CommonPhoneAndEmailFields from "components/CommonPhoneAndEmailFields/CommonPhoneAndEmailFields";

export default function PrimaryFields({
  selectedContact,
  editContactManagementProperty,
  contactTypes,
  editLeadLoading,
}) {
  const [isEditLeadP, setIsEditLeadP] = useState(false);

  //filed data
  const [pcAddress1, setPcAddress1] = useState("");
  const [pcAddress2, setPcAddress2] = useState("");
  const [pcCity, setPcCity] = useState("");
  const [pcState, setPcState] = useState("");
  const [pcZip, setPcZip] = useState("");

  const [pcFname, setPcFname] = useState("");
  const [pcLname, setPcLname] = useState("");

  const [phoneAndEmail, setPhoneAndEmail] = useState({
    phoneType: "",
    pNumber: "",
    phoneType2: "",
    pNumber2: "",
    phoneType3: "",
    pNumber3: "",
    emailType: "",
    email: "",
    emailType2: "",
    email2: "",
    emailType3: "",
    email3: "",
  });

  const [showContact, setShowContact] = useState({
    showPhone2: false,
    showPhone3: false,
    showEmail2: false,
    showEmail3: false,
  });

  const changePhoneAndEmailDetail = (value) => {
    setPhoneAndEmail((prev) => ({ ...prev, ...value }));
  };

  const changeShowContact = (value) => {
    setShowContact((prev) => ({ ...prev, ...value }));
  };

  useEffect(() => {
    loadFieldData(selectedContact.primary_data[0]);
  }, [selectedContact]);

  const loadFieldData = (lead) => {
    const {
      primaryPhone1Type,
      primaryPhone1,
      primaryPhone2Type,
      primaryPhone2,
      primaryPhone3Type,
      primaryPhone3,
      primaryEmail1Type,
      primaryEmail1,
      primaryEmail2Type,
      primaryEmail2,
      primaryEmail3Type,
      primaryEmail3,
    } = lead;

    const {
      primaryFirstName,
      primaryLastName,
      primaryAddress1,
      primaryAddress2,
      primaryCity,
      primaryState,
      primaryZip,
    } = lead;

    setPcAddress1(primaryAddress1);
    setPcAddress2(primaryAddress2);
    setPcCity(primaryCity);
    setPcState(primaryState);
    setPcZip(primaryZip);
    setPcFname(primaryFirstName);
    setPcLname(primaryLastName);

    setPhoneAndEmail({
      phoneType: primaryPhone1Type,
      pNumber: primaryPhone1,
      phoneType2: primaryPhone2Type,
      pNumber2: primaryPhone2,
      phoneType3: primaryPhone3Type,
      pNumber3: primaryPhone3,
      emailType: primaryEmail1Type,
      email: primaryEmail1,
      emailType2: primaryEmail2Type,
      email2: primaryEmail2,
      emailType3: primaryEmail3Type,
      email3: primaryEmail3,
    });
  };

  const handleAddressChange = (event, changeFunc) => {
    changeFunc(event.target.value);
    setIsEditLeadP(true);
  };

  const editCompanyData = () => {
    const {
      phoneType,
      pNumber,
      phoneType2,
      pNumber2,
      phoneType3,
      pNumber3,
      emailType,
      email,
      emailType2,
      email2,
      emailType3,
      email3,
    } = phoneAndEmail;

    const allData = {
      primaryPhone1Type: phoneType,
      primaryPhone1: pNumber,
      primaryPhone2Type: phoneType2,
      primaryPhone2: pNumber2,
      primaryPhone3Type: phoneType3,
      primaryPhone3: pNumber3,
      primaryEmail1Type: emailType,
      primaryEmail1: email,
      primaryEmail2Type: emailType2,
      primaryEmail2: email2,
      primaryEmail3Type: emailType3,
      primaryEmail3: email3,

      primaryFirstName: pcFname,
      primaryLastName: pcLname,
      primaryAddress1: pcAddress1,
      primaryAddress2: pcAddress2,
      primaryCity: pcCity,
      primaryState: pcState,
      primaryZip: pcZip,
    };

    return allData;
  };

  const handleOnClickEditContactInformaton = () => {
    editContactManagementProperty(selectedContact.primary_data[0]._id, {
      ...editCompanyData(),
    }).then(() => {
      setIsEditLeadP(false);
    });
  };

  return (
    <Col xs="12" md="12">
      <div className="d-flex align-items-center">
        <h5 style={{ padding: "25px 0", fontWeight: 600 }}>
          Primary Contact Information
        </h5>
        <span className="ms-3">
          <RequiredText>* Required</RequiredText>
        </span>
      </div>

      <Row>
        {isEditLeadP && (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="success"
              disabled={editLeadLoading}
              className="mb-4 btn btn-success ms-4"
              onClick={() => handleOnClickEditContactInformaton()}
            >
              {editLeadLoading ? (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{" "}
                  Saving...
                </>
              ) : (
                "Save"
              )}
            </Button>

            <button
              type="button"
              className="mb-4 btn btn-danger ms-4"
              disabled={editLeadLoading}
              onClick={() => {
                setIsEditLeadP(false);
                loadFieldData(selectedContact.primary_data[0]);
              }}
            >
              Cancel
            </button>
          </div>
        )}

        <Col xs="12" md="6">
          <Form.Group style={styles.formInput}>
            <Form.Label>
              First Name <RequiredText>*</RequiredText>{" "}
              <SubScriptText>Primary Contact</SubScriptText>
            </Form.Label>
            <Form.Control
              type="text"
              name="pcFname"
              value={pcFname}
              placeholder="Enter First Name"
              onChange={(event) => {
                setPcFname(event.target.value);
                setIsEditLeadP(true);
              }}
              //isInvalid={errAt === "pcFname"}
            />
          </Form.Group>
        </Col>

        <Col xs="12" md="6">
          <Form.Group style={styles.formInput}>
            <Form.Label>
              Last Name <RequiredText>*</RequiredText>{" "}
              <SubScriptText>Primary Contact</SubScriptText>
            </Form.Label>
            <Form.Control
              type="text"
              name="pcLname"
              value={pcLname}
              placeholder="Enter Last Name"
              onChange={(event) => {
                setPcLname(event.target.value);
                setIsEditLeadP(true);
              }}
              //isInvalid={errAt === "pcLname"}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <CommonPhoneAndEmailFields
          changePhoneAndEmailDetail={changePhoneAndEmailDetail}
          neededData={contactTypes}
          showData={showContact}
          formData={phoneAndEmail}
          required={true}
          setIsEditLead={setIsEditLeadP}
        />
      </Row>

      <Row>
        <CommonAddressFields
          required={true}
          address1Name="pcAddress1"
          address1={pcAddress1}
          address2Name="pcAddress2"
          address2={pcAddress2}
          cityName="pcCity"
          city={pcCity}
          stateName="pcState"
          state={pcState}
          zipName="pcZip"
          zip={pcZip}
          setAddress1={setPcAddress1}
          setAddress2={setPcAddress2}
          setCity={setPcCity}
          setState={setPcState}
          setZip={setPcZip}
          handleChange={handleAddressChange}
        />
      </Row>
    </Col>
  );
}

const SubScriptText = ({ children }) => (
  <sub style={{ color: "#999" }}>({children})</sub>
);
