import { suppliersType } from "lib/constants/const";

import { axiosInstance } from "services";
import { SuppliersActionTypes } from "./suppliers.action.types";
import { pricingCarting } from "services/offlineComputing/routes/generateQuote";
import { getUserLocal } from "lib/constants/localbase";
import { ApiEndpoints } from "lib/config/baseSettings";

export const getMSuppliers = () => (dispatch) => {
  dispatch({ type: SuppliersActionTypes.GET_MSUPPLIERS_START });

  if (!navigator.onLine) {
    let stored_data_supplier = JSON.parse(
      localStorage.getItem("persist:persist-store")
    );
    const material_supplier = JSON.parse(
      stored_data_supplier.materialSuppliers
    );

    return dispatch({
      type: SuppliersActionTypes.GET_MSUPPLIERS_SUCCESS,
      payload: material_supplier.mSuppliersData,
    });
  } else {
    return axiosInstance
      .get(ApiEndpoints.MATERIAL_SUPPLIERS.M_SUPPLIERS)
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          return dispatch({
            type: SuppliersActionTypes.GET_MSUPPLIERS_SUCCESS,
            payload: data.result,
          });
        }
        return dispatch({
          type: SuppliersActionTypes.GET_MSUPPLIERS_FAILURE,
          payload: data.error,
        });
      })
      .catch((errors) =>
        dispatch({
          type: SuppliersActionTypes.GET_MSUPPLIERS_FAILURE,
          payload: errors.message,
        })
      );
  }
};

/**
 *
 *
 */
export const getMaterialOptions = () => (dispatch) => {
  dispatch({ type: SuppliersActionTypes.GET_MSUPPLIERSOPTIONS_START });

  return axiosInstance
    .get("/admin/materialOld/getMaterialPricingOptions")
    .then((res) => res.data)
    .then((data) => {
      if (data.success) {
        return dispatch({
          type: SuppliersActionTypes.GET_MSUPPLIERSOPTIONS_SUCCESS,
          payload: data.result,
        });
      }
      return dispatch({
        type: SuppliersActionTypes.GET_MSUPPLIERSOPTIONS_FAILURE,
        payload: data.error,
      });
    })
    .catch((errors) =>
      dispatch({
        type: SuppliersActionTypes.GET_MSUPPLIERSOPTIONS_FAILURE,
        payload: errors.message,
      })
    );
};

/**
 *
 */
export const addMSuppliers = (data) => (dispatch) =>
  axiosInstance.post(ApiEndpoints.MATERIAL_SUPPLIERS.M_SUPPLIERS, data);

export const updateMSuppliers = (id, data) => (dispatch) =>
  axiosInstance.patch(
    `${ApiEndpoints.MATERIAL_SUPPLIERS.M_SUPPLIERS}/${id}`,
    data
  );

export const deleteMSuppliers = (id) => (dispatch) =>
  axiosInstance.delete(`${ApiEndpoints.MATERIAL_SUPPLIERS.M_SUPPLIERS}/${id}`);

export const getPricingMaterial = (data, id) => {
  const input = {
    q: data,
  };
  return (dispatch) => {
    dispatch({ type: SuppliersActionTypes.PRICING_MATERIAL_START });

    return axiosInstance
      .post(`${ApiEndpoints.MATERIAL_SUPPLIERS.PRICING_MATERIAL}/${id}`, {
        ...input,
      })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          return dispatch({
            type: SuppliersActionTypes.PRICING_MATERIAL_SUCCESS,
            payload: data.result,
          });
        }
        return dispatch({
          type: SuppliersActionTypes.PRICING_MATERIAL_FAILURE,
          payload: data.error,
        });
      })
      .catch((errors) =>
        dispatch({
          type: SuppliersActionTypes.PRICING_MATERIAL_FAILURE,
          payload: errors.message,
        })
      );
  };
};

export const patchMSuppliers = (id, data) => (dispatch) =>
  axiosInstance.patch(
    `${ApiEndpoints.MATERIAL_SUPPLIERS.PRICING_MATERIAL}/${id}`,
    data
  );

export const resetPricingMaterial = () => (dispatch) => {
  dispatch({ type: SuppliersActionTypes.PRICING_MATERIAL_RESET });
};

export const addMPricingCustom = (data, id) => (dispatch) =>
  axiosInstance.post(
    `${ApiEndpoints.MATERIAL_SUPPLIERS.ADD_PRICING_CUSTOM}/${id}`,
    {
      custom: data,
    }
  );

export const deleteMPricingCustom = (id) => (dispatch) =>
  axiosInstance.delete(
    `${ApiEndpoints.MATERIAL_SUPPLIERS.PRICING_MATERIAL}/${id}`
  );

export const resetPreferdMaterial = () => (dispatch) => {
  dispatch({ type: SuppliersActionTypes.PREFERD_MATERIAL_RESET });
};

export const getSubcontractorSuppliers = () => (dispatch) => {
  dispatch({ type: SuppliersActionTypes.GET_SUBCONTRACTORSUPPLIERS_START });

  if (!navigator.onLine) {
    let stored_data_supplier = JSON.parse(
      localStorage.getItem("persist:persist-store")
    );

    const subcontractor_supplier = JSON.parse(
      stored_data_supplier.subcontractorSuppliers
    );

    return dispatch({
      type: SuppliersActionTypes.GET_SUBCONTRACTORSUPPLIERS_SUCCESS,
      payload: subcontractor_supplier.subsSuppliersData,
    });
  } else {
    return axiosInstance
      .get(ApiEndpoints.SUBCONTRACTOR_SUPPLIERS.SUBCONTRACTOR_SUPPLIERS)
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          return dispatch({
            type: SuppliersActionTypes.GET_SUBCONTRACTORSUPPLIERS_SUCCESS,
            payload: data.result,
          });
        }
        return dispatch({
          type: SuppliersActionTypes.GET_SUBCONTRACTORSUPPLIERS_FAILURE,
          payload: data.error,
        });
      })
      .catch((errors) =>
        dispatch({
          type: SuppliersActionTypes.GET_SUBCONTRACTORSUPPLIERS_FAILURE,
          payload: errors.message,
        })
      );
  }
};

export const addSubcontractorSuppliers = (data) => (dispatch) =>
  axiosInstance.post(
    ApiEndpoints.SUBCONTRACTOR_SUPPLIERS.SUBCONTRACTOR_SUPPLIERS,
    data
  );

export const updateSubcontractorSuppliers = (id, data) => (dispatch) =>
  axiosInstance.patch(
    `${ApiEndpoints.SUBCONTRACTOR_SUPPLIERS.SUBCONTRACTOR_SUPPLIERS}/${id}`,
    data
  );

export const deleteSubcontractorSuppliers = (id) => (dispatch) =>
  axiosInstance.delete(
    `${ApiEndpoints.SUBCONTRACTOR_SUPPLIERS.SUBCONTRACTOR_SUPPLIERS}/${id}`
  );

export const getCrewSuppliers = () => (dispatch) => {
  dispatch({ type: SuppliersActionTypes.GET_CREWSUPPLIERS_START });
  if (!navigator.onLine) {
    let stored_data_supplier = JSON.parse(
      localStorage.getItem("persist:persist-store")
    );

    const subcontractor_supplier = JSON.parse(
      stored_data_supplier.crewSuppliers
    );

    return dispatch({
      type: SuppliersActionTypes.GET_CREWSUPPLIERS_SUCCESS,
      payload: subcontractor_supplier.crewSuppliersData,
    });
  } else {
    return axiosInstance
      .get(ApiEndpoints.CREW_SUPPLIERS.CREW_SUPPLIERS)
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          return dispatch({
            type: SuppliersActionTypes.GET_CREWSUPPLIERS_SUCCESS,
            payload: data.result,
          });
        }
        return dispatch({
          type: SuppliersActionTypes.GET_CREWSUPPLIERS_FAILURE,
          payload: data.error,
        });
      })
      .catch((errors) =>
        dispatch({
          type: SuppliersActionTypes.GET_CREWSUPPLIERS_FAILURE,
          payload: errors.message,
        })
      );
  }
};

export const addCrewSuppliers = (data) => (dispatch) =>
  axiosInstance.post(ApiEndpoints.CREW_SUPPLIERS.CREW_SUPPLIERS, data);

export const updateCrewSuppliers = (id, data) => (dispatch) =>
  axiosInstance.patch(
    `${ApiEndpoints.CREW_SUPPLIERS.CREW_SUPPLIERS}/${id}`,
    data
  );

export const deleteCrewSuppliers = (id) => (dispatch) =>
  axiosInstance.delete(`${ApiEndpoints.CREW_SUPPLIERS.CREW_SUPPLIERS}/${id}`);

export const getCartingSuppliers = () => (dispatch) => {
  dispatch({ type: SuppliersActionTypes.GET_CARTINGSUPPLIERS_START });

  if (!navigator.onLine) {
    let stored_data_supplier = JSON.parse(
      localStorage.getItem("persist:persist-store")
    );

    const carting_supplier = JSON.parse(stored_data_supplier.cartingSupplier);
    return dispatch({
      type: SuppliersActionTypes.GET_CARTINGSUPPLIERS_SUCCESS,
      payload: carting_supplier.cartingSuppliersData,
    });
  } else {
    return axiosInstance
      .get(ApiEndpoints.CARTING_SUPPLIERS.CARTING_SUPPLIERS)
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          return dispatch({
            type: SuppliersActionTypes.GET_CARTINGSUPPLIERS_SUCCESS,
            payload: data.result,
          });
        }
        return dispatch({
          type: SuppliersActionTypes.GET_CARTINGSUPPLIERS_FAILURE,
          payload: data.error,
        });
      })
      .catch((errors) =>
        dispatch({
          type: SuppliersActionTypes.GET_CARTINGSUPPLIERS_FAILURE,
          payload: errors.message,
        })
      );
  }
};

export const addCartingSuppliers = (data) => (dispatch) =>
  axiosInstance.post(ApiEndpoints.CARTING_SUPPLIERS.CARTING_SUPPLIERS, data);

export const updateCartingSuppliers = (id, data) => (dispatch) =>
  axiosInstance.patch(
    `${ApiEndpoints.CARTING_SUPPLIERS.CARTING_SUPPLIERS}/${id}`,
    data
  );

export const deleteCartingSuppliers = (id) => (dispatch) =>
  axiosInstance.delete(
    `${ApiEndpoints.CARTING_SUPPLIERS.CARTING_SUPPLIERS}/${id}`
  );

export const toggleIsPreferred = (suppTypeIdx, data) => (dispatch) =>
  axiosInstance.patch(
    `${ApiEndpoints.TOGGLE_PREFERRED_SUPPLIER.TOGGLE_IS_PREFERRED}/${suppliersType[suppTypeIdx]}`,
    data
  );

export const getPricingLabor = (data, id) => {
  const input = {
    q: data,
  };
  return (dispatch) => {
    dispatch({ type: SuppliersActionTypes.PRICING_LABOR_START });

    return axiosInstance
      .post(`${ApiEndpoints.LABOR_PRICING.PRICING_LABOR}/${id}`, { ...input })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          return dispatch({
            type: SuppliersActionTypes.PRICING_LABOR_SUCCESS,
            payload: data.result,
          });
        }
        return dispatch({
          type: SuppliersActionTypes.PRICING_LABOR_FAILURE,
          payload: data.error,
        });
      })
      .catch((errors) =>
        dispatch({
          type: SuppliersActionTypes.PRICING_LABOR_FAILURE,
          payload: errors.message,
        })
      );
  };
};

export const resetPricingLabor = () => (dispatch) => {
  dispatch({ type: SuppliersActionTypes.PRICING_LABOR_RESET });
};

export const patchLSuppliers = (id, data) => (dispatch) =>
  axiosInstance.patch(
    `${ApiEndpoints.LABOR_PRICING.PRICING_LABOR}/${id}`,
    data
  );

export const addLPricingCustom = (data, id) => (dispatch) =>
  axiosInstance.post(
    `${ApiEndpoints.LABOR_PRICING.ADD_L_PRICING_CUSTOM}/${id}`,
    {
      custom: data,
    }
  );

export const deleteLPricingCustom = (id) => (dispatch) =>
  axiosInstance.delete(`${ApiEndpoints.LABOR_PRICING.PRICING_LABOR}/${id}`);

export const getPricingCarting = (data, id) => {
  const input = {
    q: data,
  };
  return async (dispatch) => {
    dispatch({ type: SuppliersActionTypes.PRICING_CARTING_START });

    if (!navigator.onLine) {
      try {
        let userDb = await getUserLocal();

        const companyId = userDb.userData.companyId;

        if (!companyId) {
          throw "companyId not found";
        }
        const result = await pricingCarting({
          supplierId: id,
          postData: input,
          companyId,
        });

        return dispatch({
          type: SuppliersActionTypes.PRICING_CARTING_SUCCESS,
          payload: result,
        });
      } catch (err) {
        console.error(err);
        return dispatch({
          type: SuppliersActionTypes.PRICING_CARTING_FAILURE,
          payload: err,
        });
      }
    }

    console.log("horray", id);

    return axiosInstance
      .post(`${ApiEndpoints.CARTING_SUPPLIERS.PRICING_CARTING}/${id}`, {
        ...input,
      })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          return dispatch({
            type: SuppliersActionTypes.PRICING_CARTING_SUCCESS,
            payload: data.result,
          });
        }
        return dispatch({
          type: SuppliersActionTypes.PRICING_CARTING_FAILURE,
          payload: data.error,
        });
      })
      .catch((errors) =>
        dispatch({
          type: SuppliersActionTypes.PRICING_CARTING_FAILURE,
          payload: errors.message,
        })
      );
  };
};

export const patchCartingSuppliers = (id, data) => (dispatch) =>
  axiosInstance.patch(
    `${ApiEndpoints.CARTING_SUPPLIERS.PRICING_CARTING}/${id}`,
    data
  );

export const resetPricingCarting = () => (dispatch) => {
  dispatch({ type: SuppliersActionTypes.PRICING_CARTING_RESET });
};

export const getPricingCrew = (data) => {
  const input = {
    q: data,
  };
  return (dispatch) => {
    dispatch({ type: SuppliersActionTypes.PRICING_CREW_START });

    return axiosInstance
      .post(ApiEndpoints.CREW_SUPPLIERS.PRICING_CREW, { ...input })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          return dispatch({
            type: SuppliersActionTypes.PRICING_CREW_SUCCESS,
            payload: data.result,
          });
        }
        return dispatch({
          type: SuppliersActionTypes.PRICING_CREW_FAILURE,
          payload: data.error,
        });
      })
      .catch((errors) =>
        dispatch({
          type: SuppliersActionTypes.PRICING_CREW_FAILURE,
          payload: errors.message,
        })
      );
  };
};

export const resetPricingCrew = () => (dispatch) => {
  dispatch({ type: SuppliersActionTypes.PRICING_CREW_RESET });
};

export const patchCrewSuppliers = (id, data) => (dispatch) =>
  axiosInstance.patch(
    `${ApiEndpoints.CREW_SUPPLIERS.PRICING_CREW}/${id}`,
    data
  );

export const addCrewPricingCustom = (data) => (dispatch) =>
  axiosInstance.post(ApiEndpoints.CREW_SUPPLIERS.ADD_CREW_PRICING_CUSTOM, {
    custom: data,
  });

export const deleteCrewPricingCustom = (id) => (dispatch) =>
  axiosInstance.delete(`${ApiEndpoints.CREW_SUPPLIERS.PRICING_CREW}/${id}`);
