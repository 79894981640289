import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";

const ElevationMetric = ({
  metricsData = [],
  selectedData = [],
  isDisable = false,
  handleClick = () => {},
}) => {
  // Button component for rendering individual buttons
  const renderButton = (text) => (
    <button
      disabled={isDisable}
      onClick={() => handleClick(text)}
      className={`ClickableBtn ${
        selectedData.includes(text) ? "selected" : ""
      }`}
      style={{ width: "3.2rem" }}
    >
      {text}
    </button>
  );

  return (
    <>
      <Row className="d-flex flex-row">
        {["Front", "Back"].map((text) => renderButton(text))}
      </Row>

      <Row className="d-flex flex-row">
        {["Left", "Right"].map((text) => renderButton(text))}
      </Row>
    </>
  );
};

ElevationMetric.propTypes = {
  metricsData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  selectedData: PropTypes.arrayOf(PropTypes.string),
  isDisable: PropTypes.bool,
  handleClick: PropTypes.func,
};

export default ElevationMetric;
