import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import PopupModal from "components/popup-modal/popup-modal";
import DimensionModalHeader from "./DimensionModalHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import numeral from "numeral";
import Numpad from "./Numpad";
import { faEquals, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { sumDimensonTotal } from "utils/utils";

const getIcon = (icon) => <FontAwesomeIcon icon={icon} />;

const DimensionModal = ({
  show,
  label,
  hide,
  length,
  width,
  factor,
  total,
  selectDimension,
  setSelectedDimension,
  addDimensionData,
  editDimensionName,
  handleAddDimensionData,
  setDimension,
  deleteCurrentValue,
}) => {
  const roofData = useSelector((state) => state.metric.metrics);
  const [showTally, setShowTally] = useState(false);

  const { installTypes, dimensions } = roofData;

  console.log({
    dimensions,
    installTypes,
  });

  const hideModal = () => {
    setShowTally(false);
    hide();
  };
  useEffect(() => {
    // Cleanup function
    return () => {
      setShowTally(false);
    };
  }, []);

  const getTotalValueForHeader = () => {
    const total = numeral(sumDimensonTotal(dimensions)).format("0,0");

    if (installTypes) {
      const keys = Object.keys(installTypes);
      if (keys?.length && keys[0] === "shingles") {
        return { key: "shingles", total };
      }

      if (keys?.length && keys[0] === "flat") {
        return { key: "flat", total };
      }
    }
  };

  const getTypeOfRoofing = (type) => {
    const total = numeral(sumDimensonTotal(dimensions)).format("0,0");

    if (installTypes) {
      const keys = Object.keys(installTypes);

      // Check if the first key is "shingles" and the requested type is "shingles"
      if (keys?.length && keys[0] === "shingles" && type === "shingles") {
        return type; // Return "shingles" instead of total
      }

      // Check if the first key is "flat" and the requested type is "flat"
      if (keys?.length && keys[0] === "flat" && type === "flat") {
        return type; // Return "flat" instead of total
      }
    }

    return null; // Return null if no conditions are met
  };

  const editCurrentValue = (value) => {
    // setDimension()
    console.log({
      value,
    });

    setShowTally(false);
    setDimension(value);
  };

  const renderTally = () => {
    const inputsArray = [
      { key: "length", name: "Length" },
      { key: "width", name: "Width" },
      { key: "factor", name: "Factor" },
      { key: "sq_ft_s", name: "SQ Ft(S)" },
      { key: "sq_ft_f", name: "SQ Ft(F)" },
    ];

    return (
      <>
        <div className="slide-right">
          <table className="dimen_table">
            <thead>
              <tr>
                <th></th>
                {inputsArray.map((input) => (
                  <th key={input.key}>{input.name}</th>
                ))}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {dimensions.map((value, index) => (
                <tr key={index}>
                  <td>
                    <Button onClick={() => editCurrentValue(value)}>
                      Edit
                    </Button>
                  </td>
                  {/* Render input values */}
                  <td>{value?.length}</td>

                  <td>{value?.width}</td>

                  <td>{value?.factor}</td>

                  <td>
                    {getTypeOfRoofing("shingles") === "shingles" &&
                      value?.total}
                  </td>
                  <td>
                    {/* {value?.total} */}
                    <td>
                      {getTypeOfRoofing("flat") === "flat" && value?.total}
                    </td>
                  </td>
                  {/* Render delete button */}
                  <td>
                    <Button
                      variant="danger"
                      onClick={() => deleteCurrentValue(value)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  return (
    <PopupModal
      show={show}
      onHide={hideModal}
      heading={`${showTally ? `${label} Tally` : `Enter ${label}`}`}
      bold
      size="lg"
      scrollable={false}
      styles={{ padding: "1em 1em 0 1em", border: "none" }}
    >
      <div className="_carsl">
        <DimensionModalHeader
          dimensions={dimensions}
          section="1"
          num={1}
          total={getTotalValueForHeader()}
        />

        {!showTally ? (
          <div className="slide-left view">
            <div className="d-flex justify-content-center align-items-center mx-5">
              <DimensionBlock
                label="L"
                value={length}
                selected={selectDimension === "length"}
                onClick={() => setSelectedDimension("length")}
              />
              <IconBlock icon={faTimes} />
              <DimensionBlock
                label="W"
                value={width}
                selected={selectDimension === "width"}
                onClick={() => setSelectedDimension("width")}
              />
              <IconBlock icon={faTimes} />
              <DimensionBlock
                label="F"
                value={factor}
                selected={selectDimension === "factor"}
                onClick={() => setSelectedDimension("factor")}
              />
              <IconBlock icon={faEquals} />
              <DimensionBlock
                label="Total"
                value={numeral(total).format("0,0")}
              />
            </div>
            <Numpad
              addIntoDimen={addDimensionData}
              selectDimen={selectDimension}
              handleAdd={handleAddDimensionData}
              editDimen={editDimensionName}
            />
          </div>
        ) : (
          renderTally()
        )}

        <ModalFooter
          hide={hideModal}
          showTally={showTally}
          setShowTally={setShowTally}
          dimensions={dimensions}
        />
      </div>
    </PopupModal>
  );
};

const DimensionBlock = ({ label, value, selected, onClick }) => (
  <div
    onClick={onClick}
    className={`_block ${selected ? "selected" : "unselected"}`}
  >
    <label className="_block-lbl">{label}</label>
    <p className="_block-txt">{value}</p>
  </div>
);

const IconBlock = ({ icon }) => <div className="icon-btw">{getIcon(icon)}</div>;

const ModalFooter = ({ hide, dimensions, setShowTally, showTally }) => (
  <Modal.Footer
    style={{
      justifyContent: "flex-end",
      border: "none",
      padding: "0 .75rem",
    }}
  >
    <Button className="modified_btn" variant="danger" onClick={hide}>
      Close
    </Button>
    <Button
      className="modified_btn"
      disabled={!dimensions?.length}
      onClick={() => setShowTally((prevState) => !prevState)}
    >
      {showTally ? "Keypad" : "  View Tally"}
    </Button>
  </Modal.Footer>
);

DimensionModal.propTypes = {
  show: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  hide: PropTypes.func.isRequired,
  length: PropTypes.number,
  width: PropTypes.number,
  factor: PropTypes.number,
  total: PropTypes.number,
  selectDimension: PropTypes.string,
  setSelectedDimension: PropTypes.func.isRequired,
  lwfData: PropTypes.array,
  addDimensionData: PropTypes.func.isRequired,
  handleAddDimensionData: PropTypes.func.isRequired,
  editDimensionName: PropTypes.string,
};

const DimensionBlockPropTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

DimensionBlock.propTypes = DimensionBlockPropTypes;
IconBlock.propTypes = {
  icon: PropTypes.object.isRequired,
};
ModalFooter.propTypes = {
  hide: PropTypes.func.isRequired,
  lwfDataLength: PropTypes.number.isRequired,
};

export default DimensionModal;
