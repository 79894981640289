import React from "react";
import PropTypes from "prop-types";
import { Button, Col } from "react-bootstrap";

const MultipleMetric = ({
  metricsData = [],
  selectedData = [],
  isDisable,
  isQuoteLock = false,
  handleClick,
}) => {
  if (!metricsData.length) {
    return <div>metricsData not available</div>;
  }

  return metricsData.map((metric, index) => (
    <Col md="6" key={metric.id}>
      <button
        disabled={isDisable}
        onClick={() => handleClick(metric.name)}
        className={`ClickableBtn ${
          selectedData.includes(metric.name) ? "selected" : ""
        }`}
        style={
          isQuoteLock && selectedData.includes(metric.name)
            ? { backgroundColor: "#60abfb", borderColor: "#60abfb" }
            : {}
        }
      >
        {metric.name}
      </button>
    </Col>
  ));
};

MultipleMetric.propTypes = {
  metricsData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  selectedData: PropTypes.arrayOf(PropTypes.string),
  isDisable: PropTypes.bool,
  isQuoteLock: PropTypes.bool,
  handleClick: PropTypes.func,
};

export default MultipleMetric;
