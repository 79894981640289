import React from "react";
import { Button, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { ShowModal } from "../redux/roof-metric-collection/metric.action";
import { useSelector } from "react-redux";
import { getTotalValue } from "utils/utils";

// Define styles at the top
const rowStyle = (width, btnsLength) => ({
  // margin: "1em 0",
  width: width ? `${btnsLength * 200}px` : "100%",
  justifyContent: btnsLength > 3 ? "space-between" : "null",
  // paddingLeft: '1.5rem'
});

const divStyle = {
  margin: "0 1em 1em 0",
  maxWidth: "170px",
  minWidth: "150px",
};

const buttonStyle = {
  margin: "0 0 1em 0",
  width: "100%",
  height: "45px",
  padding: "0",
  color: "#000",
  fontWeight: "700",
  border: 0,

  transition: "0.7s",
  background: "transparent",
  color: "rgba(41, 40, 40, 0.911)",
  border: "1px solid rgba(200, 200, 200, 0.548)",
  borderRadius: "5px",
  background: "rgba(200, 200, 200, 0.548)",
};

const selectedBtn = {
  color: " #fff",
  background: "#007bff",
  border: "1px solid #007bff",
};

const formControlStyle = {
  textAlign: "center",
  height: "45px",
  fontWeight: "600",
};

export default function DisplayBtnsTab({ btns = [], width = false }) {
  console.log({ btns });
  const dispatch = useDispatch();

  const metric = useSelector((state) => state.metric?.metrics);
  const metricData = useSelector((state) => state.metricV2Data);

  console.log("hello ", metric);
  function onClickedTab(currentBtn) {
    const indexOfSection = metricData?.data?.sections.findIndex(
      (x) => x._id === metric?.sectionId
    );
    const data = {
      showModal: true,
      data: currentBtn,
      sectionNumber: indexOfSection + 1,
    };

    console.log({
      btnClickData: data,
      metricData,
      metric,
    });

    dispatch(ShowModal(data));
  }

  function getValue(currentBtn) {
    const currentCategoryValue = currentBtn
      ? metric?.[currentBtn?.categoryName][currentBtn?.key]
      : [];
    const total = currentCategoryValue?.length
      ? getTotalValue(currentCategoryValue)
      : 0;
    return total;
  }

  return (
    <Row style={rowStyle(width, btns?.length)}>
      {btns.map((btn) => (
        <div style={divStyle} key={btn.name}>
          <button
            onClick={() => onClickedTab(btn)}
            // className={`ClickableBtn ${getValue(btn) ? "selected" : ""} `}
            style={{
              ...buttonStyle,
              ...(getValue(btn)
                ? selectedBtn
                : { background: "rgba(200, 200, 200, 0.548)" }),
            }}
          >
            {btn.name}
          </button>
          {/* {typeof btn.total !== "undefined" && ( */}
          <Form.Control
            style={formControlStyle}
            type="text"
            disabled
            // value={btn.total ? numeral(btn.total).format("0,0") : ""}
            value={getValue(btn) ? getValue(btn) : "Total"}
            placeholder="Total"
          />
          {/* )} */}
        </div>
      ))}
    </Row>
  );
}
