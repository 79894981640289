import axios from "axios";
import { ApiEndpoints } from "lib/config/baseSettings";
import { axiosInstance } from "services"; // Assuming axiosInstance is defined in services

export const addMetricApi = async (data) => {
  try {
    console.log({
      data,
    });

    const response = await axiosInstance.post(
      ApiEndpoints.METRICS.ADD_METRIC_V2,
      { ...data }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getMetricsApi = async (requestId) => {
  try {
    const response = await axiosInstance.get(
      `${ApiEndpoints.METRICS.GET_METRIC_BY_REQUEST_ID}/${requestId}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const editMetricApi = async (editData) => {
  try {
    console.log({ editData });
    const params = new URLSearchParams();
    params.set("requestId", editData?.requestId);
    params.set("sectionId", editData?.sectionId);
    delete editData?.requestId;
    delete editData?.sectionId;

    const url = `${ApiEndpoints.METRICS.UPDATE_METRIC_V2}?${params}`;
    const response = await axiosInstance.put(url, editData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteMetricApi = async (deleteData) => {
  try {
    console.log({ deleteData });
    const params = new URLSearchParams();
    params.set("requestId", deleteData?.requestId);
    params.set("sectionId", deleteData?.sectionId);

    const url = `${ApiEndpoints.METRICS.DELETE_METRIC_V2}?${params}`;
    const response = await axiosInstance.delete(url);
    return response.data;
  } catch (error) {
    console.log({ deleteMetricApi: error });
    throw error;
  }
};
