import React from "react";
import styles from "lib/config/baseStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandshake } from "@fortawesome/free-solid-svg-icons";
import { MailOutline } from "react-ionicons";
import { FcLock } from "react-icons/fc";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateCurrentProposal } from "../redux/proposals/proposals.actions";

export const QuoteProposalLayout = ({
  children,
  proposalsData,
  setCurrentProposal,
  changeProposalId,
}) => {
  const proposalData = useSelector((state) => state.proposal);
  console.log({
    proposalData,
    count: proposalData?.data?.length,
  });
  const selectedProposal = proposalData?.selectedProposal;
  return (
    <Row style={{ marginRight: 0 }}>
      <Col style={{ ...styles.reduceWidthCol, paddingRight: 0 }}>
        <div />
        <div className="__quote_screen">
          <div className="__quote_proposals">
            <div style={{ fontWeight: "bold" }}>
              Proposals ({proposalData?.data?.length})
            </div>
            <div className="__quote_proposals_list">
              {proposalData?.data?.length ? (
                <ProposalList
                  selectedProposal={selectedProposal}
                  proposalData={proposalData?.data}
                  changeProposalId={changeProposalId}
                  setCurrentProposal={setCurrentProposal}
                />
              ) : null}
            </div>
          </div>
          {children}
        </div>
      </Col>
    </Row>
  );
};

const ProposalList = ({ proposalData, changeProposalId, selectedProposal }) => {
  const contractProposals = proposalData?.filter(
    (prop) => prop.status === "Contract"
  );
  const notSentProposals = proposalData?.filter(
    (prop) => prop.status === "As-Collected"
  );
  const sentProposals = proposalData?.filter((prop) => prop.status === "Sent");

  const dispatch = useDispatch();

  const updateProposalId = (proposalId) => {
    console.log({
      proposalId,
    });
    dispatch(updateCurrentProposal(proposalId));
  };

  console.log({
    selectedProposal111: selectedProposal,
  });

  const renderList = (data) => {
    return (
      <div
        style={
          {
            // display: "flex",
            // flexDirection: "row",
            // alignItems: "center",
          }
        }
      >
        {data?.map((proposal) => {
          return (
            <ProposalItem
              active={selectedProposal._id === proposal._id}
              idx={123}
              key={123}
              changeProposalId={updateProposalId}
              data={proposal}
            />
          );
        })}
        {/* <FontAwesomeIcon icon={faHandshake} /> */}
        {/* <MailOutline color={"#00000"} height="1.5rem" width="1.5rem" /> */}
      </div>
    );
  };

  const ProposalItem = ({ idx, data, changeProposalId, active }) => (
    <li
      role="button"
      aria-label={data.title}
      onClick={() => changeProposalId(data._id)}
      className={active ? "active mt-2" : ""}
      // className="active mt-2"
      title={data?.title}
    >
      {data?.isLocked && <FcLock size={20} />}
      {data?.title || "Quote name"}
    </li>
  );

  return (
    <>
      <div style={{ height: "10px" }} />
      <p className="proposal-grp-lablel">Contract</p>
      <ul style={{ marginBottom: "10px" }}>
        {contractProposals?.length ? renderList(contractProposals) : null}
      </ul>
      <p className="proposal-grp-lablel">Sent</p>
      <ul style={{ marginBottom: "10px" }}>
        {sentProposals?.length ? renderList(sentProposals) : null}
      </ul>

      <p className="proposal-grp-lablel">Not sent</p>
      <ul style={{ marginBottom: "10px" }}>
        {notSentProposals?.length ? renderList(notSentProposals) : null}
      </ul>
    </>
  );
};
