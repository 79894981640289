import { repairsActionTypes } from "./repairs.types";

const INITIAL_STATE = {
  isLoading: false,
  isSubmitting: false,
  isDeleting: false,
  isEditing: false,
  isLoadingRequest: false,
  isUpdatingRequest: false,
  data: null,
  errors: null,
  submitSuccess: null,
  submitFail: null,
  deletingSuccess: null,
  deletingFail: null,
  editingSuccess: null,
  editingFail: null,
  dataRequest: null,
  errorsRequest: null,
  updateReqSuccess: null,
  updateReqFail: null,
};

export const RepairTaskReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case repairsActionTypes.TASK_START:
    case repairsActionTypes.ADD_TASK_START:
    case repairsActionTypes.DELETE_TASK_START:
    case repairsActionTypes.EDIT_TASK_START:
    case repairsActionTypes.GET_REPAIR_REQUEST_START:
    case repairsActionTypes.UPDATE_REPAIR_REQUEST_START:
      return {
        ...state,
        isLoading: action.type === repairsActionTypes.TASK_START,
        isSubmitting: action.type === repairsActionTypes.ADD_TASK_START,
        isDeleting: action.type === repairsActionTypes.DELETE_TASK_START,
        isEditing: action.type === repairsActionTypes.EDIT_TASK_START,
        isLoadingRequest: action.type === repairsActionTypes.GET_REPAIR_REQUEST_START,
        isUpdatingRequest: action.type === repairsActionTypes.UPDATE_REPAIR_REQUEST_START,
        errors: null,
        submitSuccess: null,
        submitFail: null,
        deletingSuccess: null,
        deletingFail: null,
        editingSuccess: null,
        editingFail: null,
        updateReqSuccess: null,
        updateReqFail: null,
      };

    case repairsActionTypes.TASK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload?.result?.allTasks?.map(task => ({
          ...task,
          edit: false,
        })),
        errors: null,
      };

    case repairsActionTypes.ADD_TASK_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        data: [...state.data, action.payload],
        submitSuccess: action.message,
        submitFail: null,
        errors: null, // Clear errors on success
      };

    case repairsActionTypes.EDIT_TASK_SUCCESS: {
      const { dataId, payload } = action;
      const updatedData = state.data.map((task, index) =>
        index === dataId ? payload : task
      );
      return {
        ...state,
        isEditing: false,
        data: updatedData,
        editingSuccess: action.message,
        editingFail: null,
        errors: null, // Clear errors on success
      };
    }

    case repairsActionTypes.DELETE_TASK_SUCCESS: {
      const { idx } = action;
      const updatedData = state.data.filter((_, index) => index !== idx);
      return {
        ...state,
        isDeleting: false,
        data: updatedData,
        deletingSuccess: action.message,
        deletingFail: null,
      };
    }

    case repairsActionTypes.GET_REPAIR_REQUEST_SUCCESS:
      return {
        ...state,
        isLoadingRequest: false,
        dataRequest: action.payload.result,
        errorsRequest: null,
      };

    case repairsActionTypes.UPDATE_REPAIR_REQUEST_SUCCESS:
      return {
        ...state,
        isUpdatingRequest: false,
        updateReqSuccess: action.payload.result.message,
        updateReqFail: null,
      };

    case repairsActionTypes.TASK_FAILURE:
    case repairsActionTypes.ADD_TASK_FAILURE:
    case repairsActionTypes.DELETE_TASK_FAILURE:
    case repairsActionTypes.EDIT_TASK_FAILURE:
    case repairsActionTypes.GET_REPAIR_REQUEST_FAILURE:
    case repairsActionTypes.UPDATE_REPAIR_REQUEST_FAILURE:
      return {
        ...state,
        isLoading: action.type === repairsActionTypes.TASK_FAILURE ? false : state.isLoading,
        isSubmitting: action.type === repairsActionTypes.ADD_TASK_FAILURE ? false : state.isSubmitting,
        isDeleting: action.type === repairsActionTypes.DELETE_TASK_FAILURE ? false : state.isDeleting,
        isEditing: action.type === repairsActionTypes.EDIT_TASK_FAILURE ? false : state.isEditing,
        isLoadingRequest: action.type === repairsActionTypes.GET_REPAIR_REQUEST_FAILURE ? false : state.isLoadingRequest,
        isUpdatingRequest: action.type === repairsActionTypes.UPDATE_REPAIR_REQUEST_FAILURE ? false : state.isUpdatingRequest,
        errors: action.payload,
        submitFail: action.type === repairsActionTypes.ADD_TASK_FAILURE ? action.payload : state.submitFail,
        deletingFail: action.type === repairsActionTypes.DELETE_TASK_FAILURE ? action.payload : state.deletingFail,
        editingFail: action.type === repairsActionTypes.EDIT_TASK_FAILURE ? action.payload : state.editingFail,
        errorsRequest: action.type === repairsActionTypes.GET_REPAIR_REQUEST_FAILURE ? action.payload : state.errorsRequest,
        updateReqFail: action.type === repairsActionTypes.UPDATE_REPAIR_REQUEST_FAILURE ? action.payload : state.updateReqFail,
      };

    case repairsActionTypes.TOGGLE_EDIT_MODE:
      return {
        ...state,
        data: state?.data?.map((item) =>
          item._id === action.payload
            ? { ...item, edit: !item.edit } // Toggle the `edit` key
            : item
        ),
      };

    default:
      return state;
  }
};
