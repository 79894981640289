import React from "react";
import "./fail.scss"

const Fail = () => {
  setTimeout(function() {
    window.location.replace('http://localhost:3000/signup');
  }, 10000);
  return (
    <div className="main_page_fail">
        <div className="container_fail">
        <div className="cross">&#10060;</div>
        <h1 className="fail_hi">Payment Failed</h1>
        <p className="fail_p">Unfortunately, your payment has failed.</p>
        {/* <a className="button_fail" href="#">
          Go Back to Payment Page
        </a> */}
      </div>
    </div>
  );
};

export default Fail;
