import {
  SAVE_VALUE_GUTTER,
  SHOW_NUMPAD_GUTTER,
  HIDE_MODAL_GUTTER,
  EDIT_VALUE_GUTTER,
  REMOVE_VALUE_GUTTER,
  TOGGLE_INPUT_SELECTED_GUTTER,
  TOGGLE_OPTION_SELECTED_GUTTER,
  RESET_OPTIONS_SELECTED_GUTTER,
  SET_FIELD_GUTTER,
  UPDATE_FIELD_GUTTER,
  UPDATE_METRIC_FIELD_GUTTER,
  UPDATE_METRICS_GUTTER,
  UPDATE_OTHER_OPTION_METRIC_GUTTER,
  RESET_ALL_METRICS_GUTTER,
  SET_CURRENT_LEAD_DATA_GUTTER,
} from "./gutter-metric.constants";

const METRIC_INITIAL_STATE = {
  modalData: {
    showModal: false,
    data: {},
  },
  metricOptions: [
    {
      name: "Gutters & Leaders",
      key: "guttersAndLeaders",
      subTabs: [
        {
          name: "Gutters",
          additionalLabel: "Gutters Selection",
          categoryName: "Gutters & Leaders",
          btnTexts: "",
          key: "gutters",
          activeModal: false,
          isNextAvailable: true,
          unit: "Linear Feet",
          unitKey: "linearFeet",
          inputs: [
            {
              name: "Linear Feet",
              key: "linearFeet",
              selected: true,
            },
          ],
          modalOptions: [
            {
              config: {
                type: "row",
                innerType: "row",
                displayHeader: false,
                disabledOptions: [],
                hideOptions: [],
                headerName: "Gutters Type & Style",
              },
              key: "gutterTypeAndStyle",
              options: [
                {
                  config: {
                    displayHeader: false,
                    disabledOptions: [],
                    displayHeader: true,
                  },
                  name: "Types",
                  key: "types",
                  keyOptions: [
                    {
                      name: "Aluminum",
                      key: "aluminum",
                      selected: false,
                    },
                    {
                      name: "Copper",
                      key: "copper",
                      selected: false,
                    },
                  ],
                },

                {
                  config: {
                    type: "row",
                    displayHeader: false,
                    disabledOptions: [
                      {
                        name: "types",
                        value: "",
                      },
                    ],
                    hideOptions: [],
                  },
                  name: "Style",
                  key: "style",
                  keyOptions: [
                    {
                      name: "K Type",
                      key: "kType",
                      selected: false,
                    },
                    {
                      name: "Half Round",
                      key: "halfRound",
                      selected: false,
                    },
                  ],
                },
              ],
            },
            {
              config: {
                type: "row",
                displayHeader: true,
                headerName: "Gutters Size",
              },
              key: "size",
              options: [
                {
                  config: {
                    type: "row",
                    displayHeader: true,
                    disabledOptions: [
                      {
                        name: "types",
                        value: "",
                      },
                      {
                        name: "style",
                        value: "",
                      },
                    ],
                  },
                  key: "size",
                  keyOptions: [
                    {
                      name: '4"',
                      selected: false,
                    },
                    {
                      name: '5"',
                      selected: false,
                    },
                    {
                      name: '6"',
                      selected: false,
                    },
                  ],
                },
              ],
            },
            {
              key: "color",
              config: {
                type: "row",
                displayHeader: true,
                headerName: "Gutters Color",
              },
              options: [
                {
                  config: {
                    type: "row",
                    displayHeader: true,
                    disabledOptions: [
                      {
                        name: "types",
                        value: "",
                      },
                      {
                        name: "style",
                        value: "",
                      },
                      {
                        name: "size",
                        value: "",
                      },
                    ],
                    hideOptions: [
                      {
                        name: "types",
                        value: "Copper",
                      },
                    ],
                  },
                  key: "color",
                  keyOptions: [
                    {
                      name: "White",
                      selected: false,
                    },
                    {
                      name: "Brown",
                      selected: false,
                    },
                    {
                      name: "Other",
                      value: "",
                      selected: false,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: "Leaders",
          additionalLabel: "Leaders Selection",
          categoryName: "Gutters & Leaders",
          btnTexts: "",
          key: "leaders",
          activeModal: false,
          isNextAvailable: true,
          unit: "Linear Feet",
          unitKey: "linearFeet",
          inputs: [
            {
              name: "Linear Feet",
              key: "linearFeet",
              selected: true,
            },
          ],
          modalOptions: [
            {
              config: {
                type: "row",
                innerType: "row",
                displayHeader: false,
                disabledOptions: [],
                hideOptions: [],
                headerName: "Leader Type & Style",
              },
              key: "leaderTypeAndStyle",
              options: [
                {
                  config: {
                    displayHeader: true,
                    disabledOptions: [],
                  },
                  name: "Types",
                  key: "types",

                  keyOptions: [
                    {
                      name: "Aluminum",
                      key: "leaderType",
                      selected: false,
                    },
                    {
                      name: "Copper",
                      key: "leaderType",
                      selected: false,
                    },
                  ],
                },

                {
                  config: {
                    type: "row",
                    displayHeader: false,
                    disabledOptions: [
                      {
                        name: "types",
                        value: "",
                      },
                    ],
                    hideOptions: [],
                  },
                  name: "Style",
                  key: "style",
                  keyOptions: [
                    {
                      name: "Box",
                      key: "box",
                      selected: false,
                    },
                    {
                      name: "Round",
                      key: "round",
                      selected: false,
                    },
                  ],
                },
              ],
            },
            {
              config: {
                type: "row",
                displayHeader: true,
                headerName: "Leader Size",
              },
              key: "size",
              options: [
                {
                  config: {
                    type: "row",
                    displayHeader: true,
                    // disabledOptions: [
                    //   {
                    //     name: "types",
                    //     value: "",
                    //   },
                    //   {
                    //     name: "style",
                    //     value: "",
                    //   },
                    // ],
                    hideOptions: [
                      {
                        name: "types",
                        value: "",
                      },
                      {
                        name: "types",
                        value: "",
                      },
                      {
                        name: "style",
                        value: "Box",
                      },
                    ],
                  },
                  key: "size",
                  keyOptions: [
                    {
                      name: '3"',
                      selected: false,
                    },
                    {
                      name: '4"',
                      selected: false,
                    },
                    {
                      name: '5"',
                      selected: false,
                    },
                    {
                      name: '6"',
                      selected: false,
                    },
                  ],
                },
              ],
            },
            {
              config: {
                type: "row",
                displayHeader: true,
                headerName: "Leader Size",
              },
              key: "size",
              options: [
                {
                  config: {
                    type: "row",
                    displayHeader: true,
                    // disabledOptions: [
                    //   {
                    //     name: "types",
                    //     value: "",
                    //   },
                    //   {
                    //     name: "style",
                    //     value: "",
                    //   },
                    // ],
                    hideOptions: [
                      {
                        name: "types",
                        value: "",
                      },
                      {
                        name: "types",
                        value: "",
                      },
                      {
                        name: "style",
                        value: "Round",
                      },
                    ],
                  },
                  key: "size",
                  keyOptions: [
                    {
                      name: "2 x 3",
                      selected: false,
                    },
                    {
                      name: "3 x 4",
                      selected: false,
                    },
                    {
                      name: "4 x 5",
                      selected: false,
                    },
                  ],
                },
              ],
            },
            // {
            //   config: {
            //     type: "row",
            //     displayHeader: true,
            //     headerName: "Leader Size",
            //   },
            //   key: "size",
            //   options: [
            //     {
            //       config: {
            //         type: "row",
            //         displayHeader: true,
            //         disabledOptions: [
            //           {
            //             name: "types",
            //             value: "",
            //           },
            //           {
            //             name: "style",
            //             value: "",
            //           },
            //         ],
            //       },
            //       key: "size",
            //       keyOptions: [
            //         {
            //           name: "2 x 3",
            //           selected: false,
            //         },
            //         {
            //           name: "3 x 4",
            //           selected: false,
            //         },
            //         {
            //           name: "4 x 5",
            //           selected: false,
            //         },
            //       ],
            //     },
            //   ],
            // },
            {
              key: "color",
              config: {
                type: "row",
                displayHeader: true,
                headerName: "Leaders Color",
              },
              options: [
                {
                  config: {
                    type: "row",
                    displayHeader: true,
                    disabledOptions: [
                      {
                        name: "types",
                        value: "",
                      },
                      {
                        name: "style",
                        value: "",
                      },
                      {
                        name: "size",
                        value: "",
                      },
                    ],
                    hideOptions: [
                      {
                        name: "types",
                        value: "Copper",
                      },
                    ],
                  },
                  key: "color",
                  keyOptions: [
                    {
                      name: "White",
                      selected: false,
                    },
                    {
                      name: "Brown",
                      selected: false,
                    },
                    {
                      name: "Other",
                      value: "",
                      selected: false,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: "Screens",
          additionalLabel: "Screens Selection",
          categoryName: "Gutters & Leaders",
          btnTexts: "",
          key: "screens",
          activeModal: false,
          isNextAvailable: true,
          unit: "Linear Feet",
          unitKey: "linearFeet",
          inputs: [
            {
              name: "Linear Feet",
              key: "linearFeet",
              selected: true,
            },
          ],
          modalOptions: [
            {
              config: {
                type: "row",
                innerType: "row",
                displayHeader: false,
                disabledOptions: [],
                hideOptions: [],
                headerName: "Screen Types",
              },
              key: "leaderTypeAndStyle",
              options: [
                {
                  config: {
                    displayHeader: false,
                    disabledOptions: [],
                  },
                  name: "Screen Types",
                  key: "types",
                  displayHeader: false,
                  keyOptions: [
                    {
                      name: "Aluminum",
                      key: "leaderType",
                      selected: false,
                    },
                    {
                      name: "Copper",
                      key: "leaderType",
                      selected: false,
                    },
                    {
                      name: "Vinyl",
                      key: "leaderType",
                      selected: false,
                    },
                  ],
                },
              ],
            },
            {
              config: {
                type: "row",
                displayHeader: true,
                headerName: "Screen Size",
              },
              key: "size",
              options: [
                {
                  config: {
                    type: "row",
                    displayHeader: true,
                    disabledOptions: [
                      {
                        name: "types",
                        value: "",
                      },
                    ],
                  },
                  key: "size",
                  keyOptions: [
                    {
                      name: '4"',
                      selected: false,
                    },
                    {
                      name: '5"',
                      selected: false,
                    },
                    {
                      name: '6"',
                      selected: false,
                    },
                  ],
                },
              ],
            },
            {
              key: "color",
              config: {
                type: "row",
                displayHeader: true,
                headerName: "Screen Color",
              },
              options: [
                {
                  config: {
                    type: "row",
                    displayHeader: true,
                    disabledOptions: [
                      {
                        name: "types",
                        value: "",
                      },
                      {
                        name: "size",
                        value: "",
                      },
                    ],
                    hideOptions: [
                      {
                        name: "types",
                        value: "Copper",
                      },
                    ],
                  },
                  key: "color",
                  keyOptions: [
                    {
                      name: "White",
                      selected: false,
                    },
                    {
                      name: "Brown",
                      selected: false,
                    },
                    {
                      name: "Other",
                      value: "",
                      selected: false,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: "Leader Heads",
          additionalLabel: "Leader Head Selection",
          categoryName: "Gutters & Leaders",
          btnTexts: "",
          key: "leaderHeads",
          activeModal: false,
          isNextAvailable: true,
          unit: "Count",
          unitKey: "count",
          inputs: [
            {
              name: "Count",
              key: "count",
              selected: true,
            },
          ],
          modalOptions: [
            {
              config: {
                type: "row",
                innerType: "row",
                displayHeader: false,
                disabledOptions: [],
                hideOptions: [],
                headerName: "Leader Heads Type",
              },
              key: "types",
              options: [
                {
                  config: {
                    displayHeader: true,
                    disabledOptions: [],
                  },
                  name: "Leader Header Types",
                  key: "types",
                  displayHeader: false,
                  keyOptions: [
                    {
                      name: "Aluminum",
                      key: "aluminum",
                      selected: false,
                    },
                    {
                      name: "Copper",
                      key: "copper",
                      selected: false,
                    },
                    {
                      name: "Vinyl",
                      key: "vinyl",
                      selected: false,
                    },
                  ],
                },
              ],
            },
            {
              config: {
                type: "row",
                displayHeader: true,
                headerName: "Leader Heads Color",
              },
              key: "color",
              options: [
                {
                  config: {
                    type: "row",
                    displayHeader: true,
                    disabledOptions: [
                      {
                        name: "types",
                        value: "",
                      },
                    ],
                    hideOptions: [
                      {
                        name: "types",
                        value: "Copper",
                      },
                    ],
                  },
                  key: "color",
                  keyOptions: [
                    {
                      name: "White",
                      selected: false,
                    },
                    {
                      name: "Brown",
                      selected: false,
                    },
                    {
                      name: "Other",
                      value: "",
                      selected: false,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: "Fascia & Capping",
      key: "fasciaAndCapping",
      subTabs: [
        {
          name: "Fascia",
          additionalLabel: "Fascia Selections",
          categoryName: "Fascia & Capping",
          btnTexts: "",
          key: "fascia",
          activeModal: false,
          isNextAvailable: true,
          unit: "Linear Feet",
          unitKey: "linearFeet",
          inputs: [
            {
              name: "Linear Feet",
              key: "linearFeet",
              selected: true,
            },
          ],
          modalOptions: [
            {
              config: {
                type: "row",
                innerType: "row",
                displayHeader: false,
                disabledOptions: [],
                // hideOptions: [
                //   {
                //     name: "types",
                //     value: "Composite",
                //   },
                // ],
                headerName: "Fascia Type",
              },
              key: "types",
              options: [
                {
                  config: {
                    displayHeader: false,
                    disabledOptions: [],
                  },
                  name: "Fascia Types",
                  key: "types",
                  displayHeader: true,
                  keyOptions: [
                    {
                      name: "Wood",
                      key: "wood",
                      selected: false,
                    },
                    {
                      name: "Composite",
                      key: "composite",
                      selected: false,
                    },
                  ],
                },

                {
                  config: {
                    type: "row",
                    displayHeader: false,
                    disabledOptions: [
                      {
                        name: "types",
                        value: "",
                      },
                    ],
                    hideOptions: [
                      {
                        name: "types",
                        value: "",
                      },
                      {
                        name: "types",
                        value: "Wood",
                      },
                    ],
                  },
                  name: "Fascia Size",
                  key: "size",
                  keyOptions: [
                    {
                      name: "1 x 4",
                      key: "oneByFour",
                      selected: false,
                    },
                    {
                      name: "1 x 6",
                      key: "oneBySix",
                      selected: false,
                    },
                    {
                      name: "1 x 8",
                      key: "oneByEight",
                      selected: false,
                    },
                  ],
                },

                {
                  config: {
                    type: "row",
                    displayHeader: false,
                    disabledOptions: [
                      {
                        name: "types",
                        value: "",
                      },
                    ],
                    hideOptions: [
                      {
                        name: "types",
                        value: "",
                      },
                      {
                        name: "types",
                        value: "Composite",
                      },
                    ],
                  },
                  name: "Fascia Size",
                  key: "size",
                  keyOptions: [
                    {
                      name: "1 x 4",
                      key: "oneByFour",
                      selected: false,
                    },
                    {
                      name: "1 x 6",
                      key: "oneBySix",
                      selected: false,
                    },
                    {
                      name: "1 x 8",
                      key: "oneByEight",
                      selected: false,
                    },
                    {
                      name: "1 x 12",
                      key: "oneByTwelve",
                      selected: false,
                    },
                  ],
                },
              ],
            },
            {
              config: {
                type: "row",
                displayHeader: true,
                headerName: "Fascia Length",
              },
              key: "length",
              options: [
                {
                  config: {
                    type: "row",
                    displayHeader: true,
                    disabledOptions: [
                      {
                        name: "types",
                        value: "",
                      },
                      {
                        name: "size",
                        value: "",
                      },
                    ],
                  },
                  key: "length",
                  keyOptions: [
                    {
                      name: "8'",
                      selected: false,
                    },
                    {
                      name: "10'",
                      selected: false,
                    },
                    {
                      name: "12'",
                      selected: false,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: "Capping",
          additionalLabel: "Capping Selections",
          categoryName: "Fascia & Capping",
          btnTexts: "",
          key: "capping",
          activeModal: false,
          isNextAvailable: true,
          unit: "Linear Feet",
          unitKey: "linearFeet",
          inputs: [
            {
              name: "Linear Feet",
              key: "linearFeet",
              selected: true,
            },
          ],
          modalOptions: [
            {
              config: {
                type: "row",
                innerType: "row",
                displayHeader: false,
                disabledOptions: [],
                hideOptions: [],
                headerName: "Capping Type",
              },
              key: "types",
              options: [
                {
                  config: {
                    displayHeader: true,
                    disabledOptions: [],
                  },
                  name: "Capping Types",
                  key: "types",
                  keyOptions: [
                    {
                      name: "Flat",
                      key: "flat",
                      selected: false,
                    },
                    {
                      name: "PVC",
                      key: "pvc",
                      selected: false,
                    },
                    {
                      name: "Mar-Free",
                      key: "marFree",
                      selected: false,
                    },
                  ],
                },
              ],
            },
            {
              config: {
                type: "row",
                displayHeader: true,
                headerName: "Capping Color",
              },
              key: "color",
              options: [
                {
                  config: {
                    type: "row",
                    displayHeader: true,
                    disabledOptions: [
                      {
                        name: "types",
                        value: "",
                      },
                    ],
                  },
                  key: "color",
                  keyOptions: [
                    {
                      name: "White",
                      selected: false,
                    },
                    {
                      name: "Brown",
                      selected: false,
                    },
                    {
                      name: "Other",
                      value: "",
                      selected: false,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  tableData: [],
  metrics: {
    sectionId: "",
    level: "",
    elevation: "",
    elevationDetails: {
      type: "",
      more: {
        lists: [],
        other: "",
      },
    },
    guttersAndLeaders: {
      gutters: {},
      leaders: {},
      screens: {},
      leaderHeads: {},
    },
    fasciaAndCapping: {
      fascia: {},
      capping: {},
    },
    notes: "",
  },
};

export function GutterMetricReducer(state = METRIC_INITIAL_STATE, action) {
  console.log({ action });
  switch (action.type) {
    case SAVE_VALUE_GUTTER: {
      const {
        id,
        category,
        key,
        value,
        unitKey,
        unit,
        selectedDimension,
        options,
      } = action.payload;

      console.log({
        id,
        category,
        key,
        value,
        unitKey,
        unit,
        selectedDimension,
        options,
      });

      // Determine the mapped category key using the gutterCategoryMappings
      const mappedCategoryKey = gutterCategoryMappings[category] || category;

      // Create a new object to add to the array
      const newObject = {
        key: key,
        value: value,
        unitKey: unitKey,
        unit: unit,
        id: id,
        options: options,
        ...selectedDimension,
      };

      console.log({ newObject: newObject });

      // Update the state immutably by pushing the new object into the array
      return {
        ...state,
        metrics: {
          ...state.metrics,
          [mappedCategoryKey]: {
            ...state.metrics[mappedCategoryKey],
            [key]: [
              ...(state.metrics[mappedCategoryKey]?.[key] || []), // Ensure the array exists
              newObject, // Add the new object
            ],
          },
        },
      };
    }

    case SHOW_NUMPAD_GUTTER:
      return {
        ...state,
        modalData: {
          showModal: true,
          data: action.payload,
        },
      };
    case EDIT_VALUE_GUTTER: {
      const { category, key, id, selectedDimension, options } = action.payload;
      console.log({ pp: action.payload });
      return {
        ...state,
        metrics: {
          ...state.metrics,
          [category]: {
            ...state?.metrics?.[category],
            [key]: state?.metrics?.[category][key].map((item) =>
              item.id === id
                ? { ...item, ...selectedDimension, options: options }
                : item
            ),
          },
        },
      };
    }
    case REMOVE_VALUE_GUTTER: {
      const { category, key, id } = action.payload;
      console.log({ category, key, id, metric: state.metrics });
      return {
        ...state,
        metrics: {
          ...state.metrics,
          [category]: {
            ...state?.metrics?.[category],
            [key]: state?.metrics?.[category]?.[key].filter(
              (item) => item.id !== id
            ),
          },
        },
      };
    }
    case HIDE_MODAL_GUTTER:
      return {
        ...state,
        modalData: {
          showModal: false,
          data: {},
        },
      };

    case TOGGLE_INPUT_SELECTED_GUTTER: {
      const { categoryName, subTabName, inputKey } = action.payload;

      const updatedMetricOptions = state.metricOptions.map((category) => {
        if (category.name === categoryName) {
          return {
            ...category,
            subTabs: category.subTabs.map((subTab) => {
              if (subTab.name === subTabName) {
                return {
                  ...subTab,
                  inputs: subTab.inputs.map((input) => ({
                    ...input,
                    selected: input.key === inputKey,
                  })),
                };
              }
              return subTab;
            }),
          };
        }
        return category;
      });

      return {
        ...state,
        metricOptions: updatedMetricOptions,
      };
    }

    case TOGGLE_OPTION_SELECTED_GUTTER: {
      const {
        categoryName,
        subTabName,
        configKey,
        optionKey,
        optionValueName,
      } = action.payload;

      const updatedMetricOptions = state.metricOptions.map((category) => {
        if (category.name === categoryName) {
          return {
            ...category,
            subTabs: category.subTabs.map((subTab) => {
              if (subTab.name === subTabName) {
                return {
                  ...subTab,
                  modalOptions: subTab.modalOptions.map((modalOption) => {
                    if (modalOption.config.key === configKey) {
                      return {
                        ...modalOption,
                        options: modalOption.options.map((option) => {
                          if (option.key === optionKey) {
                            return {
                              ...option,
                              keyOptions: option.keyOptions.map(
                                (keyOption) => ({
                                  ...keyOption,
                                  selected:
                                    keyOption.name === optionValueName
                                      ? !keyOption.selected
                                      : false,
                                })
                              ),
                            };
                          }
                          return option;
                        }),
                      };
                    }
                    return modalOption;
                  }),
                };
              }
              return subTab;
            }),
          };
        }
        return category;
      });

      return {
        ...state,
        metricOptions: updatedMetricOptions,
      };
    }

    case UPDATE_OTHER_OPTION_METRIC_GUTTER: {
      const {
        categoryName,
        subTabName,
        optionKey,
        optionValueName,
        optionOtherInputName,
        newValue,
      } = action.payload;

      const updatedMetricOptions = state.metricOptions.map((category) => {
        if (category.name !== categoryName) return category;

        return {
          ...category,
          subTabs: category.subTabs.map((subTab) => {
            if (subTab.name !== subTabName) return subTab;

            if (!subTab.options || !subTab.options.values) return subTab;

            return {
              ...subTab,
              options: {
                ...subTab.options,
                values: {
                  ...subTab.options.values,
                  [optionKey]: {
                    ...subTab.options.values[optionKey],
                    values: subTab.options.values[optionKey].values.map(
                      (value) => {
                        if (value.name !== optionValueName) return value;

                        return {
                          ...value,
                          others: {
                            ...value.others,
                            inputs: value.others.inputs.map((input) => ({
                              ...input,
                              selected:
                                input.name === optionOtherInputName
                                  ? !input.selected
                                  : false,
                              value:
                                input.name === optionOtherInputName &&
                                input.type === "input"
                                  ? newValue
                                  : input?.value,
                            })),
                          },
                        };
                      }
                    ),
                  },
                },
              },
            };
          }),
        };
      });

      return {
        ...state,
        metricOptions: updatedMetricOptions,
      };
    }

    case RESET_OPTIONS_SELECTED_GUTTER: {
      const { categoryName, subTabName } = action.payload;

      const updatedMetricOptions = state.metricOptions.map((category) => {
        if (category.name === categoryName) {
          return {
            ...category,
            subTabs: category.subTabs.map((subTab) => {
              if (subTab.name === subTabName) {
                return {
                  ...subTab,
                  options: subTab.options
                    ? {
                        ...subTab.options,
                        values: subTab.options.values
                          ? Object.fromEntries(
                              Object.entries(subTab.options.values).map(
                                ([key, option]) => [
                                  key,
                                  {
                                    ...option,
                                    values: option.values.map((value) => ({
                                      ...value,
                                      selected: false,
                                    })),
                                  },
                                ]
                              )
                            )
                          : subTab.options.values,
                      }
                    : subTab.options,
                };
              }
              return subTab;
            }),
          };
        }
        return category;
      });

      return {
        ...state,
        metricOptions: updatedMetricOptions,
      };
    }

    case UPDATE_FIELD_GUTTER: {
      console.log({
        payload: action.payload,
      });

      return {
        ...state,
        metrics: {
          ...state.metrics,
          [action.payload.field]: action.payload.value,
        },
      };
    }

    case UPDATE_METRICS_GUTTER:
      // Update the state with new metrics and sectionId
      const updatedMetrics = action.payload;

      console.log({
        updatedMetrics,
      });

      // Function to update metricOptions based on the new metrics
      const updateMetricOptions = (metricOptions, metrics) => {
        return metricOptions.map((metricOption) => {
          if (
            metricOption.key === "guttersAndLeaders" ||
            metricOption.key === "fasciaAndCapping"
          ) {
            return {
              ...metricOption,
              subTabs: metricOption.subTabs.map((subTab) => {
                const data = metrics[metricOption.key]?.[subTab.key];
                if (data && data.options) {
                  console.log("Processing subTab:", subTab.key);
                  console.log("Data options:", data.options);

                  return {
                    ...subTab,
                    modalOptions: subTab.modalOptions.map((modalOption) => ({
                      ...modalOption,
                      options: modalOption.options.map((option) => ({
                        ...option,
                        keyOptions: option.keyOptions.map((keyOption) => {
                          const selectedOption = Object.keys(data.options).find(
                            (key) => data.options[key]?.name === keyOption.name
                          );
                          const isSelected = !!selectedOption;

                          console.log(
                            `Comparing keyOption: ${keyOption.name} with selectedOption: ${data.options[selectedOption]?.name}, selected: ${isSelected}`
                          );

                          return {
                            ...keyOption,
                            selected: isSelected,
                          };
                        }),
                      })),
                    })),
                  };
                }

                console.log("No data options found for key:", subTab.key);
                return subTab;
              }),
            };
          }

          return metricOption;
        });
      };

      // Apply the updateMetricOptions function to create a new updatedMetricOptions
      const updatedMetricOptions = updateMetricOptions(
        state.metricOptions,
        updatedMetrics
      );

      console.log({
        updatedMetricOptions,
      });
      return {
        ...state,
        metrics: {
          ...state.metrics,
          ...action.payload,
          sectionId: action?.payload?._id,
        },
        metricOptions: updatedMetricOptions,
      };

    case RESET_ALL_METRICS_GUTTER:
      return {
        ...state,
        metrics: {
          section: "",
          sectionId: "",
          level: "",
          elevation: "",
          elevationDetails: {
            type: "",
            more: {
              lists: [],
              other: "",
            },
          },
          guttersAndLeaders: {
            gutters: {},
            leaders: {},
            screens: {},
            leaderHeads: {},
          },
          fasciaAndCapping: {
            fascia: {},
            capping: {},
          },
        },
        metricOptions: state.metricOptions.map((option) => ({
          ...option,
          subTabs: option.subTabs.map((subTab) => ({
            ...subTab,
            modalOptions: subTab.modalOptions.map((modalOption) => ({
              ...modalOption,
              options: modalOption.options.map((optionGroup) => ({
                ...optionGroup,
                keyOptions: optionGroup.keyOptions.map((keyOption) => ({
                  ...keyOption,
                  selected: false, // Set all keyOptions to false
                })),
              })),
            })),
          })),
        })),
      };
    case SET_CURRENT_LEAD_DATA_GUTTER:
      return {
        ...state,
        currentLead: action.payload,
      };

    default:
      return state;
  }
}
