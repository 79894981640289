import { ApiEndpoints } from "lib/config/baseSettings";
import { NewLeadTypes } from "./new-lead.types";

import { axiosInstance } from "services";

export const SaveNewLeadSuccess = (data) => ({
  type: NewLeadTypes.NEW_LEAD_SUCCESS,
  payload: data,
});

export const SaveNewLeadFailure = (errors) => ({
  type: NewLeadTypes.NEW_LEAD_FAILURE,
  payload: errors,
});

export const SaveNewLeadStart = () => ({
  type: NewLeadTypes.NEW_LEAD_START,
});

export const SaveNewLead = (input) => {
  return (dispatch) => {
    dispatch(SaveNewLeadStart());
    return axiosInstance
      .post(`/lead/add`, { ...input })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          dispatch(SaveNewLeadSuccess(data.result.message));
        } else {
          dispatch(SaveNewLeadFailure(data.error));
        }
      })
      .catch((err) => dispatch(SaveNewLeadFailure(err.message)));
  };
};

export const SaveNewLeadRemove = () => ({
  type: NewLeadTypes.NEW_LEAD_STOP,
});

export const FetchLeadsSuccess = (data) => ({
  type: NewLeadTypes.FETCH_LEADS_SUCCESS,
  payload: data,
});

export const FetchLeadsFailure = (errors) => ({
  type: NewLeadTypes.FETCH_LEADS_FAILURE,
  payload: errors,
});

export const FetchLeadsStart = () => ({
  type: NewLeadTypes.FETCH_LEADS_START,
});

export const FetchLeads = () => {
  return (dispatch) => {
    dispatch(FetchLeadsStart());
    return axiosInstance
      .get(`/lead`)
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          dispatch(FetchLeadsSuccess(data.result));
        } else {
          dispatch(FetchLeadsFailure(data.error));
        }
      })
      .catch((err) => dispatch(FetchLeadsFailure(err.message)));
  };
};

export const DeleteLeadSuccess = (data) => ({
  type: NewLeadTypes.DELETE_LEAD_SUCCESS,
  payload: data,
});

export const DeleteLeadFailure = (errors) => ({
  type: NewLeadTypes.DELETE_LEAD_FAILURE,
  payload: errors,
});

export const DeleteLeadStart = () => ({
  type: NewLeadTypes.DELETE_LEAD_START,
});

export const DeleteLead = (id) => {
  return (dispatch) => {
    dispatch(DeleteLeadStart());
    return axiosInstance
      .post(`/lead/delete`, { ...id })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          dispatch(DeleteLeadSuccess("Lead has been successfully deleted."));
        } else {
          dispatch(
            DeleteLeadFailure(
              "An Error occured deleting the lead. Please try again later!"
            )
          );
        }
      })
      .catch((err) =>
        dispatch(
          DeleteLeadFailure(
            "An Error occured deleting the lead. Please try again later!"
          )
        )
      );
  };
};

export const EditLeadSuccess = (data) => ({
  type: NewLeadTypes.EDIT_LEAD_SUCCESS,
  payload: data,
});

export const EditLeadFailure = (errors) => ({
  type: NewLeadTypes.EDIT_LEAD_FAILURE,
  payload: errors,
});

export const EditLeadStart = () => ({
  type: NewLeadTypes.EDIT_LEAD_START,
});

export const EditLead = (id, input) => {
  return (dispatch) => {
    dispatch(EditLeadStart());
    return axiosInstance
      .patch(
        ApiEndpoints.CONTACT.editContactManagementProperty.replace(
          ":propertyAddressId",
          id
        ),
        { ...input }
      )
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          dispatch(EditLeadSuccess(data.result.message));
        } else {
          dispatch(EditLeadFailure(data.error));
        }
      })
      .catch((err) => dispatch(EditLeadFailure(err.message)));
  };
};

export const scheduleLeadSuccess = () => ({
  type: NewLeadTypes.SCHEDULE_LEAD_SUCCESS,
});

export const scheduleLeadFailure = (errors) => ({
  type: NewLeadTypes.SCHEDULE_LEAD_FAILURE,
  payload: errors,
});

export const scheduleLeadStart = () => ({
  type: NewLeadTypes.SCHEDULE_LEAD_START,
});

export const ScheduleLead = (data) => {
  return (dispatch) => {
    dispatch(scheduleLeadStart());
    return axiosInstance
      .post(`/lead/schedule`, data)
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          dispatch(scheduleLeadSuccess());
        } else {
          dispatch(
            scheduleLeadFailure(
              "An Error occured deleting the lead. Please try again later!"
            )
          );
        }
      })
      .catch((err) => dispatch(scheduleLeadFailure(err.message)));
  };
};

export const scheduleLeadStop = () => ({
  type: NewLeadTypes.SCHEDULE_LEAD_STOP,
});
/**
 * New api email type phone type etc.
 */
export const GetEmailTypeSuccess = (data) => ({
  type: NewLeadTypes.EMAIL_TYPE_SUCCESS,
  payload: data,
});

export const GetEmailTypeFailure = (errors) => ({
  type: NewLeadTypes.EMAIL_TYPE_FAILURE,
  payload: errors,
});

export const GetEmailTypeStart = () => ({
  type: NewLeadTypes.EMAIL_TYPE_START,
});

export const GetEmailType = () => {
  return (dispatch) => {
    dispatch(GetEmailTypeStart());
    return axiosInstance
      .get(`/admin/userrole/email`)
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          dispatch(GetEmailTypeSuccess(data.result));
        } else {
          dispatch(GetEmailTypeFailure(data.error));
        }
      })
      .catch((err) => {
        dispatch(GetEmailTypeFailure(err.message));
      });
  };
};

export const GetPhoneTypeSuccess = (data) => ({
  type: NewLeadTypes.PHONE_TYPE_SUCCESS,
  payload: data,
});

export const GetPhoneTypeFailure = (errors) => ({
  type: NewLeadTypes.PHONE_TYPE_FAILURE,
  payload: errors,
});

export const GetPhoneTypeStart = () => ({
  type: NewLeadTypes.PHONE_TYPE_START,
});

export const GetPhoneType = () => {
  return (dispatch) => {
    dispatch(GetPhoneTypeStart());
    return axiosInstance
      .get(`/admin/userrole/phone`)
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          dispatch(GetPhoneTypeSuccess(data.result));
        } else {
          dispatch(GetPhoneTypeFailure(data.error));
        }
      })
      .catch((err) => {
        dispatch(GetPhoneTypeFailure(err.message));
      });
  };
};

export const GetPropertyTypeSuccess = (data) => ({
  type: NewLeadTypes.PROPERTY_TYPE_SUCCESS,
  payload: data,
});

export const GetPropertyTypeFailure = (errors) => ({
  type: NewLeadTypes.PROPERTY_TYPE_FAILURE,
  payload: errors,
});

export const GetPropertyTypeStart = () => ({
  type: NewLeadTypes.PROPERTY_TYPE_START,
});

export const GetPropertyType = () => {
  return (dispatch) => {
    dispatch(GetPropertyTypeStart());
    return axiosInstance
      .get(`/admin/userrole/property`)
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          dispatch(GetPropertyTypeSuccess(data.result));
        } else {
          dispatch(GetPropertyTypeFailure(data.error));
        }
      })
      .catch((err) => {
        dispatch(GetPropertyTypeFailure(err.message));
      });
  };
};

export const GetRelationTypeSuccess = (data) => ({
  type: NewLeadTypes.RELATION_TYPE_SUCCESS,
  payload: data,
});

export const GetRelationTypeFailure = (errors) => ({
  type: NewLeadTypes.RELATION_TYPE_FAILURE,
  payload: errors,
});

export const GetRelationTypeStart = () => ({
  type: NewLeadTypes.RELATION_TYPE_START,
});

export const GetRelationType = () => {
  return (dispatch) => {
    dispatch(GetRelationTypeStart());
    return axiosInstance
      .get(`/admin/userrole/relationship`)
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          dispatch(GetRelationTypeSuccess(data.result));
        } else {
          dispatch(GetRelationTypeFailure(data.error));
        }
      })
      .catch((err) => {
        dispatch(GetRelationTypeFailure(err.message));
      });
  };
};

export const GetTasksRateSuccess = (data) => ({
  type: NewLeadTypes.TASKS_RATE_SUCCESS,
  payload: data,
});

export const GetTasksRateFailure = (errors) => ({
  type: NewLeadTypes.TASKS_RATE_FAILURE,
  payload: errors,
});

export const GetTasksRateStart = () => ({
  type: NewLeadTypes.TASKS_RATE_START,
});

export const GetTasksRate = () => {
  return async (dispatch) => {
    dispatch(GetTasksRateStart());
    try {
      const response = await axiosInstance.get(`/admin/stateTaxForCalcUse`);
      const data = response.data;

      if (data.success) {
        dispatch(GetTasksRateSuccess(data.result));
      } else {
        dispatch(GetRelationTypeFailure(data.error));
      }
    } catch (err) {
      dispatch(GetTasksRateFailure(err.message));
    }
  };
};

export const GetEditLeadData = (data) => {
  return (dispatch) => {
    dispatch({
      type: "EDITLEADDATA",
      payload: data,
    });
  };
};
