import React, { useState } from "react";
import "../css/date.scss";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { Col, Form } from "react-bootstrap";

function InstallmentData(props) {
  const {
    styles,
    installment,
    display_payment_method,
    installmentId,
    getInstallmentEdit,
    onReload,
  } = props;
  const [installmentPrice, setInstallmentPrice] = useState();
  const [installmentEdit, setInstallmentEdit] = useState(false);
  const [installmentPayment, setInstallmentPayment] = useState();
  const [installmentReceived, setInstallmentReceived] = useState();

  let installment_value = () => {
    return installmentPrice === "" || installmentPrice
      ? installmentPrice
      : installment?.installmentAmount;
  };

  return (
    <>
      {/* <Col md="1" style={{ marginRight: "-4rem" }}></Col> */}
      <Col lg="2" md="2" sm="12" xs="12" style={{ marginLeft: "14rem" }}>
        <Form.Group style={styles.formInput}>
          <Form.Label></Form.Label>
          <Form.Control
            type="number"
            value={installment_value()}
            onChange={(e) => setInstallmentPrice(e.target.value)}
            disabled={!installmentEdit}
          ></Form.Control>
        </Form.Group>
      </Col>
      <Col lg="3" md="3" sm="3" xs="3">
        <Form.Group style={styles.formInput}>
          <Form.Label></Form.Label>
          <Form.Control
            as="select"
            onChange={(e) => setInstallmentPayment(e.target.value)}
            value={
              installmentPayment
                ? installmentPayment
                : installment?.paymentMethod
            }
            disabled={!installmentEdit}
          >
            <option value="">Choose...</option>
            {display_payment_method()}
          </Form.Control>
        </Form.Group>
      </Col>
      <Col style={{ position: "relative", bottom: ".5rem" }}>
        <Form.Group style={styles.formInput}>
          <Form.Label>Received</Form.Label>
          <Form.Control
            type="date"
            value={
              installmentReceived
                ? installmentReceived
                : installment?.installmentDate
            }
            onChange={(e) => setInstallmentReceived(e.target.value)}
            disabled={!installmentEdit}
          ></Form.Control>
        </Form.Group>
      </Col>
      <Col>
        {!installmentEdit ? (
          <button
            type="button"
            className="btn btn-primary"
            style={{ marginTop: "1.5rem" }}
            onClick={() => {
              setInstallmentEdit(true);
            }}
          >
            Edit
          </button>
        ) : (
          <>
            <button
              type="button"
              className="btn btn-primary"
              style={{ marginTop: "1.5rem" }}
              disabled={
                !installmentReceived && !installmentPayment && !installmentPrice
              }
              onClick={() => {
                getInstallmentEdit({
                  _id: installmentId,
                  installmentAmount: installmentPrice,
                  paymentMethod: installmentPayment,
                  installmentDate: installmentReceived,
                });
                setInstallmentEdit(false);
                onReload();
              }}
            >
              Save
            </button>
            <button
              type="button"
              className="btn btn-warning"
              style={{ marginTop: "1.5rem", marginLeft: "1.5rem" }}
              onClick={() => {
                setInstallmentPrice(installment?.installmentAmount);
                setInstallmentPayment(installment?.paymentMethod);
                setInstallmentReceived(installment?.installmentDate);
                setInstallmentEdit(false);
              }}
            >
              Cancel
            </button>
          </>
        )}
      </Col>
    </>
  );
}

export default InstallmentData;
