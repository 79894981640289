import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { PriceInput } from "components";
import QTYInput from "../../QTYInput";
import styles from "lib/config/baseStyles";
import { debounce } from "lodash";
import validator from "validator";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCarting,
  updateCarting,
} from "screens/QuotesProposals/redux/proposals/proposals.actions";

export default function CartingListData({
  listData,
  data,
  editItem,
  isLockQuoteData,
  sectionData,
  cartingTypes,
  quoteId,
  proposalId,
  supplierId,
}) {
  const [selectedCategory, setSelectedCategory] = useState(data.pricingId);
  const dispatch = useDispatch();

  const handleEdit = (newData) => {
    // editItem(newData);
  };

  const onChangeQty = (qty) => {
    const body = {
      supplierId,
      proposalId,
      dumpsterQuoteId: quoteId,
      dumpsterId: data._id,
      qty: Number(qty),
    };

    dispatch(updateCarting(body));
    // Add further logic if necessary, validation or calculations can go here
    // handleEdit({ ...data, qty: value });
  };

  const onDelete = (carting) => {
    const body = {
      supplierId,
      proposalId,
      dumpsterQuoteId: quoteId,
      dumpsterId: carting._id,
    };
    dispatch(deleteCarting(body));
  };

  const onChangeCartingTypes = (e) => {
    const cartingId = e.target.value;

    const body = {
      supplierId,
      proposalId,
      dumpsterQuoteId: quoteId,
      newCartingTypeId: cartingId,
      dumpsterId: data._id,
    };

    console.log({
      cartingId,
      dataId: data._id,
    });

    dispatch(updateCarting(body));
  };

  return (
    <DebrisDataDisplay
      onDelete={onDelete}
      pricingType={data.pricingType}
      size={data.size}
      listData={listData}
      selectedCategory={selectedCategory}
      onChangeCartingTypes={onChangeCartingTypes}
      onChangeQty={onChangeQty}
      qty={data.qty}
      price={data.uomPrice}
      total={data.total}
      isLockQuoteData={isLockQuoteData}
      sectionData={sectionData}
      cartingTypes={cartingTypes}
      cartingId={data.cartingId}
      qtyAndPrice={data.qtyAndPrice}
      uomPrice={data.uomPrice}
      carting={data}
    />
  );
}

function DebrisDataDisplay({
  price,
  onChangeQty,
  selectedCategory,
  onChangeCartingTypes,
  listData,
  isLockQuoteData,
  total,
  sectionData,
  size,
  cartingTypes,
  cartingId,
  onDelete,
  qtyAndPrice,
  uomPrice,
  carting,
}) {
  const deleteButtonStyle = {
    ...styles.reduceWidthCol,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  };

  const [qty, setQuantity] = useState(0);

  const handleChangeQrty = (event) => {
    const value = event.target.value;
    console.log({
      value,
    });

    setQuantity(value);
    onChangeQty(value);
  };

  useEffect(() => {
    if (qtyAndPrice?.quantity) {
      setQuantity(qtyAndPrice?.quantity);
    }
  }, [qtyAndPrice?.quantity]);

  return (
    <Row>
      <Col style={deleteButtonStyle} lg="2">
        <Button
          style={{ width: "fit-content" }}
          variant="danger"
          onClick={() => onDelete(carting)}
          disabled={isLockQuoteData?.isLocked}
        >
          Delete
        </Button>
      </Col>

      <Col style={styles.reduceWidthCol} lg="2">
        <Form.Group>
          <Form.Label style={{ marginLeft: "40%" }}>
            <strong>Size</strong>
          </Form.Label>
          <Form.Control
            as="select"
            value={cartingId}
            onChange={onChangeCartingTypes}
            disabled={isLockQuoteData?.isLocked}
          >
            <option value="">Choose...</option>
            {cartingTypes?.length > 0 ? (
              cartingTypes.map((carting) => (
                <option key={carting._id} value={carting._id}>
                  {carting.size} {carting.pricingType}
                </option>
              ))
            ) : (
              <option value="">No data available</option>
            )}
          </Form.Control>
        </Form.Group>
      </Col>

      <Col style={styles.reduceWidthCol} lg="4"></Col>

      <Col style={deleteButtonStyle}>
        <QTYInput
          value={qty}
          onChange={handleChangeQrty}
          disabled={isLockQuoteData?.isLocked}
        />
      </Col>

      <Col style={deleteButtonStyle}>
        <PriceInput disabled price={uomPrice} />
      </Col>

      <Col style={deleteButtonStyle}>
        <PriceInput disabled price={qtyAndPrice?.totalPrice} input="Total" />
      </Col>
    </Row>
  );
}
