import React, { useState } from "react";
import MetricButton from "../MetricButton";
import PropTypes from "prop-types";
import DisplayModal from "../DisplayModal";
import { Row, Col } from "react-bootstrap";

const ElevationDetailsMetric = ({
  label,
  elevationDetailsData = [],
  elevationSelectedData,
  prevMetric,
  icon,
  exec = false,
  onClickBtn,
  size,
  isQuoteLock = false,
  elevationOther = [],
  elevationOtherSelected = [],
  handleClickOtherInputValue,
  otherSelectValue,
}) => {
  console.log({
    elevationSelectedData,
    elevationOtherSelected,
    otherSelectValue,
  });
  const [otherElevationDetailsModal, setOtherElevationDetailsModal] =
    useState(false);

  const ElevationDetailsButtons = (handleClick) => {
    // Button component for rendering individual buttons
    const renderButton = (text) => (
      <button
        disabled={!prevMetric}
        onClick={() => handleClick(text, "not_more")}
        className={`ClickableBtn ${
          text === elevationSelectedData ? "selected" : ""
        } `}
        // className={`ClickableBtn`}
        // className={`ClickableBtn ${
        //   selectedData.includes(text) ? "selected" : ""
        // }`}
        style={{ width: "6rem" }}
      >
        {text}
      </button>
    );

    return (
      <>
        <Row className="d-flex flex-row">
          {["Main House", "Garage"].map((text) => renderButton(text))}
        </Row>

        <Row className="d-flex flex-row">
          {["Extension"].map((text) => renderButton(text))}

          <button
            disabled={!elevationSelectedData?.length || isQuoteLock}
            style={{ width: "3.2rem" }}
            className={`ClickableBtn ${
              elevationOtherSelected.length > 0 ? "selected" : ""
            }`}
            onClick={() => {
              // handleClick(text);
              setOtherElevationDetailsModal(!otherElevationDetailsModal);
            }}
          >
            More...
          </button>
        </Row>
      </>
    );
  };

  return (
    <>
      <label className="btnheading">{label}</label>
      <Row>{ElevationDetailsButtons(onClickBtn, false)}</Row>
      <DisplayModal
        handleChange={(label, item_id, item_name, exec) => {
          console.log({
            label,
            item_id,
            item_name,
            exec,
          });

          onClickBtn(item_name, "more");
        }}
        data={elevationOther}
        array={elevationOtherSelected}
        show={otherElevationDetailsModal}
        onSubmit={() => {
          setOtherElevationDetailsModal(!otherElevationDetailsModal);
        }}
        hide={() => {
          setOtherElevationDetailsModal(!otherElevationDetailsModal);
        }}
        other={{ name: "Test", value: otherSelectValue }}
        handleOtherChange={handleClickOtherInputValue}
      />
    </>
  );
};

ElevationDetailsMetric.propTypes = {
  elevationDetailsData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  elevationSelectedData: PropTypes.arrayOf(PropTypes.string),
  elevationOtherSelected: PropTypes.any,
  isDisable: PropTypes.bool,
  isQuoteLock: PropTypes.bool,
  prevMetric: PropTypes.any,
  exec: PropTypes.bool,
  elevationOther: PropTypes.arrayOf(PropTypes.string),
  onClickBtn: PropTypes.func,
  label: PropTypes.string,
  size: PropTypes.string,
  icon: PropTypes.string,
  handleClickOtherInputValue: PropTypes.any,
  otherSelectValue: PropTypes.string,
};

export default ElevationDetailsMetric;
