import React, { useCallback, useState } from "react";
import { Row, Col, Form, Button, Modal, Alert } from "react-bootstrap";

import Tabs from "components/tabs/tabs";
import InitialLoader from "components/InitilalLoader";
import SearchField from "components/search-field/search-field";
import PopupModal from "components/popup-modal/popup-modal";
import {
  saleStatusCountData,
} from "lib/redux/sales/sales.selector";
import { fetchForCalendar } from "lib/redux/calendar/calendar.actions";
import Select from "react-select";
import MonthPickerInput from "react-month-picker-input";
import "react-month-picker-input/dist/react-month-picker-input.css";
import SalesCard from "./SalesCard";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { axiosInstance } from "services";
import { ApiEndpoints } from "lib/config/baseSettings";
import DatePicker from "react-date-picker";
import Clock from "components/clock/clock";
import moment from "moment";
import { SalesPersons } from "lib/redux/calendar/calendar.selector";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import _ from 'lodash';
import { toast } from "react-toastify";
import "./sales-activity.scss";
import { convertMonthYearToDate } from "utils/dateTimeUtils";
import { getLatestStatusRecordByStatus } from "utils/utils";

const SalesActivity = (props) => {
  const [modal, setModal] = useState(false);
  const [requestId, setRequestId] = useState("");
  const [msgType, setMsgType] = useState("");

  const [msg, setMsg] = useState("");
  const [searchString, setSearchString] = useState("");
  const [selectPerson, setSelectPerson] = useState([]);
  const [dateForMonthStages, setDateForMonthSatges] = useState(new Date());


  const [salesStages, setSalesStage] = useState([
    "Scheduled",
    "Proposals Due",
    "Sent/Follow-Up",
    "Won",
    "Future Follow-up",
    "Lost",
    "Canceled"
  ])

  const initializeData = (salesStages) =>
    salesStages.reduce((acc, st) => [...acc, { status: st, data: [] }], []);

  const [allData, setAllData] = useState(() => initializeData(salesStages));

  const [isLoadingLeads, setIsLoadingLeads] = useState(false);
  const [isLoadingLeadUpdate, setIsLoadingUpdate] = useState(false);
  const [isLoadingLeadFollowUpUpdate, serIsLoadingLeadFollowUp] = useState(false)

  const [notActedLeads, setNotActedLeads] = useState([]);
  const [selectedScheduleDate, setSelectedScheduleDate] = useState(new Date());
  const [countData, setCountData] = useState({});

  const [activeTab, setActiveTab] = useState({
    name: "",
    index: 0,
    data: [],
    count: 0
  });
  const dispatch = useDispatch();

  const salesPersons = useSelector(state => {
    return SalesPersons(state);
  })



  useEffect(() => {
    dispatch(fetchForCalendar())


    fetchLeadsByStatusCount('', selectedScheduleDate);
    fetchNotActedOpenData();
    // getAllLeads();    
  }, []);

  console.log({
    selectedScheduleDate
  });

  // Fetch not acted upon data
  const fetchNotActedOpenData = async (persons = []) => {

    try {
      const body = {
        "date": new Date().toISOString(),
        "page": 1,
        "currentStatus": 'Scheduled',
        "persons": persons,
        "searchString": ""
      }

      const response = await axiosInstance.post(ApiEndpoints.STAGE.NOT_ACTED_OPEN, body);
      const { data } = response;
      setNotActedLeads(data?.result)
      // setIsLoadingLeads(false);
      return data;
    } catch (error) {
      // setIsLoadingLeads(false);
    }
  }

  // Fetch counts of all status
  const fetchLeadsByStatusCount = async (status = "Scheduled", date = "", persons = [], searchString = "", isUpdate = false) => {
    try {
      if (date) {
        date = new Date(date).toISOString();
      }
      setIsLoadingLeads(true)

      const body = {
        "date": date,
        "page": 1,
        "persons": persons,
        "searchString": searchString,
        "currentStatus": status
      }

      console.log({
        countBody: body
      });

      const response = await axiosInstance.post(ApiEndpoints.STAGE.GET_STAGE_COUNT, body);
      const { data } = response;
      console.log({
        countResponse: data
      })
      if (data?.counts) {
        setCountData(prevState => ({
          ...prevState, // Keep the previous state
          ...data.counts // Update with new counts
        }));
      }

      // return data;
      // const cDate = selectedScheduleDate.toISOString();
      if (date && !isUpdate) {
        const leadData = await fetchLeadsByStatus('Scheduled', date)
        console.log({
          leadData
        });

        if (!activeTab?.name) {
          console.log({
            leadDataResult: leadData?.result
          });

          setActiveTab({
            data: leadData?.result || [],
            index: 0,
            name: 'Scheduled',
            // count: data?.counts
          })
        }
      }

      setIsLoadingLeads(false)

    } catch (error) {
      setIsLoadingLeads(false)
    }
  }

  console.log({
    activeTab
  });

  // Fetch data by status
  const fetchLeadsByStatus = async (status, date = "", persons = [], searchString = "") => {
    try {

      setIsLoadingLeads(true);
      const body = {
        "date": date,
        "page": 1,
        "currentStatus": status,
        "persons": persons,
        "searchString": searchString
      }

      const response = await axiosInstance.post(ApiEndpoints.STAGE.GET_STAGE, body);
      const { data } = response;
      setIsLoadingLeads(false)
      return data;
    } catch (error) {
      setIsLoadingLeads(false)

    }
  }

  const updateLeadStatus = async (status) => {
    setIsLoadingUpdate(true);

    try {
      console.log({ status });

      // Construct request body
      const body = {
        requestId,
        currentStatus: status
      };

      // Update status via API
      const response = await axiosInstance.put(ApiEndpoints.STAGE.UPDATE_STATUS, body);
      const { data } = response;
      setModal(false);

      console.log({ data });

      const getDate = () => {
        if (status === "Scheduled") {
          return selectedScheduleDate;
        } else if (["Lost", "Canceled", "Won", "Future Follow-up"].includes(status)) {
          return dateForMonthStages;
        } else {
          return new Date();
        }
      };


      if (data?.result) {
        // Fetch and update leads based on the new status
        const responseData = await fetchLeadsByStatus(status, getDate());

        setActiveTab((prevState) => ({
          ...prevState,
          name: status,
          data: responseData?.result || []
        }));

        // Determine the date based on the status


        const date = getDate();
        console.log({ otherTab: date });

        // Fetch and update lead count based on status and date
        await fetchLeadsByStatusCount("", date, [], '', true);
      }

    } catch (error) {
      console.error('Error updating lead status:', error);
      toast.error('Something went wrong!');
    } finally {
      setIsLoadingUpdate(false);
    }
  };

  const handleFollowDateChange = async (requestId, date) => {
    const formattedDate = moment(new Date(date)).format("MM/DD/YYYY")
    console.log({ requestId, date, formattedDate });
    await updateFollowUpdate(formattedDate, 'Sent/Follow-Up', requestId)
  }
  const updateFollowUpdate = async (date, status, requestId) => {
    try {
      console.log({
        status
      });
      serIsLoadingLeadFollowUp(true);

      setMsg("Please wait! While follow up is being updated!");
      setMsgType("info");

      const body = {
        "requestId": requestId,
        "currentStatus": status,
        "followUpDate": date
      }

      const response = await axiosInstance.put(ApiEndpoints.STAGE.UPDATE_FOLLOW_UP_STATUS, body);
      const { data } = response;

      console.log({
        data
      });
      if (data?.result) {
        const responseData = await fetchLeadsByStatus(status)

        setActiveTab((prevState) => ({
          ...prevState,
          name: status,
          data: responseData?.result || []
        }))
      }

      serIsLoadingLeadFollowUp(false)
      setMsg();
      setMsgType("");

      return data;
    } catch (error) {
      serIsLoadingLeadFollowUp(false)
      // toast.error('Something went wrong!')
      setMsg("Something went wrong!");
      setMsgType("error");
    }
  }

  const changeTab = async (tab, i) => {
    const todaysDate = new Date();
    setSelectedScheduleDate(todaysDate);
    setDateForMonthSatges(todaysDate);
    const persons = selectPerson?.map(sp => sp?.value);
    try {

      const newData = await fetchLeadsByStatus(tab, todaysDate.toISOString(), persons);
      console.log({
        newData
      });

      if (newData?.result) {
        setActiveTab({
          name: tab,
          index: i,
          data: newData.result || []
        });

      }
    } catch (error) {
      toast.error("Error fetching leads by status");
    }
  };

  const updateStaus = (id, type) => {
    console.log({
      id, type
    });

    if (type === 'no-acted-upon') {
      setSalesStage(prevStage => ['Unscheduled', ...prevStage])
    }
    // if (paymentStatus !== "Frozen 1") {
    setModal(true);
    setRequestId(id);
    // }
  };

  console.log({
    requestId
  });
  const onHide = () => {
    setModal(false);
    setRequestId("");
  };

  console.log({
    activeTab: activeTab
  });

  const RedirectTo = (path, leadRequest) => {
    console.log({
      leadRequest,
      path
    });
    props.history.push({
      pathname: `/dashboard/metrics/${path}/${leadRequest}`
    });
  };

  const displayTabItem = (data, type = '') => {
    console.log({
      data123: data
    });
    let visitDate = "";

    if (data?.month) {
      visitDate += `${data?.month}/`;
    }

    if (data?.day) {
      visitDate += `${data?.day}/`;
    }

    if (data?.year) {
      visitDate += `${data?.year}`;
    }

    let followUpDate = "";
    if (data?.followUpDate) {
      followUpDate = moment(new Date(data?.followUpDate)).format("MM/DD/YYYY");
    }

    if (data?.expired && data?.currentStatus === "Proposals Due") {
     const latestRecord = getLatestStatusRecordByStatus(data?.statusHistory, "Proposals Due")
      console.log({
        latestRecord
      });
      data.scheduledDate = latestRecord?.date;
    };

    const cardData = {
      id: data._id,
      name: `${data.leadFirstName} ${data.leadLastName}`,
      address1: "",
      fullAdd: data.address,
      time: data.schedules,
      created: data.createdAt,
      project: data.property_list,
      sales: data.schedules,
      roof: true,
      gutters: data.hasGutters,
      // isChanging: isChanging,
      updatedVisible: data.updatedVisible,
      updatedOn: data.updatedAt,
      updatedText: data.updatedText,
      month: data?.month,
      date: data?.date,
      year: data?.year,
      leadRequest: {
        _id: data?._id,
        createdOn: data?.createdAt,
        startTime: data?.schedules?.startTime,
        endTime: data?.schedules?.endTime,
        hasGutters: data?.hasGutters,
        hasRoof: data?.hasRoof,
        followUpDate: followUpDate,
        personDetails: {
          firstName: data?.schedules?.personDetails?.firstName,
          lastName: data?.schedules?.personDetails?.lastName,
        }
      },
      typeOfCard: type,
      personDetails: data?.personDetails,
      followUpDate: followUpDate,
      // changeStage: () => changeStage(data._id),
      // allowStageChange: salesStages.showInChange,
      // datePicked: datePicked,
      // handleDateChange: handleDateChange,
      RedirectTo: (path, data) => RedirectTo(path, data),
      data: data,
      updateStaus: updateStaus,
      handleFollowDateChange: handleFollowDateChange,
      // stageBeforeData: stageBeforeData,
      // updateStausBefore: updateStaus,
      // paymentStatus: paymentStatus,
      startTime: data?.startTime,
      endTime: data?.endTime,
      companyName: data.leadAddress,
      leadAddressData: data.leadAddress
    };

    console.log({
      cardData
    });


    if (allData?.length) {
      return (
        <div>
          <SalesCard {...cardData} />
        </div>
      )
    }

  }


  // Create a debounced function
  const debouncedFunction = useCallback(
    _.debounce((value) => {
      // Handle the debounced logic here, e.g., API call
      const persons = salesPersons?.map((person) => person?.value)
      fetchLeadsByStatus(activeTab.name, '', persons, value);
      fetchLeadsByStatusCount("", selectedScheduleDate, persons, value, true)
    }, 600), // Adjust the debounce delay as needed
    []
  );

  // Handle input change
  const handleChange = (e) => {
    const { value } = e.target;
    setSearchString(value);
    debouncedFunction(value);
  };


  const renderEmptyTab = () => {
    let msg = "";
    console.log({
      activeTab: activeTab.name
    });
    switch(activeTab.name) {
      case "Scheduled":
        msg = "No Scheduled Visits";
        break;
      case "Proposals Due":
        msg = "No Proposals Due";
        break;
      case "Sent/Follow-Up":
        msg = "No Scheduled Follow-Ups";
        break;
      case "Won":
        msg = "No Won Projects";
        break;
      case "Future Follow-up":
        msg = "No Future Scheduled Follow-ups";
        break;
      case "Lost":
        msg = "No Lost Projects";
        break;
      case "Canceled":
        msg = "No Canceled Projects";
        break;
      default:
        msg = "No data found";
    }
    return (
      <div className="empty-_tab">
        <p>{msg}</p>
      </div>
    );
  }


  const dateChange = async (date) => {
    const persons = selectPerson?.map((person) => person?.value)

    if (typeof date === "string") {
      date = convertMonthYearToDate(date).toISOString();
      console.log({
        test123: date
      });

      setDateForMonthSatges(new Date(date))
    }

    if (typeof date === "object") {
      setSelectedScheduleDate(date)
      date = new Date(date).toISOString();
      // Without waiting call the count
      fetchLeadsByStatusCount(activeTab.name, date, persons, '', true)
      console.log({
        date
      });
    }

    const filterResult = await fetchLeadsByStatus(activeTab.name, date, persons);

    const newAllData = allData.map((lead) => {
      if (lead.status === activeTab.name) {
        lead.data = filterResult;
      }
      return lead;
    });


    // Update only the 'data' property
    setActiveTab(prevState => ({
      ...prevState,
      data: filterResult?.result
    }));


    setAllData(newAllData);

  }

  const displayPersons = (persons) => {
    let Persons = [];
    persons.map(({ personId, personName }) => {
      return Persons.push({
        value: personId,
        label: personName,
      });
    });
    return Persons;
  };


  async function handleChangeSalesPerson(value, persons = []) {
    console.log({
      value
    });
    setSelectPerson(value);
    const responseData = await fetchLeadsByStatus(activeTab.name, selectedScheduleDate.toISOString(), persons)
    fetchLeadsByStatusCount("", selectedScheduleDate, persons, '', value?.length ? true : false)
    fetchNotActedOpenData(persons);
    console.log({
      responseData
    });

    setActiveTab((prevState) => ({
      ...prevState,
      name: activeTab?.name,
      data: responseData?.result || []
    }));

  }



  const renderFilter = () => {
    if (activeTab.name !== "Scheduled" && (activeTab.name !== "Proposals Due" && activeTab.name !== "Sent/Follow-Up")) {
      return (
        <Row style={{ marginRight: '5px' }}>
          <Col
            style={{
              margin: "1em auto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <div>
              <h6>Filter By Month</h6>
              <MonthPickerInput
                year={dateForMonthStages.getFullYear()}
                month={dateForMonthStages.getMonth()}
                onChange={dateChange}
              />
            </div>
          </Col>
        </Row>
      );
    }

    if (activeTab.name === "Scheduled")

      return (
        <div className="date_time">
          <div className="time">
            <Clock />
          </div>
          <div className="day_date">
            {selectedScheduleDate && (
              <div className="day">
                {moment(selectedScheduleDate).format("dddd")}
              </div>
            )}
            <div className="date-picker">
              <DatePicker
                value={selectedScheduleDate}
                onChange={dateChange}
                showLeadingZeros={true}
              />
            </div>
          </div>
        </div>
      )

  };

  return (
    <div
      style={{
        margin: "2em 1.5em",
        position: "relative",
        backgroundColor: '#f2f2f2'
      }}
    >
      {msg && <Alert variant={msgType}>{msg}</Alert>}
      <Row style={{ alignItems: "center" }}>
        <Col md="2">
          <h3 className="_title">
            Sales Activity &nbsp; &nbsp;
            <Link
              to={{
                pathname: "/dashboard/help",
                search: "Activity Sales",
              }}
              target="_blank"
              rel="noreferrer"
              style={{ cursor: "pointer" }}
            >
              <span style={{ color: "red" }}>?</span>
            </Link>
          </h3>
        </Col>

        <Col md="6" className="d-flex align-items-center">
          <SearchField
            type="search"
            placeholder="Search for sale"
            value={searchString}
            name="searchString"
            onChange={(e) => handleChange(e)}
            sm
          // disabled={paymentStatus == "Frozen 1" ? true : false}
          // paymentStatus={paymentStatus}
          />

        </Col>

        <Col md="4">
          <Form.Group style={{ margin: "0 4em" }}>
            <Select
              isMulti
              placeholder="View activity by sales persons"
              name="selectPerson"
              // value={selectPerson}
              onChange={
                (value) => {
                  const persons = value?.map((person) => person?.value)
                  handleChangeSalesPerson(value, persons);
                }
                // setState(() => ({ selectPerson: value }))
              }
              options={displayPersons(salesPersons)}
            // isDisabled={paymentStatus === "Frozen 1"}
            />
          </Form.Group>
        </Col>

      </Row>
      <Row>
        <Col>
          {salesStages && salesStages.length > 0 && (
            <>
              <Tabs
                height={""}
                tabs={salesStages}
                count={saleStatusCountData}
                active={activeTab.name}
                setTab={changeTab}
                allData={allData}
                countData={countData}
              />
              <div className="_tab-content">
                {renderFilter()}

                {!isLoadingLeads ? (
                  <>

                    {
                      activeTab?.data?.length && !isLoadingLeads ? (
                        activeTab?.data?.map((data) => (
                          displayTabItem(data)
                        ))
                      ) : (
                        renderEmptyTab()
                      )
                    }


                  </>
                ) : (
                  // <InitialLoader onReload={getAllLeads} />
                  <InitialLoader />
                )}

                {
                  activeTab.name === "Scheduled" ? (
                    <div>
                      <h5 h5 style={{ margin: "7% 2% 3%" }}>Appointments Not Acted Upon</h5>
                      {
                        (!isLoadingLeads && notActedLeads?.length) ? (
                          notActedLeads?.map((data) => (
                            displayTabItem(data, 'no-acted-upon')
                          ))

                        ) : null
                      }
                    </div>
                  ) : null
                }


              </div>


            </>
          )}
        </Col>
      </Row>

      <PopupModal show={modal} onHide={onHide} heading="Select Sales Stage">
        <Row style={{ margin: "1em" }}>
          <div style={{ textAlign: 'center', marginBottom: '1rem' }}>
            {
              isLoadingLeadUpdate ? (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              ) : null
            }
          </div>
          {salesStages.map((stage, index) => (
            <button
              disabled={activeTab.name === stage}
              key={stage}
              onClick={() =>
                updateLeadStatus(stage)
              }
              className="stage"
            >
              {stage}
            </button>
          ))}
        </Row>
        <Modal.Footer>
          <Button variant="danger" onClick={onHide}>
            Cancel
          </Button>
        </Modal.Footer>
      </PopupModal>
    </div>
  );
  // }
};


export default SalesActivity;
